import General from "Helpers/General";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

export class WeeklyDealsProductList extends Component {
  render() {
    const { product, startTime, endTime, discount } = this.props.product;
    return (
      <div className="col-lg-12 listside_newperent">
        <div className="product-listing-product">
          <div className="row">
            <div className="col-3">
              <div className="product-img-bx">
                <Link to={`/product/${product.productSlug}`}>
                  {product.adType && product.adType === "Featured" && (
                    <div className="specialtags">
                      <span
                        className={
                          (product.adType === "Featured"
                            ? "black"
                            : "green") + "_special"
                        }
                      >
                        {product.adType}{" "}
                      </span>
                    </div>
                  )}
                  {product.productMainImage ? (
                    <img
                      src={General.showImage(
                        "products/resize-180-180",
                        product.productMainImage
                      )}
                      alt={product.productName}
                    />
                  ) : (
                    <img src="/images/no-image.jpg" alt="product" />
                  )}

                  <div className="totalimg_sectab">
                    <Link
                      to={`/product/${product.productSlug}`}
                      className="totlimg_qt"
                    >
                      {product.productImages ? product.productImages.length : 0}{" "}
                      <i className="fas fa-camera"></i>
                    </Link>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-9">
              <div className="product-outer-bx productBig-outer-bx">
                <div className="product-name-bx content_gobottom_new">
                  <Moment fromNow>{product.time}</Moment>
                  <Link to={`/product/${product.productSlug}`}>
                    <h4>{product.productName}</h4>
                  </Link>
                  <h5>{product.location && product.location}</h5>
                  <h6>
                    <span>{General.showPriceDecimal(product.productPrice)}</span>
                  </h6>
                  <ul className="listofsubtabbx">
                    <li>
                      <strong>Start</strong>
                      <span>
                        <Moment format="YYYY/MM/DD">{startTime}</Moment>
                      </span>
                    </li>
                    <li>
                      <strong>Expires</strong>
                      <span>
                        <Moment format="YYYY/MM/DD">{endTime}</Moment>
                      </span>
                    </li>
                  </ul>
                  <div className="subtab_edit">
                    <div className="subtab_editlable">
                      <div class="dropdown">
                        <div id="dropdownAdActionButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="fas fa-ellipsis-v"></i></div>
                          <div class="dropdown-menu dropdown-ad-actions" aria-labelledby="dropdownAdActionButton">
                            <Link
                              data-toggle="tooltip"
                              data-placement="top"
                              title=""
                              className="dropdown-item"
                              onClick={(e) =>
                                this.props.deleteProductHandler(e, product._id)
                              }
                            >
                            Delete
                            </Link>
                          </div>
                        </div>
                      {/* <Link
                        data-toggle="tooltip"
                        data-placement="top"
                        title=""
                        data-original-title="Edit"
                      >
                        <i className="fas fa-pencil-alt"></i>
                      </Link> */}
                      
                      {/* <Link
                        data-toggle="tooltip"
                        data-placement="top"
                        title=""
                        data-original-title="View"
                      >
                        <i
                          className={
                            product.active === true
                              ? "far fa-eye"
                              : "far fa-eye-slash"
                          }
                        ></i>
                      </Link> */}
                    </div>
                    {/* <p className="subtab_editlabl">
                      <span className="labltable lablesct">
                        {product.active === true ? "Active" : "Inactive"}
                      </span>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WeeklyDealsProductList;
