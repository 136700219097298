import Model from "Models/Model";

class ScheduleCall extends Model{

	dbSchema(){
	    return {
	      _id:"", scheduleDate:"", scheduleTime:"",from_user_id:"",to_user_id:"",from_qb_user_id:"",to_qb_user_id:"",callNotPicked:"",username:""
	    };

	}
}

export default new ScheduleCall();