import Model from "Models/Model";

class SubCategory extends Model{

  dbSchema(){
    return {
      _id:"", firstName:"", lastName:"", email:"", mobileNumber:"", password:""
    };
  }
}

export default new SubCategory();
