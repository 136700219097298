import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import RoutingOfComponent from "Helpers/RoutingOfComponent";
import "react-responsive-carousel/lib/styles/carousel.min.css";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {Object.keys(RoutingOfComponent).map(function (key) {
            var Routing = RoutingOfComponent[key];
            return (
              <Routing.layoutName
                path={`/${key}`}
                component={Routing.componentName}
                key={key}
                {...Routing}
              />
            );
          })}
        </Switch>
      </Router>
    );
  }
}

export default App;
