import React from "react";
import { Route } from "react-router-dom";
import Header from "Partials/Header";
import Footer from "Partials/Footer";
import Toastr from "Partials/Toastr";
import SimpleBottomNavigation from "Components/Users/BottomNavigation";

const Default = ({ children }) => (
  <div>
    <Header />
    <SimpleBottomNavigation/>
    <Toastr />
    {children}
    <Footer />
  </div>
);

const DefaultRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Default>
          <Component {...matchProps} />
        </Default>
      )}
    />
  );
};

export default DefaultRoute;
