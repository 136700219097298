import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import Order from "Models/Order";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Modal from 'react-modal';
import DatePicker from 'react-date-picker';
import ReturnModalComponent from './ReturnModalComponent';
import QB from "Helpers/Quickblox";
//import {ORDER_POST,SHIPPED,DELIVER,ACCEPT,DISPUTE,RETURN,CANCEL} from 'Helpers/Constant';

//Modal.setAppElement('#test');
export class MyAchievementComponent extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }

  render() {

    const {
      key,
      product,
      deleteProductHandler,
      publishProductHandler,
    } = this.props;

    return (
      <>
      <div className="col-lg-12 listside_newperent mb-2 px-3">

        <div class="background-color">
            <div className="product-listing-product desktop_view">
              <div className="row">
                <div className="col-12 col-sm-3">
                  <div className="product-img-bx">
                    <Link to={`/product/${product?.productSlug}`}>
                      {product?.adType && product?.adType === "Featured" && (
                        <div className="specialtags">
                          <span
                            className={
                              (product.adType === "Featured" ? "black" : "green") +
                              "_special"
                            }
                          >
                            {product.adType}{" "}
                          </span>
                        </div>
                      )}
                      {product?.productMainImage ? (
                        <img
                          src={General.showImage(
                            "products/resize-180-180",
                            product.productMainImage
                          )}
                          onError={
                              (e) => e.target.src = '/images/no-image.jpg' 
                          }
                          alt={product.productName}
                        />
                      ) : (
                        <img src="/images/no-image.jpg" alt="product" />
                      )}

                      <div className="totalimg_sectab">
                          {product?.productImages
                            ? product.productImages.length
                            : 0}{" "}
                          <i className="fas fa-camera"></i>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-12 col-sm-9 my-productColBg">
                  <div className="product-outer-bx productBig-outer-bx">
                    <div className="product-name-bx content_gobottom_new prdNewRow_row btn_group_box">
                      <div className="newPrdList_col-1">
                          {/*<Moment fromNow>{product?.time}</Moment>*/}
                          <Link to={`/product/${product?.productSlug}`} className="d-flex">
                            <h4>{product?.productName}</h4>
                          </Link>
                          <h5>{product?.location}</h5>
                          <h6>
                            <span>{General.showPriceDecimal(product?.productPrice)}</span>
                          </h6>
                          <ul className="listofsubtabbx">
                            <li>
                              <strong>Added</strong>
                              <span>
                                <Moment format="YYYY/MM/DD">{product?.time}</Moment>
                              </span>
                            </li>
                            {product?.planId && (
                              <li>
                                <strong>Expires</strong>
                                <span>
                                  <Moment
                                    add={{ days: product.planId.duration }}
                                    format="YYYY/MM/DD"
                                  >
                                    {product.time}
                                  </Moment>
                                </span>
                              </li>
                            )}
                          </ul>
                      </div>
                      <div className="subtab_edit newPrdList_col-2 right_section">
                          <span className="labltable lablesct ml-2 bg-danger ">seller</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-listing-product mobile_view position-relative">
              
                <div className="product_listing_product_block">
                  <div className="product-img-bx">
                    <Link to={`/product/${product?.productSlug}`}>
                      {product?.adType && product?.adType === "Featured" && (
                        <div className="specialtags">
                          <span
                            className={
                              (product.adType === "Featured" ? "black" : "green") +
                              "_special"
                            }
                          >
                            {product.adType}{" "}
                          </span>
                        </div>
                      )}
                      {product?.productMainImage ? (
                        <img
                          src={General.showImage(
                            "products/resize-180-180",
                            product.productMainImage
                          )}
                          onError={
                              (e) => e.target.src = '/images/no-image.jpg' 
                          }
                          alt={product.productName}
                        />
                      ) : (
                        <img src="/images/no-image.jpg" alt="product" />
                      )}

                      <div className="totalimg_sectab">
                          {product?.productImages
                            ? product.productImages.length
                            : 0}{" "}
                          <i className="fas fa-camera"></i>
                      </div>
                    </Link>
                  </div>
                  <div className="product-outer-bx productBig-outer-bx">
                    <div className="product-name-bx content_gobottom_new prdNewRow_row btn_group_box">
                      <div className="newPrdList_col-1">
                          {/*<Moment fromNow>{product?.time}</Moment>*/}
                          <Link to={`/product/${product?.productSlug}`} className="d-flex">
                            <h4>{product?.productName}</h4>
                          </Link>
                          <h5>{product?.location}</h5>
                          <h6>
                            <span>{General.showPriceDecimal(product?.productPrice)}</span>
                          </h6>
                          
                      </div>
                     
                    </div>
                  </div>
                </div>
                <div className="date_box">
                       <ul className="listofsubtabbx">
                            <li>
                              <strong>Added</strong>
                              <span>
                                <Moment format="YYYY/MM/DD">{product?.time}</Moment>
                              </span>
                            </li>
                            {product?.planId && (
                              <li>
                                <strong>Expires</strong>
                                <span>
                                  <Moment
                                    add={{ days: product.planId.duration }}
                                    format="YYYY/MM/DD"
                                  >
                                    {product.time}
                                  </Moment>
                                </span>
                              </li>
                            )}
                          </ul> 
                      
                </div>
                 <div className="subtab_edit newPrdList_col-2 right_section">
                          <span className="labltable lablesct ml-2 bg-danger ">seller</span>
                      </div>
              

              
            </div>
        </div>
      </div>
      </>
    );
  }
}

export default MyAchievementComponent;
