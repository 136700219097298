import React, { Component } from "react";
import General from "Helpers/General";
import Product from "Models/Product";
import SubCategorySearch from "Models/SubCategorySearch";
import Category from "Models/Category";
import SubCategory from "Models/SubCategory";
import { Link } from "react-router-dom";
import Fields from "Models/Fields";
import SlideToggle from "react-slide-toggle";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContentLoader from "react-content-loader";
import { Rating } from 'react-simple-star-rating';
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
var qs = require("qs");

class SubCategoryOtherList extends Component {
  constructor(props) {
    super(props);
    const subCategoryId = props.subCategoryId;
    const subCategoryListLS = "subCategoryList_" + subCategoryId;
    const allSubCatfilterProductsLS =
      "allSubCatfilterProducts_" + subCategoryId;
    const singleCategoryDataLS = "singleCategoryData_" + subCategoryId;
    const totalRecordsLS = "totalRecords_" + subCategoryId;
    const categoryNameLS = "categoryName_" + subCategoryId;
    const categoriesLoopData = localStorage.getItem("categoriesLoopData")
      ? JSON.parse(localStorage.getItem("categoriesLoopData"))
      : [];
    const fieldsLoopData = localStorage.getItem("fieldsLoopData")
      ? JSON.parse(localStorage.getItem("fieldsLoopData"))
      : [];

    const subCategoriesListLS = localStorage.getItem(subCategoryListLS)
      ? JSON.parse(localStorage.getItem(subCategoryListLS))
      : [];

    const categoryFieldData = localStorage.getItem("CategoriesFieldData")
      ? JSON.parse(localStorage.getItem("CategoriesFieldData")).find((cat) => {
        return cat._id === props.categoryId;
      })
      : null;

    const subCatLists = localStorage.getItem("PostCategories")
      ? Object.assign(
        {},
        ...JSON.parse(localStorage.getItem("PostCategories")).filter(
          (cat) => cat._id === props.categoryId
        )
      )
      : "";

    const subCatData = localStorage.getItem("PostsubCategories")
      ? JSON.parse(localStorage.getItem("PostsubCategories")).find(
        (sub) => sub._id === subCategoryId
      )
      : null;

    const ChildCatData = subCatLists?.subCatData?.find(
      (subCat) => subCat._id === subCategoryId,
    );

    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      subCategoryFilers: SubCategorySearch.dbSchema(),
      subCategoryCustomFilers: {},
      subCategoryId: subCategoryId,
      subCatData: subCatData,
      childCatData:
        ChildCatData && ChildCatData.childCatData
          ? ChildCatData.childCatData
          : [],
      selectedChildCategory: (this.props.sctid) ? this.props.sctid : "",
      singleSubCategoryData: localStorage.getItem(singleCategoryDataLS)
        ? JSON.parse(localStorage.getItem(singleCategoryDataLS))
        : [],
      mainCategory: subCatLists,
      subCategoryList: subCatLists ? subCatLists.subCatData : [],
      allCustomFields: [],
      allSubCatfilterProducts: localStorage.getItem(allSubCatfilterProductsLS)
        ? JSON.parse(localStorage.getItem(allSubCatfilterProductsLS))
        : [],
      totalRecords: localStorage.getItem(totalRecordsLS)
        ? localStorage.getItem(totalRecordsLS)
        : "0",
      categoryName: localStorage.getItem(categoryNameLS)
        ? localStorage.getItem(categoryNameLS)
        : "",
      productCountBySubCat: [],
      categorySlug: "",
      showHideListData: false,
      showHideGridData: true,
      nothingD: false,
      productSkeleton: [
        { id: 0 },
        { id: 340 },
        { id: 680 },
        { id: 1020 },
        { id: 1360 },
      ],
      loadMoreSubCat: false,
      loadAllSubCatBtn: true,
      address: "",
      isLoading: false
    };

    // Bind the handlers
    this.handleSubCatMinPrice = this.handleSubCatMinPrice.bind(this);
    this.handleSubCatMaxPrice = this.handleSubCatMaxPrice.bind(this);

    // Create debounced versions of the handlers
    this.debouncedHandleSubCatMinPrice = General.debounce(this.handleSubCatMinPrice, 500);
    this.debouncedHandleSubCatMaxPrice = General.debounce(this.handleSubCatMaxPrice, 500);

    this.hideComponent = this.hideComponent.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handlePriceData = this.handlePriceData.bind(this);
  }

  hideComponent(name) {
    switch (name) {
      case "showHideListData":
        this.setState({ showHideListData: !this.state.showHideListData });
        this.setState({ showHideGridData: false });
        break;
      case "showHideGridData":
        this.setState({ showHideGridData: !this.state.showHideGridData });
        this.setState({ showHideListData: false });
        break;
      default:
        this.setState({ showHideListData: !this.state.showHideListData });
        break;
    }
  }

  componentDidMount = () => {
    var self = this;
    let subCategoryId = this.state.subCategoryId;
    const singleCategoryDataLS =
      "singleCategoryData_" + self.state.subCategoryId;
    const categoryNameLS = "categoryName_" + self.state.subCategoryId;
    SubCategory.postApi(
      "APIS_GET_SUB_CATEGORY_DETAIL_BY_Id",
      { params: { subCategoryId } },
      async (response) => {
        localStorage.setItem(singleCategoryDataLS, JSON.stringify(response));
        localStorage.setItem(
          categoryNameLS,
          response?.categoryId?.categoryName
        );
        self.setState({ singleSubCategoryData: response });
        self.setState({ categoryName: response?.categoryId?.categoryName });
        self.setState({ categorySlug: response?.categoryId?.slug });
        let { subCategoryFilers } = this.state;
        subCategoryFilers["subCategoryId"] =
          this.state.singleSubCategoryData._id;

        if (this.state.selectedChildCategory) {
          subCategoryFilers["thirdCategory"] = this.state.selectedChildCategory;
        }


        if (this.props.ctid && this.props.keytype) {
          let { subCategoryCustomFilers } = this.state;
          subCategoryCustomFilers["customFieldData." + this.props.keytype] = this.props.ctid;
          subCategoryFilers["customFields"] = subCategoryCustomFilers;
        }

        self.setState({ subCategoryFilers: subCategoryFilers });
        this.getDataByfilters();
        this.getSubCategoriesByCatId();
      },
      false
    );

    if (this.state.selectedChildCategory) {
      subCategoryId = this.state.selectedChildCategory;
    }
    this.getFieldsValuesData(subCategoryId);
    this.getStaticSettingData();

    const overlayElement = document.querySelector('.nodataOverlay');
    if (overlayElement) {
      overlayElement.addEventListener('click', this.handleOverlayClick);
    }
  };

  handleOverlayClick() {
    document.body.classList.remove('filter-open');
    this.setState({ openProductFilter: false });
  }

  componentWillUnmount() {
    const overlayElement = document.querySelector('.nodataOverlay');
    if (overlayElement) {
      overlayElement.removeEventListener('click', this.handleOverlayClick);
    }
  }

  getStaticSettingData() {
    Category.getApiByParams(
      "APIS_GET_SETTING_DATA_BY_TITLE",
      "categories",
      async (response) => {
        localStorage.setItem("categoriesLoopData", JSON.stringify(response));
        //self.setState({PostCategories:response});
        //self.setState({showFirstCat:false});
      },
      false
    );
    Category.getApiByParams(
      "APIS_GET_SETTING_DATA_BY_TITLE",
      "fields",
      async (response) => {
        localStorage.setItem("fieldsLoopData", JSON.stringify(response));
        //self.setState({PostCategories:response});
        //self.setState({showFirstCat:false});
      },
      false
    );
  }

  getFieldsValuesData(id) {
    var self = this;
    Fields.getApiByParams(
      "APIS_GET_FIELDS_VALUES_FILTERS",
      id,
      async (response) => {
        self.setState({ allCustomFields: response });
      },
      false
    );
  }

  getSubCategoriesByCatId() {
    var self = this;
    const subCategoryListLS = "subCategoryList_" + self.state.subCategoryId;
    SubCategory.getApiByParams(
      "APIS_GET_CHILD_SIBLING_CATEGORIES_BY_SUB_CAT_ID",
      this.state.subCategoryId,
      async (response, status) => {
        if (response) {
          localStorage.setItem(subCategoryListLS, JSON.stringify(response));
          // self.setState({ subCategoryList: response });
        }
      },
      false
    );
  }

  getDataByfilters() {
    var self = this;

    self.setState({ isLoading: true });

    // AutoDetect location

    /*  if (localStorage.getItem("location")) {
       let { subCategoryFilers } = this.state;
       subCategoryFilers["lat"] = JSON.parse(localStorage.getItem("location"))[
         "latitude"
       ];
       subCategoryFilers["lng"] = JSON.parse(localStorage.getItem("location"))[
         "longitude"
       ];
       this.setState({ subCategoryFilers: subCategoryFilers });
     } */

    if (this.props.lat && this.props.lng) {
      let { subCategoryFilers } = this.state;
      subCategoryFilers["lat"] = this.props.lat;
      subCategoryFilers["lng"] = this.props.lng;
      this.setState({ subCategoryFilers: subCategoryFilers });
    }
    if (this.props.keyword) {
      let { subCategoryFilers } = this.state;
      subCategoryFilers["keyword"] = this.props.keyword;
      this.setState({ subCategoryFilers: subCategoryFilers });
    }

    if (Product.isLogin()) {
      let { subCategoryFilers } = this.state;
      subCategoryFilers["user_id"] = Product.getAuthUserId();
      this.setState({ subCategoryFilers: subCategoryFilers });
    }

    this.setState({ openProductFilter: false });

    const allSubCatfilterProductsLS =
      "allSubCatfilterProducts_" + self.state.subCategoryId;
    const totalRecordsLS = "totalRecords_" + self.state.subCategoryId;
    Product.postApiByParams(
      "APIS_GET_PRODUCTS_BY_SUB_CATEGORY_FILTERS",
      this.state.subCategoryFilers,
      async (response, status) => {
        if (response.status) {
          localStorage.setItem(
            allSubCatfilterProductsLS,
            JSON.stringify(response.data)
          );
          localStorage.setItem(totalRecordsLS, response.totalRecords);
          self.setState({ allSubCatfilterProducts: response.data });
          self.setState({
            productCountBySubCat: response.productCountBySubCat,
          });
          self.setState({ totalRecords: response.totalRecords });
          self.setState({ next_page_number: response.next_page_number });
          self.setState({ limit: response.limit });
          self.setState({ nothingD: true });
        }
        self.setState({ isLoading: false });
      },
      false
    );
  }

  loadAllSubCategories() {
    const { loadMoreSubCat } = this.state;
    const { loadAllSubCatBtn } = this.state;
    this.setState({
      loadMoreSubCat: !loadMoreSubCat,
      loadAllSubCatBtn: !loadAllSubCatBtn,
    });
  }

  getDatabyPageno(next_page_number) {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["page_number"] = next_page_number;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  }

  getFltersdataFetch = (event) => {
    var keyType = event.currentTarget.dataset.name;
    var fValue = "";
    if (event.currentTarget.dataset.type == "Dropdown") {
      var fValue = event.currentTarget.dataset.id;
    } else {
      var fValue = event.target.value;
    }
    var self = this;
    let { subCategoryCustomFilers } = this.state;

    if (subCategoryCustomFilers["customFieldData." + keyType] === undefined) {
      subCategoryCustomFilers["customFieldData." + keyType] = fValue;
    } else {
      delete subCategoryCustomFilers["customFieldData." + keyType];
    }

    let { subCategoryFilers } = this.state;
    subCategoryFilers["customFields"] = subCategoryCustomFilers;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
    this.handleOverlayClick();
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      const address = results[0].formatted_address;

      var self = this;
      self.setState({ address });

      let { subCategoryFilers } = this.state;
      subCategoryFilers["location"] = address;
      self.setState({ subCategoryFilers: subCategoryFilers });

      getLatLng(results[0])
        .then((latLng) => {
          var self = this;
          let { subCategoryFilers } = this.state;
          subCategoryFilers["lat"] = latLng.lat;
          subCategoryFilers["lng"] = latLng.lng;
          self.setState({ subCategoryFilers: subCategoryFilers });

          // this.getDataByfilters();
        })
        .catch((error) => console.error("Error", error));
    });
  };

  handleRadiusFilter = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["radius"] = event.target.value;
    self.setState({ subCategoryFilers: subCategoryFilers });
  };

  // handleSubCatLocation = event => {
  //   var self = this;
  //   let {subCategoryFilers} = this.state;
  //   subCategoryFilers['location'] = event.target.value;
  //   self.setState({ subCategoryFilers: subCategoryFilers});
  //   this.getDataByfilters();
  // };

  handleSubCatKeyword = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["keyword"] = event.target.value;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  };

  handleFilterSorting = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["sortType"] = event.target.value;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
    this.handleOverlayClick();
  };

  handleSubCatMinPrice = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["min_price"] = event.target.value;
    self.setState({ subCategoryFilers: subCategoryFilers });
    // this.getDataByfilters();
  };

  handleCatNegotiation = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["avaliableNegotiation"] = event.target.checked;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
    this.handleOverlayClick();
  };

  handleExchange = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["avaliableExchange"] = event.target.checked;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
    this.handleOverlayClick();
  };

  handleDeliveryOptionSorting = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers[event.target.name] = event.target.checked;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
    this.handleOverlayClick();
  };

  handleLeasing = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["availableLeasing"] = event.target.checked;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  };

  handleSubCatMaxPrice = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["max_price"] = event.target.value;
    self.setState({ subCategoryFilers: subCategoryFilers });
    // this.getDataByfilters();
  };

  handlePriceData = () => {
    this.getDataByfilters();
    this.handleOverlayClick();
  }

  handleCatAdType(adType) {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["adType"] = adType;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  }

  handleCatSellerType(sellerType) {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["sellerType"] = sellerType;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  }

  handleCatrecruiterType(recruiterType) {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["recruiterType"] = recruiterType;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  }

  handleCatproviderType(providerType) {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["providerType"] = providerType;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  }

  handleThirdCategory = (event, id) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["thirdCategory"] = id;
    self.setState({
      subCategoryFilers: subCategoryFilers,
      selectedChildCategory: id,
    });
    this.getDataByfilters();
    this.getFieldsValuesData(id);
  };

  addWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_ADD_TO_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  removeWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_REMOVE_FROM_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  getCategoryCount = (catId) => {
    if (localStorage.getItem("thirdLevelCategoriesProductCount")) {
      const catData = JSON.parse(
        localStorage.getItem("thirdLevelCategoriesProductCount")
      ).find((cat) => cat._id === catId);
      if (catData) {
        return catData.productCount;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  clearFilters = () => {
    let slug = this.props.subCategorySlug;
    Product.redirectTo(`sub-category/${slug}`);
  }

  render() {
    var context = this;
    const {
      showHideListData,
      showHideGridData,
      allSubCatfilterProducts,
      subCategoryList,
      singleSubCategoryData,
      totalRecords,
      categoryName,
      categorySlug,
      allCustomFields,
      productSkeleton,
      subCatData,
      childCatData,
      isLoading
    } = this.state;

    const { keyword, pagefrom } = this.props;

    return (
      <React.Fragment>
        {isLoading && (
          <div className="loader-wrapper" id="loader_img">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        <section className="product-header special_prheadr_new">
          <div className="container">
            <div className="row justify-content-between align-items-center ad-track">
              <div className="col-md-6 col-12">
                <div className="breadcrumb-bx">
                  <h2>
                    {totalRecords} Ads in{" "}
                    {singleSubCategoryData?.subCategoryName}
                  </h2>
                  <div className="breadcrumb-bx-inner">
                    <Link to="/" className="btnx btn-primaryx">
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </Link>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to={`/category/${categorySlug}`}>
                          {categoryName}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        {singleSubCategoryData?.subCategoryName}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-5 d-none d-md-block">
                <div className="new_placetab_icon">
                  <div className="form-group-icon-bx">
                    <select
                      className="form-control-line"
                      onChange={context.handleFilterSorting}
                    >
                      <option value="">Most Recent First</option>
                      <option value="LowToHigh">Price: Low to High</option>
                      <option value="HighToLow">Price: High to Low</option>
                      <option value="RatingLowToHigh">Rating: Low to High</option>
                      <option value="RatingHighToLow">Rating: High to Low</option>
                    </select>
                    <span className="form-group-icon">
                      <img src="/images/down-arrow-icon.png" alt="icon" />
                    </span>
                  </div>
                  <div className="showing-result">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="repeat-section product-listing-main-bx bg-light-dark forsalepage_listing">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 paddingrigt_none_Cfilter">
                <div
                  className={`product-sideBar-parent new_perenttop_forspecals ${this.state.openProductFilter ? "active" : ""
                    }`}
                >
                  <div className="product-listing-sideBar desktop_fillter" id="side-bar-fixed">
                    <div className="sidebarleft_newinner">
                      <div id="filter-closer">
                        <img
                          src="/images/cancel.svg"
                          alt="icon"
                          onClick={() =>
                            this.setState({ openProductFilter: false })
                          }
                        />
                      </div>
                      <div className="filter-clear">
                        <button
                          className="btn btn-sm"
                          onClick={() => {
                            window.location.reload()
                          }
                          }
                        >
                          Clear Filter
                        </button>
                      </div>
                      <div className="product-listing-sideBar-colum sideBar-category">
                        <h2>Category</h2>
                        <ul className="toggletab-bx">
                          <li className="innerfilt_topcat_perent">
                            <span>
                              <Link
                                to={`${pagefrom ? `/${pagefrom}/` : `/category/${this.state.mainCategory.slug}`}${keyword ? `?keyword=${keyword}` : ''}`}
                              >
                                <i className="fas fa-angle-double-left"></i>
                                {pagefrom ? "Filtered Categories" : subCatData && subCatData?.subCategoryName}
                              </Link>
                            </span>{" "}

                            <ul className="innerfilt_topcat">

                              {childCatData &&
                                childCatData.map(function (rowsubcat, i) {
                                  return i <= 3 ? (
                                    <li
                                      className={
                                        context.state.selectedChildCategory ==
                                          rowsubcat._id
                                          ? "active"
                                          : ""
                                      }
                                      key={"categorylists_1_" + rowsubcat._id}
                                    >
                                      <a
                                        href="#!"
                                        id="GoFarm_machinery"
                                        className="goclass01"
                                        name="subCategoryId"
                                        onClick={(e) =>
                                          context.handleThirdCategory(
                                            e,
                                            rowsubcat._id
                                          )
                                        }
                                      >
                                        {" "}
                                        {rowsubcat.subCategoryName} (
                                        {context.getCategoryCount(
                                          rowsubcat._id
                                        )}
                                        )
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  );
                                })}
                              {this.state.loadMoreSubCat &&
                                childCatData.map(function (rowsubcat, i) {

                                  return i > 3 ? (
                                    <li
                                      key={rowsubcat._id}
                                      className={
                                        context.state.selectedChildCategory ==
                                          rowsubcat._id
                                          ? "active"
                                          : ""
                                      }
                                    >
                                      <a
                                        // href={"/sub-category/" + rowsubcat.slug}
                                        href="#!"
                                        onClick={(e) =>
                                          context.handleThirdCategory(
                                            e,
                                            rowsubcat._id
                                          )
                                        }
                                        id="GoFarm_machinery"
                                        className="goclass01"
                                        name="subCategoryId"
                                      >
                                        {" "}
                                        {rowsubcat.subCategoryName} (
                                        {context.getCategoryCount(
                                          rowsubcat._id
                                        )}
                                        )
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  );
                                })}
                              {childCatData.length > 4 &&
                                this.state.loadAllSubCatBtn ? (
                                <li>
                                  <a
                                    href="#!"
                                    name="load_all"
                                    onClick={context.loadAllSubCategories.bind(
                                      context
                                    )}
                                  >
                                    <strong>Load All</strong>
                                  </a>
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </li>
                        </ul>
                      </div>

                      <div className="product-listing-sideBar-colum">
                        <h2>Price</h2>
                        <div className="row">
                          <div className="col-5 pr-1">
                            <div className="form-group">
                              <input
                                type="number"
                                name="min_price"
                                onChange={this.debouncedHandleSubCatMinPrice}
                                className="form-control-line"
                                placeholder="Min"
                              />
                            </div>
                          </div>
                          <div className="col-5 pr-1">
                            <div className="form-group">
                              <input
                                type="number"
                                name="max_price"
                                onChange={this.debouncedHandleSubCatMaxPrice}
                                className="form-control-line"
                                placeholder="Max"
                              />
                            </div>
                          </div>
                          <div className="col-2 px-0">
                            <button className="go-btn" onClick={context.handlePriceData}>GO</button>
                          </div>
                        </div>
                      </div>

                      {allCustomFields.map(function (row) {
                        return (
                          <React.Fragment key={"1_" + row._id}>
                            {row.fieldType == "Dropdown" ? (
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  {row.fieldName}
                                </AccordionSummary>

                                <AccordionDetails>
                                  <ul
                                    className="toggletab-bx nav nav-tabs toggle-bx category-type"
                                    role="tablist"
                                    key={row._id}
                                  >
                                    {row.fieldValues.map(function (rowfield) {
                                      return (
                                        <li
                                          onClick={context.getFltersdataFetch}
                                          data-id={rowfield._id}
                                          data-name={row.slug}
                                          data-type={row.fieldType}
                                          key={rowfield._id}
                                          className={
                                            context.state.subCategoryCustomFilers["customFieldData." + row.slug] !== undefined &&
                                              context.state.subCategoryCustomFilers["customFieldData." + row.slug] === rowfield._id
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <a href="#!">{rowfield.name}</a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </AccordionDetails>
                              </Accordion>
                            ) : (
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  {row.fieldName}
                                </AccordionSummary>
                                <AccordionDetails>
                                  <input
                                    type="text"
                                    onChange={context.getFltersdataFetch}
                                    data-id={row._id}
                                    data-name={row.slug}
                                    data-type={row.fieldType}
                                    className="form-control-line"
                                    placeholder={row.fieldName}
                                    key={row._id}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                    <div className="product-listing-sideBar-colum other-option otheropt_newcls">
                      <h2>Other Options</h2>
                      <div className="otheroption_cheperent ">
                        <div className="check-box">
                          <input
                            type="checkbox"
                            value="Yes"
                            name="AvailableBuyNow"
                            id="available-buy-now"
                            onClick={context.handleDeliveryOptionSorting}
                          />
                          <label htmlFor="available-buy-now">
                            Available for delivery
                          </label>
                        </div>
                        <div className="check-box">
                          <input
                            type="checkbox"
                            value="Yes"
                            name="AvailablePickUp"
                            id="available-pickup"
                            onClick={context.handleDeliveryOptionSorting}
                          />
                          <label htmlFor="available-pickup">
                            Available for pick up
                          </label>
                        </div>
                        {General.showWeekendDeals() &&
                          <div className="check-box">
                            <input
                              type="checkbox"
                              value={1}
                              name="weeklydeals"
                              id="weeklydeals"
                              onClick={this.handleDeliveryOptionSorting}
                            />
                            <label htmlFor="weeklydeals">
                              Weekend Deal
                            </label>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div className="product-listing-sideBar mobile_fillter" id="side-bar-fixed">
                    <div className="fillter_preant">
                      <div className="sidebarleft_newinner test2">
                        <div id="filter-closer">
                          <img
                            src="/images/cancel.svg"
                            alt="icon"
                            onClick={() => {
                              this.setState({ openProductFilter: false });
                              document.body.classList.remove('filter-open');
                            }
                            }
                          />
                        </div>
                        <div className="filter-clear">
                          <button
                            className="btn btn-sm"
                            onClick={() =>
                              this.clearFilters()
                            }
                          >
                            Clear Filter
                          </button>
                        </div>
                        {this.state.filterKeyWord === "price" && <div className="product-listing-sideBar-colum sideBar-category">
                          <h2>Price</h2>
                          <div className="row">
                            <div className="col-5 pr-1">
                              <div className="form-group">
                                <input
                                  type="number"
                                  name="min_price"
                                  onChange={this.debouncedHandleSubCatMinPrice}
                                  className="form-control-line"
                                  placeholder="Min"
                                />
                              </div>
                            </div>
                            <div className="col-5 pr-1">
                              <div className="form-group">
                                <input
                                  type="number"
                                  name="max_price"
                                  onChange={this.debouncedHandleSubCatMaxPrice}
                                  className="form-control-line"
                                  placeholder="Max"
                                />
                              </div>
                            </div>
                            <div className="col-2 px-0">
                              <button className="go-btn" onClick={context.handlePriceData}>GO</button>
                            </div>
                          </div>
                        </div>}
                        {this.state.filterKeyWord === "otherfilter" &&
                          <div className="product-listing-sideBar-colum sideBar-category">
                            {allCustomFields.map(function (row) {
                              return (
                                <React.Fragment key={"1_" + row._id}>
                                  {row.fieldType == "Dropdown" ? (
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        {row.fieldName}
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <ul
                                          className="toggletab-bx nav nav-tabs toggle-bx category-type"
                                          role="tablist"
                                          key={row._id}
                                        >
                                          {row.fieldValues.map(function (rowfield) {
                                            return (
                                              <li
                                                onClick={context.getFltersdataFetch}
                                                data-id={rowfield._id}
                                                data-name={row.slug}
                                                data-type={row.fieldType}
                                                key={rowfield._id}
                                                className={
                                                  context.state.subCategoryCustomFilers["customFieldData." + row.slug] !== undefined &&
                                                    context.state.subCategoryCustomFilers["customFieldData." + row.slug] === rowfield._id
                                                    ? "active"
                                                    : ""
                                                }
                                              >
                                                <a href="#!">{rowfield.name}</a>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </AccordionDetails>
                                    </Accordion>
                                  ) : (
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                      >
                                        {row.fieldName}
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <input
                                          type="text"
                                          onChange={context.getFltersdataFetch}
                                          data-id={row._id}
                                          data-name={row.slug}
                                          data-type={row.fieldType}
                                          className="form-control-line"
                                          placeholder={row.fieldName}
                                          key={row._id}
                                        />
                                      </AccordionDetails>
                                    </Accordion>
                                  )}
                                </React.Fragment>
                              );
                            })}
                          </div>
                        }
                        {this.state.filterKeyWord === "category" &&
                          <div className="product-listing-sideBar-colum sideBar-category">
                            <h2>Category</h2>
                            <ul className="toggletab-bx">
                              <li className="innerfilt_topcat_perent">
                                <span>
                                  <Link
                                    onClick={context.handleOverlayClick}
                                    to={`${pagefrom ? `/${pagefrom}/` : `/category/${this.state.mainCategory.slug}`}${keyword ? `?keyword=${keyword}` : ''}`}
                                  >
                                    <i className="fas fa-angle-double-left"></i>
                                    {pagefrom ? "Filtered Categories" : subCatData && subCatData?.subCategoryName}
                                  </Link>
                                </span>{" "}

                                <ul className="innerfilt_topcat">

                                  {childCatData &&
                                    childCatData.map(function (rowsubcat, i) {
                                      return i <= 3 ? (
                                        <li
                                          onClick={context.handleOverlayClick}
                                          className={
                                            context.state.selectedChildCategory ==
                                              rowsubcat._id
                                              ? "active"
                                              : ""
                                          }
                                          key={"categorylists_1_" + rowsubcat._id}
                                        >
                                          <a
                                            href="#!"
                                            id="GoFarm_machinery"
                                            className="goclass01"
                                            name="subCategoryId"
                                            onClick={(e) =>
                                              context.handleThirdCategory(
                                                e,
                                                rowsubcat._id
                                              )
                                            }
                                          >
                                            {" "}
                                            {rowsubcat.subCategoryName} (
                                            {context.getCategoryCount(
                                              rowsubcat._id
                                            )}
                                            )
                                          </a>
                                        </li>
                                      ) : (
                                        ""
                                      );
                                    })}
                                  {this.state.loadMoreSubCat &&
                                    childCatData.map(function (rowsubcat, i) {

                                      return i > 3 ? (
                                        <li
                                          onClick={context.handleOverlayClick}
                                          key={rowsubcat._id}
                                          className={
                                            context.state.selectedChildCategory ==
                                              rowsubcat._id
                                              ? "active"
                                              : ""
                                          }
                                        >
                                          <a
                                            // href={"/sub-category/" + rowsubcat.slug}
                                            href="#!"
                                            onClick={(e) =>
                                              context.handleThirdCategory(
                                                e,
                                                rowsubcat._id
                                              )
                                            }
                                            id="GoFarm_machinery"
                                            className="goclass01"
                                            name="subCategoryId"
                                          >
                                            {" "}
                                            {rowsubcat.subCategoryName} (
                                            {context.getCategoryCount(
                                              rowsubcat._id
                                            )}
                                            )
                                          </a>
                                        </li>
                                      ) : (
                                        ""
                                      );
                                    })}
                                  {childCatData.length > 4 &&
                                    this.state.loadAllSubCatBtn ? (
                                    <li>
                                      <a
                                        href="#!"
                                        name="load_all"
                                        onClick={context.loadAllSubCategories.bind(
                                          context
                                        )}
                                      >
                                        <strong>Load All</strong>
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </ul>
                              </li>
                            </ul>
                          </div>
                        }
                        {this.state.filterKeyWord === "sort" && <div className="form-group-icon-bx">
                          <select
                            className="form-control-line"
                            onChange={context.handleFilterSorting}
                          >
                            <option value="">Most Recent First</option>
                            <option value="LowToHigh">Price: Low to High</option>
                            <option value="HighToLow">Price: High to Low</option>
                            <option value="RatingLowToHigh">Rating: Low to High</option>
                            <option value="RatingHighToLow">Rating: High to Low</option>
                          </select>
                          <span className="form-group-icon">
                            <img src="/images/down-arrow-icon.png" alt="icon" />
                          </span>
                        </div>}
                        {this.state.filterKeyWord === "other" &&
                          <div className="product-listing-sideBar-colum other-option otheropt_newcls">
                            <h2>Other Options</h2>
                            <div className="otheroption_cheperent ">
                              <div className="check-box">
                                <input
                                  type="checkbox"
                                  value="Yes"
                                  name="AvailableBuyNow"
                                  id="available-buy-now"
                                  onClick={context.handleDeliveryOptionSorting}
                                />
                                <label htmlFor="available-buy-now">
                                  Available for delivery
                                </label>
                              </div>
                              <div className="check-box">
                                <input
                                  type="checkbox"
                                  value="Yes"
                                  name="AvailablePickUp"
                                  id="available-pickup"
                                  onClick={context.handleDeliveryOptionSorting}
                                />
                                <label htmlFor="available-pickup">
                                  Available for pick up
                                </label>
                              </div>
                              {General.showWeekendDeals() &&
                                <div className="check-box">
                                  <input
                                    type="checkbox"
                                    value={1}
                                    name="weeklydeals"
                                    id="weeklydeals"
                                    onClick={this.handleDeliveryOptionSorting}
                                  />
                                  <label htmlFor="weeklydeals">
                                    Weekend Deal
                                  </label>
                                </div>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                {/* <div
                  id="filter-toggler"
                  className="btnx btn-secondaryx mx-3"
                  onClick={() => this.setState({ openProductFilter: true })}
                >
                  All Filter
                </div> */}
                <div className="fillter_btn_box">
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "category" })
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Category</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "sort" })
                      document.body.classList.add('filter-open');
                    }}
                  >

                    <div className="fillter_trackMobile">
                      <span>Sort</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "price" })
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Price</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "other" })
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Other Options</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "otherfilter" })
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Other Filter</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Jcompny_allprod">
                  {showHideListData && (
                    <div className="Jtaballp_content atlistingside_new">
                      {allSubCatfilterProducts.length > 0 ? (
                        <>
                          {allSubCatfilterProducts.map(function (row) {
                            return (
                              <div className="row" key={"allSubcat" + row._id}>
                                <div
                                  className="col-lg-12 listside_newperent"
                                  key={"allSubCatfilterProducts" + row._id}
                                >
                                  <div className="product-listing-product">
                                    <div className="row">
                                      <div className="col-3">
                                        <div className="product-img-bx">
                                          {
                                            row?.liveStreamingData && (
                                              <>
                                                {moment(row?.liveStreamingData?.time).diff(
                                                  moment(),
                                                  "seconds"
                                                ) <= 0 ? (<span className="live-tag">
                                                  <i className="fas fa-circle"></i> Live
                                                </span>) : ""}
                                              </>
                                            )
                                          }
                                          <Link
                                            to={"/product/" + row.productSlug}
                                          >
                                            {row.adType == "Featured" && (
                                              <div className="specialtags">
                                                <span className="black_special">
                                                  {row.adType}
                                                </span>
                                              </div>
                                            )}

                                            {row.productMainImage ? (
                                              <img
                                                src={General.showImage(
                                                  "products/resize-180-180",
                                                  row.productMainImage
                                                )}
                                                alt="uploaded image"
                                              />
                                            ) : (
                                              <img
                                                src="/images/no-image.jpg"
                                                alt="product"
                                              />
                                            )}
                                            <div className="totalimg_sectab">
                                              {/*<Link to="#" className="totlimg_qt">2 <i className="fas fa-camera"></i></Link>*/}
                                            </div>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="col-9">
                                        <div className="product-outer-bx productBig-outer-bx">
                                          <div className="product-name-bx content_gobottom_new">
                                            <time>{row.time}</time>
                                            <Link
                                              to={"/product/" + row.productSlug}
                                            >
                                              <h4>{row.productName}</h4>
                                            </Link>
                                            {row?.location && <h5>
                                              {row?.location}
                                            </h5>}
                                            {General.showWeekendDeals() &&
                                              row.isWeekEndDeal ? (
                                              <div className="d-flex align-items-center">
                                                <del>
                                                  {General.showPriceDecimal(
                                                    row.productPrice
                                                  )}
                                                </del>
                                                <span className="discountt mr-2">
                                                  -{row?.discountPercent}%
                                                </span>
                                                <h6 className="mt-0">
                                                  <span>
                                                    {General.showPriceDecimal(
                                                      row.weekendPrice
                                                    )}
                                                  </span>
                                                </h6>
                                              </div>
                                            ) : (
                                              <h6>
                                                <span>
                                                  {General.showPriceDecimal(
                                                    row.productPrice
                                                  )}
                                                </span>
                                              </h6>
                                            )}
                                            {row.productDescription && (
                                              <p>{`${row.productDescription.substring(
                                                0,
                                                80
                                              )}...`}</p>
                                            )}
                                            {row.isWishlist === 1 ? (
                                              <button
                                                className="add-to-wishlist"
                                                onClick={() =>
                                                  context.removeWishlistHandler(
                                                    row._id
                                                  )
                                                }
                                              >
                                                <i className="fas fa-heart"></i>
                                              </button>
                                            ) : (
                                              <button
                                                className="add-to-wishlist"
                                                onClick={() =>
                                                  context.addWishlistHandler(
                                                    row._id
                                                  )
                                                }
                                              >
                                                <i className="far fa-heart"></i>
                                              </button>
                                            )}
                                            {row.condition && (
                                              <div className="usednew_button">
                                                <a
                                                  href="#!"
                                                  className="btnnew_used"
                                                >
                                                  {row.condition}
                                                </a>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {this.state.nothingD ? (
                            <div className="col-lg-12">
                              <div className="alert alert-danger">
                                <strong>Info!</strong> Nothing here.
                              </div></div>
                          ) : (
                            <>
                              {this.state.productSkeleton.map(function (
                                rowsskeleton
                              ) {
                                return (
                                  <ContentLoader
                                    viewBox="0 0 1380 300"
                                    height={340}
                                    width={1380}
                                  >
                                    <rect
                                      x="20"
                                      y="{rowsskeleton.id}"
                                      rx="10"
                                      ry="10"
                                      width="300"
                                      height="300"
                                    />
                                    <rect
                                      x="360"
                                      y="{rowsskeleton.id}"
                                      rx="10"
                                      ry="10"
                                      width="1000"
                                      height="300"
                                    />
                                  </ContentLoader>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {showHideGridData && (
                    <div className="Jtaballp_content02 auctionplist_main ">
                      <div className="col-sm-12 actionpage_list">
                        <div
                          className={
                            this.state.nothingD ? "row" : "row low-margin"
                          }
                        >
                          {allSubCatfilterProducts.length > 0 ? (
                            <>
                              {allSubCatfilterProducts.map(function (row) {
                                return (
                                  <div
                                    className="col-sm-3"
                                    key={
                                      "allSubCatfilterProducts" +
                                      row.productSlug
                                    }
                                  >
                                    <div className="product-outer-bx product_card_box">
                                      <Link to={"/product/" + row.productSlug}>
                                        <div className="product-img-bx">
                                          {
                                            row?.liveStreamingData && (
                                              <>
                                                {moment(row?.liveStreamingData?.time).diff(
                                                  moment(),
                                                  "seconds"
                                                ) <= 0 ? (<span className="live-tag">
                                                  <i className="fas fa-circle"></i> Live
                                                </span>) : ""}
                                              </>
                                            )
                                          }
                                          {row.productMainImage ? (
                                            <img
                                              src={General.showImage(
                                                "products/resize-180-180",
                                                row.productMainImage
                                              )}
                                              alt="uploaded image"
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.jpg"
                                              alt="product"
                                            />
                                          )}
                                          {row.adType == "Featured" && (
                                            <div className="specialtags">
                                              <span className="black_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}
                                          {row.adType == "Urgent" && (
                                            <div className="specialtags">
                                              <span className="red_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}
                                          {row.adType == "Spotlight" && (
                                            <div className="specialtags">
                                              <span className="green_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}
                                          <div className="totalimg_sectab">
                                            {/*<Link to={"/product/"+row._id} className="totlimg_qt">2 <i className="fas fa-camera"></i></Link>*/}
                                          </div>
                                        </div>
                                        <div className="product-name-bx">
                                          <h6>
                                            {row.isWeekEndDeal && row.weekDealStart ? (
                                              <div>
                                                <span>
                                                  {General.showPriceDecimal(row?.weekendPrice)}
                                                </span>
                                                <del className="ml-1">
                                                  {General.showPriceDecimal(row.productPrice)}
                                                </del>
                                                <p className="discountt">
                                                  -{row?.discountPercent}%
                                                </p>

                                              </div>
                                            ) : (
                                              <span>
                                                {General.showPriceDecimal(
                                                  row.productPrice
                                                )}
                                              </span>
                                            )}
                                          </h6>
                                          {/* <Rating
                                            fillColor="#2f9ee4"
                                            className="rating-product"
                                            initialValue={row?.rating}
                                            allowFraction={true}
                                            readonly = {true}
                                            size={15}
                                          /> */}
                                          <h4>{row?.productName}</h4>
                                          {row?.location && <h3>
                                            {row?.location}
                                          </h3>}
                                          {row?.condition && <div className="item-condition">
                                            <span className="item-conditionTag">{row?.condition}</span>
                                          </div>}
                                          <div className="d-inline-flex- d-none">
                                            <div className="musr_img">
                                              {console.log("row", row)}
                                              {row?.userId &&
                                                row.userId
                                                  ?.profilePicture ? (
                                                <img
                                                  src={General.showImageNew(
                                                    "profile",
                                                    row.userId
                                                      ?.profilePicture
                                                  )}
                                                  alt={row.userId.firstName}
                                                  className="mCS_img_loaded"
                                                />
                                              ) : (
                                                <img
                                                  src="/images/user.png"
                                                  alt="image"
                                                  className="mCS_img_loaded"
                                                />
                                              )}
                                            </div>
                                            {row?.userId && <h4 className="ml-2">{`${row?.userId?.firstName} ${row?.userId?.lastName}`}</h4>}
                                          </div>
                                        </div>
                                      </Link>
                                      {row.isWishlist === 1 ? (
                                        <button
                                          className="add-to-wishlist"
                                          onClick={() =>
                                            context.removeWishlistHandler(
                                              row._id
                                            )
                                          }
                                        >
                                          <i className="fas fa-heart"></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="add-to-wishlist"
                                          onClick={() =>
                                            context.addWishlistHandler(row._id)
                                          }
                                        >
                                          <i className="far fa-heart"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {this.state.nothingD ? (
                                <div className="col-lg-12">
                                  <div className="alert alert-danger">
                                    <strong>Info!</strong> Nothing here.
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {this.state.productSkeleton.map(function (
                                    rowsskeleton
                                  ) {
                                    return (
                                      <ContentLoader
                                        viewBox="0 0 1380 300"
                                        height={340}
                                        width={1380}
                                      >
                                        <rect
                                          x="20"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="360"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="700"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="1040"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                      </ContentLoader>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="loading-bx">
                  {this.state.totalRecords > 0 && (
                    <>
                      <p>
                        <span>
                          You have viewed {this.state.limit} of{" "}
                          {this.state.totalRecords} Ads
                        </span>
                      </p>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span className="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </>
                  )}

                  {this.state.next_page_number > 0 && (
                    <button
                      onClick={context.getDatabyPageno.bind(
                        context,
                        this.state.next_page_number
                      )}
                    >
                      LOAD MORE
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="side-bar-stoper"></div>
          </div>
        </section>
        <section className="bg-light-dark">
          <div></div>
        </section>
      </React.Fragment>
    );
  }
}

export default SubCategoryOtherList;
