import React, { Component } from "react";
import User from "Models/User";
let baseUrl = process.env.REACT_APP_ApiBaseUrl;

class LoginHeader extends Component {
  constructor(props) {
    super(props);
  this.state = {
    headerLogo: ""
  }
}
componentDidMount= () => {
  User.getApi(
    "APIS_Get_logo",
    async (response) => {
      //  console.log("response",response.headerLogo);
      let headerLogo = `${baseUrl}image/logo/${response.headerLogo}`;
      let footerLogo = `${baseUrl}image/logo/${response.footerLogo}`;
      let mobileLogo = `${baseUrl}image/logo/${response.mobileLogo}`;
      this.setState({ headerLogo: headerLogo });
      this.setState({ footerLogo: footerLogo });
      this.setState({ mobileLogo: mobileLogo });
      localStorage.setItem(
        "HeaderLogo", headerLogo
      );
      localStorage.setItem(
        "FooterLogo", footerLogo
      );
      localStorage.setItem(
        "MobileLogo", mobileLogo
      )
    }
  )
}

  render() {
    return (
      <header>
        <section className="top-header">
          <div className="container">
            <div className="row justify-content-between text-center">
              <div className="col-12">
                <div className="logo-bx">
                  {" "}
                  {this.state.headerLogo && (
                  <a href="/">
                    {" "}
                    <img
                      src={this.state.headerLogo}
                      alt="logo.png"
                      width="250px"
                    />{" "}
                  </a>
                 ) }
                </div>
              </div>
            </div>
          </div>
        </section>
      </header>
    );
  }
}

export default LoginHeader;
