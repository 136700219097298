

class Storage{

  static getLocalData(key){
    return localStorage.getItem(key);
  }

  static setLocalData(key, value){
    localStorage.setItem(key, value);
  }

  static removeLocalData(key){
    localStorage.removeItem(key);
  }

  static clearLocalData(key){
    localStorage.clear();
  }

  static getSessionData(key){
    return sessionStorage.getItem(key);
  }

  static setSessionData(key, value){
    sessionStorage.setItem(key, value);
  }

  static removeSessionData(key){
    sessionStorage.removeItem(key);
  }

  static clearSessionData(key){
    return sessionStorage.clear(key);
  }

}

export default Storage;
