import React, { Component } from "react";
import Menu from "Partials/Menu";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import General from "Helpers/General";
import Category from "Models/Category";

class ProductRegisterToBidComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slugValue: props.slugValue,
    };
  }

  componentDidMount = () => {
    var self = this;
    Category.getApi(
      "APIS_ALL_CATEGORIES",
      async (response) => {
        self.setState({ Categories: response });
      },
      false
    );
  };

  render() {
    return (
      <React.Fragment>
        <section className="video-chat-header">
          <div className="container">
            <a href="#" className="video-chat-btn">
              <span>
                <img src="/images/file.png" alt="icon" />
              </span>{" "}
              Register to Bid
            </a>
            <a href="#" className="number-reveal-btn ml-2">
              <img src="/images/phone-icon.png" alt="icon" />{" "}
              <span>Reveal Phone Number</span>
            </a>
          </div>
        </section>
        <section className="product-header bg-light-dark">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="breadcrumb-bx">
                  <div className="breadcrumb-bx-inner">
                    <a href="#" className="btnx btn-primaryx">
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </a>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Auction</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Products</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Jardine Motors Group</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Lot</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Register to Bid
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="repeat-section product-detail-main-bx jproductdmainbx bg-light-dark">
          <div className="container">
            <div className="row">
              <div className="col-xl-1  col-lg-2">
              </div>
              <div className="col-xl-10 col-lg-8">
                <div className="product-detail-heading">
                  <div className="">
                    <h2>2020 Range Rover Sport HSE, NAV & 4WD</h2>
                    <p>Mushin, Lagos State</p>
                  </div>
                  <div className="livestrimingV text-right">
                    <h5>
                      <span></span>Live Auction
                    </h5>
                    <h6>Video Streaming</h6>
                  </div>
                </div>
                <div className="starting_estimate">
                  <div className="starting_left">
                    <p>
                      Starting Price: <strong>₦ 20,000</strong>
                    </p>
                  </div>
                  <div className="starting_left text-right">
                    <p>
                      <span>Estimated</span> Final Price:{" "}
                      <strong>₦ 50,000</strong>
                    </p>
                  </div>
                </div>
                <div className="bidding_registertion">
                  {/* <h4 className="compltr_title">
                    Complete Bidding Registration
                  </h4> */}
                  <div className="auctiontime">
                    <div className="auctionlef">Auction Starting Time</div>
                    <div className="auctionrig">
                      29 Sep, 2020 <span>10:30 BST</span>
                    </div>
                  </div>
                  <div className="auctiontime01">
                    <div className="auctionlef01">Auction Remaning Time</div>
                    <div className="auctionrig01">
                      4d <span>12:35:43</span>
                    </div>
                  </div>
                  <div className="auctiontime02">
                    <div className="auctionlef02">Enter Phone Number</div>
                    <div className="auctionrig02">
                      <input
                        type="text"
                        name=""
                        className="form-control-line Jinfuild_action"
                        placeholder="Phone no"
                      />
                      <span>
                        <img src="/images/question.png" alt="Icon" /> Why do I
                        need to verify my phone number
                      </span>
                    </div>
                  </div>
                  <div className="bidd_regcheck">
                    <div className="bidregcleft">
                      <div className="check-box">
                        <input type="checkbox" name="option" id="notif01" />
                        <label htmlFor="notif01">
                          Noify me when auction start
                        </label>
                      </div>
                    </div>
                    <div className="bidregcleft fsectside">
                      <h5>Noify me when auction start</h5>
                      <div className="notiflycheck">
                        <div className="check-box">
                          <input type="checkbox" name="option" id="notif02" />
                          <label htmlFor="notif02">Clock</label>
                        </div>
                        <div className="check-box">
                          <input type="checkbox" name="option" id="notif03" />
                          <label htmlFor="notif03">Coin & Stamp</label>
                        </div>
                        <div className="check-box">
                          <input type="checkbox" name="option" id="notif04" />
                          <label htmlFor="notif04">Collectables</label>
                        </div>
                        <div className="check-box">
                          <input type="checkbox" name="option" id="notif05" />
                          <label htmlFor="notif05">Cars</label>
                        </div>
                        <div className="check-box">
                          <input type="checkbox" name="option" id="notif06" />
                          <label htmlFor="notif06">Fine Art</label>
                        </div>
                      </div>
                      <h6>
                        <a href="#">more</a>
                      </h6>
                    </div>
                  </div>
                  <div className="getafree">
                    <div className="check-box">
                      <input type="checkbox" name="option" id="notif06" />
                      <label htmlFor="notif06">
                        Get a free daily newsletter about live and upcomming
                        auctions
                      </label>
                    </div>
                  </div>
                  <div className="priv_policy">
                    <p>
                      By clicking “Create my account now” I agree that I have
                      the site’s{" "}
                      <a href="/privacy-policy" target="_blank">
                        {" "}
                        Privacy Policy
                      </a>{" "}
                      and accept the{" "}
                      <a href="/term-conditions" target="_blank"> Terms of Services</a>.
                    </p>
                  </div>
                  <div className="registerbtn">
                    <div className="form-group">
                      <a href="#" className="btn btnx btn-secondaryx">
                        Register to Bid
                      </a>
                    </div>
                  </div>
                </div>
                <div className="whyweregis">
                  <span>
                    <img src="/images/question.png" alt="Icon" /> Why do I need
                    to verify my phone number
                  </span>
                </div>
              </div>
              <div className="col-xl-1  col-lg-2">
              </div>
              {/* <div className="col-xl-4  col-lg-5 offset-xl-1">
                <div className="product-detail-owner-bx">
                  <div className="detail-owner-header">
                    <div>
                      <h2>Jardine Motors Group</h2>
                      <p>Posting for 4+ months</p>
                    </div>
                    <div className="text-right">
                      <a href="#" className="see-all-btn">
                        All Items <i className="fas fa-arrow-right"></i>
                      </a>
                      <time>Posted, 15 days, 12:30</time>
                    </div>
                  </div>

                  <div className="detail-owner-name-bx clearfix">
                    <div className="detail-owner-img">
                      <img src="/images/profile-img.jpg" alt="image" />
                      <span className="live-dot"></span>
                    </div>
                    <div className="detail-owner-name">
                      <h2>Jardine Motors Group</h2>
                      <p>
                        <span>Last Seen:</span> 14 Days Ago
                      </p>
                      <div className="rated-star">
                        <span>Rated</span>
                        <span>
                          <strong>5.0</strong>
                        </span>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                      </div>
                    </div>
                  </div>

                  <div className="contact-bx Jcontec_bx">
                    <div>
                      <a href="#" className="video-chat-btn">
                        <span>
                          <img src="/images/file.png" alt="icon" />
                        </span>{" "}
                        Register To Bid
                      </a>
                    </div>
                    <div className="registcall">
                      <a href="#" className="number-reveal-btn">
                        <img src="/images/phone-icon.png" alt="icon" />{" "}
                        <span>Reveal Phone Number</span>
                      </a>
                      <a href="#" className="number-reveal-btn">
                        <img src="/images/email-icon.png" alt="icon" />{" "}
                        <span>Message</span>
                      </a>
                    </div>
                  </div>

                  <div className="safety-tips-bx">
                    <h2>Safety Tips</h2>

                    <ul>
                      <li>Donec convallis orci lacus, sed viverra.</li>
                      <li>Magna vulputate congue vivamus ut.</li>
                      <li>Varius nunc eu pulvinar velit.</li>
                      <li>In rutrum tellus id justo</li>
                    </ul>
                  </div>
                  <div className="detail-owner-btn">
                    <a href="#">
                      <span>
                        <i className="fas fa-heart"></i>
                      </span>{" "}
                      Wishlist
                    </a>
                    <div className="dropdown">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span>
                          <i className="fas fa-exclamation-triangle"></i>
                        </span>{" "}
                        Report
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="share-bx">
                  <p>Share:</p>
                  <ul>
                    <li>
                      <a href="#" className="facebook-btn">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="twitter-btn">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="insta-btn">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="email-btn">
                        <i className="far fa-envelope"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>

            <div className="write-review jreview_sec">
              <h2 className="product-sub-heading">Write A Review</h2>

              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="text"
                      name="your-name"
                      className="form-control-line"
                      placeholder="Your Name"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <input
                      type="email"
                      name="Email Address"
                      className="form-control-line"
                      placeholder="Email Address"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="Review Title"
                      className="form-control-line"
                      placeholder="Review Title"
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <textarea
                      className="form-control-line"
                      placeholder="Share Your Review"
                    ></textarea>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <button className="btnx btn-secondaryx">Submit</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light-dark">
          <div></div>
        </section>
      </React.Fragment>
    );
  }
}

export default ProductRegisterToBidComponent;
