import React, { Component } from "react";
import General from "Helpers/General";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MyProfile from "Models/MyProfile";
import Product from "Models/Product";
import ScheduleProductModel from "Models/ScheduleProduct";
import Plans from "Models/Plans";
import DatePicker from "react-date-picker";
import { Link, Redirect } from "react-router-dom";
import { LinearProgress, TextField } from "@material-ui/core";
import ScheduleProductList from './ScheduleProductList';
import QB from "Helpers/Quickblox";
import Storage from "Helpers/Storage";
import PaymentPopUp from "../Stripe/CheckoutForm";

export class ScheduleLiveAuction extends Component {

  constructor(props) {

    super(props);

    //this.videoStreamPlan = React.createRef();

    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      showProductDropdown: false,
      scheduleDate: "",
      scheduleTime: "",
      scheduleLength: "",
      isAllowPreBid: false,
      selectedCategories: [],
      liveAuctionDurations: [],
      scheduleSelectedProducts: [],
      scheduleSelectedProductsId: [],
      videoStreamingCharges: {},
      products: [],
      maxProductSize: 6,
      showCreateAuction: true,
      isLoading: false,
      videoStreamId: "",
      paymentAmount: 0,
      isVideoStreaming: false,
      paymentbutton: false,
      checkOutPopup: false,
      transaction_type: "video_streaming",
      videoStreamLable: "",
      slotLable: "",
      prebidAllow: false
    }

  }

  componentDidMount = () => {
    this.getLiveAuctionDuration();
    this.getAllProducts();
  };

  getLiveAuctionDuration = () => {
    this.setState({ isLoading: true });
    Product.getApi("API_GET_LIVEAUCTION_DURATION", async (response, status) => {
      if (response) {
        this.setState({ liveAuctionDurations: response });
      }
    });

    MyProfile.postApiLoader(
      "API_IS_LIVE_TIME_BY_USER",
      {},
      async (response, status) => {
        if (status == "success") {
          this.setState({ showCreateAuction: true });
        } else {
          this.setState({ showCreateAuction: false });
        }
      },
      true
    );

    this.setState({ isLoading: false });
  };


  getAllProducts = () => {
    MyProfile.getApi(
      "APIS_GET_MY_PROFILE_ACTIVE_PRODUCT",
      async (response) => {
        this.setState({ products: response.products });
      },
      true
    );

    Plans.postApi(
      "APIS_GET_ONE_SLOT_STREAMING_PLAN",
      {},
      async (response, status) => {
        if (status == "success") {
          this.setState({ videoStreamingCharges: response });
          let textLabel = "";
          if (response) {
            textLabel += response.slot + " " + response.label;
          }
          this.setState({ videoStreamLable: textLabel });
        }
      },
      true
    );

    MyProfile.postApi(
      "API_CHECK_CURRENT_USER_SLOTS",
      {},
      async (response, status) => {
        if (status == "success") {
          if (!response.payment) {
            let labelName = `${response.remainingSlot} Slot - Paid`;
            this.setState({ slotLable: labelName });
          } else {
            this.setState({ slotLable: "" });
          }
        }
      },
      true
    );

  }

  scheduleOnChangeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (this.state.scheduleDate && name == "scheduleTime") {
      var date = new Date(this.state.scheduleDate);
      let timeArr = value.split(":");
      let hour = timeArr[0];
      let min = timeArr[1];
      date.setHours(hour);
      date.setMinutes(min);
      let currentDateTime = new Date;
      currentDateTime.setMinutes(currentDateTime.getMinutes() + 30);
      if (date > currentDateTime) {
        this.setState({ prebidAllow: true });
      } else {
        this.setState({ prebidAllow: false });
      }
      if (date <= new Date) {
        return;
      }
    }

    this.setState({ [name]: value });

    if (name == "scheduleLength") {
      let maxProCount = (value == "") ? 6 : 30 / Number(value);
      this.setState({ maxProductSize: maxProCount });
    }
  };

  checkBoxChangeHandler = (e) => {
    //e.preventDefault();
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  showProductDropdownHandler = async (event) => {
    event.preventDefault();
    const { scheduleDate, scheduleTime, scheduleLength } =
      this.state;

    if (
      (await ScheduleProductModel.checkValidation(
        await ScheduleProductModel.validationRules(),
        { scheduleDate, scheduleLength }
      )) === true
    ) {
      if (scheduleDate === "" || scheduleTime === "") {
        MyProfile.toastAlert("error", "Please Select Date and Time");
      } else {
        this.setState({ isLoading: true });
        let datestring = scheduleDate.getFullYear() + "-" + (scheduleDate.getMonth() + 1) + "-" + scheduleDate.getDate();
        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        MyProfile.postApi(
          "API_LIVE_SCHEDULE_TIME_AVAILABLE_BY_USER",
          {
            date: datestring,
            time: scheduleTime,
            timezone: timezone
          },
          async (response, status) => {
            if (status == "success") {
              this.setState({ showProductDropdown: true });
              this.setState({ isLoading: false });
            } else {
              this.setState({ isLoading: false });
            }
          },
          true
        );

        //this.setState({ showProductDropdown: true });
      }
    }
  };

  scheduleProductHandler = (event, value) => {
    event.preventDefault();

    const {
      scheduleLength
    } = this.state;

    let maxAd = 30 / Number(scheduleLength);

    let selectedProdcuts = [];
    let selectedProdcutIds = [];

    value.forEach((val) => {
      selectedProdcuts.push(val);
      selectedProdcutIds.push(val._id);
    });

    if (selectedProdcuts.length > maxAd) {
      let msg = "You can select maximum " + maxAd + " products";
      MyProfile.toastAlert("error", msg);
      return;
    } else {
      this.setState({
        scheduleSelectedProducts: selectedProdcuts,
        scheduleSelectedProductsId: selectedProdcutIds,
      });
    }
  };

  hideProductDropdownHandler = (event) => {
    event.preventDefault();
    this.setState({ showProductDropdown: false });
  };

  scheduleSubmitHandler = (event) => {
    event.preventDefault();
    this.submithandlerFunction();
  };

  submithandlerFunction = () => {
    this.setState({ isLoading: true });

    const {
      products,
      scheduleDate,
      scheduleTime,
      scheduleLength,
      scheduleSelectedProductsId,
      scheduleSelectedProducts,
      isAllowPreBid,
      isVideoStreaming,

    } = this.state;

    var maxAd = 30 / Number(scheduleLength);

    let datestring = scheduleDate.getFullYear() + "-" + (scheduleDate.getMonth() + 1) + "-" + scheduleDate.getDate();

    let error = 0;

    if (scheduleSelectedProducts && scheduleSelectedProducts.length > 0) {
      if (scheduleSelectedProducts.length > maxAd) {
        error++;
        let msg = "You can select maximum " + maxAd + " products";
        MyProfile.toastAlert("error", msg);
      } else {
        for (let index = 0; index < scheduleSelectedProducts.length; index++) {
          const product = scheduleSelectedProducts[index];
          if (typeof product.starting_price == 'undefined' || product.starting_price <= 0) {
            document.getElementById("starting_price_error_" + index).style.display = "block";
            error++;
          } else {
            document.getElementById("starting_price_error_" + index).style.display = "none";
          }

          if (typeof product.selling_estimate == 'undefined' || product.selling_estimate <= 0) {
            document.getElementById("selling_estimate_error_" + index).style.display = "block";
            error++;
          } else {
            document.getElementById("selling_estimate_error_" + index).style.display = "none";
            if (Number(product.starting_price) > Number(product.selling_estimate)) {
              document.getElementById("selling_estimate_price_error_" + index).style.display = "block";
              error++;
            } else {
              document.getElementById("selling_estimate_price_error_" + index).style.display = "none";
            }
          }
        }
      }
    } else {
      error++;
      MyProfile.toastAlert("error", "You can select minimum 1 or maximum 6 products");
    }

    if (error > 0) {
      this.setState({ isLoading: false });
      return;
    } else {

      let params = {
        type: 1,
        name: "public_chat",
      };
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // QB.chat.dialog.create(params, (error, dialog) => {
      //   if (!error) {
          let channelId = 1;//dialog._id;
          MyProfile.postApi(
            "API_CREATE_LIVE_SCHEDULE",
            {
              date: datestring,
              time: scheduleTime,
              timezone: timezone,
              length: scheduleLength,
              productId: scheduleSelectedProductsId,
              products: scheduleSelectedProducts,
              channelId: channelId,
              isAllowPreBid: isAllowPreBid,
              isVideoStreaming: isVideoStreaming

            },
            async (response) => {
              if (response) {
                this.setState({
                  scheduleDate: "",
                  scheduleTime: "",
                  scheduleLength: "",
                  scheduleSelectedProductsId: [],
                  scheduleSelectedProducts: [],
                  selectedCategories: [],
                  showProductDropdown: false,
                  isAllowPreBid: false,
                  videoStreamId: "",
                  isVideoStreaming: false,
                  paymentbutton: false
                });

                this.props.getAllScheduleProducts();
                this.props.getLiveStreamingProducts();
                this.props.handleTabes('upcoming_auction');
                /* this.getAllProducts();
                this.props.getAllScheduleProducts();
                this.props.getLiveStreamingProducts(); */
              }
            },
            true
          );
      //   } else {
      //     MyProfile.toastAlert("error", "Somthing went wrong.Please try again.");
      //   }
      // });
    }
  };

  onChangeHanlde = (event) => {
    this.setState({ isVideoStreaming: event.target.checked }, () => {
      if (event.target.checked) {
        MyProfile.postApi(
          "API_CHECK_CURRENT_USER_SLOTS",
          {},
          async (response, status) => {
            if (status == "success") {
              this.setState({ paymentbutton: response.payment });
              if (response.payment) {
                this.planChangeHandler();
              }
            }
          },
          true
        );
      } else {
        this.setState({ videoStreamId: "", paymentAmount: 0, paymentbutton: event.target.checked });
      }
    });
  };


  planChangeHandler = () => {
    let { videoStreamingCharges, isVideoStreaming } = this.state;
    if (isVideoStreaming) {
      let videoStreamId = (videoStreamingCharges.id) ? videoStreamingCharges.id : '';
      let charge = (videoStreamingCharges.price) ? videoStreamingCharges.price : 0;
      this.setState({ videoStreamId: videoStreamId, paymentAmount: charge });
    }
  };

  checkValidations = async () => {

    //this.setState({isLoading:true});

    const {
      scheduleLength,
      scheduleSelectedProducts,
    } = this.state;

    var maxAd = 30 / Number(scheduleLength);

    let error = 0;

    if (scheduleSelectedProducts && scheduleSelectedProducts.length > 0) {
      if (scheduleSelectedProducts.length > maxAd) {
        error++;
        let msg = "You can select maximum " + maxAd + " products";
        MyProfile.toastAlert("error", msg);
      } else {
        for (let index = 0; index < scheduleSelectedProducts.length; index++) {
          const product = scheduleSelectedProducts[index];
          if (typeof product.starting_price == 'undefined' || product.starting_price <= 0) {
            document.getElementById("starting_price_error_" + index).style.display = "block";
            error++;
          } else {
            document.getElementById("starting_price_error_" + index).style.display = "none";
          }

          if (typeof product.selling_estimate == 'undefined' || product.selling_estimate <= 0) {
            document.getElementById("selling_estimate_error_" + index).style.display = "block";
            error++;
          } else {
            document.getElementById("selling_estimate_error_" + index).style.display = "none";
          }
        }
      }
    } else {
      error++;
      MyProfile.toastAlert("error", "You can select minimum 1 or maximum 6 products");
    }

    if (error > 0) {
      //this.setState({isLoading:false});
      return;
    } else {
      this.setState({ checkOutPopup: true });
    }
  };

  render() {
    const { liveAuctionDurations, scheduleSelectedProductsId, scheduleSelectedProducts, scheduleLength, isLoading, videoStreamingCharges, videoStreamLable } = this.state;

    const PaystackHookExample = () => {

      if (this.state.paymentbutton) {
        return (
          <button
            type="button"
            onClick={
              this.checkValidations
            }
            className="btnx btn-secondaryx"
          >
            Pay Or Publish Auction
          </button>
        );
      } else {
        return (
          <button
            type="button"
            onClick={
              this.scheduleSubmitHandler
            }
            className="btnx btn-secondaryx font-size-14"
          >
            Publish Auction
          </button>
        );
      }
    };

    const onSuccess = (ref) => {

      let paymentIntent = window.btoa(JSON.stringify(ref));
      this.setState({ checkOutPopup: false });

      MyProfile.postApi(
        "API_UPDATE_TRANSACTION_STATUS",
        {
          intent_id: ref.id,
          payment_intent: paymentIntent,
          video_stream_id: this.state.videoStreamId,
          status: "active",
        },
        async (response, status) => {
          if (status == "success") {
            //this.setState({ current_package: response });
            this.submithandlerFunction();
          }
        },
        true
      );
    };

    const onClose = () => {
      this.setState({ isLoading: false });
      this.setState({ checkOutPopup: false });
    };

    const onLoading = (value) => {
      this.setState({ isLoading: value });
    };

    return (
      <>
        {isLoading && (
          <div className="loader-wrapper" id="loader_img">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        <div
          className="tab-pane active show"
          id="subtabpackage02"
          role="tabpanel"
        >
          {console.log("kumar", this.state.showCreateAuction, this.state.showProductDropdown)}
          {this.state.showCreateAuction ?
            <>
              {this.state.showProductDropdown ===
                false ? (
                <div className="Schedule_live Schedule_live_block "> 
                  <div className="Schedule_live_heading text-left text-dark pb-3 font-size-16">
                    Schedule Live Auction Show   {" "}
                  </div>
                  <div className="pb-2 live_Video mobile-center">
                      Start Date and Time
                  </div>
                  <div className="d-flex show_auction">
                    <div className="Schedule_date_time mb-3 mb-md-0">

                      <DatePicker
                        name="scheduleDate"
                        onChange={(value) =>
                          this.setState({
                            scheduleDate: value,
                            scheduleTime: ""
                          })
                        }
                        minDate={new Date()}
                        value={this.state.scheduleDate}
                      />
                    </div>

                    <div className="Schedule_date_time mb-3 mb-md-0">
                      <TextField
                        type="time"
                        //mintime='9:00'
                        name="scheduleTime"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={
                          this.state.scheduleTime
                        }
                        style={{ width: "100%" }}
                        onChange={
                          this.scheduleOnChangeHandler
                        }
                      />


                    </div>
                  </div>

                  <div className="py-2 py-md-3">
                    <div className="Schedule_live_heading text-left text-dark auction_tooltip pb-3">
                      <span className="font-size-16">Auction length Per item</span>
                      <div className="tooltips">
                        <i className="fas fa-info-circle"></i>
                        <span className="tooltiptext">Note: Single advery auction will be
                          sold to the higest bidder at the end
                          of the auction see{" "}
                          <a href="#"> term & Condition </a>
                          you can only add maximum of {this.state.maxProductSize} ad's for 1 hour slot.
                        </span>
                      </div>
                    </div>
                    <div className="header-input-icon header-input-icon_block">
                      <select
                        className="header-input profile_input"
                        name="scheduleLength"
                        onChange={
                          this.scheduleOnChangeHandler
                        }
                        value={
                          this.state.scheduleLength
                        }
                      >
                        <option className="item" value="">
                          Select Length
                        </option>
                        {liveAuctionDurations &&
                          liveAuctionDurations.map(
                            (d) => (
                              <option
                                value={d.duration}
                                key={d._id}
                              >
                                {d.duration} Min
                              </option>
                            )
                          )}
                      </select>
                    </div>
                  </div>
                  {this.state.prebidAllow && (
                    <div className="Schedule_live Schedule_live_block ">
                      <div className="Schedule_live_heading text-left text-dark pb-3">
                        Allow Pre-bidding{" "}
                      </div>
                      <label className="switch">
                        <input type="checkbox" name="isAllowPreBid" onChange={this.checkBoxChangeHandler} checked={this.state.isAllowPreBid} />
                        <span className="slider round"></span>
                      </label>
                    </div>
                  )}


                  <div className="note_block mt-2 mt-md-5">
                    Allow pre-bidding on the auction before going live <br />
                    Note : If not enabled, buyers will only be able to bid on the auction during live aution,
                    <a href="/term-conditions"> see terms & Condition </a>
                  </div>

                  <div className="bottonsend text-center">
                    <button
                      type="button"
                      onClick={
                        this.showProductDropdownHandler
                      }
                      className="btnx btn-secondaryx font-size-14"
                    >
                      Next
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <div className="py-3 row align-items-center next_block">
                    <div className="Schedule_live_heading text-left   col-lg-5 mb-0 pb-0 font-size-16">
                      {" "}
                      Add Item to live Auction Show{" "}
                    </div>
                    <div className="col-lg-7">
                      <div className="header-input-icon header-input-icon_block w-100 ">
                        <Autocomplete
                          multiple
                          onChange={
                            this.scheduleProductHandler
                          }
                          options={this.state.products}
                          getOptionLabel={(option) =>
                            option.productName
                          }
                          value={
                            scheduleSelectedProducts
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Select Products"
                            />
                          )}
                        />
                      </div>
                    </div>{" "}
                  </div>
                  <div className="text-center">Or</div>
                  <div className="py-3 row align-items-center next_block">
                    <div className="Schedule_live_heading text-left   col-lg-5 mb-0 pb-0 font-size-16">
                      Post Item To Add To Live Auction Show
                    </div>

                    <div className="col-lg-7">
                      <div className="header-input-icon header-input-icon_block w-100 mobile-center">
                        <Link
                          to={`/post-an-ad?from_page=auction`}
                        ><button className="btn-sm btn-secondary-sm font-size-16">
                            POST AN AD
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="white_boxss special_makead_stand">
                    <div className="row">
                      <div className="col-sm-9">
                        <div className="ad_box">
                          <ul>
                            <li>
                              <div className="check-box big">
                                <input
                                  type="checkbox"
                                  name="isVideoStreaming"
                                  id="Featured"
                                  onChange={this.onChangeHanlde}
                                />
                                <label htmlFor="Featured">
                                  <span className="badge badge-primary">
                                    Video streaming
                                  </span>{" "}
                                  Publish your live auction with live video streaming for {videoStreamLable} {" "}
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <div className="ad_box streaming_price_label">
                          {(this.state.slotLable && this.state.slotLable != "") ? (<span className="badge badge-primary">{`${this.state.slotLable}`}</span>) :
                            (<span className="badge badge-primary">{`${videoStreamingCharges?.slot} ${videoStreamingCharges?.label} - ${General.showPriceDecimal(videoStreamingCharges?.price)}`}</span>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state
                    .scheduleSelectedProducts &&
                    this.state.scheduleSelectedProducts.map(
                      (product, index) => (
                        <ScheduleProductList
                          product={product}
                          scheduleLength={scheduleLength}
                          index={index}
                          key={product._id}
                        />
                      )
                    )}

                  <div className="bottonsend text-center">
                    <button
                      type="button"
                      className="btnx btn-secondaryx mr-3 font-size-14"
                      onClick={
                        this.hideProductDropdownHandler
                      }
                    >
                      Back
                    </button>
                    <PaystackHookExample />
                    {/*  <button
                    type="button"
                    onClick={
                    this.scheduleSubmitHandler
                    }
                    className="btnx btn-secondaryx font-size-16"
                >
                    Publish Auction
                </button> */}
                  </div>
                  <PaymentPopUp parentState={this.state} onClose={onClose} onSuccess={onSuccess} onLoading={onLoading} />

                </>
              )}
            </> : <div className="Schedule_live Schedule_live_block ">
              <div className="Schedule_live_heading text-left text-dark pb-3">Live streaming auction has started</div><div><button className="btn-sm btn-secondary-sm mb-3" onClick={() => this.props.handleTabes('start_live_auction')}>Go To Start Live Auction</button></div></div>}
        </div>
      </>
    );
  }
}

export default ScheduleLiveAuction;
