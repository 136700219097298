import { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Product from "Models/Product";
import socket from "Helpers/Socket";

class SimpleBottomNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "recents",
      unreadAlerts: [],
    };
  }

  componentDidMount = () => {
    if (Product.isLogin()) {
      socket.emit("getAllAlerts", { userId: Product.getAuthUserId() });
      socket.on("getAllAlertsResponse", (data) => {
        this.setState({ Alerts: data });
        let unreadAlert = data.filter((f) => f.read === false);
        this.setState({ unreadAlerts: unreadAlert });
      });
      socket.emit("login", { userId: Product.getAuthUserId() });
    }
  }

  handleChange = (newValue) => {
    this.setState({ value: newValue });
  };

  // handleNavItemClick = (event, value) => {
  //   const notificationBox = document.querySelector(".notification_box");

  //   if (value === "alert") {
  //     if (notificationBox) {
  //       notificationBox.classList.add("show_notification");
  //     }
  //   } else {
  //     notificationBox.classList.remove("show_notification");
  //   }
  // };

  handleNavItemClick = (event, value) => {
    const notificationBox = document.querySelector(".notification_box");

    if (value === "alert" && notificationBox) {
      notificationBox.classList.toggle("show_notification");
    } else if (notificationBox) {
      notificationBox.classList.remove("show_notification");
    }
  };


  render() {
    const { value } = this.state;

    return (
      <>
        <div className="bottom-navigation">
          <ul class="bottom_nav">
            <li class="nav-item">
              <a class="nav-link" href="/">
                <span className="nav_icon_box">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.44001 0.296069C9.58956 0.149487 9.79061 0.0673828 10 0.0673828C10.2094 0.0673828 10.4105 0.149487 10.56 0.296069L19.56 9.1494C19.7031 9.29966 19.7822 9.49962 19.7806 9.70707C19.779 9.91453 19.6969 10.1133 19.5516 10.2613C19.4063 10.4094 19.2091 10.4953 19.0017 10.5007C18.7944 10.5062 18.5929 10.4309 18.44 10.2907L17.3333 9.20274V16.6667C17.3333 16.8435 17.2631 17.0131 17.1381 17.1381C17.0131 17.2632 16.8435 17.3334 16.6667 17.3334H3.33335C3.15654 17.3334 2.98697 17.2632 2.86194 17.1381C2.73692 17.0131 2.66668 16.8435 2.66668 16.6667V9.20274L1.56001 10.2907C1.486 10.3685 1.39709 10.4305 1.29858 10.4732C1.20008 10.5159 1.094 10.5383 0.986656 10.5391C0.879312 10.5399 0.772903 10.5191 0.673766 10.4779C0.574629 10.4368 0.484793 10.3761 0.409606 10.2994C0.33442 10.2228 0.275423 10.1319 0.236128 10.032C0.196834 9.93206 0.178045 9.82528 0.180883 9.71797C0.18372 9.61066 0.208124 9.50502 0.252643 9.40734C0.297162 9.30966 0.360883 9.22194 0.440013 9.1494L9.44001 0.296069ZM10 1.9894L16 7.89074V16.0001H13.3333V11.3334C13.3333 11.1566 13.2631 10.987 13.1381 10.862C13.0131 10.737 12.8435 10.6667 12.6667 10.6667H8.66668C8.48987 10.6667 8.3203 10.737 8.19528 10.862C8.07025 10.987 8.00001 11.1566 8.00001 11.3334V16.0001H4.00001V7.89074L10 1.9894ZM9.33335 16.0001H12V12.0001H9.33335V16.0001Z"
                      fill="currentcolor"
                    />
                  </svg>
                </span>
                Home
              </a>
            </li>
            <li class="nav-item">
              {
                Product.isLogin() ? (
                  <a class="nav-link" href="/UserMessageBox">
                    <span className="nav_icon_box">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 9H16M8 13H14M18 4C18.7956 4 19.5587 4.31607 20.1213 4.87868C20.6839 5.44129 21 6.20435 21 7V15C21 15.7956 20.6839 16.5587 20.1213 17.1213C19.5587 17.6839 18.7956 18 18 18H13L8 21V18H6C5.20435 18 4.44129 17.6839 3.87868 17.1213C3.31607 16.5587 3 15.7956 3 15V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H18Z"
                          stroke="#25324B"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    Message
                  </a>
                ) : (
                  <a class="nav-link" href="/login">
                    <span className="nav_icon_box">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 9H16M8 13H14M18 4C18.7956 4 19.5587 4.31607 20.1213 4.87868C20.6839 5.44129 21 6.20435 21 7V15C21 15.7956 20.6839 16.5587 20.1213 17.1213C19.5587 17.6839 18.7956 18 18 18H13L8 21V18H6C5.20435 18 4.44129 17.6839 3.87868 17.1213C3.31607 16.5587 3 15.7956 3 15V7C3 6.20435 3.31607 5.44129 3.87868 4.87868C4.44129 4.31607 5.20435 4 6 4H18Z"
                          stroke="#25324B"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    Message
                  </a>
                )
              }
            </li>
            {
              Product.isLogin() ? (
                <li
                  id="notification_nav"
                  className="nav-item"
                  onClick={(e) => {
                    this.handleNavItemClick(e, "alert");
                  }}
                >
                  <a className="nav-link" href="#">
                    <span className="nav_icon_box">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.9128 18.0872L24.375 15.5494V12.1875C24.3721 9.86422 23.5078 7.62454 21.9493 5.90155C20.3909 4.17855 18.2488 3.09462 15.9375 2.85937V0.9375H14.0625V2.85937C11.7512 3.09462 9.60915 4.17855 8.05066 5.90155C6.49217 7.62454 5.62791 9.86422 5.625 12.1875V15.5494L3.08719 18.0872C2.91136 18.263 2.81255 18.5014 2.8125 18.75V21.5625C2.8125 21.8111 2.91127 22.0496 3.08709 22.2254C3.2629 22.4012 3.50136 22.5 3.75 22.5H10.3125V23.2284C10.2917 24.4179 10.7108 25.5732 11.4893 26.4727C12.2679 27.3721 13.3511 27.9525 14.5312 28.1025C15.183 28.1671 15.841 28.0946 16.4631 27.8897C17.0851 27.6847 17.6573 27.3519 18.143 26.9125C18.6287 26.4731 19.017 25.9369 19.283 25.3384C19.549 24.74 19.6868 24.0924 19.6875 23.4375V22.5H26.25C26.4986 22.5 26.7371 22.4012 26.9129 22.2254C27.0887 22.0496 27.1875 21.8111 27.1875 21.5625V18.75C27.1874 18.5014 27.0886 18.263 26.9128 18.0872ZM17.8125 23.4375C17.8125 24.1834 17.5162 24.8988 16.9887 25.4262C16.4613 25.9537 15.7459 26.25 15 26.25C14.2541 26.25 13.5387 25.9537 13.0113 25.4262C12.4838 24.8988 12.1875 24.1834 12.1875 23.4375V22.5H17.8125V23.4375ZM25.3125 20.625H4.6875V19.1381L7.22531 16.6003C7.40114 16.4245 7.49995 16.1861 7.5 15.9375V12.1875C7.5 10.1984 8.29018 8.29072 9.6967 6.8842C11.1032 5.47768 13.0109 4.6875 15 4.6875C16.9891 4.6875 18.8968 5.47768 20.3033 6.8842C21.7098 8.29072 22.5 10.1984 22.5 12.1875V15.9375C22.5001 16.1861 22.5989 16.4245 22.7747 16.6003L25.3125 19.1381V20.625Z"
                          fill="currentcolor"
                        />
                      </svg>
                  <span class="live_dot_bell"></span>

                    </span>
                    Alert
                  </a>
                </li>
              ) : (
                <li
                  id="notification_nav"
                  className="nav-item"
                >
                  <a className="nav-link" href="/login">
                    <span className="nav_icon_box">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 30 30"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M26.9128 18.0872L24.375 15.5494V12.1875C24.3721 9.86422 23.5078 7.62454 21.9493 5.90155C20.3909 4.17855 18.2488 3.09462 15.9375 2.85937V0.9375H14.0625V2.85937C11.7512 3.09462 9.60915 4.17855 8.05066 5.90155C6.49217 7.62454 5.62791 9.86422 5.625 12.1875V15.5494L3.08719 18.0872C2.91136 18.263 2.81255 18.5014 2.8125 18.75V21.5625C2.8125 21.8111 2.91127 22.0496 3.08709 22.2254C3.2629 22.4012 3.50136 22.5 3.75 22.5H10.3125V23.2284C10.2917 24.4179 10.7108 25.5732 11.4893 26.4727C12.2679 27.3721 13.3511 27.9525 14.5312 28.1025C15.183 28.1671 15.841 28.0946 16.4631 27.8897C17.0851 27.6847 17.6573 27.3519 18.143 26.9125C18.6287 26.4731 19.017 25.9369 19.283 25.3384C19.549 24.74 19.6868 24.0924 19.6875 23.4375V22.5H26.25C26.4986 22.5 26.7371 22.4012 26.9129 22.2254C27.0887 22.0496 27.1875 21.8111 27.1875 21.5625V18.75C27.1874 18.5014 27.0886 18.263 26.9128 18.0872ZM17.8125 23.4375C17.8125 24.1834 17.5162 24.8988 16.9887 25.4262C16.4613 25.9537 15.7459 26.25 15 26.25C14.2541 26.25 13.5387 25.9537 13.0113 25.4262C12.4838 24.8988 12.1875 24.1834 12.1875 23.4375V22.5H17.8125V23.4375ZM25.3125 20.625H4.6875V19.1381L7.22531 16.6003C7.40114 16.4245 7.49995 16.1861 7.5 15.9375V12.1875C7.5 10.1984 8.29018 8.29072 9.6967 6.8842C11.1032 5.47768 13.0109 4.6875 15 4.6875C16.9891 4.6875 18.8968 5.47768 20.3033 6.8842C21.7098 8.29072 22.5 10.1984 22.5 12.1875V15.9375C22.5001 16.1861 22.5989 16.4245 22.7747 16.6003L25.3125 19.1381V20.625Z"
                          fill="currentcolor"
                        />
                      </svg>
                    </span>
                    Alert
                  </a>
                </li>
              )
            }
            {
              Product.isLogin() ? (
                <li class="nav-item">
                  <a class="nav-link" href="/post-an-ad">
                    <span className="nav_icon_box">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 12H16"
                          stroke="currentcolor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 16V8"
                          stroke="currentcolor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                          stroke="currentcolor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    Sell
                  </a>
                </li>
              ) : (
                <li class="nav-item">
                  <a class="nav-link" href="/login">
                    <span className="nav_icon_box">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 12H16"
                          stroke="currentcolor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 16V8"
                          stroke="currentcolor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                          stroke="currentcolor"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    Sell
                  </a>
                </li>
              )
            }
          </ul>
        </div>
        <div className="notification_box ">
          <div className="row">
            <div className="text-left">
              <h4 className="text-center">
                <span className=""> My Alerts</span>
                ({this.state.unreadAlerts?.length})
              </h4>
              <div className="event-list-box " data-mcs-theme="dark">
                <ul className="event-list">
                  {this.state.Alerts && this.state.Alerts.length > 0 ? (
                    this.state.Alerts.map((alert) => (
                      <li key={alert._id}>
                        <div className="row m-0 align-items-center w-100">
                          <div className="col p-0 col-profile alert_notifi">
                            <div>
                              <i className="far fa-bell" aria-hidden="true"></i>
                              <span className="live-dot"></span>
                            </div>
                            <h6>
                              <Moment fromNow ago>
                                {alert.time}
                              </Moment>
                            </h6>
                          </div>
                          <div className="col p-0">
                            <Link
                              to={alert.link ? alert.link : ""}
                              className="notififaction-test"
                            >
                              <p>{alert.type}</p>
                              <strong>{alert.message}</strong>
                            </Link>
                          </div>
                          <div className="col p-0 time">
                            <h5>
                              <Link
                                to="#"
                                onClick={() => this.readAlertHandler(alert._id)}
                                data-toggle="tooltip"
                                data-placement="left"
                                title="Mark as read"
                              >
                                <i
                                  className={`far fa-envelope${alert.read === true ? "-open" : ""
                                    }`}
                                ></i>
                              </Link>
                            </h5>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <div className="no_alerts">No Alerts</div>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SimpleBottomNavigation;
