import Model from "Models/Model";

class Plans extends Model{

  dbSchema(){
    return {
      _id:""
    };
  }
}

export default new Plans();
