import React from "react";
import Moment from "moment";
import General from "Helpers/General";

export class Channel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      msgCheck: false,
    };
  }
  click = () => {
    this.props.onClick(this.props.id);
  };

  setClassHide = () => {
    const currentState = this.state.msgCheck;
    this.setState({ msgCheck: !currentState });
  };

  render() {
    return this.props.name ? (
      <div className="channel-item" onClick={this.click}>
        <div className="chatteammem_bx m-0" onClick={this.setClassHide}>
          <div className="chatuser">
            <div className="userimgC">
              <img
                src={
                  this.props.profilePicture
                    ? General.showImageNew("profile", this.props.profilePicture)
                    : "/images/muser.jpg"
                }
                alt="Image"
              />{" "}
            </div>
            {this.props.status === true ? (
              <span className="livestats_offline"></span>
            ) : (
              <span className="livestats"></span>
            )}
          </div>
          <div className="chatusername">
            <p>{this.props.name}</p>
          </div>{" "}
          <div className="chatu_time">
            <p>
              {this.props.lastLogin
                ? Moment(this.props.lastLogin).format("HH:mm")
                : ""}
            </p>
          </div>
          <a href="#!" className="onhovelink"></a>
        </div>
        {/* <div>{this.props.name}</div> */}
        {/* <span>{this.props.participants}</span> */}
      </div>
    ) : (
      ""
    );
  }
}
