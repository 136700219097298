import Lodash from "lodash";
import Validator from "validator";

class CheckValidation {
  static responseBoolean = {
    NotEmpty: false,
    isEmpty: false,
    contains: false,
    isAlpha: false,
  };

  static async validateFormData(FormRules, FormData) {
    var errors = {};
    var flag = true;

    await Object.keys(FormRules).map(async (field) => {
      if (Lodash.has(FormData, field)) {
        let fieldErrors = [];
        await FormRules[field].map(async (rule, index) => {
          let option = Lodash.has(rule, "option");
          var matchResult = CheckValidation.responseBoolean[rule.func]
            ? CheckValidation.responseBoolean[rule.func]
            : false;
          switch (rule.lib) {
            case "Lodash":
              if (
                option &&
                (await Lodash[rule.func](FormData[field], rule.option)) ===
                  matchResult
              ) {
                flag = false;
                fieldErrors.push(rule.msg);
              } else if (
                (await Lodash[rule.func](FormData[field])) === matchResult
              ) {
                flag = false;
                fieldErrors.push(rule.msg);
              }
              break;
            case "Validator":
              if (
                option &&
                (await Validator[rule.func](
                  FormData[field].toString(),
                  rule.option
                )) === matchResult
              ) {
                flag = false;
                fieldErrors.push(rule.msg);
              } else if (
                (await Validator[rule.func](FormData[field].toString())) ===
                matchResult
              ) {
                flag = false;
                fieldErrors.push(rule.msg);
              }
              break;
            default:
              if (
                option &&
                (await CheckValidation[rule.func](
                  FormData[field],
                  rule.option
                )) === matchResult
              ) {
                flag = false;
                fieldErrors.push(rule.msg);
              } else if (
                (await CheckValidation[rule.func](FormData[field])) ===
                matchResult
              ) {
                flag = false;
                fieldErrors.push(rule.msg);
              }
          }
          return;
        });
        if (fieldErrors.length) {
          errors[field] = fieldErrors;
        }
      }
      return;
    });
    // if(!Lodash.isEmpty(errors)){
    //   console.log(errors);
    // }
    return flag ? flag : errors;
  }

  static async NotEmpty(value = "") {
    return !Validator.isEmpty(value.toString(), { ignore_whitespace: true });
  }
  
}

export default CheckValidation;
