import Model from "Models/Model";

class ContactUs extends Model {
  dbSchema() {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
      showCaptcha: localStorage.getItem("CaptchaSetting")
        ? JSON.parse(localStorage.getItem("CaptchaSetting"))
        : false,
      captchaValue: "",
    };
  }

  validationRules() {
    return {
      name: [{ lib: "Custom", func: "NotEmpty", msg: "Name is required" },
            /* { lib: "Validator", func: "isAlpha", msg: "Special correctors are not allowed."} */],
      email: [{ lib: "Custom", func: "NotEmpty", msg: "Email is required" },
            {lib:"Validator", func:"isEmail", msg:"This is not a valid email address"}],
      subject: [
        { lib: "Custom", func: "NotEmpty", msg: "Subject is required" },
      ],
      message: [
        { lib: "Custom", func: "NotEmpty", msg: "Message is required" },
      ],
    };
  }
}

export default new ContactUs();
