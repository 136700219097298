import React, { Component } from "react";
import LoginForm from "Forms/LoginForm";
import SignupForm from "Forms/SignupForm";
import User from "Models/User";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEmailLogin: true,
      showFacebookLogin: true,
      showTwitterLogin: true,
    };
  }

  componentWillMount = () => {
    // Get Account Setting
    User.getApi(
      "APIS_GET_ACCOUNT_SETTING",
      async (response) => {
        if (response && response[0]["common_setting"]) {
          this.setState({
            showEmailLogin: response[0]["common_setting"][0]["emailAddress"],
            showTwitterLogin: response[0]["common_setting"][0]["twitter"],
            showFacebookLogin: response[0]["common_setting"][0]["facebook"],
          });
        }
      },
      false
    );
  };

  render() {
    return (
      <section className="signin_Newpage contentBodyHeight bg-light-dark repeat-section-login-New">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="loginbx_main_New">
                <div className="log_sign_inner_New">
                  <ul className="nav nav-tabs logintab_New" role="tablist">
                    <li className="nav-item New-W-50">
                      {" "}
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#tabs-1"
                        role="tab"
                      >
                        Login
                      </a>{" "}
                    </li>
                    <li className="nav-item New-W-50">
                      {" "}
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#tabs-2"
                        role="tab"
                      >
                        Register
                      </a>{" "}
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane active"
                      id="tabs-1"
                      role="tabpanel"
                    >
                      {(this.state.showTwitterLogin === true ||
                        this.state.showFacebookLogin === true) && (
                        <div className="headingsign_New">
                          <p className="text_small">
                            Log in with social (recommended)
                          </p>
                        </div>
                      )}
                      <LoginForm showEmailLogin={this.state.showEmailLogin} />
                    </div>
                    <div className="tab-pane" id="tabs-2" role="tabpanel">
                      {(this.state.showTwitterLogin === true ||
                        this.state.showFacebookLogin === true) && (
                        <div className="headingsign_New">
                          <p className="text_small">
                            Register in with social (recommended)
                          </p>
                        </div>
                      )}
                      <SignupForm showEmailLogin={this.state.showEmailLogin} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Login;
