import React, { Component } from "react";
import SocialForm from "Forms/SocialForm";
import User from "Models/User";
import Lodash from "lodash";
import { Link } from "react-router-dom";


class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: User.dbSchema("LOGIN"),
      password: true,
      showEmailLogin: this.props.showEmailLogin,
    };
    
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.showEmailLogin !== state.showEmailLogin) {
      return {
        showEmailLogin: props.showEmailLogin,
      };
    }
  };

  submitHandler = async (event) => {
    event.preventDefault();
    var validationRules = await User.validationRules();
    validationRules.password = [
      { lib: "Custom", func: "NotEmpty", msg: "Password is required" },
    ];
    if (
      (await User.checkValidation(validationRules, this.state.User)) === true
    ) {
      User.postApi(
        "APIS_LOGIN",
        this.state.User,
        async (response) => {
          // console.log(response);
          if (Lodash.has(response, "_id")) {
            localStorage.setItem("firstname", response.firstName);
            localStorage.setItem("username", this.state.User.email);
            localStorage.setItem("UserData", JSON.stringify(response));
            // localStorage.setItem("password", this.state.User.password);
           // User.redirectTo("", true);
            //window.location.href = '/';
          console.log("prev url",localStorage.getItem("CurrentUrl"))
          let prevUrl = localStorage.getItem("CurrentUrl");
          if(prevUrl){
            window.location.href = prevUrl;
          }else{
            window.location.href = '/';
          }
         
          }
        },
        false
      );
    }
  };

  onChangeHanlde = (event) => {
    let { User } = this.state;
    User[event.target.name] = event.target.value;
    this.setState({ User: User });
  };

  passwordToggle = (event) => {
    event.preventDefault();
    var password = this.state.password ? false : true;
    this.setState({ password: password });
  };

  render() {
    return (
      <div className="formgrop_main_New">
        <SocialForm />
        <form onSubmit={this.submitHandler} method="post">
          <div className="row">
            {this.state.showEmailLogin === true && (
              <>
                <div className="col-sm-12 margin_top_new">
                  <div className="form-group">
                    <input
                      type="text"
                      name="email"
                      value={this.state.User.email}
                      onChange={this.onChangeHanlde}
                      className="form-control New_control"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  <div className="form-group icon_input signpass_New">
                    <input
                      type={this.state.password ? "password" : "text"}
                      name="password"
                      value={this.state.User.password}
                      onChange={this.onChangeHanlde}
                      className="form-control New_control"
                      placeholder="Password"
                    />
                    <a
                      href="#"
                      onClick={this.passwordToggle}
                      className="eyeshow_New"
                    >
                      <img
                        src="/images/eye.svg"
                        onClick={this.passwordToggle}
                        alt="Icon"
                        width="20"
                      />
                    </a>
                  </div>
                </div>
                <div className="col-sm-6">
                  <p className="text_New">
                    <Link to="/forget-password">Forgot Password?</Link>{" "}
                  </p>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="custom_cheak_New">
                      <ul>
                        <li>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="exampleCheck1"
                            />
                            <label
                              className="form-check-label f-12"
                              for="exampleCheck1"
                            >
                              Keep me logged in
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 marginfour_side">
                  <div className="form-group text-center">
                    <button type="submit" className="btnx btn-secondaryx">
                      Login
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    );
  }
}
export default LoginForm;
