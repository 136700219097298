import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import Order from "Models/Order";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import socket from "Helpers/Socket";
import DatePicker from "react-date-picker";
import ReturnModalComponent from "./ReturnModalComponent";
import QB from "Helpers/Quickblox";
//import {ORDER_POST,SHIPPED,DELIVER,ACCEPT,DISPUTE,RETURN,CANCEL} from 'Helpers/Constant';

//Modal.setAppElement('#test');
export class MyOrderComponent extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      expectedDeliveryDate: new Date(),
      returnExpectedDeliveryDate: new Date(),
      shippingNote: "",
      retrunNote: "",
      modalIsOpen: false,
      returnModalIsOpen: false,
      showAddressModal: false,
      address: "",
      returnShippingNote: "",
      isModalOpen: false,
    };
    this.handleShippingNote = this.handleShippingNote.bind(this);
    this.handleReturnShippingNote = this.handleReturnShippingNote.bind(this);
    this.submitHandler = this.submitHandler.bind(this);
    this.returnsubmitHandler = this.returnsubmitHandler.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  selectExpDeliveryData = (date) => {
    this.setState({ expectedDeliveryDate: date });
  };
  selectReturnExpDeliveryData = (date) => {
    this.setState({ returnExpectedDeliveryDate: date });
  };

  dateTimeConverson = (dateObj) => {
    const dateTime = new Date(dateObj);
    return dateTime.toLocaleString("en-ZA", { hour12: true });
  };

  sendAutoMessageOnChat = (email, msg) => {
    var searchParams = { login: email };
    QB.users.get(searchParams, function (error, user) {
      if (!error) {
        let params = {
          type: 3,
          occupants_ids: [user.id],
        };

        QB.chat.dialog.create(params, (error, dialog) => {
          if (!error) {
            let message = {
              type: "chat",
              body: msg,
              extension: {
                save_to_history: 1,
                dialog_id: dialog._id,
              },
              markable: 1,
            };
            QB.chat.send(user.id, message);
          } else {
            console.log("Dialog Create Error", error);
          }
        });
      }
    });
  };

  returnOrderStatusHandler = (return_status, orderId) => {
    MyProfile.postApi(
      "API_CHANGE_ORDER_RETURN_STATUS",
      { orderId, return_status },
      async (res, status) => {
        if (status === "success") {
          let { order } = this.props;
          order["return_status"] = return_status;
          this.setState({ order: order });
          socket.emit("orderStatusHandler", order);
          let msg =
            "Return Request Reject Of Product " + res.product_id.productName;
          this.sendAutoMessageOnChat(res.buyer_id.email, msg);
        }
      },
      true
    );
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    this.returnAddressHandler(1);
  };

  returnAddressHandler = (status) => {
    // console.log("status return",status);
    const { address } = this.state;
    // console.log("address",address);
    const orderId = this.props.order._id;
    const return_status = "Accepted";
    let productReturn = 0;
    if (status == 1) {
      productReturn = 1;
      if (address.trim() == "") {
        document.getElementById("return-address").style.display = "block";
        return;
      }
    }

    Order.postApi(
      "API_CHANGE_ORDER_RETURN_ADDRESS",
      { orderId, return_status, address, productReturn },
      async (res, status) => {
        if (status === "success") {
          let { order } = this.props;
          order["return_status"] = res.return_status;
          order["return_address"] = res.return_address;
          order["is_refunded"] = res.is_refunded;
          this.setState({
            order: order,
            showAddressModal: false,
            showAcceptModal: false,
          });
          socket.emit("orderStatusHandler", order);
          // let msg = "Return Request Accepted Of Product "+res.product_id.productName;
          // this.sendAutoMessageOnChat(res.buyer_id.email, msg);
        }
      },
      true
    );
  };
  orderRefund = (orderId, orderStatus) => {
    Order.postApi(
      "API_ORDER_REFUND",
      { orderId, orderStatus },
      async (res, status) => {
        if (status === "success") {
          let { order } = this.props;
          order["orderStatus"] = 11;
          this.setState({ order: order });
          socket.emit("orderStatusHandler", order);
          let msg = "Order Refunded Successfully " + res.product_id.productName;
          this.sendAutoMessageOnChat(res.buyer_id.email, msg);
        }
      },
      true
    );
  };

  handleShippingNote = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  handleReturnShippingNote = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleChildReq = (e) => {
    let { order } = this.props;
    order["orderStatus"] = 5;
    if (e.reson !== null) {
      order["reson_id"] = e.reson;
    } else {
      order["other_reson"] = e.other_reson;
    }

    this.setState({ order: order, returnModalIsOpen: false });
    socket.emit("orderStatusHandler", order);

    this.sendAutoMessageOnChat(e.seller_email, e.msg);
  };

  checkEndTimeToCancel = (order) => {
    const originalDate = new Date(order.created_at);

    const newDate = new Date(originalDate.getTime() + 60 * 60 * 1000);
    // console.log("newDate",newDate)
    const formattedDate = newDate.toISOString();
    // console.log("formattedDate",formattedDate)
    const formattedDateAsDate = new Date(formattedDate);
    // console.log("formattedDateAsDate",formattedDateAsDate)
    const currentTime = new Date();
    // console.log("currentTime",currentTime)
    let test = currentTime < formattedDateAsDate;
    // console.log("test",test)
    return currentTime < formattedDateAsDate;
  };

  handleChildCloseReq = () => {
    this.setState({ returnModalIsOpen: false });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const { expectedDeliveryDate, shippingNote } = this.state;

    if (!expectedDeliveryDate) {
      document.getElementById("expected-date").style.display = "block";
      return;
    }
    if (shippingNote.trim() === "") {
      document.getElementById("shipping-note").style.display = "block";
      return;
    }

    let datestring =
      expectedDeliveryDate.getFullYear() +
      "-" +
      (expectedDeliveryDate.getMonth() + 1) +
      "-" +
      expectedDeliveryDate.getDate();

    let dateExpect = this.dateTimeConverson(expectedDeliveryDate);

    Order.postApi(
      "APIS_SUBMIT_EXPECTED_DELIVERY_DATE",
      {
        orderId: this.props.order._id,
        expected_delivery_date: datestring,
        shipping_note: shippingNote,
        orderStatus: 2,
      },
      async (response, status) => {
        if (status === "success") {
          let { order } = this.props;
          order["orderStatus"] = 2;
          order["expected_delivery_date"] = expectedDeliveryDate;
          this.setState({ order: order });
          socket.emit("orderStatusHandler", order);
          this.setState({ modalIsOpen: false });
          this.sendAutoMessageOnChat(
            response.buyer_id.email,
            "Your order has been shipped for product " +
            response.product_id.productName +
            " and expected delivery date is " +
            dateExpect +
            " with notes (" +
            shippingNote +
            ")"
          );
        }
      },
      true
    );
  };

  returnsubmitHandler = (e) => {
    e.preventDefault();
    const { returnExpectedDeliveryDate, returnShippingNote } = this.state;

    if (!returnExpectedDeliveryDate) {
      document.getElementById("expected-date").style.display = "block";
      return;
    }
    if (returnShippingNote.trim() === "") {
      document.getElementById("shipping-note").style.display = "block";
      return;
    }

    let datestring =
      returnExpectedDeliveryDate.getFullYear() +
      "-" +
      (returnExpectedDeliveryDate.getMonth() + 1) +
      "-" +
      returnExpectedDeliveryDate.getDate();

    let dateExpect = this.dateTimeConverson(returnExpectedDeliveryDate);

    Order.postApi(
      "APIS_SUBMIT_RETURN_SHIPPING_NOTE",
      {
        orderId: this.props.order._id,
        return_shipping_note: returnShippingNote,
        return_expected_delivery_date: datestring,
        orderStatus: 10,
      },
      async (response, status) => {
        if (status === "success") {
          let { order } = this.props;
          order["orderStatus"] = 10;
          order["return_expected_delivery_date"] = returnExpectedDeliveryDate;
          this.setState({ order: order });
          socket.emit("orderStatusHandler", order);
          this.setState({ returnShippedModal: false });
          // this.sendAutoMessageOnChat(response.buyer_id.email, 'Your order has been shipped for product '+response.product_id.productName+' and expected delivery date is '+ dateExpect+' with notes ('+shippingNote+')');
        }
      },
      true
    );
  };

  render() {
    const { isModalOpen } = this.state;

    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };

    const {
      product,
      keyIndex,
      order,
      status,
      deleteProductHandler,
      publishProductHandler,
    } = this.props;

    const sellerRequestHandler = (orderId, sellerRequest) => {
      if (sellerRequest) {
        MyProfile.postApi(
          "API_CHANGE_SELLER_REQUEST_STATUS",
          { orderId, sellerRequest },
          async (res, status) => {
            if (status === "success") {
              order["sellerRequest"] = Number(sellerRequest);
              this.setState({ order: order });
            }
          },
          true
        );
      }
    };

    const orderStatusHandler = (orderId, orderStatus) => {
      //  console.log("orderStatus",orderStatus)

      if (orderStatus) {
        if (Number(orderStatus) === 2) {
          this.openModal();
          return;
        }

        if (Number(orderStatus) === 5) {
          this.setState({ returnModalIsOpen: true });
          return;
        }
        if (Number(orderStatus) === 11) {
          this.orderRefund(orderId, orderStatus);
          return;
        }
        MyProfile.postApi(
          "API_CHANGE_ORDER_STATUS",
          { orderId, orderStatus },
          async (res, status) => {
            if (status === "success") {
              order["orderStatus"] = Number(orderStatus);
              this.setState({ order: order });
              socket.emit("orderStatusHandler", order);
            }
          },
          true
        );
      }
    };
    const returnShipped = (orderId, status) => {
      this.setState({ returnShippedModal: true });
    };

    return (
      <>



        <div className="col-lg-12 listside_newperent px-2 mb-2">
          {this.state.returnModalIsOpen && (
            <ReturnModalComponent
              returnModalIsOpen={this.state.returnModalIsOpen}
              orderId={order._id}
              handler={this.handleChildReq}
              closeModal={this.handleChildCloseReq}
            />
          )}
          {/* <DeliveryDetail modalIsOpen={this.state.modalIsOpen} /> */}
          <Modal
            isOpen={this.state.modalIsOpen}
            style={customStyles}
            contentLabel="shippedModal Modal"
            ariaHideApp={false}
            className="shipModal"
            onRequestClose={() =>
              this.setState({
                modalIsOpen: false,
                shippingNote: "",
              })
            }
          >
            <button onClick={() => this.closeModal()} className="shipCloseBtn">
              <i className="fa fa-times"></i>
            </button>
            <form className="form-horizontal" onSubmit={this.submitHandler}>
              <div className="form-group row">
                <label className="control-label col-sm-12" for="expdate">
                  Expected Delivery Date:
                </label>
                <div className="col-sm-12">
                  <DatePicker
                    id="expdate"
                    className="form-control"
                    onChange={this.selectExpDeliveryData}
                    value={this.state.expectedDeliveryDate}
                  />
                  <p
                    id="expected-date"
                    className="text-danger"
                    style={{ display: "none" }}
                  >
                    Expected Delivery Date is required*
                  </p>
                </div>
              </div>
              <div className="form-group row">
                <label className="control-label col-sm-12">
                  Shipping Note:
                </label>
                <div className="col-sm-12">
                  <textarea
                    name="shippingNote"
                    value={this.state.shippingNote}
                    onChange={this.handleShippingNote}
                    class="form-control"
                    rows="2"
                    placeholder="shipping note.."
                  ></textarea>
                  <p
                    id="shipping-note"
                    className="text-danger"
                    style={{ display: "none" }}
                  >
                    Shipping Note is required*
                  </p>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-12 text-center">
                  <button type="submit" className="btn themeBtn">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal>
          <Modal
            isOpen={this.state.returnShippedModal}
            style={customStyles}
            contentLabel="shippedModal Modal"
            ariaHideApp={false}
            className="shipModal"
            onRequestClose={() =>
              this.setState({
                returnShippedModal: false,
                shippingNote: "",
              })
            }
          >
            <button onClick={() => this.closeModal()} className="shipCloseBtn">
              <i className="fa fa-times"></i>
            </button>
            <form
              className="form-horizontal"
              onSubmit={this.returnsubmitHandler}
            >
              <div className="form-group row">
                <label className="control-label col-sm-12" for="expdate">
                  Expected Delivery Date:
                </label>
                <div className="col-sm-12">
                  <DatePicker
                    id="expdate"
                    className="form-control"
                    onChange={this.selectReturnExpDeliveryData}
                    value={this.state.returnExpectedDeliveryDate}
                  />
                  <p
                    id="expected-date"
                    className="text-danger"
                    style={{ display: "none" }}
                  >
                    Expected Delivery Date is required*
                  </p>
                </div>
              </div>
              <div className="form-group row">
                <label className="control-label col-sm-12">
                  Shipping Note:
                </label>
                <div className="col-sm-12">
                  <textarea
                    name="returnShippingNote"
                    value={this.state.returnShippingNote}
                    onChange={this.handleReturnShippingNote}
                    class="form-control"
                    rows="2"
                    placeholder="shipping note.."
                  ></textarea>
                  <p
                    id="shipping-note"
                    className="text-danger"
                    style={{ display: "none" }}
                  >
                    Shipping Note is required*
                  </p>
                </div>
              </div>
              <div class="form-group">
                <div class="col-sm-offset-2 col-sm-12 text-center">
                  <button type="submit" className="btn themeBtn">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </Modal>


          <div className="background-color">


            <div className="product-listing-product desktop_view">
              <div className="row">
                <div className="col-12 col-sm-3">
                  <div className="product-img-bx">
                    <Link to={`/product/${product?.productSlug}`}>
                      {product?.adType && product?.adType === "Featured" && (
                        <div className="specialtags">
                          <span
                            className={
                              (product.adType === "Featured"
                                ? "black"
                                : "green") + "_special"
                            }
                          >
                            {product.adType}{" "}
                          </span>
                        </div>
                      )}
                      {product?.productMainImage ? (
                        <img
                          src={General.showImage(
                            "products/resize-180-180",
                            product.productMainImage
                          )}
                          onError={(e) => (e.target.src = "/images/no-image.jpg")}
                          alt={product.productName}
                        />
                      ) : (
                        <img src="/images/no-image.jpg" alt="product" />
                      )}

                      <div className="totalimg_sectab">
                        {product?.productImages
                          ? product.productImages.length
                          : 0}{" "}
                        <i className="fas fa-camera"></i>
                      </div>
                    </Link>
                  </div>
                </div>

                <div className="col-12 col-sm-9 my-productColBg">
                  <div className="product-outer-bx productBig-outer-bx">
                    <div className="product-name-bx content_gobottom_new prdNewRow_row">
                      <div className="newPrdList_col-1">
                        {/* <Moment fromNow>{order?.created_at}</Moment> */}
                        <Link
                          to={`/product/${product?.productSlug}`}
                          className="d-flex"
                        >
                          <h4>{product?.productName}</h4>

                        </Link>
                        <h5>{product?.location}</h5>
                        <h6>
                          <span>{General.showPriceDecimal(product?.productPrice)}</span>
                        </h6>
                        <span
                          className="labltable lablesct view_btn"
                          style={{ cursor: "pointer", marginTop: "5px" }}
                          onClick={this.handleOpenModal}
                        >
                          <i className="far fa-eye"></i> View Address
                        </span>

                        <Modal
                          isOpen={isModalOpen}
                          onRequestClose={this.handleCloseModal}
                          contentLabel="Buyer Protection"
                          className="modal-content"
                          overlayClassName="modal-overlay"
                        >
                          <button
                            onClick={this.handleCloseModal}
                            className="shipCloseBtn"
                          >
                            <i className="fa fa-times "></i>
                          </button>
                          <div className="product-outer-bx productBig-outer-bx">
                            <div className="product-name-bx content_gobottom_new prdNewRow_row">
                              <div className="viewAddressPopup viewAddressPopupTrack">
                                {order.delivery === 2 ? (
                                  <h5>
                                    <span>Shipping Address : </span>
                                    {`${order?.shipping_address?.name} ${order?.shipping_address?.address?.line1
                                      } ${order?.shipping_address?.address?.line2
                                      } ${order?.shipping_address?.address?.city} ${order?.shipping_address?.address?.country ==
                                        "GB"
                                        ? "United Kingdom"
                                        : order?.shipping_address?.address
                                          ?.country
                                      } ${order?.shipping_address?.address
                                        ?.postal_code
                                      }`}
                                  </h5>
                                ) :
                                  <span>No Address Found</span>
                                }
                              </div>
                            </div>
                          </div>
                        </Modal>
                        <ul className="listofsubtabbx">
                          <li>
                            <strong>Added</strong>
                            <span>
                              <Moment format="YYYY/MM/DD">
                                {order?.created_at}
                              </Moment>
                            </span>
                          </li>
                          {/* {product?.planId && (
                            <li>
                              <strong>Expires</strong>
                              <span>
                                <Moment
                                  add={{ days: product.planId.duration }}
                                  format="YYYY/MM/DD"
                                >
                                  {product.time}
                                </Moment>
                              </span>
                            </li>
                          )} */}
                          {order?.expected_delivery_date &&
                            order.orderStatus === 2 && (
                              <li>
                                <strong>Expected Delivery</strong>
                                <span>
                                  <Moment format="YYYY/MM/DD">
                                    {order?.expected_delivery_date}
                                  </Moment>
                                </span>
                              </li>
                            )}
                        </ul>
                        {order.orderStatus === 5 && (
                          <ul>
                            <li>
                              <strong>Return Reson :</strong>
                              {order?.reson_id !== null ? (
                                <span>{order?.reson_id["name"]}</span>
                              ) : (
                                <span>{order?.other_reson}</span>
                              )}
                              <div className="mt-2">
                                {order?.return_status &&
                                  order?.return_status != null && (
                                    <>
                                      <span className="labltable lablesct">
                                        {order.return_status}
                                      </span>
                                    </>
                                  )}
                                {status.toLowerCase() === "sold" &&
                                  order?.return_status === null && (
                                    <>
                                      <button
                                        className="btn-sm accept-theme mr-1"
                                        onClick={() =>
                                          this.setState({ showAcceptModal: true })
                                        }
                                      >
                                        Accept
                                      </button>
                                      <button
                                        className="btn-sm reject-theme"
                                        onClick={() =>
                                          this.returnOrderStatusHandler(
                                            "Rejected",
                                            order?._id
                                          )
                                        }
                                      >
                                        Reject
                                      </button>
                                    </>
                                  )}
                              </div>
                            </li>
                            {order?.return_address &&
                              order.return_address !== null && (
                                <li>
                                  <strong>Return Address : </strong>
                                  <span>{order.return_address}</span>
                                </li>
                              )}
                          </ul>
                        )}
                      </div>

                      <div className="subtab_edit newPrdList_col-2 right_section">
                        <div className="subtab_editlabl">
                          <span className="labltable lablesct mb-2">
                            {(() => {
                              if (order.orderStatus === 0) {
                                return "Order Posted";
                              } else if (order.orderStatus === 1) {
                                return "Cancelled";
                              } else if (order.orderStatus === 2) {
                                return "Shipped";
                              } else if (order.orderStatus === 3) {
                                return "Delivered";
                              } else if (order.orderStatus === 4) {
                                return "Completed";
                              } else if (
                                order.orderStatus === 11 ||
                                (order.orderStatus === 5 &&
                                  order.is_refunded == 1) ||
                                order.orderStatus === 8 ||
                                order.orderStatus === 9
                              ) {
                                return "Suspend";
                              } else if (order.orderStatus === 6) {
                                return "Order Disputed";
                              } else if (
                                [5].includes(order.orderStatus) &&
                                order?.return_status == "Accepted" &&
                                status.toLowerCase() === "bought" &&
                                order.is_refunded == 0
                              ) {
                                return "Return Order";
                              } else if (order.orderStatus === 10) {
                                return "Return Order Shipped";
                              } else if (
                                [5].includes(order.orderStatus) &&
                                order?.return_status == null &&
                                order.is_refunded == 0
                              ) {
                                return "Return Requested";
                              } else if (
                                [5].includes(order.orderStatus) &&
                                order?.return_status == "Accepted" &&
                                status.toLowerCase() === "sold" &&
                                order.is_refunded == 0
                              ) {
                                return "Return Accepted";
                              } else if (
                                [5].includes(order.orderStatus) &&
                                order?.return_status == "Rejected"
                              ) {
                                return "Return Rejected";
                              }
                            })()}
                          </span>
                        </div>

                        {(() => {
                          if (status.toLowerCase() === "sold") {
                            if (order.sellerRequest === 1) {
                              return (
                                <>
                                  <div className="subtab_editlabl">
                                    <span className="labltable lablesct">
                                      Request Money
                                    </span>
                                  </div>
                                </>
                              );
                            } else if (order.sellerRequest === 2)
                              return (
                                <>
                                  <div className="subtab_editlabl">
                                    <span className="labltable lablesct">
                                      Release Money
                                    </span>
                                  </div>
                                </>
                              );
                          }
                        })()}
                        {order.orderStatus != 1 && (
                          <span className="labltable lablesct  my-2 bg-danger ">
                            {status}
                          </span>
                        )}
                        <div className="subtab_editlabl select_bx">
                          {order.orderStatus === 0 &&
                            status.toLowerCase() === "sold" && (
                              <select
                                className="custom-select form-control"
                                value={order?.orderStatus}
                                onChange={(e) =>
                                  orderStatusHandler(order._id, e.target.value)
                                }
                              >
                                <option value="">Select Option</option>
                                {order.orderStatus === 0 && (
                                  <option value="1">Cancel Order</option>
                                )}
                                {order.orderStatus === 0 &&
                                  order.delivery === 2 && (
                                    <option value="2">Shipped </option>
                                  )}
                              </select>
                            )}
                          {order.orderStatus === 6 &&
                            order?.return_status == "Rejected" &&
                            status.toLowerCase() === "sold" && (
                              <select
                                className="custom-select form-control"
                                value={order?.orderStatus}
                                onChange={(e) =>
                                  orderStatusHandler(order._id, e.target.value)
                                }
                              >
                                <option value="">Select Option</option>
                                <option value="8">Resolve for Buyer</option>
                              </select>
                            )}

                          {/* {(([0].includes(order.orderStatus) && order?.return_status !== 'Accepted' && status.toLowerCase() === "bought" && this.checkEndTimeToCancel(order))) && (
                        <select
                          className="custom-select form-control"
                          value={order?.orderStatus}
                          onChange={(e) =>
                            orderStatusHandler(order._id, e.target.value)
                          }
                        >
                          <option value="">Select Option</option>
                          {order.orderStatus === 0 && (<option value="1">Cancel Order</option>) }
                        </select>
                        )} */}
                          {[0].includes(order.orderStatus) &&
                            order?.return_status !== "Accepted" &&
                            status.toLowerCase() === "bought" &&
                            order.delivery == 1 && (
                              <select
                                className="custom-select form-control"
                                value={order?.orderStatus}
                                onChange={(e) =>
                                  orderStatusHandler(order._id, e.target.value)
                                }
                              >
                                <option value="">Select Option</option>
                                {order.orderStatus === 0 &&
                                  this.checkEndTimeToCancel(order) && (
                                    <option value="1">Cancel Order</option>
                                  )}
                                <option value="4"> Completed </option>
                              </select>
                            )}
                          {[5].includes(order.orderStatus) &&
                            order?.return_status == "Accepted" &&
                            status.toLowerCase() === "bought" &&
                            order.is_refunded == 0 && (
                              <select
                                className="custom-select form-control"
                                value={order?.orderStatus}
                                onChange={(e) =>
                                  returnShipped(order._id, e.target.value)
                                }
                              >
                                <option value="">Select Option</option>
                                <option value="1">Shipped</option>
                              </select>
                            )}
                          {[10].includes(order.orderStatus) &&
                            status.toLowerCase() === "sold" &&
                            order.is_refunded == 0 && (
                              <select
                                className="custom-select form-control"
                                value={order?.orderStatus}
                                onChange={(e) =>
                                  orderStatusHandler(order._id, e.target.value)
                                }
                              >
                                <option value="">Select Option</option>
                                <option value="11">Order Returned</option>
                              </select>
                            )}
                          {[2, 3].includes(order.orderStatus) &&
                            status.toLowerCase() === "bought" && (
                              <select
                                className="custom-select form-control"
                                value={order?.orderStatus}
                                onChange={(e) =>
                                  orderStatusHandler(order._id, e.target.value)
                                }
                              >
                                <option value="">Select Option</option>
                                {/* {order.orderStatus === 0 && this.checkEndTimeToCancel(order) && (<option value="1">Cancel Order</option>) } */}
                                {order.orderStatus === 2 && (
                                  <option value="3">Delivered </option>
                                )}
                                {(order.orderStatus === 2 ||
                                  order.orderStatus === 3 ||
                                  order.delivery === 1) && (
                                    <option value="4"> Completed </option>
                                  )}
                                {order.orderStatus > 2 &&
                                  order.orderStatus < 5 && (
                                    <option value="5"> Return </option>
                                  )}
                                {/* {(order.orderStatus === 5 && order?.return_status === 'Rejected') && (<option value="6">Dispute Order{" "}</option>) }
                          {(order.orderStatus === 5 && order?.return_status === 'Rejected') && (<option value="4">Complete Order{" "}</option>) }
                          {(order.orderStatus === 6 && order?.return_status === 'Rejected') && (<option value="9">Resolve for Seller{" "}</option>) } */}
                              </select>
                            )}
                          {[5, 6].includes(order.orderStatus) &&
                            order?.return_status == "Rejected" &&
                            status.toLowerCase() === "bought" && (
                              <select
                                className="custom-select form-control"
                                value={order?.orderStatus}
                                onChange={(e) =>
                                  orderStatusHandler(order._id, e.target.value)
                                }
                              >
                                <option value="">Select Option</option>
                                {/* {order.orderStatus === 0 && this.checkEndTimeToCancel(order) && (<option value="1">Cancel Order</option>) } */}
                                {/* {order.orderStatus === 2 && (<option value="3">Delivered{" "}</option>) }
                          {(order.orderStatus === 2 || order.orderStatus === 3 || order.delivery === 1) && ( <option value="4"> Completed{" "}</option>)}
                          {(order.orderStatus > 2 && order.orderStatus < 5) && ( <option value="5"> Return{" "}</option>)} */}
                                {order.orderStatus === 5 &&
                                  order?.return_status === "Rejected" && (
                                    <option value="6">Dispute Order </option>
                                  )}
                                {order.orderStatus === 5 &&
                                  order?.return_status === "Rejected" && (
                                    <option value="4">Complete Order </option>
                                  )}
                                {order.orderStatus === 6 &&
                                  order?.return_status === "Rejected" && (
                                    <option value="9">Resolve for Seller </option>
                                  )}
                              </select>
                            )}
                        </div>

                        {status.toLowerCase() === "sold" &&
                          order.sellerRequest === 0 &&
                          order.orderStatus === 4 ? (
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => sellerRequestHandler(order._id, 1)}
                          >
                            Request Money
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-listing-product mobile_view position-relative">


              <div className="product_listing_product_block">
                <div className="product-img-bx">
                  <Link to={`/product/${product?.productSlug}`}>
                    {product?.adType && product?.adType === "Featured" && (
                      <div className="specialtags">
                        <span
                          className={
                            (product.adType === "Featured"
                              ? "black"
                              : "green") + "_special"
                          }
                        >
                          {product.adType}{" "}
                        </span>
                      </div>
                    )}
                    {product?.productMainImage ? (
                      <img
                        src={General.showImage(
                          "products/resize-180-180",
                          product.productMainImage
                        )}
                        onError={(e) => (e.target.src = "/images/no-image.jpg")}
                        alt={product.productName}
                      />
                    ) : (
                      <img src="/images/no-image.jpg" alt="product" />
                    )}

                    <div className="totalimg_sectab">
                      {product?.productImages
                        ? product.productImages.length
                        : 0}{" "}
                      <i className="fas fa-camera"></i>
                    </div>
                  </Link>
                </div>
                <div className="product-outer-bx productBig-outer-bx">
                  <div className="product-name-bx content_gobottom_new">
                    <div className="newPrdList_col-1">
                      {/* <Moment fromNow>{order?.created_at}</Moment> */}
                      <Link
                        to={`/product/${product?.productSlug}`}
                        className="d-flex"
                      >
                        <h4 className="product_mobile_view">{product?.productName}</h4>
                      </Link>
                      <h5>{product?.location}</h5>
                      <h6>
                        <span>{General.showPriceDecimal(product?.productPrice)}</span>
                      </h6>


                      <Modal
                        isOpen={isModalOpen}
                        onRequestClose={this.handleCloseModal}
                        contentLabel="Buyer Protection"
                        className="modal-content"
                        overlayClassName="modal-overlay"
                      >
                        <button
                          onClick={this.handleCloseModal}
                          className="shipCloseBtn"
                        >
                          <i className="fa fa-times "></i>
                        </button>
                        <div className="product-outer-bx productBig-outer-bx">
                          <div className="product-name-bx content_gobottom_new prdNewRow_row">
                            <div className="viewAddressPopup viewAddressPopupTrack">
                              {order.delivery === 2 ? (
                                <h5>
                                  <span>Shipping Address : </span>
                                  {`${order?.shipping_address?.name} ${order?.shipping_address?.address?.line1
                                    } ${order?.shipping_address?.address?.line2
                                    } ${order?.shipping_address?.address?.city} ${order?.shipping_address?.address?.country ==
                                      "GB"
                                      ? "United Kingdom"
                                      : order?.shipping_address?.address
                                        ?.country
                                    } ${order?.shipping_address?.address
                                      ?.postal_code
                                    }`}
                                </h5>
                              ) :
                                <span>No Address Found</span>
                              }
                            </div>
                          </div>
                        </div>
                      </Modal>

                      {order.orderStatus === 5 && (
                        <ul>
                          <li>
                            <strong>Return Reson :</strong>
                            {order?.reson_id !== null ? (
                              <span>{order?.reson_id["name"]}</span>
                            ) : (
                              <span>{order?.other_reson}</span>
                            )}
                            <div className="mt-2">
                              {order?.return_status &&
                                order?.return_status != null && (
                                  <>
                                    <span className="labltable lablesct">
                                      {order.return_status}
                                    </span>
                                  </>
                                )}
                              {status.toLowerCase() === "sold" &&
                                order?.return_status === null && (
                                  <>
                                    <button
                                      className="btn-sm accept-theme mr-1"
                                      onClick={() =>
                                        this.setState({ showAcceptModal: true })
                                      }
                                    >
                                      Accept
                                    </button>
                                    <button
                                      className="btn-sm reject-theme"
                                      onClick={() =>
                                        this.returnOrderStatusHandler(
                                          "Rejected",
                                          order?._id
                                        )
                                      }
                                    >
                                      Reject
                                    </button>
                                  </>
                                )}
                            </div>
                          </li>
                          {order?.return_address &&
                            order.return_address !== null && (
                              <li>
                                <strong>Return Address : </strong>
                                <span>{order.return_address}</span>
                              </li>
                            )}
                        </ul>
                      )}
                    </div>


                  </div>
                </div>
              </div>



              <div className="date_box">
                <ul className="listofsubtabbx">
                  <li>
                    <strong>Added</strong>
                    <span>
                      <Moment format="YYYY/MM/DD">
                        {order?.created_at}
                      </Moment>
                    </span>
                  </li>
                  {/* {product?.planId && (
                            <li>
                              <strong>Expires</strong>
                              <span>
                                <Moment
                                  add={{ days: product.planId.duration }}
                                  format="YYYY/MM/DD"
                                >
                                  {product.time}
                                </Moment>
                              </span>
                            </li>
                          )} */}
                  {order?.expected_delivery_date &&
                    order.orderStatus === 2 && (
                      <li>
                        <strong>Expected Delivery</strong>
                        <span>
                          <Moment format="YYYY/MM/DD">
                            {order?.expected_delivery_date}
                          </Moment>
                        </span>
                      </li>
                    )}
                </ul>

              </div>
              <div className="subtab_edit newPrdList_col-2 right_section">


                <div className="subtab_editlabl"><span
                  className="labltable lablesct view_btn my-2"
                  style={{ cursor: "pointer" }}
                  onClick={this.handleOpenModal}
                >
                  <i className="far fa-eye"></i> View Address
                </span>
                </div>
                <div className="subtab_editlabl">
                  <span className="labltable lablesct  my-2">
                    {(() => {
                      if (order.orderStatus === 0) {
                        return "Order Posted";
                      } else if (order.orderStatus === 1) {
                        return "Cancelled";
                      } else if (order.orderStatus === 2) {
                        return "Shipped";
                      } else if (order.orderStatus === 3) {
                        return "Delivered";
                      } else if (order.orderStatus === 4) {
                        return "Completed";
                      } else if (
                        order.orderStatus === 11 ||
                        (order.orderStatus === 5 &&
                          order.is_refunded == 1) ||
                        order.orderStatus === 8 ||
                        order.orderStatus === 9
                      ) {
                        return "Suspend";
                      } else if (order.orderStatus === 6) {
                        return "Order Disputed";
                      } else if (
                        [5].includes(order.orderStatus) &&
                        order?.return_status == "Accepted" &&
                        status.toLowerCase() === "bought" &&
                        order.is_refunded == 0
                      ) {
                        return "Return Order";
                      } else if (order.orderStatus === 10) {
                        return "Return Order Shipped";
                      } else if (
                        [5].includes(order.orderStatus) &&
                        order?.return_status == null &&
                        order.is_refunded == 0
                      ) {
                        return "Return Requested";
                      } else if (
                        [5].includes(order.orderStatus) &&
                        order?.return_status == "Accepted" &&
                        status.toLowerCase() === "sold" &&
                        order.is_refunded == 0
                      ) {
                        return "Return Accepted";
                      } else if (
                        [5].includes(order.orderStatus) &&
                        order?.return_status == "Rejected"
                      ) {
                        return "Return Rejected";
                      }
                    })()}
                  </span>
                </div>

                {(() => {
                  if (status.toLowerCase() === "sold") {
                    if (order.sellerRequest === 1) {
                      return (
                        <>
                          <div className="subtab_editlabl">
                            <span className="labltable lablesct">
                              Request Money
                            </span>
                          </div>
                        </>
                      );
                    } else if (order.sellerRequest === 2)
                      return (
                        <>
                          <div className="subtab_editlabl">
                            <span className="labltable lablesct my-2">
                              Release Money
                            </span>
                          </div>
                        </>
                      );
                  }
                })()}
                {order.orderStatus != 1 && (
                  <span className="labltable lablesct  my-2 bg-danger ">
                    {status}
                  </span>
                )}
                <div className="subtab_editlabl select_bx">
                  {order.orderStatus === 0 &&
                    status.toLowerCase() === "sold" && (
                      <select
                        className="custom-select form-control"
                        value={order?.orderStatus}
                        onChange={(e) =>
                          orderStatusHandler(order._id, e.target.value)
                        }
                      >
                        <option value="">Select Option</option>
                        {order.orderStatus === 0 && (
                          <option value="1">Cancel Order</option>
                        )}
                        {order.orderStatus === 0 &&
                          order.delivery === 2 && (
                            <option value="2">Shipped </option>
                          )}
                      </select>
                    )}
                  {order.orderStatus === 6 &&
                    order?.return_status == "Rejected" &&
                    status.toLowerCase() === "sold" && (
                      <select
                        className="custom-select form-control"
                        value={order?.orderStatus}
                        onChange={(e) =>
                          orderStatusHandler(order._id, e.target.value)
                        }
                      >
                        <option value="">Select Option</option>
                        <option value="8">Resolve for Buyer</option>
                      </select>
                    )}

                  {/* {(([0].includes(order.orderStatus) && order?.return_status !== 'Accepted' && status.toLowerCase() === "bought" && this.checkEndTimeToCancel(order))) && (
                        <select
                          className="custom-select form-control"
                          value={order?.orderStatus}
                          onChange={(e) =>
                            orderStatusHandler(order._id, e.target.value)
                          }
                        >
                          <option value="">Select Option</option>
                          {order.orderStatus === 0 && (<option value="1">Cancel Order</option>) }
                        </select>
                        )} */}
                  {[0].includes(order.orderStatus) &&
                    order?.return_status !== "Accepted" &&
                    status.toLowerCase() === "bought" &&
                    order.delivery == 1 && (
                      <select
                        className="custom-select form-control"
                        value={order?.orderStatus}
                        onChange={(e) =>
                          orderStatusHandler(order._id, e.target.value)
                        }
                      >
                        <option value="">Select Option</option>
                        {order.orderStatus === 0 &&
                          this.checkEndTimeToCancel(order) && (
                            <option value="1">Cancel Order</option>
                          )}
                        <option value="4"> Completed </option>
                      </select>
                    )}
                  {[5].includes(order.orderStatus) &&
                    order?.return_status == "Accepted" &&
                    status.toLowerCase() === "bought" &&
                    order.is_refunded == 0 && (
                      <select
                        className="custom-select form-control"
                        value={order?.orderStatus}
                        onChange={(e) =>
                          returnShipped(order._id, e.target.value)
                        }
                      >
                        <option value="">Select Option</option>
                        <option value="1">Shipped</option>
                      </select>
                    )}
                  {[10].includes(order.orderStatus) &&
                    status.toLowerCase() === "sold" &&
                    order.is_refunded == 0 && (
                      <select
                        className="custom-select form-control"
                        value={order?.orderStatus}
                        onChange={(e) =>
                          orderStatusHandler(order._id, e.target.value)
                        }
                      >
                        <option value="">Select Option</option>
                        <option value="11">Order Returned</option>
                      </select>
                    )}
                  {[2, 3].includes(order.orderStatus) &&
                    status.toLowerCase() === "bought" && (
                      <select
                        className="custom-select form-control"
                        value={order?.orderStatus}
                        onChange={(e) =>
                          orderStatusHandler(order._id, e.target.value)
                        }
                      >
                        <option value="">Select Option</option>
                        {/* {order.orderStatus === 0 && this.checkEndTimeToCancel(order) && (<option value="1">Cancel Order</option>) } */}
                        {order.orderStatus === 2 && (
                          <option value="3">Delivered </option>
                        )}
                        {(order.orderStatus === 2 ||
                          order.orderStatus === 3 ||
                          order.delivery === 1) && (
                            <option value="4"> Completed </option>
                          )}
                        {order.orderStatus > 2 &&
                          order.orderStatus < 5 && (
                            <option value="5"> Return </option>
                          )}
                        {/* {(order.orderStatus === 5 && order?.return_status === 'Rejected') && (<option value="6">Dispute Order{" "}</option>) }
                          {(order.orderStatus === 5 && order?.return_status === 'Rejected') && (<option value="4">Complete Order{" "}</option>) }
                          {(order.orderStatus === 6 && order?.return_status === 'Rejected') && (<option value="9">Resolve for Seller{" "}</option>) } */}
                      </select>
                    )}
                  {[5, 6].includes(order.orderStatus) &&
                    order?.return_status == "Rejected" &&
                    status.toLowerCase() === "bought" && (
                      <select
                        className="custom-select form-control"
                        value={order?.orderStatus}
                        onChange={(e) =>
                          orderStatusHandler(order._id, e.target.value)
                        }
                      >
                        <option value="">Select Option</option>
                        {/* {order.orderStatus === 0 && this.checkEndTimeToCancel(order) && (<option value="1">Cancel Order</option>) } */}
                        {/* {order.orderStatus === 2 && (<option value="3">Delivered{" "}</option>) }
                          {(order.orderStatus === 2 || order.orderStatus === 3 || order.delivery === 1) && ( <option value="4"> Completed{" "}</option>)}
                          {(order.orderStatus > 2 && order.orderStatus < 5) && ( <option value="5"> Return{" "}</option>)} */}
                        {order.orderStatus === 5 &&
                          order?.return_status === "Rejected" && (
                            <option value="6">Dispute Order </option>
                          )}
                        {order.orderStatus === 5 &&
                          order?.return_status === "Rejected" && (
                            <option value="4">Complete Order </option>
                          )}
                        {order.orderStatus === 6 &&
                          order?.return_status === "Rejected" && (
                            <option value="9">Resolve for Seller </option>
                          )}
                      </select>
                    )}
                </div>

                {status.toLowerCase() === "sold" &&
                  order.sellerRequest === 0 &&
                  order.orderStatus === 4 ? (
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => sellerRequestHandler(order._id, 1)}
                  >
                    Request Money
                  </button>
                ) : (
                  ""
                )}
              </div>


            </div>


          </div>
        </div>





        <Modal
          isOpen={this.state.showAddressModal}
          onRequestClose={() =>
            this.setState({
              showAddressModal: false,
              address: "",
            })
          }
          className="shipModal"
          style={customStyles}
        >
          <button
            onClick={() =>
              this.setState({ showAddressModal: false, address: "" })
            }
            className="shipCloseBtn"
          >
            <i className="fa fa-times"></i>
          </button>

          <form className="form-horizontal" onSubmit={this.handleFormSubmit}>
            <div class="row">
              <div className="col-sm-12">
                <div class="form-group">
                  <textarea
                    name="address"
                    value={this.state.address}
                    onChange={(e) => this.setState({ address: e.target.value })}
                    class="form-control"
                    rows="4"
                    placeholder="Return address.."
                  ></textarea>
                  <p
                    id="return-address"
                    className="text-danger"
                    style={{ display: "none" }}
                  >
                    Return Address is required*
                  </p>
                </div>
              </div>

              <div class="col-sm-12 text-center">
                <div class="form-group">
                  <button type="submit" className="btn themeBtn">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
        <Modal
          isOpen={this.state.showAcceptModal}
          onRequestClose={() =>
            this.setState({
              showAcceptModal: false,
            })
          }
          className="shipModal"
          style={customStyles}
        >
          <button
            onClick={() => this.setState({ showAcceptModal: false })}
            className="shipCloseBtn"
          >
            <i className="fa fa-times"></i>
          </button>

          <div className="income_heading py-2">
            <h3>Would you like the item to be returned?</h3>
          </div>
          <div className="btn-block text-center">
            <button
              className="btn btn-success mr-2"
              onClick={() =>
                this.setState({
                  showAcceptModal: false,
                  showAddressModal: true,
                })
              }
            >
              Yes
            </button>
            <button
              className="btn btn-danger"
              onClick={() => this.returnAddressHandler(0)}
            >
              No
            </button>
          </div>
        </Modal>
      </>
    );
  }
}

export default MyOrderComponent;
