import Model from "Models/Model";

class Quickblox extends Model {

    dbSchema() {
        return {
            login: "", password: "", name: ""
        };
    }
}

export default new Quickblox();
