import Product from "Models/Product";
import SubCategory from "Models/SubCategory";
import React, { Component } from "react";
import PostEditForm from "./PostEditForm";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
var qs = require("qs");

class EditAnAd extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    let session_id = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).session_id;
    let session_resp = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).response;

    const productId = props.match.params.productId;
    this.state = {
      productId: productId,
      productData: "",
      categoryType: "",
      categoryId: "",
      subCategoryId: "",
      session_id: session_id ? session_id : "",
      session_resp: session_resp ? session_resp : "",
    };
  }

  componentDidMount = () => {
    let session_id = this.state.session_id;
    let session_resp = this.state.session_resp;
    const { productId } = this.state;
    Product.getApiByParams(
      "APIS_GET_PRODUCT_BY_SLUG",
      productId,
      async (response, status) => {
        if (response) {
          // const currentUrl = window.location.href;
          // console.log("currentUrl",currentUrl);
          // const newUrl = currentUrl.split("?")[0];
          // window.history.replaceState({ path: newUrl }, "", newUrl);
          
          this.setState({ productData: response });
          this.setState({ subCategoryId: response.products.subCategoryId._id });

          SubCategory.postApi(
            "APIS_GET_SUB_CATEGORY_DETAIL_BY_Id",
            { params: { subCategoryId: response.products.subCategoryId._id } },
            async (response) => {
              this.setState({ categoryType: response.categoryId.slug });
              this.setState({ categoryId: response.categoryId._id });
            },
            false
          );
        }
      }
    );
  };

  render() {
    const { productData, categoryType, subCategoryId,session_id,session_resp } = this.state;
    return (
      <>
        {productData && subCategoryId ? (
          <PostEditForm
            product={productData.products}
            productCustomFields={productData.productCustomFields}
            subCategoryId={subCategoryId}
            session_id={session_id}
            session_resp={session_resp}
          />
        ) : (
          <>
            <section className="product-header bg-light-dark">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="breadcrumb-bx">
                      <div className="breadcrumb-bx-inner">
                        <Link to="/" className="btnx btn-primaryx">
                          <i className="fas fa-long-arrow-alt-left"></i> Back
                        </Link>
                        <ol className="breadcrumb mb-0 ml-3">
                          <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            Edit an AD
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="bg-light-dark">
              <ContentLoader
                speed={2}
                width={400}
                height={460}
                viewBox="0 0 400 460"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                // {...props}
              >
                <rect x="130" y="5" rx="2" ry="2" width="150" height="10" />
                <rect x="20" y="20" rx="2" ry="2" width="350" height="50" />
                <rect x="20" y="75" rx="2" ry="2" width="350" height="70" />
                <rect x="20" y="150" rx="2" ry="2" width="350" height="50" />
                <rect x="20" y="205" rx="2" ry="2" width="350" height="30" />
                <rect x="20" y="240" rx="2" ry="2" width="350" height="50" />
                <rect x="20" y="295" rx="2" ry="2" width="350" height="50" />
                <rect x="20" y="350" rx="2" ry="2" width="350" height="70" />
                <rect x="20" y="435" rx="2" ry="2" width="350" height="70" />
              </ContentLoader>
            </section>
          </>
        )}
      </>
    );
  }
}

export default EditAnAd;
