import Model from "Models/Model";

class ScheduleProductModel extends Model {
  dbSchema() {
    return {
      scheduleDate: "",
      scheduleTime: "",
      scheduleDuration: "",
      scheduleLength: "",
      editScheduleDuration: "",
      editScheduleLength: "",
    };
  }

  validationRules() {
    return {
      scheduleDate: [
        { lib: "Custom", func: "NotEmpty", msg: "Date is required" },
      ],
      scheduleTime: [
        { lib: "Custom", func: "NotEmpty", msg: "Time is required" },
      ],
      scheduleDuration: [
        { lib: "Custom", func: "NotEmpty", msg: "Please Select Duration" },
      ],
      scheduleLength: [
        { lib: "Custom", func: "NotEmpty", msg: "Please Select Length" },
      ],
      editScheduleDuration: [
        { lib: "Custom", func: "NotEmpty", msg: "Please Select Duration" },
      ],
      editScheduleLength: [
        { lib: "Custom", func: "NotEmpty", msg: "Please Select Length" },
      ],
    };
  }
}

export default new ScheduleProductModel();
