import React, { Component } from "react";
import {CardElement, Elements, ElementsConsumer, Billingu} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import Order from "Models/Order";
import Modal from "react-modal";
import General from "Helpers/General";
import { NoEncryption } from "@material-ui/icons";

class PurchaseSubscription extends Component {

    constructor(props) {
        super(props);
        this.state={
            isLoading:false
        }
    }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {stripe, elements, parentState, onSuccess, onClose, onLoading} = this.props;
    this.setState({isLoading:true});
    onLoading(true);

    if (elements == null) {
      return;
    }

    let buyer_id = Order.getAuthUserId();
    let transaction_type = parentState.transaction_type_other;
    let total_amount = parentState.sellerType === 2 ? ((parentState.businessType === 1) ? Number(parentState.pack.business_amount) : Number(parentState.pack.soletrader_amount)) : Number(parentState.pack.private_amount);
    total_amount += Number(parentState.video_stream_amount);

    let intentId;
    await Order.postApi(
        "API_STRIPE_INTENT",
        {
          buyer_id: buyer_id,
          transaction_type:transaction_type,
          total_amount:total_amount
        },
        async (response,status) => {
          if (status == "success") {
            intentId = response; 
          }
        },
        true
      );

      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
      });

        if(error){
            this.setState({isLoading:false});
            onLoading(false);
            return;
        }

      const confirmCardPayment = await stripe.confirmCardPayment(intentId,{
        payment_method: paymentMethod.id
      });

      if(confirmCardPayment.paymentIntent.status === "succeeded"){
        onSuccess(confirmCardPayment.paymentIntent);
      }else{
        onClose();
        Order.toastAlert("error", "Transaction failed!");
      }
  };

  render() {
    const { stripe, parentState } = this.props;

    const CardElementOptions = {
      style:{
        base: {
          fontWeight: '600'
        }
      },
      hidePostalCode:true
    };
    let amt =  parentState.sellerType === 2 ? ((parentState.businessType === 1) ? Number(parentState.pack.business_amount) : Number(parentState.pack.soletrader_amount)) : Number(parentState.pack.private_amount);
    amt += Number(parentState.video_stream_amount);
    return (  
      <div>
      <div className="heading_modalBlock">Payment </div>
        <form onSubmit={this.handleSubmit}>
          <div className="row px-4 py-2">
            <div className="row">
            <div className="col-12">
                <div className="mb-1">
                <CardElement options={CardElementOptions}/>
                </div>
                <div className="sr_img">
                  <img src="/images/img-1.png" alt="Image"/>
                </div>
            </div>
    
            <div className="col-12 pt-3 border-top">
                <div className="form-group">
                <button type="submit" className="btn themeBtn" disabled={(this.state.isLoading)}>
                    Pay {General.showPriceDecimal(amt)}
                </button>
                </div>
            </div>
            </div>
            </div>
        </form>
      </div>
    );
  }
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
};

const PurchaseSubscriptionPopUp = (props) => (
    <>
    <Modal
        isOpen={props.parentState['purchaseSubPopup']}
        onRequestClose={() =>
            props.onClose()
        }
        style={customStyles}
        ariaHideApp={false}
        className="shipModal stripePaymentModal"
    >
        <button onClick={() => props.onClose() } className="shipCloseBtn">
            <i className="fa fa-times"></i>
        </button>
        <Elements stripe={stripePromise}>
            <ElementsConsumer>
            {({stripe, elements}) => (
                <PurchaseSubscription stripe={stripe} elements={elements} parentState={props.parentState} onClose={props.onClose} onSuccess={props.onSuccess} onLoading={props.onLoading} />
            )}
            </ElementsConsumer>
        </Elements>
    </Modal>
    </>
);

export default PurchaseSubscriptionPopUp;