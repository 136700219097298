import Model from "Models/Model";

class CategorySearch extends Model{

	dbSchema(){
    return {
	      categoryId:"",
	      keyword:"",
	      location:"",
	      lat:"",
	      lng:"",
	      min_price:"",
	      subCategoryId:"",
	      AvailableBuyNow:"",
	      AvailablePickUp:"",
	      availableLeasing:"",
	      sellerType:"",
	      adType:"",
	      sortType:"",
	      radius:"",
	      recruiterType:"",
	      providerType:"",
	      max_price:"",
		  weeklydeals:false,
	    };
	}

}

export default new CategorySearch();
