import React, { Component } from "react";
import Page from "Models/Page";
import AboutUsComponent from 'Components/Pages/AboutUsComponent';
import CommonPageComponent from 'Components/Pages/CommonPageComponent';

class CMSPagesComponent extends Component {

   constructor(props) {
    super(props);
    const slug = props.match.params.slug;
    this.state = {
      slug:slug
    };
  }

  render() {
    const { slug } = this.state;
    return (
      <React.Fragment>
        {
          slug == "about-us" ? (
            <AboutUsComponent slug={slug} />  
          ) : (
            <CommonPageComponent slug={slug} />          
          )
        }
      </React.Fragment>
    );
  }
}

export default CMSPagesComponent;
