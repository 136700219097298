import React, { Component } from "react";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import Category from "Models/Category";
import PurchaseSubscriptionPopUp from "../Stripe/PurchaseSubscription";
import Order from "Models/Order";
var qs = require("qs");

export class PremiumService extends Component {

    constructor(props) {

        super(props);

        let session_id = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        }).session_id;
        let session_resp = qs.parse(window.location.search, {
            ignoreQueryPrefix: true,
        }).response;

        this.state = {
            userId: localStorage.getItem("UserData")
                ? JSON.parse(localStorage.getItem("UserData"))["_id"]
                : "",
            email: localStorage.getItem("UserData")
                ? JSON.parse(localStorage.getItem("UserData"))["email"]
                : "",
            sellerType: localStorage.getItem("UserData")
                ? JSON.parse(localStorage.getItem("UserData"))["sellerType"]
                : "",
            businessType: localStorage.getItem("UserData")
                ? JSON.parse(localStorage.getItem("UserData"))["businessType"]
                : 0,
            packages: [],
            current_package: "",
            package_details: [],
            pack: "",
            packageDetailStatus: true,
            purchaseSubPopup: false,
            transaction_type_other: "package",
            video_stream_amount: 0,
            video_stream_id: "",
            term_duration: 0,
            packId: "",
            isLoading: false,
            already_feature: "",
            session_id: (session_id) ? session_id : "",
            session_resp: (session_resp) ? session_resp : "",
        }

    }

    componentDidMount = async () => {
        await this.getAllPackages();
        let stripe_code = this.state.session_id;
        let session_resp = this.state.session_resp;
        if (session_resp == "success") {
            console.log("stripe_code mount", stripe_code)
            this.setState({ session_id: stripe_code });
            this.setState({ session_resp: session_resp });
            this.onSuccess(stripe_code, session_resp);

        } else if (session_resp == "error") {
            //  this.getPaidFeatureLableData(); 
            this.getAllPackages();
            const currentUrl = window.location.href;
            const newUrl = currentUrl.split('?')[0];
            window.history.replaceState({ path: newUrl }, '', newUrl);
        }
    }

    getAllPackages = async () => {
        const response = await new Promise((resolve, reject) => {
            Category.getApi("APIS_GET_PACKAGES", (apiResponse) => {
                //  console.log("apiResponse",apiResponse)
                resolve(apiResponse);
            }, false);
        });
        if (response) {
            this.setState({ packages: response });
            await this.getCurrentPackage();
        }


    }

    getCurrentPackage = async () => {
        const response = await new Promise((resolve, reject) => {
            Category.postApi("API_CURRENT_PACKAGE", {}, (apiResponse) => {
                //  console.log("apiResponse 2",apiResponse)
                resolve(apiResponse);
            }, true);
        });

        if (response) {
            //console.log("getCurrentPackage", response)
            this.setState({ current_package: response });
        }
        await this.getSelectedPackage(null, this.state.packages[0].package_name, 0);
    }


    getSelectedPackage = async (e = null, package_name, i, id = "") => {
        if (e != null) {
            e.preventDefault();
        }
        const response = await new Promise((resolve, reject) => {
            MyProfile.postApi("API_SELECT_PACKAGES", { package_name: package_name }, (apiResponse) => {
                // console.log("apiResponse 3",apiResponse)
                resolve(apiResponse);
            }, true);
        });

        if (response) {
            console.log("response get selected", response)
            this.setState({
                pack: response[i],
                package_details: response,
                current_index: i,
                term_duration: response[i].duration
            });
            this.getPaidFeatureLableData();
        }

    };


    getPaidFeatureLableData = () => {
        let { current_package, term_duration } = this.state;
        // console.log("current_package",current_package)
        if (current_package && current_package != "" && current_package.feature_day && current_package.feature_day.length > 0) {
            let term = "";
            for (let index = 0; index < current_package.feature_day.length; index++) {
                const data = current_package.feature_day[index];
                if (data.duration == term_duration) {
                    term = data;
                }

            }
            this.setState({ already_feature: term });
            console.log("already_feature", this.state.already_feature)
        }
    }

    stripeCheckout = async () => {
        console.log("stripe check video id", this.state.video_stream_id)
        console.log("stripe check pack id", this.state.pack)
        let frontUrl = process.env.REACT_APP_FRONTEND_URL;
        let buyer_id = Order.getAuthUserId();
        let total_amount = this.state.sellerType === 2 ? ((this.state.businessType === 1) ? Number(this.state.pack.business_amount) : Number(this.state.pack.soletrader_amount)) : Number(this.state.pack.private_amount);
        total_amount += Number(this.state.video_stream_amount);
        Order.postApi(
            "APIS_STRIPE_CHECKOUT",
            {
                amount: total_amount,
                buyer_id: buyer_id,
                transaction_type: this.state.transaction_type_other,
                quantity: 1,
                success_url: `${frontUrl}/my-profile/premium-services`,
                cancel_url: `${frontUrl}/my-profile/premium-services`,
                productName: this.state.packages[0].package_name,
                pack: this.state.pack,
                video_stream_id: this.state.video_stream_id
            },
            async (response, status) => {
                if (status == "success" && response) {
                    console.log("response", response);
                    window.location.href = `${response.url}`
                } else {
                    console.log("error")
                }

            }
        );
    }
    onSuccess = async (stripe_code, session_resp) => {
        // console.log("this.state.video_stream_id",this.state.video_stream_id)
        MyProfile.postApi(
            "API_CURRENT_TRANSACTION_DETAILS",
            { intent_id: stripe_code },
            async (response, status) => {
                this.setState({ video_stream_id: response.video_stream_id })
                this.setState({ pack: response.pack })
                MyProfile.postApi(
                    "API_UPDATE_TRANSACTION",
                    {
                        package_id: this.state.pack._id,
                        video_stream_id: (this.state.video_stream_id && this.state.video_stream_id != "") ? this.state.video_stream_id : null,
                        intent_id: stripe_code,
                        status: "active",
                        txnstatus: "active"
                    },
                    async (response, status) => {
                        this.setState({ current_package: response });
                        this.setState({ isLoading: false });
                        this.getPaidFeatureLableData();
                        setTimeout(() => {
                            window.location.href = window.location.href.split("?")[0]
                        }, 3000);
                    },
                    true
                );
            },
            true
        );
    };

    render() {
        const { isLoading, packages, current_package, package_details, pack, packageDetailStatus } = this.state;

        // const onSuccess = (ref) => {
        //     let paymentIntent = window.btoa(JSON.stringify(ref));

        //     MyProfile.postApi(
        //         "API_UPDATE_TRANSACTION",
        //         {
        //         package_id: this.state.pack._id,
        //         video_stream_id: (this.state.video_stream_id && this.state.video_stream_id != "") ? this.state.video_stream_id : null ,
        //         intent_id: ref.id,
        //         payment_intent: paymentIntent,
        //         status: "active",
        //         },
        //         async (response,status) => {
        //         if(status == "success"){
        //             this.setState({ current_package: response });
        //             this.setState({purchaseSubPopup:false});
        //             this.setState({isLoading:false});
        //             this.getPaidFeatureLableData();
        //         }
        //         },
        //         true
        //     );
        // };

        const onClose = () => {
            this.setState({ isLoading: false });
            this.setState({ purchaseSubPopup: false });
        };

        const onLoading = (value) => {
            this.setState({ isLoading: value });
        };

        return (
            <>
                <div
                    className="tab-pane allcom_rodtab  tabpremium_target active"
                    id="Jcomp_allprod"
                    role="tabpanel"
                >
                    <div className="row"></div>

                    <div className="product-discmydetails">
                        <div className="product-all-detail-tab premium-services-tabs">
                            <ul
                                className="nav nav-tabs my-custom-tab my-custom-tab-box cuttan_newimg specialshap_likedetailP"
                                role="tablist"
                            >
                                {packages && packages.length > 0 ? (
                                    packages.map(
                                        (packs, index) =>
                                            <li className="nav-item" key={packs._id}>
                                                <a
                                                    className={`nav-link ${index === 0 ? "active" : ""
                                                        }`}
                                                    data-toggle="tab"
                                                    href={"#subtabpackage" + index}
                                                    onClick={(e) => {
                                                        this.getSelectedPackage(
                                                            e,
                                                            packs.package_name,
                                                            0
                                                        );
                                                    }}
                                                >

                                                    <span>{packs.package_name}</span>
                                                </a>
                                            </li>
                                    )
                                ) : (
                                    <></>
                                    // <div className="alert alert-danger col-lg-12">
                                    //     <strong>Info!</strong> Nothing here.
                                    // </div>
                                )}
                            </ul>
                        </div>
                        <div className="tab-content subtab_tabcont03">
                            <div className={`tab-pane allcom_rodtab active`}>
                                <div className="subtab_prodlist_NEW_forpadd">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="subcateleft_imgsec d-none d-md-block ">
                                                    <img
                                                        src="/images/account-settings-limg.png"
                                                        alt="icon"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-9 padding-0">
                                                <div className="formnotification_inner withbg_white border-radius border-bottom_1 formnotification_inner_block">
                                                    <div className="noti_enable repeatSec_innerr border-0">
                                                        <div className="box_perentrepet">
                                                            <div className="buttonon_off">
                                                                <div className="btnoff_onlable lebal_services">
                                                                    Price
                                                                </div>
                                                                <div className="btnoff_onlable status_services">
                                                                    {this.state.sellerType === 2 ? ((this.state.businessType === 1) ? General.showPriceDecimal(pack.business_amount) : General.showPriceDecimal(pack.soletrader_amount)) : General.showPriceDecimal(pack.private_amount)}
                                                                </div>
                                                                {/* <div className="btnoff_onlableRight"></div>*/}
                                                            </div>
                                                        </div>

                                                        <div className="box_perentrepet">
                                                            <div className="buttonon_off">
                                                                <div className="btnoff_onlable lebal_services">
                                                                    Items Featured
                                                                </div>
                                                                <div className="btnoff_onlable status_services">
                                                                    {pack.ad_featured}
                                                                </div>
                                                                {(this.state.already_feature && this.state.already_feature != "") && <div className="btnoff_onlable">
                                                                    <div className="already_paid">
                                                                        {`${this.state.already_feature.number_of_ad} - Paid`}
                                                                    </div>

                                                                </div>}
                                                            </div>
                                                        </div>

                                                        <div className="box_perentrepet">
                                                            <div className="buttonon_off">
                                                                <div className="btnoff_onlable lebal_services">
                                                                    Video streaming
                                                                </div>

                                                                <div className="btnoff_onlable status_services">

                                                                    <div className="form-group select_box">
                                                                        <select className="form-control-line" name="video_stream" onChange={(e) => {
                                                                            let index = e.target.selectedIndex;
                                                                            let optionElement = e.target.childNodes[index]
                                                                            let price = optionElement.getAttribute('price');
                                                                            // this.videoStreamId(price,e.target.value)
                                                                            this.setState({
                                                                                video_stream_amount: price,
                                                                                video_stream_id: e.target.value,
                                                                            }, () => {
                                                                                console.log("id video", this.state.video_stream_id)
                                                                            })
                                                                            console.log("e", e.target.value)
                                                                            console.log("video_stream_id", this.state.video_stream_id)
                                                                        }
                                                                        }>
                                                                            <option className="item" value="">Select</option>
                                                                            {pack.video_stream &&
                                                                                pack.video_stream.length > 0 && (
                                                                                    pack.video_stream.map((stream) => (
                                                                                        <option className="item" value={stream._id} key={stream._id} price={stream.price}>{`${stream.slot} ${stream.label} - ${General.showPriceDecimal(stream.price)}`}</option>
                                                                                    )
                                                                                    ))}
                                                                        </select>
                                                                    </div>

                                                                </div>


                                                                {(current_package && current_package?.number_of_video_stream) && <div className="btnoff_onlable">
                                                                    <div className="already_paid">
                                                                        {`${current_package.number_of_video_stream} Slot - Paid`}
                                                                    </div>

                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="select_btns">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <div className="selectStatus_btns">
                                                                        {package_details &&
                                                                            package_details.length > 0 ? (
                                                                            package_details.map(
                                                                                (term, index) => (
                                                                                    <div className="selectStatus_box" key={term._id}>
                                                                                        <input
                                                                                            type="radio"
                                                                                            id={
                                                                                                `statusUpcoming_` +
                                                                                                index
                                                                                            }
                                                                                            name={`radio-${index}`}
                                                                                            onChange={(e) =>
                                                                                                this.setState({
                                                                                                    pack: this.state
                                                                                                        .package_details[
                                                                                                        index
                                                                                                    ],
                                                                                                    current_index: index,
                                                                                                    term_duration: this.state
                                                                                                        .package_details[
                                                                                                        index
                                                                                                    ].duration,

                                                                                                }, () => this.getPaidFeatureLableData())
                                                                                            }
                                                                                            checked={
                                                                                                index ==
                                                                                                this.state.current_index
                                                                                            }
                                                                                        />
                                                                                        <label
                                                                                            htmlFor={
                                                                                                `statusUpcoming_` +
                                                                                                index
                                                                                            }
                                                                                        >
                                                                                            {term.duration} days
                                                                                        </label>
                                                                                    </div>
                                                                                )
                                                                            )
                                                                        ) : (
                                                                            // <div className="alert alert-danger col-lg-12">
                                                                            //     <strong>Info!</strong> Nothing
                                                                            //     here.
                                                                            // </div>
                                                                            <></>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button
                                                        className="payment-btn"
                                                        onClick={() => {
                                                            // this.setState({purchaseSubPopup:true});
                                                            this.stripeCheckout()
                                                        }}
                                                    >
                                                        Made Payment
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <PurchaseSubscriptionPopUp parentState={this.state} onClose={onClose} onSuccess={onSuccess} onLoading={onLoading}/> */}
            </>
        );
    }
}

export default PremiumService;
