import React, { Component } from "react";
import SocialForm from "Forms/SocialForm";
import User from "Models/User";
import Lodash from "lodash";
import $ from "jquery";
import ReCAPTCHA from "react-google-recaptcha";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: User.dbSchema("SIGNUP"),
      password: true,
      confirmPassword: true,
      captchaValue: "",
      showCaptcha: false,
      showEmailLogin: this.props.showEmailLogin,
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    if (props.showEmailLogin !== state.showEmailLogin) {
      return {
        showEmailLogin: props.showEmailLogin,
      };
    }
  };

  componentDidMount = () => {
    User.getApi(
      "APIS_GET_SECURITY_SETTING",
      async (response) => {
        if (response[0] && response[0]["captcha_setting"][0]) {
          this.setState({
            showCaptcha: response[0]["captcha_setting"][0]["captcha"],
          });
        }
      },
      false
    );
  };

  submitHandler = async (event) => {
    event.preventDefault();
    if (this.state.showCaptcha === true && !this.state.captchaValue) {
      User.toastAlert("error", "Please Verify Captcha");
      return false;
    }
    if (
      (await User.checkValidation(
        await User.validationRules(),
        this.state.User
      )) === true
    ) {
      User.postApiByParams(
        "APIS_SIGNUP",
        this.state.User,
        async (response,status) => {
          if(status == "success"){
            User.toastAlert("success",response.message);
            setTimeout(function(){ User.redirectTo("login", true) }, 3000);
          }else{
            Object.keys(response.data).map(function (key) {
              console.log(response.data[key]);
              $("[name='" + key + "']:visible")
                .addClass("validation-error")
                .parents("div:first")
                .append(
                  "<div><span class='validation-span'>" +
                    response.data[key] +
                    "</span></div>"
                );
            });  
          }
          
         /*  if (response == true) {
            User.toastAlert("success", "Please Verify Captcha");
            setTimeout(function(){ User.redirectTo("login", true) }, 3000);
          } */
        },
        false
      );
    }
  };

  onChangeHanlde = (event) => {
    let { User } = this.state;
    User[event.target.name] = event.target.value;
    this.setState({ User: User });
  };

  passwordToggle = (event) => {
    event.preventDefault();
    var password = this.state.password ? false : true;
    this.setState({ password: password });
  };

  confirmPasswordToggle = (event) => {
    event.preventDefault();
    var confirmPassword = this.state.confirmPassword ? false : true;
    this.setState({ confirmPassword: confirmPassword });
  };

  onChange = (value) => {
    this.setState({ captchaValue: value });
  };

  handleChange = (address) => {
    let { User } = this.state;
    User["location"] = address;
    this.setState({ User: User });
  };

  handleSelect = (address) => {
    const { User } = this.state;
    geocodeByAddress(address).then((results) => {
      const place_id = results[0].place_id;
      const address = results[0].formatted_address;
      User["location"] = address;
      User["place_id"] = place_id;
      getLatLng(results[0])
        .then((latLng) => {
          User["latitude"] = latLng.lat;
          User["longitude"] = latLng.lng;
          this.setState({ User: User });
        })
        .catch((error) => console.error("Error", error));
    });
  };

  render() {
    return (
      <div className="formgrop_main_New">
        <SocialForm />
        <form onSubmit={this.submitHandler} method="post">
          <div className="row">
            {this.state.showEmailLogin === true && (
              <>
                <div className="col-sm-6 margin_top_new">
                  <div className="form-group">
                    <input
                      type="text"
                      onChange={this.onChangeHanlde}
                      value={this.state.User.firstName}
                      name="firstName"
                      className="form-control New_control"
                      placeholder="First name"
                    />
                    {/*<p className="text_New">Please check that this is correct as we will need to contact you via email. </p>*/}
                  </div>
                </div>
                <div className="col-sm-6 margin_top_new">
                  <div className="form-group">
                    <input
                      type="text"
                      onChange={this.onChangeHanlde}
                      value={this.state.User.lastName}
                      name="lastName"
                      className="form-control New_control"
                      placeholder="Surname"
                    />
                    {/*<p className="text_New">Please check that this is correct as we will need to contact you via email. </p>*/}
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  <div className="form-group">
                    <input
                      type="text"
                      onChange={this.onChangeHanlde}
                      value={this.state.User.email}
                      name="email"
                      className="form-control New_control"
                      placeholder="Email address"
                    />
                    {/*<p className="text_New">Please check that this is correct as we will need to contact you via email. </p>*/}
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  <div className="form-group">
                    <input
                      type="text"
                      onChange={this.onChangeHanlde}
                      value={this.state.User.username}
                      name="username"
                      className="form-control New_control"
                      placeholder="Username"
                    />
                    {/*<p className="text_New">Please check that this is correct as we will need to contact you via email. </p>*/}
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  <div className="form-group  signpass_New">
                    <input
                      type={this.state.password ? "password" : "text"}
                      onChange={this.onChangeHanlde}
                      value={this.state.User.password}
                      name="password"
                      className="form-control New_control"
                      placeholder="Password"
                    />
                    <a
                      href="#"
                      onClick={this.passwordToggle}
                      className="eyeshow_New"
                    >
                      <img src="/images/eye.svg" alt="Icon" width="20" />
                    </a>
                    {/*<p className="text_New">Your password must be at least 8 characters, and contain at least one upper and lower case letter, one number and one special character.</p>*/}
                  </div>
                </div>
                <div className="col-sm-12 mt-2">
                  <div className="form-group">
                    <PlacesAutocomplete
                      searchOptions={{
                        componentRestrictions: {
                          country: "UK",
                        },
                      }}
                      value={this.state.User.location}
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <div className=" location-box">
                          <input
                            {...getInputProps({
                              placeholder: "Location",
                              className: "location-search-input social_red_fuild",
                              name: "location"
                            })}
                          />
                          <span className="location-icon"> <i class="fas fa-map-marker-alt"></i></span>
                          </div>
                          <div className="autocomplete-dropdown-container">
                            <div>
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                    key={suggestion.index}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                    {/*<p className="text_New">Please check that this is correct as we will need to contact you via email. </p>*/}
                  </div>
                </div>
                {this.state.showCaptcha && (
                  <div className="col-sm-12 mt-2">
                    <div className="form-group icon_input signpass_New d-flex justify-content-center">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_CLIENT_KEY}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                )}
                <div className="col-sm-12 marginfour_side">
                  <div className="form-group text-center">
                    <button type="submit" className="btnx btn-secondaryx">
                      Register
                    </button>
                    <p className="text_New">
                      By creating an account with us, you confirm that you agree
                      to our privacy policy
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    );
  }
}
export default SignupForm;
