import Model from "Models/Model";

class Dictionary extends Model{

	dbSchema(type = ""){
      return [];
	}
	
	setReduxStore(state = [], action){
	    switch (action.type) {
	      	case 'MENU_LIST':
      			state["Categories"] = action.text;
	        	return state;
	      	default:
	        	return state;
	    }
	}
}

export default new Dictionary();
