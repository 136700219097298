/** Layouts **/
import DefaultLayout from "Layouts/Default";
import LoginLayout from "Layouts/LoginLayout";
import HomeComponent from "Components/Home/Home";
import CMSPagesComponent from "Components/Pages/CMSPagesComponent";
import FaqsComponent from "Components/Pages/FaqsComponent";
import HelpCenterComponent from "Components/Pages/HelpCenterComponent";
import LearnToSellComponent from "Components/Pages/LearnToSellComponent";
import LearnToBuyComponent from "Components/Pages/LearnToBuyComponent";
import GettingStartedComponent from "Components/Pages/GettingStartedComponent";
import ContactUsComponent from "Components/Pages/ContactUsComponent";
import ProductsByCatListComponent from "Components/Products/ProductsByCatListComponent";
import ProductsBySubCatListComponent from "Components/Products/ProductsBySubCatListComponent";
import AuctionCatListComponent from "Components/Products/AuctionCatListComponent";
import ProductsList from "Components/Search/ProductsList";

// import AboutUsComponent from 'Components/Pages/AboutUsComponent';
// import TermConditionsComponent from 'Components/Pages/TermConditionsComponent';
import ProductDetailComponent from "Components/Products/ProductDetailComponent";
import CheckoutComponent from "Components/Products/CheckoutComponent";
import Thankyou from "Components/Products/Thankyou";
import ProductRegisterToBidComponent from "Components/Products/ProductRegisterToBidComponent";
import ProductListComponent from "Components/Users/ProductListComponent";
// import PrivacyPolicyComponent from 'Components/Pages/PrivacyPolicyComponent';
import MessagesComponent from "Components/Messages/MessagesComponent";
import MyProfileComponent from "Components/Users/MyProfileComponent";
//import MyProfileComponent from "Components/Users/Profile";
import LoginComponent from "Components/Login/Login";
import PostAnAdCategoryComponent from "Components/PostAnAdComponent/PostAnAdCategoryComponent";
import PostAnAdComponent from "Components/PostAnAdComponent/PostAnAdComponent";
import ForgetPasswordComponent from "Components/Login/ForgetPassword";
import VerifyComponent from "Components/Login/Verify";
import InstagramCallbackComponent from "Components/Login/InstagramCallback";
import ResetPasswordComponent from "Components/Login/ResetPassword";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import EditAnAd from "Components/EditAnAdComponent/EditAnAd";
import UserMessageBox from "../Components/Users/UserMessageBox";
import LiveStreaming from "Components/Home/LiveStreaming";
import WeeklyDeals from "Components/Home/WeeklyDeals";
import LiveStreamingDetailComponent from "Components/Products/LiveStreamingDetailComponent";
import UpcomingLiveStreamingProductDetailComponent from "Components/Products/UpcomingLiveStreamingProductDetailComponent";
import LearnToLivestreamComponent from "Components/Pages/LearnToLivestreamComponent";
import SafetyTipsComponent from "Components/Pages/SafetyTipsComponent";
import LiveStreamingPage from "Components/Products/LiveStreamingPage";
import AuctionRegisterToBid from "Components/Products/AuctionRegisterToBid";
/** Components **/

const RoutingOfComponent = {
  "": { layoutName: DefaultLayout, componentName: HomeComponent, exact: true },
  login: { layoutName: LoginLayout, componentName: LoginComponent },
  signup: { layoutName: LoginLayout, componentName: LoginComponent },
  "forget-password": {
    layoutName: LoginLayout,
    componentName: ForgetPasswordComponent,
  },
  "verify/:slug": { layoutName: LoginLayout, componentName: VerifyComponent },
  "reset-password/:slug": {
    layoutName: LoginLayout,
    componentName: ResetPasswordComponent,
  },
  faqs: { layoutName: DefaultLayout, componentName: FaqsComponent },
  "learn-to-buy": {
    layoutName: DefaultLayout,
    componentName: LearnToBuyComponent,
  },
  "learn-to-start": {
    layoutName: DefaultLayout,
    componentName: GettingStartedComponent,
  },
  "help-center": { layoutName: DefaultLayout, componentName: HelpCenterComponent },
  "learn-to-sell": {
    layoutName: DefaultLayout,
    componentName: LearnToSellComponent,
  },
  "learn-to-livestream": {
    layoutName: DefaultLayout,
    componentName: LearnToLivestreamComponent,
  },
  "safety-tips": {
    layoutName: DefaultLayout,
    componentName: SafetyTipsComponent,
  },
  "contact-us": {
    layoutName: DefaultLayout,
    componentName: ContactUsComponent,
  },
  "category/:categoryslug": {
    layoutName: DefaultLayout,
    componentName: ProductsByCatListComponent,
  },
  "sub-category/:subCategoryId": {
    layoutName: DefaultLayout,
    componentName: ProductsBySubCatListComponent,
  },
  "live-auction/:categoryslug": {
    layoutName: DefaultLayout,
    componentName: AuctionCatListComponent,
  },
  "live-auctions/sub-category/:subCategoryId": {
    layoutName: DefaultLayout,
    componentName: ProductsBySubCatListComponent,
  },
  search: { layoutName: DefaultLayout, componentName: ProductsList },

  // "about-us" : {layoutName:DefaultLayout, componentName:AboutUsComponent},
  //"term-conditions" : {layoutName:DefaultLayout, componentName:TermConditionsComponent},
  //"privacy-policy" : {layoutName:DefaultLayout, componentName:PrivacyPolicyComponent},
  messages: { layoutName: DefaultLayout, componentName: MessagesComponent },
  "post-an-ad/:subCategoryId": {
    layoutName: DefaultLayout,
    componentName: PostAnAdComponent,
  },
  "post-an-edit/:productId": {
    layoutName: DefaultLayout,
    componentName: PostAnAdComponent,
  },
  "post-an-ad": {
    layoutName: DefaultLayout,
    componentName: PostAnAdCategoryComponent,
  },
  UserMessageBox: {
    layoutName: DefaultLayout,
    // componentName: UserMessageBox,
    componentName: MessagesComponent,
  },
  "my-profile": {
    layoutName: DefaultLayout,
    componentName: MyProfileComponent,
    exact: true 
  },
  "my-profile/:slug": {
    layoutName: DefaultLayout,
    componentName: MyProfileComponent, 
    exact: true
  },
  "instagram-callback": {
    layoutName: LoginLayout,
    componentName: InstagramCallbackComponent,
  },
  "linkedin-callback": {
    layoutName: LoginLayout,
    componentName: LinkedInPopUp,
  },
  "product/:productId": {
    layoutName: DefaultLayout,
    componentName: ProductDetailComponent,
  },
  "checkout/:productSlug": {
    layoutName: DefaultLayout,
    componentName: CheckoutComponent,
  },
  "thankyou/:orderId": {
    layoutName: DefaultLayout,
    componentName: Thankyou,
  },
  "edit-product/:productId": {
    layoutName: DefaultLayout,
    componentName: EditAnAd,
  },
  "product-register-to-bid/:productId": {
    layoutName: DefaultLayout,
    componentName: ProductRegisterToBidComponent,
  },
  "user/all-products/:userId": {
    layoutName: DefaultLayout,
    componentName: ProductListComponent,
  },
  livestreaming: {
    layoutName: DefaultLayout,
    componentName: LiveStreaming,
  },
  weeklydeals: {
    layoutName: DefaultLayout,
    componentName: WeeklyDeals,
  },
  "livestreamingProduct/:livestreamingId": {
    layoutName: DefaultLayout,
    componentName: LiveStreamingDetailComponent,
  },
  "upcomingLiveStreamingProduct/:upcomingstreamingId/:livestreamingId": {
    layoutName: DefaultLayout,
    componentName: UpcomingLiveStreamingProductDetailComponent,
  },
  "live-streaming/:categorySlug": {
    layoutName: DefaultLayout,
    componentName: LiveStreamingPage,
  },
  "auctionRegisterToBid/:productId/:livestreamingId": {
    layoutName: DefaultLayout,
    componentName: AuctionRegisterToBid,
  },
  ":slug": { layoutName: DefaultLayout, componentName: CMSPagesComponent },
};

export default RoutingOfComponent;
