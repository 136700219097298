import Model from "Models/Model";

class Page extends Model{

	dbSchema(){
	    return {
	      _id:""
	    };
	}
}

export default new Page();
