import React, { Component } from "react";
import ContactDetail from "./ContactDetail";
import Notification from "./Notification";
import Payment from "./Payment";
import ManageAccount from "./ManageAccount";
import Security from "./Security";
import ContentLoader from "react-content-loader";
import Postage from "./Postage";

export class AccountSettings extends Component {

    constructor(props) {

        super(props);

        this.state = {
            headerLogo: localStorage.getItem("HeaderLogo"),
            userId: localStorage.getItem("UserData")
                ? JSON.parse(localStorage.getItem("UserData"))["_id"]
                : "",
            email: localStorage.getItem("UserData")
                ? JSON.parse(localStorage.getItem("UserData"))["email"]
                : "",
            isLoading: false,
            tabView: 'profile_detail'
        }

    }

    handleTabes = (tab) => {
        this.setState({
            tabView: tab
        });
    }

    componentDidMount = () => {
    };

    render() {

        const { tabView, isLoading } = this.state;

        return (
            <React.Fragment>
                {isLoading && (
                    <div className="loader-wrapper" id="loader_img">
                        <div className="loader">
                            <img src={this.state.headerLogo} alt="" />
                            <div className="material-spinner"></div>
                        </div>
                    </div>
                )}
                <div
                    id="myownlevel"
                    role="tabpanel"
                >
                    <div className="product-all-detail-tab">
                        <ul
                            className="nav nav-tabs my-custom-tab cuttan_newimg specialshap_likedetailP account_setting_tabs"
                            role="tablist"
                        >
                            <li className="nav-item">
                                <a
                                    className="nav-link active in"
                                    data-toggle="tab"
                                    href={void (0)}
                                    onClick={() => this.handleTabes('profile_detail')}
                                    role="tab"
                                >
                                    <span> Profile Details </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href={void (0)}
                                    onClick={() => this.handleTabes('notification')}
                                    role="tab"
                                >
                                    <span> Notification </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href={void (0)}
                                    role="tab"
                                    onClick={() => this.handleTabes('payment')}
                                >
                                    <span> Payment </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href={void (0)}
                                    role="tab"
                                    onClick={() => this.handleTabes('sercurity')}
                                >
                                    <span> Security </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href={void (0)}
                                    role="tab"
                                    onClick={() => this.handleTabes('postage')}
                                >
                                    <span> Postage </span>
                                </a>
                            </li>
                            <li>
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    href={void (0)}
                                    role="tab"
                                    onClick={() => this.handleTabes('manage_account')}
                                >
                                    <span> Manage Account </span>
                                </a>
                            </li>

                        </ul>
                    </div>

                    <div className="tab-content subtab_tabcont">
                        {(tabView === 'profile_detail') ?
                            <ContactDetail />
                            : (tabView === 'notification') ?
                                <Notification />
                                : (tabView === 'payment') ?
                                    <Payment />
                                    : (tabView === 'sercurity') ?
                                        <Security />
                                        : (tabView === 'postage') ?
                                            <Postage />
                                            : (tabView === 'manage_account') ?
                                                <ManageAccount />
                                                : <section className="bg-light-dark">
                                                    <ContentLoader
                                                        speed={2}
                                                        width={400}
                                                        height={460}
                                                        viewBox="0 0 400 460"
                                                        backgroundColor="#f3f3f3"
                                                        foregroundColor="#ecebeb"
                                                    >
                                                        <rect x="130" y="5" rx="2" ry="2" width="150" height="10" />
                                                        <rect x="20" y="20" rx="2" ry="2" width="350" height="50" />
                                                        <rect x="20" y="75" rx="2" ry="2" width="350" height="70" />
                                                        <rect x="20" y="150" rx="2" ry="2" width="350" height="50" />
                                                        <rect x="20" y="205" rx="2" ry="2" width="350" height="30" />
                                                        <rect x="20" y="240" rx="2" ry="2" width="350" height="50" />
                                                        <rect x="20" y="295" rx="2" ry="2" width="350" height="50" />
                                                        <rect x="20" y="350" rx="2" ry="2" width="350" height="70" />
                                                        <rect x="20" y="435" rx="2" ry="2" width="350" height="70" />
                                                    </ContentLoader>
                                                </section>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AccountSettings;
