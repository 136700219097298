import React, { Component } from "react";
import General from "Helpers/General";
import Product from "Models/Product";
import Category from "Models/Category";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Moment from "react-moment";

class ProductsList extends Component {
  constructor(props) {
    super(props);

    const categories = localStorage.getItem("MainCategories")
      ? JSON.parse(localStorage.getItem("MainCategories"))
      : [];

    // const categoryId = qs.parse(this.props.location.search, {
    //   ignoreQueryPrefix: true,
    // }).categoryId;
    this.state = {
      allWeeklyProducts: localStorage.getItem("AllWeeklyDeals")
        ? JSON.parse(localStorage.getItem("AllWeeklyDeals"))
        : [],
      totalRecords: "",
      address: "",
      lat: "",
      lng: "",
      radius: "",
      page_number: 1,
      keyword: "",
      sortType: "",
      categoryId: "",
      min_price: "",
      max_price: "",
      showHideListData: true,
      nothingD: false,
      showHideGridData: false,
      allCategories: categories,
      productSkeleton: [
        { id: 0 },
        { id: 340 },
        { id: 680 },
        { id: 1020 },
        { id: 1360 },
      ],
      openProductFilter: false,
    };
  }

  hideComponent = (name) => {
    switch (name) {
      case "showHideListData":
        this.setState({ showHideListData: !this.state.showHideListData });
        this.setState({ showHideGridData: false });
        break;
      case "showHideGridData":
        this.setState({ showHideGridData: !this.state.showHideGridData });
        this.setState({ showHideListData: false });
        break;
      default:
        this.setState({ showHideListData: !this.state.showHideListData });
        break;
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.getDataByfilters(1);
  };

  getDataByfilters(page_number) {
    var self = this;
    var userId;

    if (Product.isLogin()) {
      userId = Product.getAuthUserId();
    }
    Product.postApiByParams(
      "API_GET_WEEKLY_DEALS_PAGE_DATA",
      {
        page_number: page_number,
        lat: this.state.lat,
        lng: this.state.lng,
        radius: this.state.radius,
        keyword: this.state.keyword,
        sortType: this.state.sortType,
        categoryId: this.state.categoryId,
        min_price: this.state.min_price,
        max_price: this.state.max_price,
        user_id: userId,
      },
      async (response, status) => {
        if (response.status) {

          self.setState({ openProductFilter: false });
          localStorage.setItem("AllWeeklyDeals", JSON.stringify(response.data));
          self.setState({ allWeeklyProducts: response.data });
          self.setState({ totalRecords: response.totalRecords });
          self.setState({ next_page_number: response.next_page_number });
          self.setState({ limit: response.limit });
          self.setState({ nothingD: true });
        }
      },
      false
    );
  }

  getDatabyPageno(next_page_number) {
    this.getDataByfilters(next_page_number);
  }

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      const address = results[0].formatted_address;
      var self = this;
      self.setState({ address });
      getLatLng(results[0])
        .then((latLng) => {
          var self = this;
          self.setState({
            lat: latLng.lat,
            lng: latLng.lng,
          });
        })
        .catch((error) => console.error("Error", error));
    });
  };

  handleFilterSorting = (event) => {
    this.setState({ sortType: event.target.value }, () => {
      this.getDataByfilters();
    });
  };

  handleRadiusFilter = (event) => {
    this.setState({ radius: event.target.value });
  };

  handleCatNegotiation = (event) => {
    // event.preventDefault();
    this.getDataByfilters(this.state.page_number);
  };

  otherOptionHandler = (event) => {
    this.setState({ [event.target.name]: event.target.checked }, () => {
      this.getDataByfilters(this.state.page_number);
    });
  };

  addWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_ADD_TO_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  removeWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_REMOVE_FROM_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  categoryHandler = (id = "") => {
    this.setState({ categoryId: id }, () => {
      this.getDataByfilters();
    });
  };

  priceHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.getDataByfilters();
    });
  };

  render() {
    var context = this;
    const {
      showHideListData,
      showHideGridData,
      allWeeklyProducts,
      totalRecords,
      allCategories,
    } = this.state;

    return (
      <React.Fragment>
        <section className="product-header special_prheadr_new">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-6">
                <div className="breadcrumb-bx">
                  <h2>{totalRecords} Ads</h2>
                  <div className="breadcrumb-bx-inner">
                    <Link to="/" className="btnx btn-primaryx">
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </Link>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">Weeklydeals</li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="new_placetab_icon">
                  <div className="form-group-icon-bx">
                    <select
                      className="form-control-line"
                      onChange={context.handleFilterSorting}
                    >
                      <option value="">Most Recent First</option>
                      <option value="LowToHigh">Price: Low to High</option>
                      <option value="HighToLow">Price: High to Low</option>
                    </select>
                    <span className="form-group-icon">
                      <img src="/images/down-arrow-icon.png" alt="icon" />
                    </span>
                  </div>
                  <div className="showing-result">
                    {/* {showHideListData ? (
                      <Link className="gridList" id="tabOne">
                        <i className="fas fa-list "></i>
                      </Link>
                    ) : (
                      <Link
                        className=""
                        id="tabOne"
                        onClick={() => this.hideComponent("showHideListData")}
                      >
                        <i className="fas fa-list"></i>
                      </Link>
                    )}

                    {showHideGridData ? (
                      <Link className="gridList" id="tabOne">
                        <i className="fas fa-th "></i>
                      </Link>
                    ) : (
                      <Link
                        className=""
                        id="tabOne"
                        onClick={() => this.hideComponent("showHideGridData")}
                      >
                        <i className="fas fa-th "></i>
                      </Link>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="repeat-section product-listing-main-bx bg-light-dark forsalepage_listing">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 paddingrigt_none_Cfilter">
                <div
                  className={`product-sideBar-parent new_perenttop_forspecals ${this.state.openProductFilter ? "active" : ""
                    }`}
                >
                  <div className="transparent-layer"></div>
                  <div className="product-listing-sideBar" id="side-bar-fixed">
                    <div className="sidebarleft_newinner">
                      <div id="filter-closer">
                        <img
                          src="/images/cancel.svg"
                          alt="icon"
                          onClick={() =>
                            this.setState({ openProductFilter: false })
                          }
                        />
                      </div>
                      <div className="product-listing-sideBar-colum sideBar-location">
                        <h2>Location</h2>
                        <div className="form-group">
                          <PlacesAutocomplete
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                            searchOptions={{
                              componentRestrictions: {
                                country: "UK",
                              },
                            }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "location-search-input",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                      : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div class="form-group mb-0 sideBar-choose-distance">
                                  <div class="form-group-icon-bx">
                                    <input
                                      type="number"
                                      name="radius"
                                      onChange={this.handleRadiusFilter}
                                      class="form-control-line"
                                      placeholder="Distance"
                                    />

                                    {/*<span class="form-group-icon"><img src="/images/down-arrow-icon.png" alt="icon" /></span>*/}
                                  </div>
                                  <button
                                    class="go-btn"
                                    onClick={this.handleCatNegotiation}
                                  >
                                    GO
                                  </button>
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                      <div class="product-listing-sideBar-colum sideBar-category">
                        <h2>Category</h2>
                        <ul>
                          <li class="innerfilt_topcat_perent">
                            <ul class="innerfilt_topcat">
                              <li
                                className={
                                  this.state.categoryId === "" ? "active" : ""
                                }
                              >
                                <Link
                                  onClick={() => this.categoryHandler()}
                                  className="goclass01"
                                >
                                  All
                                </Link>
                              </li>
                              {allCategories &&
                                allCategories.map((cat) => (
                                  <li
                                    className={
                                      this.state.categoryId === cat._id
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <Link
                                      onClick={() =>
                                        this.categoryHandler(cat._id)
                                      }
                                      // to={`/category/${cat.slug}`}
                                      class="goclass01"
                                    >
                                      {cat.categoryName}
                                    </Link>
                                  </li>
                                ))}
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div class="product-listing-sideBar-colum sideBar-category">
                        <h2>Price</h2>

                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <input
                                type="number"
                                name="min_price"
                                onChange={this.priceHandler}
                                class="form-control-line"
                                placeholder="Min"
                                min="0"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <input
                                type="number"
                                name="max_price"
                                onChange={this.priceHandler}
                                class="form-control-line"
                                placeholder="Max"
                                min="0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div class="product-listing-sideBar-colum sideBar-category">
                        <h2 class="toggle-category">
                          Ad Type <i class="fas fa-chevron-down"></i>
                        </h2>

                        <ul class="toggle-bx">
                          <li>
                            <Link>
                              <strong>Feature</strong>
                            </Link>
                          </li>
                          <li>
                            <Link>
                              <strong>Urgent</strong>
                            </Link>
                          </li>
                          <li>
                            <Link>
                              <strong>Spotlight</strong>
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                    {/* <div class="product-listing-sideBar-colum other-option otheropt_newcls">
                      <h2>Other Options</h2>
                      <div class="otheroption_cheperent">
                        <div class="check-box">
                          <input
                            type="checkbox"
                            name="premium"
                            value="yes"
                            id="results-with-pic"
                            onClick={this.otherOptionHandler}
                            defaultChecked={this.state.premium}
                          />
                          <label for="results-with-pic">premium</label>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div
                  id="filter-toggler"
                  className="btnx btn-secondaryx mx-3"
                  onClick={() => this.setState({ openProductFilter: true })}
                >
                  All Filter
                </div>
                <div className="Jcompny_allprod">
                  {showHideListData && (
                    <div className="Jtaballp_content atlistingside_new">
                      {allWeeklyProducts.length > 0 ? (
                        <>
                          {allWeeklyProducts.map(function (row) {
                            return (
                              <div className="row">
                                <div
                                  className="col-lg-12 listside_newperent"
                                  key={"allWeeklyProducts" + row._id}
                                >
                                  <div className="product-listing-product">
                                    <div className="row">
                                      <div className="col-3">
                                        <div className="product-img-bx">
                                          <Link
                                            to={"/product/" + row.productSlug}
                                          >
                                            {row.adType == "Featured" && (
                                              <div className="specialtags">
                                                <span className="black_special">
                                                  {row.adType}
                                                </span>
                                              </div>
                                            )}
                                            {row.adType == "Urgent" && (
                                              <div className="specialtags">
                                                <span className="red_special">
                                                  {row.adType}
                                                </span>
                                              </div>
                                            )}
                                            {row.adType == "Spotlight" && (
                                              <div className="specialtags">
                                                <span className="green_special">
                                                  {row.adType}
                                                </span>
                                              </div>
                                            )}

                                            {row.productMainImage ? (
                                              <img
                                                src={General.showImage(
                                                  "products/resize-180-180",
                                                  row.productMainImage
                                                )}
                                                alt="uploaded image"
                                              />
                                            ) : (
                                              <img
                                                src="/images/no-image.jpg"
                                                alt="product"
                                              />
                                            )}
                                            <div className="totalimg_sectab">
                                              {/*<Link to="#" className="totlimg_qt">2 <i className="fas fa-camera"></i></Link>*/}
                                            </div>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="col-9">
                                        <div className="product-outer-bx productBig-outer-bx">
                                          <div className="product-name-bx content_gobottom_new">
                                            <Moment fromNow>{row.time}</Moment>
                                            <Link
                                              to={"/product/" + row.productSlug}
                                            >
                                              <h4>{row.productName}</h4>
                                            </Link>
                                            <h5>
                                              {/* {row.categoryId?.categoryName}{" "}
                                              {row.subCategoryId &&
                                                "- " +
                                                  row.subCategoryId
                                                    ?.subCategoryName}{" "} */}
                                              {row?.location}
                                            </h5>

                                            <div class="d-flex align-items-center">
                                              <del>
                                                {General.showPriceDecimal(
                                                  row.productPrice
                                                )}
                                              </del>
                                              <span className="discountt mr-2">
                                                -{row.discount}%
                                              </span>
                                              <h6 className="mt-0">
                                                <span>
                                                  {General.showPriceDecimal(
                                                    row.productPrice -
                                                    (row.productPrice *
                                                      row.discount) /
                                                    100
                                                  )}
                                                </span>
                                              </h6>
                                            </div>
                                            {row.productDescription && (
                                              <p>{`${row.productDescription.substring(
                                                0,
                                                80
                                              )}...`}</p>
                                            )}
                                            {row.isWishlist === 1 ? (
                                              <button
                                                className="add-to-wishlist"
                                                onClick={() =>
                                                  context.removeWishlistHandler(
                                                    row.productId
                                                  )
                                                }
                                              >
                                                <i className="fas fa-heart"></i>
                                              </button>
                                            ) : (
                                              <button
                                                className="add-to-wishlist"
                                                onClick={() =>
                                                  context.addWishlistHandler(
                                                    row.productId
                                                  )
                                                }
                                              >
                                                <i className="far fa-heart"></i>
                                              </button>
                                            )}
                                            {row.condition && (
                                              <div className="usednew_button">
                                                <button
                                                  type="button"
                                                  className="btnnew_used"
                                                >
                                                  {row.condition}
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {this.state.nothingD ? (
                            <div className="alert alert-danger col-lg-12">
                              <strong>Info!</strong> Nothing here.
                            </div>
                          ) : (
                            <>
                              {this.state.productSkeleton.map(function (
                                rowsskeleton
                              ) {
                                return (
                                  <ContentLoader
                                    viewBox="0 0 1380 300"
                                    height={340}
                                    width={1380}
                                  >
                                    <rect
                                      x="20"
                                      y="{rowsskeleton.id}"
                                      rx="10"
                                      ry="10"
                                      width="300"
                                      height="300"
                                    />
                                    <rect
                                      x="360"
                                      y="{rowsskeleton.id}"
                                      rx="10"
                                      ry="10"
                                      width="1000"
                                      height="300"
                                    />
                                  </ContentLoader>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}

                  {showHideGridData && (
                    <div className="Jtaballp_content02 auctionplist_main ">
                      <div className="col-sm-12 actionpage_list">
                        <div
                          className={
                            this.state.nothingD ? "row" : "row low-margin"
                          }
                        >
                          {allWeeklyProducts.length > 0 ? (
                            <>
                              {allWeeklyProducts.map(function (row) {
                                return (
                                  <div
                                    className="col-sm-3"
                                    key={"allWeeklyProducts" + row._id}
                                  >
                                    <div className="product-outer-bx">
                                      <Link to={"/product/" + row.productSlug}>
                                        <div className="product-img-bx">
                                          {row.productMainImage ? (
                                            <img
                                              src={General.showImage(
                                                "products/resize-180-180",
                                                row.productMainImage
                                              )}
                                              alt="uploaded image"
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.jpg"
                                              alt="product"
                                            />
                                          )}
                                          {row.adType == "Featured" && (
                                            <div className="specialtags">
                                              <span className="black_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}
                                          {row.adType == "Urgent" && (
                                            <div className="specialtags">
                                              <span className="red_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}
                                          {row.adType == "Spotlight" && (
                                            <div className="specialtags">
                                              <span className="green_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}
                                          <div className="totalimg_sectab">
                                            {/*<Link to={"/product/"+row._id} className="totlimg_qt">2 <i className="fas fa-camera"></i></Link>*/}
                                          </div>
                                        </div>
                                        <div className="product-name-bx">
                                          <h5>{row.time}</h5>
                                          <h4>{row.productName}</h4>
                                          <h3>
                                            {row.categoryId?.categoryName}{" "}
                                            <br />{" "}
                                            {row.subCategoryId?.subCategoryName}
                                          </h3>
                                          <h6>
                                            <Link
                                              to={"/product/" + row.productSlug}
                                              className="btnnew_used"
                                            >
                                              View
                                            </Link>{" "}
                                            <span>
                                              {General.showPriceDecimal(
                                                row.productPrice
                                              )}
                                            </span>
                                          </h6>
                                        </div>
                                      </Link>
                                      {row.isWishlist === 1 ? (
                                        <button
                                          className="add-to-wishlist"
                                          onClick={() =>
                                            context.removeWishlistHandler(
                                              row.productId
                                            )
                                          }
                                        >
                                          <i className="fas fa-heart"></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="add-to-wishlist"
                                          onClick={() =>
                                            context.addWishlistHandler(
                                              row.productId
                                            )
                                          }
                                        >
                                          <i className="far fa-heart"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {this.state.nothingD ? (
                                <div className="alert alert-danger col-lg-12">
                                  <strong>Info!</strong> Nothing here.
                                </div>
                              ) : (
                                <>
                                  {this.state.productSkeleton.map(function (
                                    rowsskeleton
                                  ) {
                                    return (
                                      <ContentLoader
                                        viewBox="0 0 1380 300"
                                        height={340}
                                        width={1380}
                                        key={rowsskeleton.id}
                                      >
                                        <rect
                                          x="20"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="360"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="700"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="1040"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                      </ContentLoader>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="loading-bx">
                  {this.state.totalRecords > 0 && (
                    <>
                      <p>
                        <span>
                          You have viewed {this.state.limit} of{" "}
                          {this.state.totalRecords} Ads
                        </span>
                      </p>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span className="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </>
                  )}

                  {this.state.next_page_number > 0 && (
                    <button
                      onClick={context.getDatabyPageno.bind(
                        context,
                        this.state.next_page_number
                      )}
                    >
                      LOAD MORE
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="side-bar-stoper"></div>
          </div>
        </section>
        <section className="bg-light-dark">
          <div></div>
        </section>
      </React.Fragment>
    );
  }
}

export default ProductsList;
