import Model from "Models/Model";

class NewsLetter extends Model{

	dbSchema(){
    return {
      email:""
    };
	}

	validationRules(){
    return {
      email:[
        {lib:"Custom", func:"NotEmpty", msg:"Email is required"},
        {lib:"Validator", func:"isEmail", msg:"This is not a valid email address"},
			]
    };
  }

}

export default new NewsLetter();
