import React, { Component } from "react";
import ResetPasswordForm from 'Forms/ResetPasswordForm';

class ResetPassword extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <section className="signin_Newpage bg-light-dark contentBodyHeight repeat-section-login-New">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="loginbx_main_New">
                  <div className="log_sign_inner_New">
                    <div className="headingsign_New">
                        <p>Reset Password</p>
                      </div>
                      <ResetPasswordForm code={this.props.match.params.slug}/>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ResetPassword;
