import React, { Component } from "react";
import General from "Helpers/General";
import { loadStripe } from "@stripe/stripe-js";
import {CardElement, Elements, ElementsConsumer, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import MyProfile from "Models/MyProfile";
import CheckoutModel from "Models/CheckoutModel";
import Storage from "Helpers/Storage";
import Modal from "react-modal";

export class Payment extends Component {

  constructor(props) {

      super(props);

      this.state = {
        headerLogo: localStorage.getItem("HeaderLogo"),
        userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
        bankDetail:localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["bankDetail"]
        : {},
        billingAddress : localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["billingAddress"]
        : {},
        countries : [],
        regions : [],
        cities : [],
        addressOpen : false,
        showCardModal: false,
        showWithdrawFundModal: false,
        isCardDetailAdd : false,
        isBankDetailAdd : false,
        isBillingAddress: false,
        isLoading: false,
      }

  }

  componentDidMount = () => {
    this.getUserByEmail();
    this.getAllCountries();
  };

  getUserByEmail = () => {
    MyProfile.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        if (response) {
          Storage.setLocalData("UserData", JSON.stringify(response));
          this.setState({
            userId: response._id,
            bankDetail: (response.bankDetail) ? response.bankDetail : {},
            billingAddress : (response.billingAddress) ? response.billingAddress : {},
            isCardDetailAdd: response.isCardDetailAdd,
            isBankDetailAdd: response.isBankDetailAdd,
            isBillingAddress: response.isBillingAddress,
          });
        }
      },
      true
    );
  };

  handleSubmitChangeBankDetails = async (event) => {
    event.preventDefault();
    const { bankDetail,isBankDetailAdd } = this.state;
    if (
      (await MyProfile.checkValidation(await MyProfile.validationRules(), {
        bankDetail
      })) === true
    ) {
      this.setState({ isLoading: true });
      MyProfile.postApi(
        "API_UPDATE_ACCOUNT_DETAIL",
        {
          bankDetail,
          isBankDetailAdd : true
        },
        async (response, err) => {
          if (response) {
            this.getUserByEmail();
            this.setState({ isLoading: false, showWithdrawFundModal: false, isBankDetailAdd : true });
          }
        },
        true
      );
    }
  };

  
  handleSubmitBillingAddressDetails = async (event) => {
    event.preventDefault();
    const { billingAddress,isBillingAddress } = this.state;
    if (
      (await CheckoutModel.checkValidation(await CheckoutModel.validationRules(),
        billingAddress
      )) === true
    ) {
      this.setState({ isLoading: true });
      MyProfile.postApi(
        "API_UPDATE_ACCOUNT_DETAIL",
        {
          billingAddress,
          isBillingAddress : true
        },
        async (response, err) => {
          if (response) {
            this.getUserByEmail();
            this.setState({ isLoading: false, isBillingAddress : true });
          }
        },
        true
      );
    }
  };

  handleSubmitCardDetails = async (event) => {
    event.preventDefault();
    const { isCardDetailAdd } = this.state;
    if (
      (await MyProfile.checkValidation(await MyProfile.validationRules(), {
        isCardDetailAdd
      })) === true
    ) {
      this.setState({ isLoading: true });
      MyProfile.postApi(
        "API_UPDATE_ACCOUNT_DETAIL",
        {
          isCardDetailAdd : true
        },
        async (response, err) => {
          if (response) {
            this.getUserByEmail();
            this.setState({ isLoading: false, isCardDetailAdd : true });
          }
        },
        true
      );
    }
  };

  getAllCountries = () => {
    MyProfile.postApiByParams(
    "APIS_GET_COUNTRIES",
    {},
    async (response,status) => {
        if (status === "success") {
        this.setState({
            countries: response.data,
        });
        }

        this.getAllRegions();
        this.getAllCities();
    },
    true
    );
};

getAllRegions = () => {
    let country_id = (this.state.billingAddress.country_id) ? this.state.billingAddress.country_id : this.state.businessAddress.country_id;
    if(country_id != undefined && country_id != ""){
        MyProfile.postApiByParams(
        "APIS_GET_STATE",
        {country_id : country_id},
        async (response,status) => {
            if (status === "success") {
            this.setState({
                regions: response.data,
            });
            }
        },
        true
        );
    }
};

getAllCities = () => {
    let state_id = (this.state.billingAddress.region_id) ? this.state.billingAddress.region_id : this.state.businessAddress.region_id;
    if(state_id != undefined && state_id != ""){
        MyProfile.postApiByParams(
        "APIS_GET_CITIES",
        {state_id : state_id},
        async (response,status) => {
            if (status === "success") {
            this.setState({
                cities: response.data,
            });
            }
        },
        true
        );
    }
};

  handleMyProfileBankDetail = (event) => {
    const {bankDetail} = this.state;
    bankDetail[event.target.name] = event.target.value;
    this.setState(bankDetail);
  };

  billingAddressShow = () => {
    const { addressOpen } = this.state;
    let changeStatus = !addressOpen;
    this.setState({addressOpen :changeStatus });
  }

  handleMyProfileContactDetails = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handelCountryDetail = (event) => {
      const {billingAddress} = this.state;
      billingAddress[event.target.name] = event.target.value;
      this.setState(billingAddress,()=>{
          this.getAllRegions();
      });
  };

  handelRegionDetail = (event) => {
      const {billingAddress} = this.state;
      billingAddress[event.target.name] = event.target.value;
      this.setState(billingAddress,()=>{
          this.getAllCities();
      });
  };

  handlebillingAddress = (event) => {
    const {billingAddress} = this.state;
    billingAddress[event.target.name] = event.target.value;
    this.setState(billingAddress);
};


  render() {

    const { isLoading, showCardModal, showWithdrawFundModal, countries, regions, cities } = this.state;

    const CardElementOptions = {
      style:{
        base:{
          fontWeight: '600'
        }
      }
    };

    const stripePromise = loadStripe('pk_test_51MQpP0EqxNjfpKtvmr2d0jdaycnjRdlvvfFK6HoY9CzlMeSddYtKm6tIIoecdKY3S6RBbwVn1JWqIi3s6VFw4mJY00JrmbLMEh');

    return (
      <>
        {isLoading && (
        <div className="loader-wrapper" id="loader_img">
            <div className="loader">
            <img src={this.state.headerLogo} alt="" />
            <div className="material-spinner"></div>
            </div>
        </div>
        )}
        <div
        className="tab-pane allcom_rodtab active"
        id="subtab026"
        role="tabpanel"
        >
        <div className="subtab_prodlist_NEW_forpadd">
            <div className="container">
            <div className="row">
                <div
                className="col-lg-3 subtab_firstmain noneat_mobile paddingrigt_none_Cfilter d-none d-md-block"
                id="subtab_secondfixed"
                >
                <div className="subcateleft_imgsec">
                    <img
                    src="/images/account-settings-limg.png"
                    alt="Image"
                    />
                </div>
                </div>
                <div className="col-lg-9 subtab_secondmain">
                  <div className="formnotification_inner withbg_white border-radius p-4">
                  
                    <div className="d-flex justify-content-between mb-2">
                      <span className="btnoff_onlable">{this.state.isCardDetailAdd ? "Edit" : "Add" } New Card</span>
                      <button 
                        className="btnx btn-secondaryx font-size-14"
                        onClick={() =>
                          this.setState({
                              showCardModal: true,
                          })
                        }
                      >
                        {this.state.isCardDetailAdd ? "Edit" : "Add" }
                      </button>
                    </div>
                    <div className="d-flex justify-content-between mb-2 pb-2">
                      <span className="btnoff_onlable">Billing Address</span>
                      <a href={void(0)} onClick={this.billingAddressShow}>
                        <svg width="30" height="30" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.9998 14.2688L18.3623 8.90625L19.8941 10.4381L12.9998 17.3324L6.10547 10.4381L7.6373 8.90625L12.9998 14.2688Z" fill="#000"/>
                        </svg>
                      </a>
                    </div>
                    {this.state.addressOpen && 
                      <div className="mb-2">
                        <form
                            onSubmit={this.handleSubmitBillingAddressDetails}
                        >
                          <div className="contdetail_group">
                            <div className="contact_label">
                                Address
                            </div>
                            <div className="cont_fuild">
                                <div className="row">
                                    <div className="contdetail_groups col-md-6 ">
                                        <div className="cont_fuilds">
                                            <input
                                            onChange={
                                                this.handlebillingAddress
                                            }
                                            name="streetAddress"
                                            type="text"
                                            value={(this.state?.billingAddress?.streetAddress)?this.state?.billingAddress.streetAddress:""}
                                            className="social_red_fuild font-size-16"
                                            placeholder="Street Address"
                                            />
                                        </div>
                                    </div>
  
                                    <div className="contdetail_groups col-md-6 ">
                                        <div className="cont_fuilds">
                                            <input
                                            onChange={
                                                this.handlebillingAddress
                                            }
                                            name="streetAddressSecond"
                                            type="text"
                                            value={(this.state.billingAddress?.streetAddressSecond)?this.state.billingAddress.streetAddressSecond:""}
                                            className="social_red_fuild font-size-16"
                                            placeholder="Street Address 2"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                        <div className="contdetail_groups col-12">
                                            <div className="cont_fuilds">
                                                <select 
                                                    name="country_id" 
                                                    className="social_red_fuild font-size-16"
                                                    value={(this.state?.billingAddress?.country_id)?this.state?.billingAddress.country_id:""}
                                                    onChange={
                                                        this.handelCountryDetail
                                                    }
                                                >
                                                    <option value="">Select country</option>
                                                    {countries && countries.length > 0 && countries.map(function(country){
                                                        return(
                                                            <option value={country.id} key={country._id}>{country.name}</option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    <div className="contdetail_groups col-md-4">
                                        <div className="cont_fuilds">
                                            <select 
                                                name="region_id" 
                                                className="social_red_fuild font-size-16" 
                                                onChange={
                                                    this.handelRegionDetail
                                                }
                                                value={(this.state?.billingAddress?.region_id)?this.state?.billingAddress.region_id:""}
                                            >
                                                <option value="">Region</option>
                                                {regions && regions.length > 0 && regions.map(function(region){
                                                    return(
                                                        <option value={region.id} key={region._id}>{region.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
  
                                    <div className="contdetail_groups col-md-4">
                                        <div className="cont_fuilds">
                                            <select 
                                                name="city_id" 
                                                className="social_red_fuild font-size-16"
                                                onChange={
                                                    this.handlebillingAddress
                                                }
                                                value={(this.state?.billingAddress?.city_id)?this.state?.billingAddress.city_id:""}
                                            >
                                                <option value="">City</option>
                                                {cities && cities.length > 0 && cities.map(function(city){
                                                    return(
                                                        <option value={city.id} key={city._id}>{city.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
  
                                    <div className="contdetail_groups col-md-4">
                                        <div className="cont_fuilds">
                                            <input
                                            onChange={
                                                this.handlebillingAddress
                                            }
                                            name="postcode"
                                            type="text"
                                            value={(this.state.billingAddress?.postcode)?this.state.billingAddress.postcode:""}
                                            className="social_red_fuild font-size-16"
                                            placeholder="Postcode"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                           {/*  <div className="cont_fuild">
                                <textarea
                                    name="billingAddress"
                                    onChange={
                                    this.handleMyProfileContactDetails
                                    }
                                    value={this.state.billingAddress}
                                    rows="10"
                                    placeholder="Billing address.."
                                    className="form-control"
                                ></textarea>
                            </div> */}
                          </div>
                          <div className="contdetail_group">
                            <div className="bottonsend text-right">
                                <button
                                type="submit"
                                className="btnx btn-secondaryx font-size-14"
                                >
                                Save Changes
                                </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      } 
                    <p className="btnoff_onlable mb-3 border-top w-100 pt-3">For Withdrawings Your Funds</p>
                    
                    <div className="d-flex justify-content-between  align-items-baseline mb-2">
                      {/* {this.state.isBankDetailAdd ? 
                      <span className="btnoff_onlable">Edit your bank details</span>
                      :
                      <span className="btnoff_onlable">Add your bank details</span>
                      } */}
                      <span className="btnoff_onlable">Update your bank details</span>
                      <a href={`${process.env.REACT_APP_STRIPE_BANK_UPDATE} `} target="_blank"
                        className="btnx btn-secondaryx font-size-14"
                      >
                        Update
                      </a>
                      {/* <button 
                        className="btnx btn-secondaryx"
                        onClick={() =>
                          this.setState({
                            showWithdrawFundModal: true,
                          })
                        }
                      >
                        {this.state.isBankDetailAdd ? "Edit" : "Add"}
                      </button> */}
                    </div>
                  </div>
                {/* <form
                    onSubmit={this.handleSubmitChangeBankDetails}
                >
                    <div className="formnotification_inner withbg_white border-radius">
                    <div className="noti_enable repeatSec_innerr ">
                        <h3 className="titleof_tbasec">
                        CHANGE BANK DETAIL
                        </h3>
                        <div className="box_perentrepet">
                        <div className="contdetail_group">
                            <div className="contact_label">
                            Bank Name
                            </div>
                            <div className="cont_fuild">
                            <input
                                type="text"
                                onChange={
                                this.handleMyProfileBankDetail
                                }
                                name="bankName"
                                value={(this.state.bankDetail?.bankName)?this.state.bankDetail.bankName:""}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        <div className="contdetail_group">
                            <div className="contact_label">
                            Account Number
                            </div>
                            <div className="cont_fuild">
                            <input
                                type="number"
                                onChange={
                                this.handleMyProfileBankDetail
                                }
                                name="accountNumber"
                                value={(this.state.bankDetail?.accountNumber)?this.state.bankDetail.accountNumber:""}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        <div className="contdetail_group">
                            <div className="contact_label">
                            Routing Number
                            </div>
                            <div className="cont_fuild">
                            <input
                                type="number"
                                onChange={
                                this.handleMyProfileBankDetail
                                }
                                name="routingNumber"
                                value={(this.state.bankDetail?.routingNumber)?this.state.bankDetail.routingNumber:""}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        <div className="contdetail_group">
                            <div className="bottonsend text-center">
                            <button
                                type="submit"
                                className="btnx btn-secondaryx"
                            >
                                Save Changes
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </form> */}
                </div>
            </div>
            </div>
        </div>
        </div>
        <Modal
          isOpen={showCardModal}
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ showCardModal: false })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
          className="shipModal"
        >
          <button onClick={() =>this.setState({ showCardModal: false }) } className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
              <div
                className="col-lg-12 listside_newperent"
              >
                 <form
                    onSubmit={this.handleSubmitCardDetails}
                >
                    <div className="formnotification_inner withbg_white border-radius">
                    <div className="noti_enable">
                        <h3 className="titleof_tbasec">
                        CARD DETAIL
                        </h3>
                        <div className="box_perentrepet">
                        {/* <div className="contdetail_group"> */}
                            <div className="contact_label">
                             Name On Card
                            </div>
                            <div className="cont_fuilds">
                            <input
                                type="text"
                                onChange={
                                this.handleMyProfileBankDetail
                                }
                                name="cardHolderName"
                                value={(this.state.cardDetail?.cardHolderName)?this.state.cardDetail.cardHolderName:""}
                                className="social_red_fuild"
                            />
                            </div>
                        {/* </div> */}
                        <div className="">
                          <div className="mb-1">
                            <Elements stripe={stripePromise}>
                              <ElementsConsumer>
                                {({stripe, elements}) => (
                                  <>
                                  <div className="contact_label">
                                    Card Number
                                  </div>
                                  <div className="cont_fuilds">
                                    <CardNumberElement options={CardElementOptions}/>
                                  </div>
                                  <div className="contact_label">
                                    Expiration Date
                                  </div>
                                  <div className="cont_fuilds">
                                    <CardExpiryElement options={CardElementOptions}/>
                                  </div>
                                  <div className="contact_label">
                                    CVV
                                  </div>
                                  <div className="cont_fuilds">
                                    <CardCvcElement options={CardElementOptions}/>
                                  </div>
                                  </>
                                )}
                              </ElementsConsumer>
                            </Elements>
                          </div>
                          <div className="sr_img">

                          <img src="/images/img-1.png" alt="Image"/>
                          </div>
                        </div>
                        <div className="contdetail_group">
                            <div className="bottonsend text-center">
                            <button
                                type="submit"
                                className="btnx btn-secondaryx"
                            >
                                Save Changes
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </form>
              </div>
        </Modal>
        <Modal
          isOpen={showWithdrawFundModal}
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ showWithdrawFundModal: false })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
          className="shipModal"
        >
          <button onClick={() =>this.setState({ showWithdrawFundModal: false }) } className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
              <div
                className="col-lg-12 listside_newperent"
              >
                <form
                    onSubmit={this.handleSubmitChangeBankDetails}
                >
                    <div className="formnotification_inner withbg_white border-radius">
                    <div className="noti_enable">
                        <h3 className="titleof_tbasec">
                        BANK DETAIL
                        </h3>
                        <div className="box_perentrepet">
                        <div className="contdetail_group">
                            <div className="contact_label">
                            Bank Name
                            </div>
                            <div className="cont_fuild">
                            <input
                                type="text"
                                onChange={
                                this.handleMyProfileBankDetail
                                }
                                name="bankName"
                                value={(this.state.bankDetail?.bankName)?this.state.bankDetail.bankName:""}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        <div className="contdetail_group">
                            <div className="contact_label">
                            Account Number
                            </div>
                            <div className="cont_fuild">
                            <input
                                type="number"
                                onChange={
                                this.handleMyProfileBankDetail
                                }
                                name="accountNumber"
                                value={(this.state.bankDetail?.accountNumber)?this.state.bankDetail.accountNumber:""}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        <div className="contdetail_group">
                            <div className="contact_label">
                            Routing Number
                            </div>
                            <div className="cont_fuild">
                            <input
                                type="number"
                                onChange={
                                this.handleMyProfileBankDetail
                                }
                                name="routingNumber"
                                value={(this.state.bankDetail?.routingNumber)?this.state.bankDetail.routingNumber:""}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        <div className="contdetail_group">
                            <div className="bottonsend text-center">
                            <button
                                type="submit"
                                className="btnx btn-secondaryx"
                            >
                                Save Changes
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </form>
              </div>
        </Modal>
      </>
    );
  }
}

export default Payment;
