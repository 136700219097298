import Model from "Models/Model";

class ProductReview extends Model {
  dbSchema() {
    return {
      // name: "",
      // email: "",
      title: "",
      review: "",
      reply: "",
    };
  }

  validationRules(minLength, maxLength) {
    return {
      // name: [{ lib: "Custom", func: "NotEmpty", msg: "Name is required" }],
      // email: [{ lib: "Custom", func: "NotEmpty", msg: "Email is required" }],
      title: [{ lib: "Custom", func: "NotEmpty", msg: "Title is required" }],
      rating: [{ lib: "Custom", func: "NotEmpty", msg: "Rating is required" },
              { lib: "Validator", func: "isInt", msg: "Rating is required", 
              option: { gt: 0 } }],
      review: [
        { lib: "Custom", func: "NotEmpty", msg: "Review is required" },
        {
          lib: "Validator",
          func: "isLength",
          msg: `Your review length should be greater than ${minLength} and less than ${maxLength} characters`,
          option: {
            max: maxLength,
            min: minLength,
          },
        },
      ],
      reply: [{ lib: "Custom", func: "NotEmpty", msg: "Reply is required" }],
    };
  }
}

export default new ProductReview();
