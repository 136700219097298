import Model from "Models/Model";

class Fields extends Model{

  dbSchema(){
    return {
      _id:""
    };
  }
}

export default new Fields();
