import React, { Component } from "react";
import Faqs from "Models/Faqs";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";

class HelpCenterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LearnsData: localStorage.getItem("LearnsData")
        ? this.sortLearnsData(JSON.parse(localStorage.getItem("LearnsData")))
        : [],
      allLearns: localStorage.getItem("allLearns")
        ? JSON.parse(localStorage.getItem("allLearns"))
        : [],
      selectedCategoryIndex: 0,
      singleSubCatData: null,
      loading: true,
    };
  }

  componentDidMount = () => {
    var self = this;
    Faqs.getApi(
      "APIS_ALL_LEARN_LIST",
      async (response) => {
        const sortedResponse = this.sortLearnsData(response);
        localStorage.setItem("LearnsData", JSON.stringify(sortedResponse));
        self.setState({ LearnsData: sortedResponse, loading: false });

        if (
          sortedResponse &&
          sortedResponse.length > 0 &&
          sortedResponse[this.state.selectedCategoryIndex].subCatData.length > 0
        ) {
          this.setSingleSubCatData(sortedResponse);
        }
      },
      false
    );

    Faqs.getApi(
      "APIS_ALL_LEARNS",
      async (response) => {
        localStorage.setItem("allLearns", JSON.stringify(response));
        self.setState({ allLearns: response });
      },
      false
    );
  };

  setSingleSubCatData = (response) => {
    this.setState({
      singleSubCatData:
        response[this.state.selectedCategoryIndex].subCatData[0],
    });
  };

  sortLearnsData = (data) => {
    const order = ["Getting Started", "Selling", "Buying", "Safety tips"];
    return data.sort((a, b) => order.indexOf(a.category_name) - order.indexOf(b.category_name));
  };

  hrefRouting = (learnCat) => {
    if (learnCat.category_name === "Getting Started") {
      return "/learn-to-start";
    } else if (learnCat.category_name === "Buying") {
      return "/learn-to-buy";
    } else if (learnCat.category_name === "Safety tips") {
      return "/safety-tips";
    } else if (learnCat.category_name === "Selling") {
      return "/learn-to-sell";
    }
  };

  render() {
    const { LearnsData, singleSubCatData, selectedCategoryIndex, loading } = this.state;
    return (
      <>
        <section className="abouttop_banner tremsuse_banner help_page_banner">
          <div className="image_abtbanner-2">
            {/* <img src="/images/terms-use-Bimg.png" alt="Image" /> */}
            <div className="privacy_ply text-center">
              <h2 className="bannerheading">Help Center</h2>
            </div>
          </div>
        </section>
        <section className="learntop_sectwo bg-light-dark onlyforlearn_sectionpa help_card_block">
          <div className="learnsel_faqtabperent navClass">
            <div className="container">
              <ul className="nav nav-tabs learnsel_faqtab" role="tablist">
                {loading
                  ? // Render skeletons while loading
                  [1, 2, 3, 4].map((index) => (
                    <li className="nav-item" key={`skeleton_${index}`}>
                      <Skeleton
                        variant="rect"
                        width={210}
                        height={118}
                        animation="pulse"
                      />
                      <Skeleton width="60%" animation="pulse" />
                      <Skeleton width="40%" animation="pulse" />
                    </li>
                  ))
                  : LearnsData &&
                  LearnsData.map((learnCat, index) => (
                    <li
                      className="nav-item"
                      key={`learnCatData_${learnCat._id}`}
                    >
                      <a
                        className={`nav-link ${index === selectedCategoryIndex ? "" : ""
                          }`}
                        href={this.hrefRouting(learnCat)}
                      >
                        <div className="learnlistbox">
                          <div className="learn_imgbx">
                            {/* <img src="/images/learnsell05.png" alt="Image" /> */}
                            <img
                              src={
                                `${process.env.REACT_APP_ApiBaseUrl}image/categories/resize/` +
                                learnCat.image_name
                              }
                              alt="Image"
                            />
                          </div>
                          <div className="learnbx_content">
                            <h2>{learnCat.category_name}</h2>
                            <div className="button_learn d-none d-md-block">
                              <span className="btnx btn-secondaryx font-size-14">
                                View
                              </span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default HelpCenterComponent;
