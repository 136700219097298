export const OPEN_MESSAGE_BOX = "OPEN_MESSAGE_BOX";
export const CLOSE_MESSAGE_BOX = "CLOSE_MESSAGE_BOX";
export const MINIMIZE_MESSAGE_BOX = "MINIMIZE_MESSAGE_BOX";
export const TOGGLE_SELECTED_CHANNEL = "TOGGLE_SELECTED_CHANNEL";
export const MESSAGE_DATA = "MESSAGE_DATA";
export const SET_SELECTED_CHANNEL = "SET_SELECTED_CHANNEL";

export const SET_INCOMING_CALL = "SET_INCOMING_CALL";
export const SET_OUTGOING_CALL = "SET_OUTGOING_CALL";
export const SET_QUICKBLOX_SESSION = "SET_QUICKBLOX_SESSION";
export const SET_SCHEDULE_MEETING = "SET_SCHEDULE_MEETING";

export const SET_CONTACT_LIST = "SET_CONTACT_LIST";
export const SET_SELECTED_CHANNEL1 = "SET_SELECTED_CHANNEL1";
export const SET_SELECTED_USER = "SET_SELECTED_USER";
export const SET_SELECTED_CHANNEL_DATA = "SET_SELECTED_CHANNEL_DATA";
export const SET_QUICKBLOX_CONNECTION = "SET_QUICKBLOX_CONNECTION";
export const SET_QUICKBLOX_UNREADMESSAGE = "SET_QUICKBLOX_UNREADMESSAGE";
