import React, { Component } from "react";
import General from "Helpers/General";
import MyOwnLevelComponent from "./MyOwnLevelComponent";
import MyProfile from "Models/MyProfile";
import CustomerReviewComponent from "./CustomerReviewComponent";

export class Profile extends Component {

    constructor(props) {

        super(props);

        this.state = {
            userId: localStorage.getItem("UserData")
            ? JSON.parse(localStorage.getItem("UserData"))["_id"]
            : "",
            tabView : 'follwers'
        }

    }


    handleTabes = (tab) => {
        this.setState({
            tabView : tab
        });
    }

    render() {

        const {tabView} = this.state;

        return (
            <React.Fragment>
                <div
                    id="myownlevel"
                    role="tabpanel"
                >
                    <div className="product-all-detail-tab">
                        <ul
                        className="nav nav-tabs my-custom-tab cuttan_newimg specialshap_likedetailP product_sall_tab"
                        role="tablist"
                        >
                            <li className="nav-item">
                                <a
                                    className="nav-link active in"
                                    data-toggle="tab"
                                    herf={void(0)}
                                    role="tab"
                                    onClick={() => this.handleTabes('follwers')}
                                >
                                    <i className="fas fa-user-plus"></i>
                                    <span> Followers </span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    herf={void(0)}
                                    role="tab"
                                    onClick={() => this.handleTabes('customer_reviews')}
                                >
                                    <span> Customers Reviews</span>
                                </a>
                            </li>
                        </ul>
                    </div>
         
                    <div className="tab-content subtab_tabcont">
                        { (tabView === 'follwers') ? <MyOwnLevelComponent/>  : <CustomerReviewComponent/> }
                    </div>

                </div>

            </React.Fragment>
        );
    }
}

export default Profile;
