import General from "Helpers/General";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Fancybox } from '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';

export class MyProductComponent extends Component {

  render() {
    const { product, deleteProductHandler, publishProductHandler } = this.props;

    return (
      <div className="col-lg-12 listside_newperent productColBg_Row px-3 mb-2 mb-md-0">

        <div className="background-color">
          <div className="product-listing-product desktop_view">
            <div className="row">
              <div className="col-12 col-sm-3">
                <div className="product-img-bx">
                  {/* <Link to={`/product/${product.productSlug}`}> */}
                  {product.adType && product.adType === "Featured" && (
                    <div className="specialtags">
                      <span
                        className={
                          (product.adType === "Featured" ? "black" : "green") +
                          "_special"
                        }
                      >
                        {product.adType}{" "}
                      </span>
                    </div>
                  )}
                  {product.productImages && product.productImages.length > 0 ? (
                    <div>
                      {product.productImages.map((image, index) => (
                        <a className={(index === 0 ? '' : 'd-none')} key={index} data-src={General.showImage("products/resize-566-400", image)}
                          data-fancybox={`desktop-product-${product._id}`} href={General.showImage(
                            "products/resize-566-400",
                            image
                          )}>
                          <img
                            src={General.showImage(
                              "products/resize-180-180",
                              image
                            )}
                            onError={
                              (e) => e.target.src = '/images/no-image.jpg'
                            }
                            alt={product.productName} />
                        </a>
                      ))}
                    </div>

                  ) : (
                    <img src="/images/no-image.jpg" alt="product" />
                  )}

                  <div className="totalimg_sectab">
                    {product.productImages ? product.productImages.length : 0}{" "}
                    <i className="fas fa-camera"></i>
                  </div>
                  {/* </Link> */}
                </div>
              </div>
              <div className="col-12 col-sm-9 my-productColBg">
                <div className="product-outer-bx productBig-outer-bx">
                  <div className="product-name-bx content_gobottom_new">
                    {/* <Moment fromNow>{product.time}</Moment> */}
                    <Link to={`/product/${product.productSlug}`}>
                      <h4>{product.productName}</h4>
                    </Link>
                    <h5>{product.location && product.location}</h5>
                    <h6>
                      <span>
                        {General.showPriceDecimal(product.productPrice)}
                      </span>
                    </h6>
                    <ul className="listofsubtabbx">
                      <li>
                        <strong>Added</strong>
                        <span>
                          <Moment format="YYYY/MM/DD">{product.time}</Moment>
                        </span>
                      </li>
                      {product?.expiredAt && (
                        <li>
                          <strong>Expires</strong>
                          <span>
                            <Moment
                              format="YYYY/MM/DD"
                            >
                              {product?.expiredAt}
                            </Moment>
                          </span>
                        </li>
                      )}
                    </ul>
                    <div className="subtab_edit h-row-btn">
                      {product.isExpired === false &&
                        <div className="subtab_editlabl">
                          <div className="dropdown">
                            <div
                              id="dropdownAdActionButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fas fa-ellipsis-v"></i>
                            </div>
                            <div
                              className="dropdown-menu dropdown-ad-actions dropdown-menu-end"
                              aria-labelledby="dropdownAdActionButton"
                            >
                              <Link
                                to={`/edit-product/${product.productSlug}`}
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                className="dropdown-item"
                              >
                                Edit
                              </Link>
                              <Link
                                to="#!"
                                onClick={(e) =>
                                  deleteProductHandler(e, product._id)
                                }
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                className="dropdown-item"
                              >
                                Delete
                              </Link>
                              <Link
                                to="#!"
                                onClick={(e) =>
                                  publishProductHandler(e, product._id)
                                }
                                data-toggle="tooltip"
                                data-placement="top"
                                title=""
                                className="dropdown-item"
                              >
                                Change Status
                              </Link>
                            </div>
                          </div>
                        </div>
                      }
                      <p className="subtab_editlabl">
                        <span className="labltable lablesct">
                          {product.isExpired === true ? "Expired" : product.active === true ? "Active" : "Inactive"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="product-listing-product mobile_view position-relative">
            {product.isExpired === false &&
              <div className="subtab_editlabl dropBox dropBoxNew">
                <div className="dropdown">
                  <div
                    id="dropdownAdActionButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </div>
                  <div
                    className="dropdown-menu dropdown-ad-actions dropdown-menu-end"
                    aria-labelledby="dropdownAdActionButton"
                  >
                    <Link
                      to={`/edit-product/${product.productSlug}`}
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      className="dropdown-item"
                    >
                      Edit
                    </Link>
                    <Link
                      to="#!"
                      onClick={(e) =>
                        deleteProductHandler(e, product._id)
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      className="dropdown-item"
                    >
                      Delete
                    </Link>
                    <Link
                      to="#!"
                      onClick={(e) =>
                        publishProductHandler(e, product._id)
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      className="dropdown-item"
                    >
                      Change Status
                    </Link>
                  </div>
                </div>
              </div>
            }
            <div className="product_listing_product_block">
              <div className="product-img-bx 22222222">
                {/* <Link to={`/product/${product.productSlug}`}> */}
                {product.adType && product.adType === "Featured" && (
                  <div className="specialtags">
                    <span
                      className={
                        (product.adType === "Featured" ? "black" : "green") +
                        "_special"
                      }
                    >
                      {product.adType}{" "}
                    </span>
                  </div>
                )}
                {product.productImages && product.productImages.length > 0 ? (
                  <div>
                    {product.productImages.map((image, index) => (
                      <a className={(index === 0 ? '' : 'd-none')} key={index} data-fancybox={`mobile-product-${product._id}`} href={General.showImage(
                        "products/resize-566-400",
                        image
                      )}>
                        <img
                          src={General.showImage(
                            "products/resize-180-180",
                            image
                          )}
                          onError={
                            (e) => e.target.src = '/images/no-image.jpg'
                          }
                          alt={product.productName} />
                      </a>
                    ))}
                  </div>

                ) : (
                  <img src="/images/no-image.jpg" alt="product" />
                )}
                <div className="totalimg_sectab">
                  {product.productImages ? product.productImages.length : 0}{" "}
                  <i className="fas fa-camera"></i>
                </div>
                {/* </Link> */}
              </div>
              <div className="my-productColBg">
                <div className="product-outer-bx productBig-outer-bx">
                  <div className="product-name-bx content_gobottom_new py-0">
                    {/* <Moment fromNow>{product.time}</Moment> */}
                    <Link to={`/product/${product.productSlug}`}>
                      <h4 className="product_mobile_view">{product.productName}?</h4>
                    </Link>
                    <h5>{product.location && product.location}</h5>
                    <h6>
                      <span>£ {product.productPrice}</span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="date_box">
              <ul className="listofsubtabbx">
                <li>
                  <strong>Added</strong>
                  <span>
                    <Moment format="YYYY/MM/DD">{product.time}</Moment>
                  </span>
                </li>
                {product?.expiredAt && (
                  <li>
                    <strong>Expires</strong>
                    <span>
                      <Moment
                        format="YYYY/MM/DD"
                      >
                        {product?.expiredAt}
                      </Moment>
                    </span>
                  </li>
                )}
              </ul>
            </div>
            <div className="subtab_edit">

              <p className="subtab_editlabl">
                <span className="labltable lablesct mb-0">
                  {product.isExpired === true ? "Expired" : product.active === true ? "Active" : "Inactive"}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyProductComponent;
