import React, { Component } from "react";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";

export class MyPendingTransaction extends Component {

  constructor(props) {

      super(props);

      this.state = {
          userId: localStorage.getItem("UserData")
          ? JSON.parse(localStorage.getItem("UserData"))["_id"]
          : "",
          totalWithdrawlBalance: 0,
          totalPendingBalance:0
      }

  }

  componentDidMount = () => {
    this.getPendingBalance();
  };


  getPendingBalance = () => {
    const { userId } = this.state;
    MyProfile.getApi(
      "API_GET_SELLER_TOTAL_BALANCE",
      async (res) => {
        if (res) {
          this.setState({
            totalPendingBalance: res.totalPendingBalance,
            totalWithdrawlBalance:res.totalWithdrawlBalance
          });
        }
      },
      { params: { seller_id: userId } },
      true
    )
    };


  render() {

    const { totalPendingBalance } = this.state;

    return (
      <>
          <div
            className="tab-pane active in this_subManage_Listings"
            id="pending-transaction"
            role="tabpanel"
          >
            <div className="subtab_prodlist_NEW_forpadd">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 d-none d-md-block">
                            <div className="subcateleft_imgsec">
                                <img
                                src="/images/account-settings-limg.png"
                                alt="icon"
                                />
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="formnotification_inner withbg_white border-radius border-bottom_1">
                                <div className="noti_enable repeatSec_innerr border-0">
                                  <div>
                                    {General.showPriceDecimal(totalPendingBalance)}
                                  </div>
                                  <div>
                                    Uncompleted Transaction <i className="fas fa-info-circle"></i>
                                  </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </>
    );
  }
}

export default MyPendingTransaction;
