import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import {
  OpenMessageBox,
  MinimizeMessageBox,
  CloseMessageBox,
  toggleSelectedChannel,
  SetSelectedChannel1,
  SetSelectedUser,
  SetSelectedChannelData,
  SetOutgoingCallState,
  SetQuickbloxSession,
} from "../../Helpers/Actions";
import QB, { playCallAudio } from "Helpers/Quickblox";
import Zoom from "react-medium-image-zoom";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import General from "Helpers/General";
import Product from "Models/Product";
// import lodash from "lodash";

export class Message extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.msgDivRef = React.createRef();
    this.state = {
      // msgCheck: props.msgCheck,
      // selectedUser: props.selectedUser,
      messages: [],
      textMessage: "",
      image: null,
      selectEmo: false,
      selectedUserDetails:null,
      addPosted : 0,
    };
  }

  addEmoji = (emoji) => {
    this.setState({
      selectEmo: false,
      textMessage: this.state.textMessage + emoji.native,
    });
  };

  componentDidMount = () => {
    if (
      this.props.msgCheck &&
      this.props.selectedChannel &&
      this.props.selectedUser
    ) {
      this.handleChannelSelect(this.props.selectedUser.id);
    }

    if (
      this.props.selectedUser
    ) {
      this.handleChannelSelect(this.props.selectedUser.id);
    }

    QB.chat.onMessageListener = this.onMessage;
  };

  static getDerivedStateFromProps = (props, state) => {
    if (props.selectedUser !== state.selectedUser) {
      // that.handleChannelSelect(props.selectedUser.id);
      // return {
      //   selectedUser: props.selectedUser,
      //   msgCheck: true,
      // };
    }
    return null;
  };

  openMessageBoxHandler = () => {
    localStorage.setItem("isOpenMessageBox", JSON.stringify(true));
    localStorage.setItem("isClosedMessageBox", JSON.stringify(false));
    this.props.OpenMessageBox(true);
  };

  minimizeMessageBoxHandler = () => {
    this.props.MinimizeMessageBox(false);
    localStorage.setItem("isOpenMessageBox", JSON.stringify(false));
  };

  closeMessageBoxHandler = () => {
    this.props.CloseMessageBox(true);
    localStorage.setItem("isClosedMessageBox", JSON.stringify(true));
    // this.setClassHide();
    // this.props.toggleSelectedChannel(false);
    // localStorage.setItem("selectedMessageBox", JSON.stringify(false));
  };

  setClassHide = () => {
    // const currentState = this.props.msgCheck;
    this.props.toggleSelectedChannel(false);
    this.props.SetSelectedChannel1(null);
    this.props.SetSelectedUser([]);
    this.props.SetSelectedChannelData([]);
    localStorage.setItem("selectedMessageBox", JSON.stringify(false));
    localStorage.removeItem("QB_SELECTED_CHANNEL");
    localStorage.removeItem("QB_SELECTED_USER");
    localStorage.removeItem("QB_SELECTED_CHANNEL_DATA");
    this.setState({ textMessage: "" });
  };

  handleChannelSelect = (userId) => {
    this.openDialogBox(userId);
  };

  openDialogBox = (userId) => {
    const selectedContact = JSON.parse(
      localStorage.getItem("QB_CONTACT_LIST")
    ).filter((u) => u.user.id === userId);

    localStorage.setItem("QB_SELECTED_USER", JSON.stringify(selectedContact));
    this.props.SetSelectedUser(selectedContact);
    let params = {
      type: 3,
      occupants_ids: [userId],
    };
    QB.chat.dialog.create(params, (error, dialog) => {
      if (!error) {
        localStorage.setItem("QB_SELECTED_CHANNEL", JSON.stringify(dialog));
        //   load Messages
        this.props.SetSelectedChannel1(dialog);
        this.loadMessages(dialog._id);
        this.handleSelectedProfile(selectedContact[0].user.email);
      }
    });
  };

  // load messages
  loadMessages = (dialogId) => {
    let params = {
      chat_dialog_id: dialogId,
      sort_asc: "date_sent",
    };

    QB.chat.message.list(params, (err, messages) => {
      if (!err) {
        this.setState({ messages: messages.items });
        
        localStorage.setItem(
          "QB_SELECTED_CHANNEL_DATA",
          JSON.stringify(messages.items)
        );
        this.props.toggleSelectedChannel(true);
        localStorage.setItem("selectedMessageBox", JSON.stringify(true));
        this.props.SetSelectedChannelData(messages.items);
        // if (this.props.messages.length > 0) {
        this.msgDivRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
        // }
      }
    });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  sendMessageHandler = (e) => {
    e.preventDefault();

    let opponentId = this.props.selectedChannel.occupants_ids.filter(
      (id) => id !== JSON.parse(localStorage.getItem("QB_USER")).id
    );

    const inputFile = document.getElementsByName("image")[0].files[0];
    if (inputFile) {
      let fileParams = {
        name: inputFile.name,
        file: inputFile,
        type: inputFile.type,
        size: inputFile.size,
        public: false,
      };

      QB.content.createAndUpload(fileParams, (error, result) => {
        
        let message = {
          type: "chat",
          body: this.state.textMessage,
          extension: {
            save_to_history: 1,
            dialog_id: this.props.selectedChannel._id,
            attachments: [{ id: result.uid, type: fileParams.type }],
          },
          markable: 1,
        };

        try {
          message.id = QB.chat.send(...opponentId, message);
          this.setState({ textMessage: "", image: "" });
          this.fileInputRef.current.value = "";
          this.loadMessages(this.props.selectedChannel._id);
          this.setContact(...opponentId);
        } catch (e) {
          console.log(e);
          if (e.name === "ChatNotConnectedError") {
            // not connected to chat
          }
        }
      });
    } else {
      let message = {
        type: "chat",
        body: this.state.textMessage,
        extension: {
          save_to_history: 1,
          dialog_id: this.props.selectedChannel._id,
        },
        markable: 1,
      };

      try {
        message.id = QB.chat.send(...opponentId, message);
        this.setState({ textMessage: "", image: "" });
        this.fileInputRef.current.value = "";
        this.loadMessages(this.props.selectedChannel._id);
        this.setContact(...opponentId);
      } catch (e) {
        console.log(e);
        if (e.name === "ChatNotConnectedError") {
          // not connected to chat
        }
      }
    }
  };

  setContact = (userId) => {
    try {
      QB.chat.roster.add(userId, function () {});
    } catch (e) {
      if (e.name === "ChatNotConnectedError") {
        // not connected to chat
      }
    }
  };

  onMessage = (userId, message) => {
    this.openMessageBoxHandler();
    this.handleChannelSelect(userId);
  };

  startVideoCall = () => {
    if (this.props.selectedUser) {
      this.initiateCall(this.props.selectedUser.id);
    }
  };

  startAudioCall = () => {
    if (this.props.selectedUser) {
      this.initiateCall(this.props.selectedUser.id, 2);
    }
  };

  initiateCall = (userId, callType = 1) => {
    this.setClassHide();
    var calleesIds = [userId]; // Users' ids
    var sessionType =
      callType === 2 ? QB.webrtc.CallType.AUDIO : QB.webrtc.CallType.VIDEO;
    var additionalOptions = {};

    var session = QB.webrtc.createNewSession(
      calleesIds,
      sessionType,
      null,
      additionalOptions
    );
   
    var mediaParams = {
      audio: true,
      video: callType === 2 ? false : { min: 320, ideal: 720, max: 1024 },
      options: {
        muted: true,
        mirror: true,
      },
      elemId: "localVideoElem",
    };
    session.stop({});
    this.props.SetQuickbloxSession(session);
    session.getUserMedia(mediaParams, (error, stream) => {
      if (error) {
        // this.props.SetOutgoingCallState(false);
        // this.props.SetQuickbloxSession(null);
        // session.stop({});
        console.log("Get user stream error => ", error);
      } else {
        //run call function here
        // console.log(stream);
        // session.attachMediaStream("localVideoElem", stream);
        if (stream) {
          const callee = JSON.parse(localStorage.getItem("QB_USER"));
          session.call({ callee: callee?.full_name }, (error1) => {
            playCallAudio();
            this.props.SetOutgoingCallState(true);
            console.log(error1);
          });
        }
      }
    });
  };

  // checkOnlineStatus = (userId) => {
  //   // ping user (by user ID)
  //   try {
  //     QB.chat.ping(userId, function (error) {
  //       if (error) {
  //         // no pong received
  //         // console.log(userId, error);
  //         return true;
  //       } else {
  //         // console.log(userId, "Online");
  //         return false;
  //         // pong received from user
  //       }
  //     });
  //   } catch (e) {
  //     if (e.name === "ChatNotConnectedError") {
  //       // not connected to chat
  //       // console.log(userId, "Offline");
  //       return false;
  //     }
  //   }
  // };
  _handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.sendMessageHandler(e);
    }
  };

  handleSelectedProfile = (email) => {
    fetch(process.env.REACT_APP_ApiBaseUrl + `getSelectedUser`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(async (response) => {
        if (response && response !== undefined) {
          let data = await response.json();
          if (data && data.length) {
            this.setState({ selectedUserDetails: data }, () => {
              this.adPostedStatus();
            });
          }
        }
      }).catch((err) => {
        console.log(err)
      })
  };

  adPostedStatus = () => {
    Product.postApi(
      "API_GET_AD_POSTED_STATUS",
      { userId: this.state.selectedUserDetails[0]._id },
      async (response,status) => {
        console.log("viajy",this.state.selectedUserDetails[0]._id)
        if(status === "success"){
          this.setState({ addPosted: 1 });
        }else{
          this.setState({ addPosted: 0 });
        }
      },
      true
    );
  }

  render() {
    return (
      <div className="chatBottom_parent">
        <div
          className={
            this.props.isOpenMessageBox
              ? "rightchat_list"
              : "rightchat_list active"
          }
        >
          <div className="chatbox_head">
            <div
              className="chatleftH_title"
              onClick={this.openMessageBoxHandler}
            >
              <h6>
                <img src="/images/messanger.png" alt="Icon" />
                <span className="catbbss_titl">Messanger</span>
              </h6>
            </div>
            <div className="chatclose">
              <a href={void(0)} onClick={this.openMessageBoxHandler}>
                <img src="/images/envelope.png" alt="Icon" />
              </a>{" "}
              &nbsp;
              <a href={void(0)} onClick={this.minimizeMessageBoxHandler}>
                <img src="/images/minimise.png" alt="Icon" />
              </a>{" "}
              &nbsp;
              <a href={void(0)} onClick={this.closeMessageBoxHandler}>
                <img src="/images/close.png" alt="Icon" />
              </a>
            </div>
          </div>
          <div
            className="chat_teambx mCustomScrollbar mini-message_block"
            data-mcs-theme="dark"
          >
            {this.props.contactList.map((channel,index) => (
              <div
                key={index}
                className="channel-item"
                onClick={() => this.handleChannelSelect(channel.user.id)}
              >
                <div className="chatteammem_bx m-0">
                  <div className="chatuser">
                    <div className="userimgC">
                      {/* {console.log(
                        JSON.parse(channel.user.custom_data).profilePicture
                      )} */}
                      <img
                        src={
                          channel.user.custom_data &&
                          JSON.parse(channel.user.custom_data).profilePicture
                            ? General.showImageNew(
                                "profile",
                                JSON.parse(channel.user.custom_data)
                                  .profilePicture
                              )
                            : "/images/user.png"
                        }
                        alt=""
                      />
                    </div>
                    {/* {this.checkOnlineStatus(channel.user.id) === true ? (
                      <span className="livestats_offline"></span>
                    ) : (
                      <span className="livestats"></span>
                    )} */}
                  </div>
                  <div className="chatusername">
                    <p>{channel.user.full_name}</p>
                  </div>{" "}
                  <div className="chatu_time">
                    <p>
                      {Moment(channel.user.last_request_at).format("HH:mm")}
                    </p>
                  </div>
                  <a href={void(0)} className="onhovelink"></a>
                </div>
                {/* <div>{this.props.name}</div> */}
                {/* <span>{this.props.participants}</span> */}
              </div>
            ))}
          </div>
        </div>

        <div className={this.props.msgCheck ? "chatboxL" : "chatboxL d-none"}>
          <div className="chatbox_head">
            <div className="chatleftH_title">
              <h6>
                <a
                  href={void(0)}
                  className="backonlymes_chatmob pl-0"
                  onClick={this.setClassHide}
                >
                  <i className="fas fa-arrow-left"></i>
                </a>
                <img src="/images/messanger.png" alt="Icon" />

                <span className="catbbss_titl">
                  {this.props.selectedUser?.full_name}
                </span>
                <p>
                  Last seen{" "}
                  {Moment(this.props.selectedUser?.last_request_at).fromNow()} |{" "}
                  {Moment(this.props.selectedUser?.last_request_at).format(
                    "MMM Do, h:mm:ss a"
                  )}
                </p>
              </h6>
            </div>
            <div className="chatclose">
            {(this.state.addPosted == 1 && this.state.selectedUserDetails && this.state.selectedUserDetails.length > 0 && this.state.selectedUserDetails[0]?.isOnline == true && this.state.selectedUserDetails[0]?.goesOffline == 1) &&
              <>
              <a href={void(0)} onClick={this.startVideoCall}>
                <img src="/images/videoicon-white.png" alt="Icon" />
              </a>{" "}
              &nbsp;
              <a href={void(0)} onClick={this.startAudioCall}>
                <img src="/images/phone-icon-white.png" alt="Icon" />
              </a>
              &nbsp;
              </>
            }
              <a href={void(0)} onClick={this.setClassHide}>
                <img src="/images/close.png" alt="Icon" />
              </a>
            </div>
          </div>
          <div className="p-2">
            <div className="sent_sidemain-top">
              {this.props.messages.length ? (
                this.props.messages.map((msg,index) => (
                  <div key={index} className="msg-list" ref={this.msgDivRef}>
                    <div className="time_ofchat">
                      {Moment(msg.created_at).format("MMM Do, h:mm:ss a")}
                    </div>
                    {msg.sender_id ===
                    JSON.parse(localStorage.getItem("QB_USER")).id ? (
                      <div className="sent_sidemain_block">
                        <div className="myside_chat">
                          <div className="chat_textdesc">
                            <span className="chat_hello">{msg.message}</span>
                            {/* {msg.attachments && msg.attachments.length > 0 && (
                              <Zoom>
                                <img
                                  src={QB.content.privateUrl(
                                    msg.attachments[0].id
                                  )}
                                  width={50}
                                  height={50}
                                  alt=""
                                />
                              </Zoom>
                            )} */}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="sent_sidemain_block">
                        <div className="sent_sidemain">
                          <div className="chat_textdesc">
                            <span className="chat_hello">
                              <span>{msg.message}</span>
                              {/* {msg.attachments && msg.attachments.length > 0 && (
                                <Zoom>
                                  <img
                                    src={QB.content.privateUrl(
                                      msg.attachments[0].id
                                    )}
                                    width={50}
                                    height={50}
                                    alt=""
                                  />
                                </Zoom>
                              )} */}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p className="start_conversation">
                  Once you start a new conversation, you’ll see it listed here.
                </p>
              )}
            </div>
          </div>
          <div className="chatbox_footer .bg-light-dark">
            <div className="writesomt_full">
              <a
                href={void(0)}
                onClick={() =>
                  this.setState({ selectEmo: !this.state.selectEmo })
                }
                className="writesomt_smiley"
              >
                <i
                  className="far fa-smile"
                  style={{ color: this.state.selectEmo ? "#249ee4" : "" }}
                >
                  {/* {this.state.selectEmo ? (
                    <Picker onEmojiSelected={this.logEmoji} />
                  ) : (
                    ""
                  )} */}
                </i>
              </a>
              {this.state.selectEmo && (
                <Picker
                  onEmojiSelected
                  onSelect={this.addEmoji}
                  style={{
                    position: "absolute",
                    bottom: "5px",
                    right: "10px",
                  }}
                />
              )}
              <div className="file-upload-button">
                <input
                  type="file"
                  name="image"
                  ref={this.fileInputRef}
                  onChange={this.handleInput}
                  id="validationCustom01"
                />
                <button className="btn">
                  {/* {this.state.IsSelected == true ? (
                    <i className="fa fa-paperclip" style={{ color: "red" }}></i>
                  ) : ( */}
                  <i className="fa fa-paperclip" aria-hidden="true"></i>
                  {/* )} */}
                </button>
              </div>
              {/* {this.state.IsSelected ? (<div>
					<p>{this.state.selectedFile.name}</p></div>):''} */}
              <input
                type="text"
                onKeyPress={this._handleKeyPress}
                onChange={this.handleInput}
                value={this.state.textMessage}
                name="textMessage"
                autoComplete="off"
                placeholder="Write something . . ."
              />
              <div className="fulsend_right">
                <button onClick={this.sendMessageHandler}>
                  <i className="fas fa-paper-plane"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOpenMessageBox: state.MESSAGE_BOX.isOpenMessageBox,
    msgCheck: state.MESSAGE_BOX.msgCheck,
    contactList: state.MESSAGE_BOX.contactList,
    selectedUser: state.MESSAGE_BOX.selectedUser.length
      ? state.MESSAGE_BOX.selectedUser[0].user
      : [],
    selectedChannel: state.MESSAGE_BOX.selectedChannel,
    messages: state.MESSAGE_BOX.selectedChannelData,
  };
};

export default connect(mapStateToProps, {
  OpenMessageBox,
  MinimizeMessageBox,
  CloseMessageBox,
  toggleSelectedChannel,
  SetSelectedChannel1,
  SetSelectedUser,
  SetSelectedChannelData,
  SetOutgoingCallState,
  SetQuickbloxSession,
})(Message);
