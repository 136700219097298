import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import Category from "Models/Category";
import Fields from "Models/Fields";
import CategorySearch from "Models/CategorySearch";
import SubCategory from "Models/SubCategory";
import Product from "Models/Product";
import General from "Helpers/General";
import ContentLoader from "react-content-loader";
import Moment from "react-moment";

export class LiveStreamingPage extends Component {
  constructor(props) {
    super(props);
    const categoryslug = props.match.params.categorySlug;
    const allLiveStreamingFilterProductsLS =
      "allLiveStreamingFilterProducts_" + categoryslug;
    const allUpcomingLiveStreamingFilterProductsLS =
      "allUpcomingLiveStreamingFilterProducts_" + categoryslug;
    const singleCategoryDataLS = "singleCategoryData_" + categoryslug;
    const totalRecordsLS = "totalLiveStreamingRecords_" + categoryslug;
    const fieldsLoopData = localStorage.getItem("fieldsLoopData")
      ? JSON.parse(localStorage.getItem("fieldsLoopData"))
      : [];
    const subCat = localStorage.getItem("PostCategories")
      ? Object.assign(
        {},
        ...JSON.parse(localStorage.getItem("PostCategories")).filter(
          (cat) => cat.slug === categoryslug
        )
      )
      : {};
    this.state = {
      categoryFilers: CategorySearch.dbSchema(),
      categoryCustomFilers: {},
      singleCategoryData: subCat,
      categoryslug: categoryslug,
      subCategoryList: subCat && subCat.subCatData ? subCat.subCatData : [],
      allLiveStreamingFilterProducts: localStorage.getItem(
        allLiveStreamingFilterProductsLS
      )
        ? JSON.parse(localStorage.getItem(allLiveStreamingFilterProductsLS))
        : [],
      allUpcomingLiveStreaming: localStorage.getItem(
        allUpcomingLiveStreamingFilterProductsLS
      )
        ? JSON.parse(
          localStorage.getItem(allUpcomingLiveStreamingFilterProductsLS)
        )
        : [],
      allCustomFields: fieldsLoopData[categoryslug]
        ? fieldsLoopData[categoryslug]
        : [],
      totalRecords: localStorage.getItem(totalRecordsLS)
        ? localStorage.getItem(totalRecordsLS)
        : "0",
      categoryId: null,
      showHideListData: true,
      nothingD: false,
      showHideGridData: false,
      productSkeleton: [
        { id: 0 },
        { id: 340 },
        { id: 680 },
        { id: 1020 },
        { id: 1360 },
      ],
      loadMoreSubCat: false,
      loadAllSubCatBtn: true,
      openProductFilter: false,
      nothingD: false,
    };
  }

  componentDidMount = () => {
    // var self = this;
    const { categoryslug } = this.state;
    const singleCategoryDataLS = "singleCategoryData_" + categoryslug;
    Category.postApi(
      "APIS_GET_CATEGORY_DETAIL",
      { params: { categoryslug } },
      async (response) => {
        localStorage.setItem(singleCategoryDataLS, JSON.stringify(response));
        this.setState({
          singleCategoryData: response,
          categoryId: response._id,
        });
        let { categoryFilers } = this.state;
        categoryFilers["categoryId"] = response._id;
        this.setState({ categoryFilers: categoryFilers });
        this.getDataByfilters();
        this.getSubCategoriesByCatId();
        this.getFieldsValuesData();
        // this.getStaticSettingData();
      },
      false
    );
  };

  getFieldsValuesData = () => {
    Fields.getApiByParams(
      "APIS_GET_CAT_FIELDS_VALUES_FILTERS",
      this.state.categoryId,
      async (response) => {
        this.setState({ allCustomFields: response });
      },
      false
    );
  };

  getSubCategoriesByCatId = () => {
    //const subCategoryListLS = "subCategoryList_"+self.state.categoryslug;
    SubCategory.getApiByParams(
      "APIS_GET_SUB_CATEGORIES_BY_CAT_ID",
      this.state.categoryId,
      async (response, status) => {
        if (response) {
          //localStorage.setItem(subCategoryListLS, JSON.stringify(response));
          // self.setState({ subCategoryList: response });
        }
      },
      false
    );
  };

  getDataByfilters = () => {
    if (localStorage.getItem("location")) {
      let { categoryFilers } = this.state;
      categoryFilers["lat"] = JSON.parse(localStorage.getItem("location"))[
        "latitude"
      ];
      categoryFilers["lng"] = JSON.parse(localStorage.getItem("location"))[
        "longitude"
      ];
      this.setState({ categoryFilers: categoryFilers });
    }

    if (this.props.lat && this.props.lng) {
      let { categoryFilers } = this.state;
      categoryFilers["lat"] = this.props.lat;
      categoryFilers["lng"] = this.props.lng;
      this.setState({ categoryFilers: categoryFilers });
    }
    if (this.props.keyword) {
      let { categoryFilers } = this.state;
      categoryFilers["keyword"] = this.props.keyword;
      this.setState({ categoryFilers: categoryFilers });
    }

    if (Product.isLogin()) {
      let { categoryFilers } = this.state;
      categoryFilers["user_id"] = Product.getAuthUserId();
      this.setState({ categoryFilers: categoryFilers });
    }

    const allLiveStreamingFilterProductsLS =
      "allLiveStreamingFilterProducts_" + this.state.categoryslug;
    const allUpcomingLiveStreamingFilterProductsLS =
      "allUpcomingLiveStreamingFilterProducts_" + this.state.categoryslug;
    const totalRecordsLS =
      "totalLiveStreamingRecords_" + this.state.categoryslug;
    Product.postApiByParams(
      "APIS_GET_LIVESTREAMING_PRODUCTS_BY_CATEGORY_FILTERS",
      this.state.categoryFilers,
      async (response, status) => {
        if (response.status) {
          localStorage.setItem(
            allLiveStreamingFilterProductsLS,
            JSON.stringify(response.data.livestreamProducts)
          );
          localStorage.setItem(
            allUpcomingLiveStreamingFilterProductsLS,
            JSON.stringify(response.data.upcomingLiveProducts)
          );
          localStorage.setItem(totalRecordsLS, response.totalRecords);
          this.setState({
            allLiveStreamingFilterProducts: response.data.livestreamProducts,
          });
          this.setState({
            allUpcomingLiveStreaming: response.data.upcomingLiveProducts,
          });
          this.setState({ totalRecords: response.totalRecords });
          this.setState({ next_page_number: response.next_page_number });
          this.setState({ limit: response.limit });
          this.setState({ nothingD: true });
        }
      },
      false
    );
  };

  loadAllSubCategories = () => {
    const { loadMoreSubCat, loadAllSubCatBtn } = this.state;
    this.setState({
      loadMoreSubCat: !loadMoreSubCat,
      loadAllSubCatBtn: !loadAllSubCatBtn,
    });
  };

  getDatabyPageno = (next_page_number) => {
    let { categoryFilers } = this.state;
    categoryFilers["page_number"] = next_page_number;
    this.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  getFltersdataFetch = (event) => {
    var keyType = event.currentTarget.dataset.name;
    var fValue = "";
    if (event.currentTarget.dataset.type == "Dropdown") {
      var fValue = event.currentTarget.dataset.id;
    } else {
      var fValue = event.target.value;
    }
    var self = this;
    let { categoryCustomFilers } = this.state;
    categoryCustomFilers["customFieldData." + keyType] = fValue;

    let { categoryFilers } = this.state;
    categoryFilers["customFields"] = categoryCustomFilers;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      const address = results[0].formatted_address;
      var self = this;
      this.setState({ address });
      let { categoryFilers } = this.state;
      categoryFilers["location"] = address;
      this.setState({ categoryFilers: categoryFilers });
      getLatLng(results[0])
        .then((latLng) => {
          //   var self = this;
          let { categoryFilers } = this.state;
          categoryFilers["lat"] = latLng.lat;
          categoryFilers["lng"] = latLng.lng;
          this.setState({ categoryFilers: categoryFilers });
          this.getDataByfilters();
        })
        .catch((error) => console.error("Error", error));
    });
  };

  handleCatKeyword = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["keyword"] = event.target.value;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleFilterSorting = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["sortType"] = event.target.value;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleRadiusFilter = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["radius"] = event.target.value;
    self.setState({ categoryFilers: categoryFilers });
  };

  handleCatMinPrice = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["min_price"] = event.target.value;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleCatMaxPrice = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["max_price"] = event.target.value;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleCatNegotiation = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["avaliableNegotiation"] = event.target.checked;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleExchange = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["avaliableExchange"] = event.target.checked;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleLeasing = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["availableLeasing"] = event.target.checked;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleCatSubCategory = (subCategoryId) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["subCategoryId"] = subCategoryId;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleCatSellerType = (sellerType) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["sellerType"] = sellerType;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleCatAdType = (adType) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["adType"] = adType;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleCatrecruiterType = (recruiterType) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["recruiterType"] = recruiterType;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleCatproviderType = (providerType) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["providerType"] = providerType;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  addWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_ADD_TO_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  removeWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_REMOVE_FROM_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  getCategoryCount = (catId) => {
    const { allLiveStreamingFilterProducts } = this.state;
    if (allLiveStreamingFilterProducts && catId) {
      return allLiveStreamingFilterProducts.filter(
        (pro) => pro?.subCategoryId === catId
      ).length;
    } else {
      return 0;
    }
  };

  render() {
    var context = this;
    const {
      showHideListData,
      showHideGridData,
      singleCategoryData,
      categoryslug,
      allCustomFields,
      subCategoryList,
      totalRecords,
      loadMoreSubCat,
      loadAllSubCatBtn,
      allLiveStreamingFilterProducts,
      allUpcomingLiveStreaming,
      openProductFilter,
      productSkeleton,
      nothingD,
      next_page_number,
      limit,
    } = this.state;

    return (
      <Fragment>
        <section className="product-header special_prheadr_new">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-6">
                <div className="breadcrumb-bx">
                  <h2>
                    {totalRecords} Ads in {singleCategoryData.categoryName}
                  </h2>
                  <div className="breadcrumb-bx-inner">
                    <Link to="/" className="btnx btn-primaryx">
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </Link>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to="/livestreaming">live-streaming</Link>
                      </li>
                      <li className="breadcrumb-item">
                        {singleCategoryData?.categoryName}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="new_placetab_icon">
                  <div className="form-group-icon-bx">
                    <select
                      className="form-control-line"
                      onChange={context.handleFilterSorting}
                    >
                      <option value="">Most Recent First</option>
                      <option value="LowToHigh">Price: Low to High</option>
                      <option value="HighToLow">Price: High to Low</option>
                    </select>
                    <span className="form-group-icon">
                      <img src="/images/down-arrow-icon.png" alt="icon" />
                    </span>
                  </div>
                  <div className="showing-result">
                    {/* {showHideListData ? (
                      <Link className="gridList" id="tabOne">
                        <i className="fas fa-list "></i>
                      </Link>
                    ) : (
                      <Link
                        className=""
                        id="tabOne"
                        onClick={() => this.hideComponent("showHideListData")}
                      >
                        <i className="fas fa-list "></i>
                      </Link>
                    )} */}

                    {/* {showHideGridData ? (
                      <Link className="gridList" id="tabOne">
                        <i className="fas fa-th "></i>
                      </Link>
                    ) : (
                      <Link
                        className=""
                        id="tabOne"
                        onClick={() => this.hideComponent("showHideGridData")}
                      >
                        <i className="fas fa-th "></i>
                      </Link>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="repeat-section product-listing-main-bx bg-light-dark forsalepage_listing">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 paddingrigt_none_Cfilter">
                <div
                  className={`product-sideBar-parent new_perenttop_forspecals ${this.state.openProductFilter ? "active" : ""
                    }`}
                >

                  {/* <div class="Jcompny_actin tab_Jcompny">
                    <ul class="nav nav-tabs" role="tablist">
                      <li class="nav-item">
                        <a
                          class="nav-link active"
                          data-toggle="tab"
                          href="#tabs-1"
                          role="tab"
                        >
                          live Streaming
                        </a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          data-toggle="tab"
                          href="#tabs-2"
                          role="tab"
                        >
                          Upcomimg live Streaming
                        </a>
                      </li>
                    </ul>
                  </div> */}

                  <div className="product-listing-sideBar" id="side-bar-fixed">
                    <div className="sidebarleft_newinner">
                      <div id="filter-closer">
                        <img
                          src="/images/cancel.svg"
                          alt="icon"
                          onClick={() =>
                            this.setState({
                              openProductFilter: false,
                            })
                          }
                        />
                      </div>
                      <div className="product-listing-sideBar-colum sideBar-location">
                        <h2>Location</h2>
                        <div className="form-group">
                          <PlacesAutocomplete
                            searchOptions={{
                              componentRestrictions: {
                                country: "UK",
                              },
                            }}
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "location-search-input",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                      : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={suggestion.placeId}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div class="form-group mb-0 sideBar-choose-distance">
                                  <div class="form-group-icon-bx">
                                    <input
                                      type="number"
                                      name="radius"
                                      onChange={context.handleRadiusFilter}
                                      class="form-control-line"
                                      placeholder="Distance"
                                    />

                                    {/*<span class="form-group-icon"><img src="/images/down-arrow-icon.png" alt="icon" /></span>*/}
                                  </div>
                                  <button
                                    class="go-btn"
                                    onClick={context.handleCatNegotiation}
                                  >
                                    GO
                                  </button>
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                      <div className="product-listing-sideBar-colum sideBar-category">
                        <h2>Category</h2>
                        <ul class="toggletab-bx">
                          <li className="innerfilt_topcat_perent">
                            <span>
                              <Link to="/livestreaming">
                                <i className="fas fa-angle-double-left"></i>
                              </Link>
                            </span>{" "}
                            <a href={`/category/${singleCategoryData.slug}`}>
                              {singleCategoryData.categoryName}
                            </a>
                            <ul className="innerfilt_topcat">
                              {subCategoryList &&
                                subCategoryList.map(function (rowsubcat, i) {
                                  return i <= 3 ? (
                                    // <li>
                                    //   <a
                                    //     href={"/sub-category/" + rowsubcat.slug}
                                    //     id="GoFarm_machinery"
                                    //     className="goclass01"
                                    //     name="subCategoryId"
                                    //   >
                                    //     {" "}
                                    //     {rowsubcat.subCategoryName} (
                                    //     {context.getCategoryCount(
                                    //       rowsubcat._id
                                    //     )}
                                    //     )
                                    //   </a>
                                    // </li>
                                    <li>
                                      <a
                                        href
                                        id="GoFarm_machinery"
                                        className="goclass01"
                                        name="subCategoryId"
                                        onClick={context.handleCatSubCategory.bind(
                                          context,
                                          rowsubcat._id
                                        )}
                                      >
                                        {" "}
                                        {rowsubcat.subCategoryName} (
                                        {context.getCategoryCount(
                                          rowsubcat._id
                                        )}
                                        )
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  );

                                  /*return <li><a id="GoFarm_machinery" className="goclass01" name="subCategoryId" onClick={context.handleCatSubCategory.bind(context, rowsubcat._id)}> {rowsubcat.subCategoryName}</a></li>  */
                                })}

                              {loadMoreSubCat &&
                                subCategoryList.map(function (rowsubcat, i) {
                                  return i > 3 ? (
                                    <li>
                                      <a
                                        href={"/sub-category/" + rowsubcat.slug}
                                        id="GoFarm_machinery"
                                        className="goclass01"
                                        name="subCategoryId"
                                      >
                                        {" "}
                                        {rowsubcat.subCategoryName} (
                                        {context.getCategoryCount(
                                          rowsubcat._id
                                        )}
                                        )
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  );
                                })}
                              {subCategoryList.length > 4 &&
                                loadAllSubCatBtn ? (
                                <li>
                                  <a
                                    href
                                    name="load_all"
                                    onClick={context.loadAllSubCategories}
                                  >
                                    <strong>Load All</strong>
                                  </a>
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div class="product-listing-sideBar-colum sideBar-category">
                        <h2>Price</h2>

                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <input
                                type="number"
                                name="min_price"
                                onChange={this.handleCatMinPrice}
                                class="form-control-line"
                                placeholder="Min"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <input
                                type="number"
                                name="max_price"
                                onChange={this.handleCatMaxPrice}
                                class="form-control-line"
                                placeholder="Max"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {allCustomFields.map(function (row) {
                        return (
                          <React.Fragment>
                            {row.fieldType == "Dropdown" ? (
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  {row.fieldName}
                                </AccordionSummary>
                                <AccordionDetails>
                                  <ul
                                    class="toggletab-bx nav nav-tabs toggle-bx"
                                    role="tablist"
                                  >
                                    {row.fieldValues.map(function (rowfield) {
                                      return (
                                        <li
                                          onClick={context.getFltersdataFetch}
                                          data-id={rowfield._id}
                                          data-name={row.slug}
                                          data-type={row.fieldType}
                                        >
                                          <a href>{rowfield.name}</a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </AccordionDetails>
                              </Accordion>
                            ) : (
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  {row.fieldName}
                                </AccordionSummary>
                                <AccordionDetails>
                                  <input
                                    type="text"
                                    onChange={context.getFltersdataFetch}
                                    data-id={row._id}
                                    data-name={row.slug}
                                    data-type={row.fieldType}
                                    class="form-control-line"
                                    placeholder={row.fieldName}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </React.Fragment>
                        );
                      })}

                      {/* <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          Ad Type
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul
                            class="toggletab-bx nav nav-tabs toggle-bx"
                            role="tablist"
                          >
                            <li>
                              <a
                                href
                                name="adType"
                                onClick={context.handleCatAdType.bind(
                                  context,
                                  "Featured"
                                )}
                              >
                                Featured
                              </a>
                            </li>
                            <li>
                              <a
                                href
                                name="adType"
                                onClick={context.handleCatAdType.bind(
                                  context,
                                  "Urgent"
                                )}
                              >
                                Urgent
                              </a>
                            </li>
                            <li>
                              <a
                                href
                                name="adType"
                                onClick={context.handleCatAdType.bind(
                                  context,
                                  "Spotlight"
                                )}
                              >
                                Spotlight
                              </a>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion> */}

                      {/*
                        {
                          (context.state.categoryslug == "agriculture") || (context.state.categoryslug == "for-sale") ? (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >Seller Type 
                              </AccordionSummary>
                              <AccordionDetails>
                                <ul class="toggletab-bx nav nav-tabs toggle-bx" role="tablist">
                                  <li><a href="#" name="sellerType" onClick={context.handleCatSellerType.bind(context, 'Company')}><strong>Company</strong></a></li>
                                  <li><a href="#" name="sellerType" onClick={context.handleCatSellerType.bind(context, 'Private')}><strong>Private</strong></a></li>                                                 
                                </ul>
                              </AccordionDetails>
                            </Accordion>   

                          ) : (<></>)
                        }

                        {
                          (context.state.categoryslug == "property") ? (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >Seller Type 
                              </AccordionSummary>
                              <AccordionDetails>
                                <ul class="toggletab-bx nav nav-tabs toggle-bx" role="tablist">
                                    <li><a href="#" name="sellerType" onClick={context.handleCatSellerType.bind(context, 'Company')}><strong>Company</strong></a></li>
                                    <li><a href="#" name="sellerType" onClick={context.handleCatSellerType.bind(context, 'Private')}><strong>Private</strong></a></li>
                                    <li><a href="#" name="sellerType" onClick={context.handleCatSellerType.bind(context, 'Agent')}><strong>Agent</strong></a></li>
                                                                                  
                                </ul>
                              </AccordionDetails>
                            </Accordion> 
                          ) : (<></>)
                        }

                        {
                          (context.state.categoryslug == "jobs") ? (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >Seller Type 
                              </AccordionSummary>
                              <AccordionDetails>
                                <ul class="toggletab-bx nav nav-tabs toggle-bx" role="tablist">
                                  <li><a href="#" name="recruiterType" onClick={context.handleCatrecruiterType.bind(context, 'Company')}><strong>Company</strong></a></li>
                                  <li><a href="#" name="recruiterType" onClick={context.handleCatrecruiterType.bind(context, 'Private')}><strong>Private</strong></a></li>                                          
                                </ul>
                              </AccordionDetails>
                            </Accordion>
                          ) : (<></>)
                        }

                        {
                          (context.state.categoryslug == "services") ? (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >Seller Type 
                              </AccordionSummary>
                              <AccordionDetails>
                                <ul class="toggletab-bx nav nav-tabs toggle-bx" role="tablist">
                                  <li><a href="#" name="providerType" onClick={context.handleCatproviderType.bind(context, 'Company')}><strong>Company</strong></a></li>
                                  <li><a href="#" name="providerType" onClick={context.handleCatproviderType.bind(context, 'Private')}><strong>Private</strong></a></li>                                         
                                </ul>
                              </AccordionDetails>
                            </Accordion>
                          ) : (<></>)
                      */}
                    </div>

                    {categoryslug == "agriculture" && (
                      <div class="product-listing-sideBar-colum other-option otheropt_newcls">
                        <h2>Other Options</h2>
                        <div class="otheroption_cheperent ">
                          <div class="check-box">
                            <input
                              type="checkbox"
                              value="Yes"
                              name="avaliableNegotiation"
                              id="results-with-pic"
                              onClick={context.handleCatNegotiation}
                            />
                            <label for="results-with-pic">
                              Avaliable for Negotiation
                            </label>
                          </div>
                          <div class="check-box">
                            <input
                              type="checkbox"
                              value="Yes"
                              name="avaliableExchange"
                              id="avaliable-exchange"
                              onClick={context.handleExchange}
                            />
                            <label for="avaliable-exchange">
                              Avaliable for exchange
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    {categoryslug == "for-sale" && (
                      <div class="product-listing-sideBar-colum other-option otheropt_newcls">
                        <h2>Other Options</h2>
                        <div class="otheroption_cheperent ">
                          <div class="check-box">
                            <input
                              type="checkbox"
                              value="Yes"
                              name="avaliableNegotiation"
                              id="results-with-pic"
                              onClick={context.handleCatNegotiation}
                            />
                            <label for="results-with-pic">
                              Avaliable for Negotiation
                            </label>
                          </div>
                          <div class="check-box">
                            <input
                              type="checkbox"
                              value="Yes"
                              name="avaliableExchange"
                              id="avaliable-exchange"
                              onClick={context.handleExchange}
                            />
                            <label for="avaliable-exchange">
                              Avaliable for exchange
                            </label>
                          </div>
                          {/* <div class="check-box">
                            <input
                              type="checkbox"
                              value="Yes"
                              name="availableLeasing"
                              id="avaliable-leasing"
                              onClick={context.handleLeasing}
                            />
                            <label for="avaliable-leasing">
                              Avaliable for Leasing
                            </label>
                          </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div class="tab-content">
                  <div class="tab-pane active" id="tabs-1" role="tabpanel">
                    <div
                      id="filter-toggler"
                      className="btnx btn-secondaryx mx-3"
                      onClick={() => this.setState({ openProductFilter: true })}
                    >
                      All Filter
                    </div>
                    <div className="Jcompny_allprod Jcompny_allprod_st">
                      {showHideListData && (
                        <div className="Jtaballp_content atlistingside_new">
                          {allLiveStreamingFilterProducts.length > 0 ? (
                            <>
                              {allLiveStreamingFilterProducts.map(function (
                                row
                              ) {
                                return (
                                  <div className="row">
                                    <div
                                      className="col-lg-12 listside_newperent"
                                      key={"allCatFilterProducts" + row._id}
                                    >
                                      <div className="product-listing-product">
                                        <div className="row">
                                          <div className="col-3">
                                            <div className="product-img-bx">
                                              <Link
                                                to={
                                                  "/livestreamingProduct/" +
                                                  row.livestreamingId
                                                }
                                              >
                                                {row.adType == "Featured" && (
                                                  <div className="specialtags">
                                                    <span className="live-tag">
                                                      <i
                                                        class="fas fa-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                      {/* {row.adType} */}
                                                      Live
                                                    </span>
                                                  </div>
                                                )}
                                                {row.adType == "Urgent" && (
                                                  <div className="specialtags">
                                                    <span className="live-tag">
                                                      <i
                                                        class="fas fa-circle"
                                                        aria-hidden="true"
                                                      ></i>
                                                      {/* {row.adType} */}
                                                      Live
                                                    </span>
                                                  </div>
                                                )}
                                                {row.adType == "Spotlight" && (
                                                  <div className="specialtags">
                                                    <span className="live-tag">
                                                      {/* {row.adType} */}
                                                      Live
                                                    </span>
                                                  </div>
                                                )}

                                                {row.productMainImage ? (
                                                  <img
                                                    src={General.showImage(
                                                      "products/resize-180-180",
                                                      row.productMainImage
                                                    )}
                                                    alt="uploaded image"
                                                  />
                                                ) : (
                                                  <img
                                                    src="/images/no-image.jpg"
                                                    alt="product"
                                                  />
                                                )}
                                                <div className="totalimg_sectab">
                                                  <Link
                                                    to="#"
                                                    className="totlimg_qt"
                                                  >
                                                    2{" "}
                                                    <i className="fas fa-camera"></i>
                                                  </Link>
                                                </div>
                                              </Link>
                                            </div>
                                          </div>
                                          <div className="col-9">
                                            <div className="product-outer-bx productBig-outer-bx">
                                              <div className="product-name-bx content_gobottom_new">
                                                <Moment fromNow>
                                                  {row.time}
                                                </Moment>
                                                <Link
                                                  to={
                                                    "/livestreamingProduct/" +
                                                    row.livestreamingId
                                                  }
                                                >
                                                  <h4>{row.productName}</h4>
                                                </Link>
                                                <h5>
                                                  {/* {row.categoryId.categoryName}{" "}
                                              {row.subCategoryId &&
                                                "- " +
                                                  row.subCategoryId
                                                    .subCategoryName}{" "} */}
                                                  {/* {
                                                row.subCategoryId
                                                  .subCategoryName
                                              } */}
                                                  {row?.location}
                                                </h5>
                                                <h6>
                                                  <span>
                                                    {General.showPriceDecimal(
                                                      row.productPrice
                                                    )}
                                                  </span>
                                                </h6>
                                                {row.productDescription && (
                                                  <p>{`${row.productDescription.substring(
                                                    0,
                                                    200
                                                  )}...`}</p>
                                                )}
                                                {row.isWishlist === 1 ? (
                                                  <button
                                                    className="add-to-wishlist"
                                                    onClick={() =>
                                                      context.removeWishlistHandler(
                                                        row.productId
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-heart"></i>
                                                  </button>
                                                ) : (
                                                  <button
                                                    className="add-to-wishlist"
                                                    onClick={() =>
                                                      context.addWishlistHandler(
                                                        row.productId
                                                      )
                                                    }
                                                  >
                                                    <i className="far fa-heart"></i>
                                                  </button>
                                                )}
                                                {row.condition && (
                                                  <div className="usednew_button">
                                                    <a
                                                      href=""
                                                      className="btnnew_used"
                                                    >
                                                      {row.condition}
                                                    </a>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {nothingD ? (
                                <div className="alert alert-danger col-lg-12">
                                  <strong>Info!</strong> Nothing here.
                                </div>
                              ) : (
                                <>
                                  {productSkeleton.map(function (rowsskeleton) {
                                    return (
                                      <ContentLoader
                                        viewBox="0 0 1380 300"
                                        height={340}
                                        width={1380}
                                      >
                                        <rect
                                          x="20"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="360"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="1000"
                                          height="300"
                                        />
                                      </ContentLoader>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                      {showHideGridData && (
                        <div className="Jtaballp_content02 auctionplist_main ">
                          <div className="col-sm-12 actionpage_list">
                            <div
                              className={nothingD ? "row" : "row low-margin"}
                            >
                              {allLiveStreamingFilterProducts.length > 0 ? (
                                <>
                                  {allLiveStreamingFilterProducts.map(function (
                                    row
                                  ) {
                                    return (
                                      <div
                                        className="col-sm-3"
                                        key={
                                          "allLiveStreamingFilterProducts" +
                                          row.productId
                                        }
                                      >
                                        <div className="product-outer-bx">
                                          <Link
                                            to={
                                              "/livestreamingProduct/" +
                                              row.livestreamingId
                                            }
                                          >
                                            <div className="product-img-bx">
                                              {row.productMainImage ? (
                                                <img
                                                  src={General.showImage(
                                                    "products/resize-180-180",
                                                    row.productMainImage
                                                  )}
                                                  alt="uploaded image"
                                                />
                                              ) : (
                                                <img
                                                  src="/images/no-image.jpg"
                                                  alt="product"
                                                />
                                              )}
                                              {row.adType == "Featured" && (
                                                <div className="specialtags">
                                                  <span className="black_special">
                                                    {row.adType}
                                                  </span>
                                                </div>
                                              )}
                                              {row.adType == "Urgent" && (
                                                <div className="specialtags">
                                                  <span className="red_special">
                                                    {row.adType}
                                                  </span>
                                                </div>
                                              )}
                                              {row.adType == "Spotlight" && (
                                                <div className="specialtags">
                                                  <span className="green_special">
                                                    {row.adType}
                                                  </span>
                                                </div>
                                              )}
                                              <div className="totalimg_sectab">
                                                {/*<Link to={"/product/"+row._id} className="totlimg_qt">2 <i className="fas fa-camera"></i></Link>*/}
                                              </div>
                                            </div>
                                            <div className="product-name-bx">
                                              <h5>
                                                {
                                                  <Moment fromNow>
                                                    {row.time}
                                                  </Moment>
                                                }
                                              </h5>
                                              <h4>{row.productName}</h4>
                                              <h3>
                                                {/* {row.categoryId.categoryName} <br />{" "}
                                            {row.subCategoryId.subCategoryName} */}
                                                {row?.location}
                                              </h3>
                                              <h6>
                                                <Link
                                                  to={
                                                    "/livestreamingProduct/" +
                                                    row.livestreamingId
                                                  }
                                                  className="btnnew_used"
                                                >
                                                  View
                                                </Link>{" "}
                                                <span>
                                                  {General.showPriceDecimal(
                                                    row.productPrice
                                                  )}
                                                </span>
                                              </h6>
                                            </div>
                                          </Link>
                                          {row.isWishlist === 1 ? (
                                            <button
                                              className="add-to-wishlist"
                                              onClick={() =>
                                                context.removeWishlistHandler(
                                                  row.productId
                                                )
                                              }
                                            >
                                              <i className="fas fa-heart"></i>
                                            </button>
                                          ) : (
                                            <button
                                              className="add-to-wishlist"
                                              onClick={() =>
                                                context.addWishlistHandler(
                                                  row.productId
                                                )
                                              }
                                            >
                                              <i className="far fa-heart"></i>
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  {nothingD ? (
                                    <div className="alert alert-danger col-lg-12">
                                      <strong>Info!</strong> Nothing here.
                                    </div>
                                  ) : (
                                    <>
                                      {productSkeleton.map(function (
                                        rowsskeleton
                                      ) {
                                        return (
                                          <ContentLoader
                                            viewBox="0 0 1380 300"
                                            height={340}
                                            width={1380}
                                            key={rowsskeleton.id}
                                          >
                                            <rect
                                              x="20"
                                              y="{rowsskeleton.id}"
                                              rx="10"
                                              ry="10"
                                              width="300"
                                              height="300"
                                            />
                                            <rect
                                              x="360"
                                              y="{rowsskeleton.id}"
                                              rx="10"
                                              ry="10"
                                              width="300"
                                              height="300"
                                            />
                                            <rect
                                              x="700"
                                              y="{rowsskeleton.id}"
                                              rx="10"
                                              ry="10"
                                              width="300"
                                              height="300"
                                            />
                                            <rect
                                              x="1040"
                                              y="{rowsskeleton.id}"
                                              rx="10"
                                              ry="10"
                                              width="300"
                                              height="300"
                                            />
                                          </ContentLoader>
                                        );
                                      })}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="loading-bx">
                      {totalRecords > 0 && (
                        <>
                          <p>
                            <span>
                              You have viewed {limit} of {totalRecords} Ads
                            </span>
                          </p>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <span className="sr-only">70% Complete</span>
                            </div>
                          </div>
                        </>
                      )}

                      {next_page_number > 0 && (
                        <button
                          onClick={() =>
                            context.getDatabyPageno(next_page_number)
                          }
                        >
                          LOAD MORE
                        </button>
                      )}
                    </div>
                  </div>
                  <div class="tab-pane" id="tabs-2" role="tabpanel">
                    <div
                      id="filter-toggler"
                      className="btnx btn-secondaryx mx-3"
                      onClick={() => this.setState({ openProductFilter: true })}
                    >
                      All Filter
                    </div>
                    <div className="Jcompny_allprod Jcompny_allprod_st">
                      {showHideListData && (
                        <div className="Jtaballp_content atlistingside_new">
                          {allLiveStreamingFilterProducts.length > 0 ? (
                            <>
                              {allLiveStreamingFilterProducts.map(function (
                                row
                              ) {
                                return (
                                  <div className="row">
                                    <div
                                      className="col-lg-12 listside_newperent"
                                      key={"allCatFilterProducts" + row._id}
                                    >
                                      <div className="product-listing-product">
                                        <div className="row">
                                          <div className="col-3">
                                            <div className="product-img-bx">
                                              <Link
                                                to={
                                                  "/livestreamingProduct/" +
                                                  row.livestreamingId
                                                }
                                              >
                                                {row.productMainImage ? (
                                                  <img
                                                    src={General.showImage(
                                                      "products/resize-180-180",
                                                      row.productMainImage
                                                    )}
                                                    alt="uploaded image"
                                                  />
                                                ) : (
                                                  <img
                                                    src="/images/no-image.jpg"
                                                    alt="product"
                                                  />
                                                )}
                                                <div className="totalimg_sectab">
                                                  <Link
                                                    to="#"
                                                    className="totlimg_qt"
                                                  >
                                                    2{" "}
                                                    <i className="fas fa-camera"></i>
                                                  </Link>
                                                </div>
                                              </Link>
                                            </div>
                                          </div>
                                          <div className="col-9">
                                            <div className="product-outer-bx productBig-outer-bx">
                                              <div className="product-name-bx content_gobottom_new">
                                                <Moment fromNow>
                                                  {row.time}
                                                </Moment>
                                                <Link
                                                  to={
                                                    "/livestreamingProduct/" +
                                                    row.livestreamingId
                                                  }
                                                >
                                                  <h4>{row.productName}</h4>
                                                </Link>
                                                <h5>
                                                  {/* {row.categoryId.categoryName}{" "}
                                              {row.subCategoryId &&
                                                "- " +
                                                  row.subCategoryId
                                                    .subCategoryName}{" "} */}
                                                  {/* {
                                                row.subCategoryId
                                                  .subCategoryName
                                              } */}
                                                  {row?.location}
                                                </h5>
                                                <h6>
                                                  <span>
                                                    {General.showPriceDecimal(
                                                      row.productPrice
                                                    )}
                                                  </span>
                                                </h6>
                                                {row.productDescription && (
                                                  <p>{`${row.productDescription.substring(
                                                    0,
                                                    200
                                                  )}...`}</p>
                                                )}
                                                {row.isWishlist === 1 ? (
                                                  <button
                                                    className="add-to-wishlist"
                                                    onClick={() =>
                                                      context.removeWishlistHandler(
                                                        row.productId
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-heart"></i>
                                                  </button>
                                                ) : (
                                                  <button
                                                    className="add-to-wishlist"
                                                    onClick={() =>
                                                      context.addWishlistHandler(
                                                        row.productId
                                                      )
                                                    }
                                                  >
                                                    <i className="far fa-heart"></i>
                                                  </button>
                                                )}
                                                {row.condition && (
                                                  <div className="usednew_button">
                                                    <a
                                                      href=""
                                                      className="btnnew_used"
                                                    >
                                                      {row.condition}
                                                    </a>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {nothingD ? (
                                <div className="alert alert-danger col-lg-12">
                                  <strong>Info!</strong> Nothing here.
                                </div>
                              ) : (
                                <>
                                  {productSkeleton.map(function (rowsskeleton) {
                                    return (
                                      <ContentLoader
                                        viewBox="0 0 1380 300"
                                        height={340}
                                        width={1380}
                                      >
                                        <rect
                                          x="20"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="360"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="1000"
                                          height="300"
                                        />
                                      </ContentLoader>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      )}
                      {showHideGridData && (
                        <div className="Jtaballp_content02 auctionplist_main ">
                          <div className="col-sm-12 actionpage_list">
                            <div
                              className={nothingD ? "row" : "row low-margin"}
                            >
                              {allUpcomingLiveStreaming.length > 0 ? (
                                <>
                                  {allUpcomingLiveStreaming.map(function (row) {
                                    return (
                                      <div
                                        className="col-sm-3"
                                        key={
                                          "allUpcomingLiveStreamingFilterProducts" +
                                          row.productId
                                        }
                                      >
                                        <div className="product-outer-bx">
                                          <Link
                                            to={
                                              "/livestreamingProduct/" +
                                              row.livestreamingId
                                            }
                                          >
                                            <div className="product-img-bx">
                                              {row.productMainImage ? (
                                                <img
                                                  src={General.showImage(
                                                    "products/resize-180-180",
                                                    row.productMainImage
                                                  )}
                                                  alt="uploaded image"
                                                />
                                              ) : (
                                                <img
                                                  src="/images/no-image.jpg"
                                                  alt="product"
                                                />
                                              )}
                                              {row.adType == "Featured" && (
                                                <div className="specialtags">
                                                  <span className="black_special">
                                                    {row.adType}
                                                  </span>
                                                </div>
                                              )}
                                              {row.adType == "Urgent" && (
                                                <div className="specialtags">
                                                  <span className="red_special">
                                                    {row.adType}
                                                  </span>
                                                </div>
                                              )}
                                              {row.adType == "Spotlight" && (
                                                <div className="specialtags">
                                                  <span className="green_special">
                                                    {row.adType}
                                                  </span>
                                                </div>
                                              )}
                                              <div className="totalimg_sectab">
                                                {/*<Link to={"/product/"+row._id} className="totlimg_qt">2 <i className="fas fa-camera"></i></Link>*/}
                                              </div>
                                            </div>
                                            <div className="product-name-bx">
                                              <h5>
                                                {
                                                  <Moment fromNow>
                                                    {row.time}
                                                  </Moment>
                                                }
                                              </h5>
                                              <h4>{row.productName}</h4>
                                              <h3>
                                                {/* {row.categoryId.categoryName} <br />{" "}
                                            {row.subCategoryId.subCategoryName} */}
                                                {row?.location}
                                              </h3>
                                              <h6>
                                                <Link
                                                  to={
                                                    "/livestreamingProduct/" +
                                                    row.livestreamingId
                                                  }
                                                  className="btnnew_used"
                                                >
                                                  View
                                                </Link>{" "}
                                                <span>
                                                  {General.showPriceDecimal(
                                                    row.productPrice
                                                  )}
                                                </span>
                                              </h6>
                                            </div>
                                          </Link>
                                          {row.isWishlist === 1 ? (
                                            <button
                                              className="add-to-wishlist"
                                              onClick={() =>
                                                context.removeWishlistHandler(
                                                  row.productId
                                                )
                                              }
                                            >
                                              <i className="fas fa-heart"></i>
                                            </button>
                                          ) : (
                                            <button
                                              className="add-to-wishlist"
                                              onClick={() =>
                                                context.addWishlistHandler(
                                                  row.productId
                                                )
                                              }
                                            >
                                              <i className="far fa-heart"></i>
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                <>
                                  {nothingD ? (
                                    <div className="alert alert-danger col-lg-12">
                                      <strong>Info!</strong> Nothing here.
                                    </div>
                                  ) : (
                                    <>
                                      {productSkeleton.map(function (
                                        rowsskeleton
                                      ) {
                                        return (
                                          <ContentLoader
                                            viewBox="0 0 1380 300"
                                            height={340}
                                            width={1380}
                                            key={rowsskeleton.id}
                                          >
                                            <rect
                                              x="20"
                                              y="{rowsskeleton.id}"
                                              rx="10"
                                              ry="10"
                                              width="300"
                                              height="300"
                                            />
                                            <rect
                                              x="360"
                                              y="{rowsskeleton.id}"
                                              rx="10"
                                              ry="10"
                                              width="300"
                                              height="300"
                                            />
                                            <rect
                                              x="700"
                                              y="{rowsskeleton.id}"
                                              rx="10"
                                              ry="10"
                                              width="300"
                                              height="300"
                                            />
                                            <rect
                                              x="1040"
                                              y="{rowsskeleton.id}"
                                              rx="10"
                                              ry="10"
                                              width="300"
                                              height="300"
                                            />
                                          </ContentLoader>
                                        );
                                      })}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div className="loading-bx">
                      {totalRecords > 0 && (
                        <>
                          <p>
                            <span>
                              You have viewed {limit} of {totalRecords} Ads
                            </span>
                          </p>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              aria-valuenow="70"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <span className="sr-only">70% Complete</span>
                            </div>
                          </div>
                        </>
                      )}

                      {next_page_number > 0 && (
                        <button
                          onClick={() =>
                            context.getDatabyPageno(next_page_number)
                          }
                        >
                          LOAD MORE
                        </button>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="side-bar-stoper"></div>
          </div>
        </section>
        <section className="bg-light-dark">
          <div></div>
        </section>
      </Fragment>
    );
  }
}

export default LiveStreamingPage;
