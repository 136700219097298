import React from "react";
import { Channel } from "./Channel";

export class ChannelList extends React.Component {
  handleClick = (id) => {
    this.props.onSelectChannel(id);
  };

  render() {
    let list = "";
    if (this.props.channels && this.props.channels.length) {
      const senderNamew = localStorage.getItem("AuthIdentity");
      const base64Url = senderNamew.split(".")[1];
      const base64 = base64Url.replace("-", "+").replace("_", "/");
      const UserId = JSON.parse(window.atob(base64));
      list = this.props.channels.map((c) => (
        <Channel
          key={UserId.response._id !== c.userId ? c.userId : ""}
          id={UserId.response._id !== c.userId ? c.userId : ""}
          name={UserId.response._id !== c.userId ? c.firstName : ""}
          onClick={() => this.handleClick(c.userId)}
          status={c.isOnline ? c.isOnline : ""}
          profilePicture={c.profilePicture ? c.profilePicture : ""}
          lastLogin={c.lastLogin ? c.lastLogin : ""}
        />
      ));
    } else {
      list = (
        <div className="no-content-message">There is no channels to show</div>
      );
    }
    return <div>{list}</div>;
  }
}
