import {
  CLOSE_MESSAGE_BOX,
  OPEN_MESSAGE_BOX,
  MINIMIZE_MESSAGE_BOX,
  MESSAGE_DATA,
  TOGGLE_SELECTED_CHANNEL,
  SET_SELECTED_CHANNEL,
  SET_OUTGOING_CALL,
  SET_INCOMING_CALL,
  SET_QUICKBLOX_SESSION,
  SET_CONTACT_LIST,
  SET_SELECTED_CHANNEL1,
  SET_SELECTED_USER,
  SET_SELECTED_CHANNEL_DATA,
  SET_QUICKBLOX_CONNECTION,
  SET_QUICKBLOX_UNREADMESSAGE,
  SET_SCHEDULE_MEETING
} from "./Constant";
// Messages Actions

export const OpenMessageBox = () => async (dispatch) => {
  dispatch({
    type: OPEN_MESSAGE_BOX,
    payload: true,
  });
};

export const MinimizeMessageBox = () => async (dispatch) => {
  dispatch({
    type: MINIMIZE_MESSAGE_BOX,
    payload: false,
  });
};

export const CloseMessageBox = () => async (dispatch) => {
  dispatch({
    type: CLOSE_MESSAGE_BOX,
    payload: true,
  });
};

export const toggleSelectedChannel = (value) => async (dispatch) => {
  dispatch({
    type: TOGGLE_SELECTED_CHANNEL,
    payload: value,
  });
};

export const MessageData = (data) => async (dispatch) => {
  dispatch({
    type: MESSAGE_DATA,
    payload: data,
  });
};

export const SetSelectedChannel = (id) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_CHANNEL,
    payload: id,
  });
};

export const SetContactList = (contacts) => async (dispatch) => {
  dispatch({
    type: SET_CONTACT_LIST,
    payload: contacts,
  });
};

export const SetSelectedUser = (user) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_USER,
    payload: user,
  });
};

export const SetSelectedChannel1 = (channel) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_CHANNEL1,
    payload: channel,
  });
};

export const SetSelectedChannelData = (data) => async (dispatch) => {
  dispatch({
    type: SET_SELECTED_CHANNEL_DATA,
    payload: data,
  });
};

// Video call reducer

export const SetOutgoingCallState = (state) => async (dispatch) => {
  dispatch({
    type: SET_OUTGOING_CALL,
    payload: state,
  });
};

export const SetIncomingCallState = (state) => async (dispatch) => {
  dispatch({
    type: SET_INCOMING_CALL,
    payload: state,
  });
};

export const SetQuickbloxSession = (session) => async (dispatch) => {
  dispatch({
    type: SET_QUICKBLOX_SESSION,
    payload: session,
  });
};

export const SetScheduleMeetingState = (state) => async (dispatch) => {
  dispatch({
    type: SET_SCHEDULE_MEETING,
    payload: state,
  });
};

export const SetQuickbloxUnreadMessage = (value) => async (dispatch) => {
  dispatch({
    type: SET_QUICKBLOX_UNREADMESSAGE,
    payload: value,
  });
};

export const SetQuickblox = (value) => async (dispatch) => {
  dispatch({
    type: SET_QUICKBLOX_CONNECTION,
    payload: value,
  });
};
