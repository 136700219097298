import React, { Component } from "react";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import Storage from "Helpers/Storage";

export class Notification extends Component {

  constructor(props) {

      super(props);

      this.state = {
          userId: localStorage.getItem("UserData")
          ? JSON.parse(localStorage.getItem("UserData"))["_id"]
          : "",
          messaging: false,
          reviews: false,
          agriculture: false,
          sale: false,
          jobs: false,
          services: false,
          motors: false,
          property: false,
          categories: [],
          notificationDetail : {},
          isLoading: false,
      }

  }

  componentDidMount = () => {
    this.getMainCategories();
  };

  getMainCategories = () => {
    MyProfile.postApiByParams(
      "API_GET_MAIN_CATEGORIES",
      {},
      async (response) => {
        console.log("resp noti",response)
        if (response) {
            this.setState({"categories":response});
            this.getUserByEmail();
        }
      },
      true
    );
  };

  getUserByEmail = () => {
    MyProfile.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        console.log(response);
        if (response) {
          Storage.setLocalData("UserData", JSON.stringify(response));
          this.setState({
            userId: response._id,
            messaging: (response?.messaging) ? response.messaging : false,
            reviews: (response?.reviews) ? response.reviews : false,
            notificationDetail: (response?.notificationDetail) ? response.notificationDetail : {}
          });
        }
      },
      true
    );
  };

  handleCategoryNotification = async (event) => {
    const {messaging,reviews,notificationDetail} = this.state;
    //let tempObj = {'cat_id':event.target.name, 'status':event.target.checked};
    //notificationDetail.push(tempObj);
    notificationDetail[event.target.name] = event.target.checked;
    this.setState(notificationDetail,
    () => {
        let notiDetail = this.state.notificationDetail;
        console.log(notiDetail);
        this.setState({ isLoading: true });
        MyProfile.postApi(
            "API_UPDATE_NOTIFICATION",
            {
            messaging,
            reviews,
            notificationDetail:notiDetail
            },
            async (response) => {
            this.setState({ isLoading: false });
            },
            true
        );
    });
  };
  

  handleMyProfileNotification = async (event) => {
    this.setState(
      {
        [event.target.name]: event.target.checked,
      },
      () => {
        let {
          messaging,
          reviews,
          notificationDetail
        } = this.state;
        this.setState({ isLoading: true });
        MyProfile.postApi(
          "API_UPDATE_NOTIFICATION",
          {
            messaging,
            reviews,
            notificationDetail
          },
          async (response) => {
            this.setState({ isLoading: false });
          },
          true
        );
      }
    );
  };


  render() {

    return (
      <>
       <div
        className="tab-pane allcom_rodtab active"
        id="subtab022"
        role="tabpanel"
        >
        <div className="subtab_prodlist_NEW_forpadd">
            <div className="container">
            <div className="row">
                <div
                className="col-lg-3 subtab_firstmain noneat_mobile paddingrigt_none_Cfilter d-none d-md-block"
                id="subtab_secondfixed"
                >
                <div className="subcateleft_imgsec">
                    <img
                    src="/images/account-settings-limg.png"
                    alt="Image"
                    />
                </div>
                </div>
                <div className="col-lg-9 subtab_secondmain">
                <div className="formnotification_inner withbg_white border-radius">
                    <div className="noti_enable repeatSec_innerr">
                    <h3 className="titleof_tbasec">
                        <strong>Enable/Disable</strong>{" "}
                        notifications
                    </h3>
                    <div className="box_perentrepet">
                        <div className="buttonon_off">
                        <div className="btnoff_onlable">
                            Messaging
                        </div>
                        <div className="btnoff_onlableRight">
                            <label
                            className="switch"
                            htmlFor="messageLabel"
                            >
                            <input
                                id="messageLabel"
                                name="messaging"
                                type="checkbox"
                                value={this.state.messaging}
                                checked={this.state.messaging}
                                onChange={
                                this.handleMyProfileNotification
                                }
                            />
                            <div className="slider round">
                                <span
                                className={
                                    this.state.messaging === true
                                    ? "on"
                                    : "off"
                                }
                                ></span>
                            </div>
                            </label>
                        </div>
                        </div>
                        <div className="buttonon_off">
                        <div className="btnoff_onlable">
                            Reviews
                        </div>
                        <div className="btnoff_onlableRight">
                            <label className="switch">
                            <input
                                onChange={
                                this.handleMyProfileNotification
                                }
                                checked={this.state.reviews}
                                value={this.state.reviews}
                                name="reviews"
                                type="checkbox"
                                id="togBtn02"
                            />
                            <div className="slider round">
                                <span
                                className={
                                    this.state.reviews === true
                                    ? "on"
                                    : "off"
                                }
                                ></span>
                            </div>
                            </label>
                        </div>
                        </div>
                    </div>
                    </div>
                    {(this.state.categories && this.state.categories.length > 0) && 
                    <div className="noti_enable repeatSec_innerr border_bott_none">
                        {/* <h3 className="titleof_tbasec">
                            Notify me by e-mail when a ad gets posted
                            thats is relevant to my choice
                        </h3> */}
                        <div className="box_perentrepet">
                            {this.state.categories.map((cat) => (
                            <div className="buttonon_off" key={cat._id}>
                                <div className="btnoff_onlable">
                                    {cat.categoryName}
                                </div>
                                <div className="btnoff_onlableRight">
                                    <label className="switch">
                                    <input
                                        onChange={
                                        this.handleCategoryNotification
                                        }
                                        checked={(this.state.notificationDetail[cat._id] !== undefined) ? this.state.notificationDetail[cat._id] : false}
                                        name={cat._id}
                                        value={(this.state.notificationDetail[cat._id] !== undefined) ? this.state.notificationDetail[cat._id] : false}
                                        type="checkbox"
                                        id="togBtn02"
                                    />
                                    <div className="slider round">
                                        <span
                                        className={
                                            this.state.notificationDetail[cat._id] !== undefined && this.state.notificationDetail[cat._id]
                                            ? "on"
                                            : "off"
                                        }
                                        ></span>
                                    </div>
                                    </label>
                                </div>
                            </div>
                            ))}
                        </div>
                    </div>
                    }
                    
                </div>
                </div>
            </div>
            </div>
        </div>
        </div> 
      </>
    );
  }
}

export default Notification;
