import React, { Component } from "react";
import User from "Models/User";

class Verify extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    User.postApi("APIS_VERIFY_EMAIL", {code:this.props.match.params.slug}, async(response)=>{
      if(response == true){
        User.redirectTo("login", true);
      }
    }, false);
  }

  render() {
    return (
      <section className="signin_Newpage bg-light-dark repeat-section-login-New">
        <div className="container">
          <div className="row">
            <div className="col-12">
              Please wait while we are checking with your verification.....
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Verify;
