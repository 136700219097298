import React, { Component } from "react";
import Faqs from "Models/Faqs";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class SafetyTipsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      LearnsData: localStorage.getItem("LearnsData")
        ? JSON.parse(localStorage.getItem("LearnsData"))
        : [],
      allLearns: localStorage.getItem("allLearns")
        ? JSON.parse(localStorage.getItem("allLearns"))
        : [],
      selectedCategoryIndex: 1,
      singleSubCatData: null,
      singleSubCatDataIndex: null,
      selectedCategory: "",
      isLoading: false,
      expandedAccordion: 0,
      isActive: false,
    };
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isActive !== this.state.isActive) {
      if (this.state.isActive) {
        document.documentElement.classList.add("scroll-off");
      } else {
        document.documentElement.classList.remove("scroll-off");
      }
    }
  }

  componentDidMount = () => {
    var self = this;
    this.setState({ isLoading: true });
    Faqs.getApi(
      "APIS_ALL_LEARN_LIST",
      async (response) => {
        localStorage.setItem("LearnsData", JSON.stringify(response));
        self.setState({ LearnsData: response });
        console.log("learnsData", response);
        this.state.LearnsData.map((item) => {
          if (item.category_name == "Safety tips") {
            this.setState({ selectedCategory: item });
            this.setState({ isLoading: false });
          }
        })

        if (
          response &&
          response.length > 0 &&
          response[this.state.selectedCategoryIndex].subCatData.length > 0
        ) {
          this.setSingleSubCatData(response);
        }
      },
      false
    );

    Faqs.getApi(
      "APIS_ALL_LEARNS",
      async (response) => {
        localStorage.setItem("allLearns", JSON.stringify(response));
        self.setState({ allLearns: response });
      },
      false
    );
    const overlayElement = document.querySelector('.nodataOverlay');
    if (overlayElement) {
      overlayElement.addEventListener('click', this.handleOverlayClick);
    }
  };

  handleOverlayClick() {
    document.body.classList.remove('filter-open');
    this.setState({ isActive: false });
  }

  componentWillUnmount() {
    const overlayElement = document.querySelector('.nodataOverlay');
    if (overlayElement) {
      overlayElement.removeEventListener('click', this.handleOverlayClick);
    }
  }

  setSingleSubCatData = (response) => {
    this.setState({
      singleSubCatData:
        response[this.state.selectedCategoryIndex].subCatData[0],
    });
  };
  tabmanage = (learnSubCat, id) => {
    const elemToRemove = document.getElementsByClassName('tabContent');

    if (elemToRemove.length > 0) {
      const elemeArray = Array.from(elemToRemove);
      elemeArray.forEach((ent) => {
        ent.classList.remove('active');
      });
    }

    this.setState({
      singleSubCatData:
        learnSubCat,
    });
    let ele = document.getElementById('learnData_' + id);
    ele.classList.add("active");
    let eles = document.getElementById('learnsData_' + id);
    eles.classList.add("active");
  }

  handleAccordionChange = (panel) => (event, isExpanded) => {
    this.setState({
      expandedAccordion: isExpanded ? panel : null,
    });
  };

  toggleActiveClass = () => {
    this.setState({ isActive: !this.state.isActive });
    document.body.classList.add('filter-open');
  };

  closeActiveClass = () => {
    this.setState({ isActive: false });
    document.body.classList.remove('filter-open');
  };

  render() {
    const { LearnsData, singleSubCatData, selectedCategoryIndex, selectedCategory, isLoading, expandedAccordion, isActive } = this.state;
    return (
      <React.Fragment>
        {isLoading && (
          <div className="loader-wrapper">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        <section className="learntop_sectwo bg-light-dark onlyforlearn_sectionpa helpComponent">
          <div className="learnsel_faqtabperent">
            <div className="tab-content mainperent_tabcontent">
              {selectedCategory && <div
                className={`tab-pane active`}
                id={`learnCat_${selectedCategory._id}`}
                role="tabpanel"
              >
                <section className="learnmultipal_secns bg-light-dark">
                  <h2 className="sectionmainhead text-center">
                    <span>{selectedCategory.category_name}</span>
                  </h2>
                  <div className="container">
                    <div id="filter-toggler01" className="mobilebtn_togg" onClick={this.toggleActiveClass}>
                    <span>Menu</span>
                    <span class="dropdown_arrow">
                          <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white"></path></svg>
                    </span>  
                    </div>
                    <div className="row specalfor_multirow">
                      <div className="col-sm-3">
                        <div className={`product-sideBar-parent PsidebarP_01 ${isActive ? "active" : ""}`}>
                          <div
                            className="product-listing-sideBar"
                            id="side-bar-fixed"
                          >
                            <div id="filter-closer" onClick={this.closeActiveClass}>
                              <img src="/images/cancel.svg" alt="icon" />
                            </div>
                            {selectedCategory.subCatData &&
                              selectedCategory.subCatData.map((learnSubCat, index) => (
                                <div className="product-listing-sideBar-colum sideBar-category" key={"row.subCatData" + learnSubCat._id}>
                                  <Accordion expanded={expandedAccordion === index}
                                    onChange={this.handleAccordionChange(index)}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      className={`toggle-category`}
                                      aria-controls="panel1a-content"
                                    >{learnSubCat.sub_category_name}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <ul
                                        className="toggletab-bx nav nav-tabs"
                                        role="tablist"
                                      >
                                        {learnSubCat.learnData &&
                                          learnSubCat.learnData.map(
                                            (learn, subindex) => (
                                              <li className={`nav-item`} onClick={this.closeActiveClass}>
                                                <a className={index == 0 && subindex == 0 ? 'tabContent nav-link active' : 'tabContent nav-link'}
                                                  //data-toggle="tab"
                                                  id={`learnsData_${learn._id}`}
                                                  href={void (0)}
                                                  onClick={() =>
                                                    this.tabmanage(learnSubCat, learn._id)

                                                  }
                                                >
                                                  {console.log("learn", learn)}{learn.title}
                                                </a>
                                              </li>
                                            )
                                          )}
                                      </ul>
                                    </AccordionDetails>
                                  </Accordion>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-9">
                        <div className="tab-content">
                          {console.log("detail", selectedCategory) ||
                            selectedCategory.subCatData &&
                            selectedCategory.subCatData.map(
                              (subCatDataEach, indexsubcat) => (
                                subCatDataEach && subCatDataEach.learnData && subCatDataEach.learnData.map((learn, indexlearndata) => (
                                  <div
                                    key={indexlearndata}
                                    id={`learnData_${learn._id}`}
                                    className={indexlearndata == 0 && indexsubcat == 0 ? 'tabContent tab-pane active' : 'tabContent tab-pane'}
                                  >
                                    {console.log("check index", learn, indexlearndata)}
                                    <div className={`content_tabmulti_First`}>
                                      <div className="bxinner_multi">
                                        <div className="heading_multi">
                                          <h2 className="multilable">
                                            {learn.title}{" "}

                                          </h2>
                                        </div>
                                        <div className="multi_regularcont">
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: learn.content,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              )
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              }
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default SafetyTipsComponent;
