import React, { Component } from 'react';

class SharePopover extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    return (
      <div className="share-container">
          <div className="share-bx">
          <p className='color'>Share:</p>
          <ul>
            <li>
              <a href="#" className="facebook-btn">
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="#" className="twitter-btn">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="#" className="insta-btn">
                <i className="fab fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="#" className="email-btn">
                <i className="far fa-envelope"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default SharePopover;
