import Model from "Models/Model";

class SubCategorySearch extends Model {
  dbSchema() {
    return {
      subCategoryId: "",
      keyword: "",
      min_price: "",
      max_price: "",
      AvailableBuyNow:"",
      AvailablePickUp:"",
      availableLeasing: "",
      sellerType: "",
      recruiterType: "",
      adType: "",
      lat: "",
      lng: "",
      radius: "",
      sortType: "",
      providerType: "",
      location: "",
      weeklydeals:false,
      customFields: {},
    };
  }
}

export default new SubCategorySearch();
