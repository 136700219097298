import React, { Component } from "react";
import General from "Helpers/General";
import Product from "Models/Product";
import SubCategorySearch from "Models/SubCategorySearch";
import Category from "Models/Category";
import SubCategory from "Models/SubCategory";
import { Link } from "react-router-dom";
import Fields from "Models/Fields";
import SlideToggle from "react-slide-toggle";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContentLoader from "react-content-loader";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

class MotorSubCategoryOtherList extends Component {
  constructor(props) {
    super(props);
    const subCategoryId = props.subCategoryId;
    const subCategoryListLS = "subCategoryList_" + subCategoryId;
    const allSubCatfilterProductsLS =
      "allSubCatfilterProducts_" + subCategoryId;
    const singleCategoryDataLS = "singleCategoryData_" + subCategoryId;
    const totalRecordsLS = "totalRecords_" + subCategoryId;
    const categoryNameLS = "categoryName_" + subCategoryId;
    const categoriesLoopData = localStorage.getItem("categoriesLoopData")
      ? JSON.parse(localStorage.getItem("categoriesLoopData"))
      : [];
    const fieldsLoopData = localStorage.getItem("fieldsLoopData")
      ? JSON.parse(localStorage.getItem("fieldsLoopData"))
      : [];

    const subCategoriesListLS = localStorage.getItem(subCategoryListLS)
      ? JSON.parse(localStorage.getItem(subCategoryListLS))
      : [];

    const categoryFieldData = localStorage.getItem("CategoriesFieldData")
      ? JSON.parse(localStorage.getItem("CategoriesFieldData")).find((cat) => {
        return cat._id === props.categoryId;
      })
      : null;
    const fields =
      categoryFieldData && categoryFieldData.fields
        ? categoryFieldData.fields
        : [];

    const subCatFields = fields
      ? fields.filter((fData) => fData.subCategories.includes(subCategoryId))
      : [];
    // console.log({ subCatFields, subCategoryId });
    const subCatLists = localStorage.getItem("PostCategories")
      ? Object.assign(
        {},
        ...JSON.parse(localStorage.getItem("PostCategories")).filter(
          (cat) => cat._id === props.categoryId
        )
      )
      : "";

    const motorSubCategories = localStorage.getItem("MotorCategories")
      ? JSON.parse(localStorage.getItem("MotorCategories"))[0]
      : null;

    const mainCategoryData = localStorage.getItem("PostsubCategories")
      ? JSON.parse(localStorage.getItem("PostsubCategories")).find(
        (subCat) => subCat._id === subCategoryId
      )
      : null;

    // const ChildCatData = subCatLists.subCatData.find(
    //   (subCat) => subCat._id === subCategoryId
    // );

    // console.log(mainCategoryData);

    // console.log(motorSubCategories);
    // console.log(subCatLists);
    // const newKey = "categoriesLoopData."+categoryslug;
    // console.log(newKey);
    // console.log(categoriesLoopData[subCategoryId]);
    // console.log(categoriesLoopData);
    this.state = {
      subCategoryFilers: SubCategorySearch.dbSchema(),
      subCategoryCustomFilers: {},
      subCategoryId: subCategoryId,
      singleSubCategoryData: localStorage.getItem(singleCategoryDataLS)
        ? JSON.parse(localStorage.getItem(singleCategoryDataLS))
        : [],
      // subCategoryList: categoriesLoopData[subCategoryId]
      //   ? categoriesLoopData[subCategoryId]
      //   : [],
      // subCategoryList: subCategoriesListLS,
      mainCategoryData: mainCategoryData,
      subCategoryList: subCatLists ? subCatLists.subCatData : [],
      selectedSubCat: motorSubCategories,
      // allCustomFields: fieldsLoopData[subCategoryId]
      //   ? fieldsLoopData[subCategoryId]
      //   : [],
      allCustomFields: subCatFields,
      allSubCatfilterProducts: localStorage.getItem(allSubCatfilterProductsLS)
        ? JSON.parse(localStorage.getItem(allSubCatfilterProductsLS))
        : [],
      totalRecords: localStorage.getItem(totalRecordsLS)
        ? localStorage.getItem(totalRecordsLS)
        : "0",
      categoryName: localStorage.getItem(categoryNameLS)
        ? localStorage.getItem(categoryNameLS)
        : "",
      productCountBySubCat: [],
      categorySlug: "",
      showHideListData: true,
      showHideGridData: false,
      nothingD: false,
      productSkeleton: [
        { id: 0 },
        { id: 340 },
        { id: 680 },
        { id: 1020 },
        { id: 1360 },
      ],
      //allCustomFields: [],
      loadMoreSubCat: false,
      loadAllSubCatBtn: true,
    };
    this.hideComponent = this.hideComponent.bind(this);
  }

  hideComponent(name) {
    switch (name) {
      case "showHideListData":
        this.setState({ showHideListData: !this.state.showHideListData });
        this.setState({ showHideGridData: false });
        break;
      case "showHideGridData":
        this.setState({ showHideGridData: !this.state.showHideGridData });
        this.setState({ showHideListData: false });
        break;
      default:
        this.setState({ showHideListData: !this.state.showHideListData });
        break;
    }
  }

  componentDidMount = () => {
    var self = this;
    let subCategoryId = this.state.subCategoryId;
    const singleCategoryDataLS =
      "singleCategoryData_" + self.state.subCategoryId;
    const categoryNameLS = "categoryName_" + self.state.subCategoryId;
    SubCategory.postApi(
      "APIS_GET_SUB_CATEGORY_DETAIL_BY_Id",
      { params: { subCategoryId } },
      async (response) => {
        localStorage.setItem(singleCategoryDataLS, JSON.stringify(response));
        localStorage.setItem(
          categoryNameLS,
          response?.categoryId?.categoryName
        );
        self.setState({ singleSubCategoryData: response });
        self.setState({ categoryName: response?.categoryId?.categoryName });
        self.setState({ categorySlug: response?.categoryId?.slug });
        let { subCategoryFilers } = this.state;
        subCategoryFilers["subCategoryId"] =
          this.state.singleSubCategoryData._id;
        self.setState({ subCategoryFilers: subCategoryFilers });
        this.getDataByfilters();
        this.getSubCategoriesByCatId();
      },
      false
    );
    this.getFieldsValuesData();
    this.getStaticSettingData();
  };

  getStaticSettingData() {
    Category.getApiByParams(
      "APIS_GET_SETTING_DATA_BY_TITLE",
      "categories",
      async (response) => {
        // console.log(response);
        localStorage.setItem("categoriesLoopData", JSON.stringify(response));
        //self.setState({PostCategories:response});
        //self.setState({showFirstCat:false});
      },
      false
    );
    Category.getApiByParams(
      "APIS_GET_SETTING_DATA_BY_TITLE",
      "fields",
      async (response) => {
        console.log("fieldsLoopData");
        console.log(response);
        localStorage.setItem("fieldsLoopData", JSON.stringify(response));
        //self.setState({PostCategories:response});
        //self.setState({showFirstCat:false});
      },
      false
    );
  }

  getFieldsValuesData() {
    var self = this;
    let subCategoryId = this.state.subCategoryId;
    Fields.getApiByParams(
      "APIS_GET_FIELDS_VALUES_FILTERS",
      this.state.subCategoryId,
      async (response) => {
        // console.log(response);
        self.setState({ allCustomFields: response });
      },
      false
    );
  }

  getSubCategoriesByCatId() {
    var self = this;
    const subCategoryListLS = "subCategoryList_" + self.state.subCategoryId;
    SubCategory.getApiByParams(
      "APIS_GET_CHILD_SIBLING_CATEGORIES_BY_SUB_CAT_ID",
      this.state.subCategoryId,
      async (response, status) => {
        if (response) {
          localStorage.setItem(subCategoryListLS, JSON.stringify(response));
          // self.setState({ subCategoryList: response });
        }
      },
      false
    );
  }

  getDataByfilters() {
    var self = this;

    // AutoDetect location

    if (localStorage.getItem("location")) {
      let { subCategoryFilers } = this.state;
      subCategoryFilers["lat"] = JSON.parse(localStorage.getItem("location"))[
        "latitude"
      ];
      subCategoryFilers["lng"] = JSON.parse(localStorage.getItem("location"))[
        "longitude"
      ];
      this.setState({ subCategoryFilers: subCategoryFilers });
    }

    if (this.props.lat && this.props.lng) {
      let { subCategoryFilers } = this.state;
      subCategoryFilers["lat"] = this.props.lat;
      subCategoryFilers["lng"] = this.props.lng;
      this.setState({ subCategoryFilers: subCategoryFilers });
    }
    if (this.props.keyword) {
      let { subCategoryFilers } = this.state;
      subCategoryFilers["keyword"] = this.props.keyword;
      this.setState({ subCategoryFilers: subCategoryFilers });
    }

    if (Product.isLogin()) {
      let { subCategoryFilers } = this.state;
      subCategoryFilers["user_id"] = Product.getAuthUserId();
      this.setState({ subCategoryFilers: subCategoryFilers });
    }

    this.setState({ openProductFilter: false });

    const allSubCatfilterProductsLS =
      "allSubCatfilterProducts_" + self.state.subCategoryId;
    const totalRecordsLS = "totalRecords_" + self.state.subCategoryId;
    Product.postApiByParams(
      "APIS_GET_PRODUCTS_BY_SUB_CATEGORY_FILTERS",
      this.state.subCategoryFilers,
      async (response, status) => {
        if (response.status) {
          localStorage.setItem(
            allSubCatfilterProductsLS,
            JSON.stringify(response.data)
          );
          localStorage.setItem(totalRecordsLS, response.totalRecords);
          self.setState({ allSubCatfilterProducts: response.data });
          self.setState({
            productCountBySubCat: response.productCountBySubCat,
          });
          self.setState({ totalRecords: response.totalRecords });
          self.setState({ next_page_number: response.next_page_number });
          self.setState({ limit: response.limit });
          self.setState({ nothingD: true });
        }
      },
      false
    );
  }

  loadAllSubCategories() {
    const { loadMoreSubCat } = this.state;
    const { loadAllSubCatBtn } = this.state;
    this.setState({
      loadMoreSubCat: !loadMoreSubCat,
      loadAllSubCatBtn: !loadAllSubCatBtn,
    });
  }

  getDatabyPageno(next_page_number) {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["page_number"] = next_page_number;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  }

  getFltersdataFetch = (event) => {
    var keyType = event.currentTarget.dataset.name;
    var fValue = "";
    if (event.currentTarget.dataset.type == "Dropdown") {
      var fValue = event.currentTarget.dataset.id;
    } else {
      console.log("input");
      var fValue = event.target.value;
    }
    var self = this;
    let { subCategoryCustomFilers } = this.state;
    subCategoryCustomFilers["customFieldData." + keyType] = fValue;

    let { subCategoryFilers } = this.state;
    subCategoryFilers["customFields"] = subCategoryCustomFilers;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      const address = results[0].formatted_address;

      var self = this;
      self.setState({ address });

      let { subCategoryFilers } = this.state;
      subCategoryFilers["location"] = address;
      self.setState({ subCategoryFilers: subCategoryFilers });

      getLatLng(results[0])
        .then((latLng) => {
          var self = this;
          let { subCategoryFilers } = this.state;
          subCategoryFilers["lat"] = latLng.lat;
          subCategoryFilers["lng"] = latLng.lng;
          self.setState({ subCategoryFilers: subCategoryFilers });

          // this.getDataByfilters();
        })
        .catch((error) => console.error("Error", error));
    });
  };

  handleRadiusFilter = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["radius"] = event.target.value;
    self.setState({ subCategoryFilers: subCategoryFilers });
  };

  // handleSubCatLocation = event => {
  //   var self = this;
  //   let {subCategoryFilers} = this.state;
  //   subCategoryFilers['location'] = event.target.value;
  //   self.setState({ subCategoryFilers: subCategoryFilers});
  //   this.getDataByfilters();
  // };

  handleSubCatKeyword = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["keyword"] = event.target.value;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  };

  handleFilterSorting = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["sortType"] = event.target.value;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  };

  handleSubCatMinPrice = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["min_price"] = event.target.value;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  };

  handleCatNegotiation = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["avaliableNegotiation"] = event.target.checked;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  };

  handleExchange = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["avaliableExchange"] = event.target.checked;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  };

  handleLeasing = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["availableLeasing"] = event.target.checked;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  };

  handleSubCatMaxPrice = (event) => {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["max_price"] = event.target.value;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  };

  handleCatAdType(adType) {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["adType"] = adType;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  }

  handleCatSellerType(sellerType) {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["sellerType"] = sellerType;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  }

  handleCatrecruiterType(recruiterType) {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["recruiterType"] = recruiterType;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  }

  handleCatproviderType(providerType) {
    var self = this;
    let { subCategoryFilers } = this.state;
    subCategoryFilers["providerType"] = providerType;
    self.setState({ subCategoryFilers: subCategoryFilers });
    this.getDataByfilters();
  }

  addWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_ADD_TO_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  removeWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_REMOVE_FROM_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  getCategoryCount = (catId) => {
    // if (this.state.productCountBySubCat && catId) {
    //   return this.state.productCountBySubCat.filter(
    //     (pro) => pro?.subCategoryId === catId
    //   ).length;
    // } else {
    //   return 0;
    // }
    if (localStorage.getItem("thirdLevelCategoriesProductCount")) {
      const catData = JSON.parse(
        localStorage.getItem("thirdLevelCategoriesProductCount")
      ).find((cat) => cat._id === catId);
      if (catData) {
        return catData.productCount;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  selectSubCatHandler = (slug) => {
    const motorSubCategories = localStorage.getItem("MotorCategories")
      ? JSON.parse(localStorage.getItem("MotorCategories")).find(
        (motorCat) => motorCat.slug === slug
      )
      : null;
    this.setState({ selectedSubCat: motorSubCategories });
  };
  render() {
    var context = this;
    const {
      subCategoryId,
      showHideListData,
      showHideGridData,
      allSubCatfilterProducts,
      subCategoryList,
      singleSubCategoryData,
      totalRecords,
      categoryName,
      categorySlug,
      allCustomFields,
      productSkeleton,
      selectedSubCat,
    } = this.state;
    return (
      <React.Fragment>
        <section className="product-header special_prheadr_new">
          <div className="container">
            <div className="row justify-content-between align-items-center">
              <div className="col-6">
                <div className="breadcrumb-bx">
                  <h2>
                    {totalRecords} Ads in{" "}
                    {singleSubCategoryData?.subCategoryName}
                  </h2>
                  <div className="breadcrumb-bx-inner">
                    <Link to="/" className="btnx btn-primaryx">
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </Link>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        <Link to={"/category/" + categorySlug}>
                          {categoryName}
                        </Link>
                      </li>
                      <li className="breadcrumb-item">
                        {singleSubCategoryData?.subCategoryName}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="new_placetab_icon">
                  <div className="form-group-icon-bx">
                    <select
                      className="form-control-line"
                      onChange={context.handleFilterSorting}
                    >
                      <option value="">Most Recent First</option>
                      <option value="LowToHigh">Price: Low to High</option>
                      <option value="HighToLow">Price: High to Low</option>
                    </select>
                    <span className="form-group-icon">
                      <img src="/images/down-arrow-icon.png" alt="icon" />
                    </span>
                  </div>
                  <div className="showing-result">
                    {/* {showHideListData ? (
                      <Link className="gridList" id="tabOne">
                        <i className="fas fa-list "></i>
                      </Link>
                    ) : (
                      <Link
                        className=""
                        id="tabOne"
                        onClick={() => this.hideComponent("showHideListData")}
                      >
                        <i className="fas fa-list "></i>
                      </Link>
                    )} */}

                    {/* {showHideGridData ? (
                      <Link className="gridList" id="tabOne">
                        <i className="fas fa-th "></i>
                      </Link>
                    ) : (
                      <Link
                        className=""
                        id="tabOne"
                        onClick={() => this.hideComponent("showHideGridData")}
                      >
                        <i className="fas fa-th "></i>
                      </Link>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="repeat-section product-listing-main-bx bg-light-dark forsalepage_listing">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 paddingrigt_none_Cfilter">
                <div
                  className={`product-sideBar-parent new_perenttop_forspecals ${this.state.openProductFilter ? "active" : ""
                    }`}
                >
                  <div className="product-listing-sideBar" id="side-bar-fixed">
                    <div className="sidebarleft_newinner">
                      <div id="filter-closer">
                        <img
                          src="/images/cancel.svg"
                          alt="icon"
                          onClick={() =>
                            this.setState({ openProductFilter: false })
                          }
                        />
                      </div>
                      <div className="product-listing-sideBar-colum sideBar-location">
                        {/*<div className="form-group">
                          <input type="text" name="keyword" onChange={this.handleSubCatKeyword}  className="form-control-line" placeholder="Keyword." />
                        </div>*/}
                        <h2>Location</h2>
                        <div className="form-group">
                          {/*<input type="text" name="location" onChange={this.handleSubCatLocation} className="form-control-line" placeholder="Location" />*/}

                          <PlacesAutocomplete
                            searchOptions={{
                              componentRestrictions: {
                                country: "UK",
                              },
                            }}
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "location-search-input",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                      : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>

                                <div class="form-group mb-0 sideBar-choose-distance">
                                  <div class="form-group-icon-bx">
                                    {/*<select class="form-control-line">
                                      <option>Choose Distance</option>
                                      <option>Most two</option>
                                      <option>Most three</option>
                                    </select>
                                    <span class="form-group-icon"><img src="/images/down-arrow-icon.png" alt="icon" /></span>*/}

                                    <input
                                      type="number"
                                      name="radius"
                                      onChange={context.handleRadiusFilter}
                                      class="form-control-line"
                                      placeholder="Distance"
                                    />
                                  </div>
                                  <button
                                    class="go-btn"
                                    onClick={context.handleCatNegotiation}
                                  >
                                    GO
                                  </button>
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                        {/*<div className="form-group mb-0 sideBar-choose-distance">
                          <div className="form-group-icon-bx">
                            <select className="form-control-line">
                              <option>Choose Distance</option>
                              <option>Most two</option>
                              <option>Most three</option>
                            </select>
                            <span className="form-group-icon"><img src="/images/down-arrow-icon.png" alt="icon" /></span>
                          </div>
                          <button className="go-btn">GO</button>
                        </div>*/}
                      </div>

                      <div className="product-listing-sideBar-colum sideBar-category">
                        <h2>Category</h2>
                        <ul class="toggletab-bx">
                          <li className="innerfilt_topcat_perent">
                            {selectedSubCat &&
                              selectedSubCat.slug === "vehicles-for-sale" ? (
                              <>
                                <span>
                                  <i className="fas fa-angle-double-left"></i>
                                </span>{" "}
                                <a
                                  href={"/category/" + this.props.categorySlug}
                                >
                                  {categoryName}
                                </a>
                              </>
                            ) : (
                              <>
                                <span
                                  onClick={() =>
                                    this.selectSubCatHandler(
                                      "vehicles-for-sale"
                                    )
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <i className="fas fa-angle-double-left"></i>
                                </span>{" "}
                                <a
                                  href={"/category/" + this.props.categorySlug}
                                >
                                  {selectedSubCat &&
                                    selectedSubCat.subCategoryName}
                                </a>
                              </>
                            )}
                            <ul className="innerfilt_topcat">
                              {selectedSubCat &&
                                selectedSubCat.subCatData &&
                                selectedSubCat.subCatData.map(
                                  (rowsubcat, i) => (
                                    <li
                                      className={`${subCategoryId === rowsubcat
                                        ? "actve"
                                        : ""
                                        }`}
                                    >
                                      <a
                                        href={"/sub-category/" + rowsubcat.slug}
                                        id="GoFarm_machinery"
                                        className="goclass01"
                                        name="subCategoryId"
                                      >
                                        {" "}
                                        {rowsubcat.subCategoryName} (
                                        {context.getCategoryCount(
                                          rowsubcat._id
                                        )}
                                        )
                                      </a>
                                    </li>
                                  )
                                )}
                              {selectedSubCat &&
                                selectedSubCat.slug === "vehicles-for-sale" && (
                                  <li>
                                    <a
                                      href
                                      // id="GoFarm_machinery"
                                      className="goclass01"
                                      name="subCategoryId"
                                      onClick={() =>
                                        this.selectSubCatHandler(
                                          "vehicle-parts-for-sale"
                                        )
                                      }
                                    >
                                      {" "}
                                      vehicle Parts For sale
                                    </a>
                                  </li>
                                )}
                            </ul>
                          </li>
                        </ul>
                      </div>

                      <div class="product-listing-sideBar-colum">
                        <h2>Price</h2>

                        <div class="row">
                          <div class="col-6">
                            <div class="form-group">
                              <input
                                type="number"
                                name="min_price"
                                onChange={this.handleSubCatMinPrice}
                                class="form-control-line"
                                placeholder="Min"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group">
                              <input
                                type="number"
                                name="max_price"
                                onChange={this.handleSubCatMaxPrice}
                                class="form-control-line"
                                placeholder="Max"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      {allCustomFields.map(function (row) {
                        return (
                          <React.Fragment>
                            {row.fieldType == "Dropdown" ? (
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  {row.fieldName}
                                </AccordionSummary>
                                <AccordionDetails>
                                  <ul
                                    class="toggletab-bx nav nav-tabs toggle-bx"
                                    role="tablist"
                                  >
                                    {row.fieldValues.map(function (rowfield) {
                                      return (
                                        <li
                                          onClick={context.getFltersdataFetch}
                                          data-id={rowfield._id}
                                          data-name={row.slug}
                                          data-type={row.fieldType}
                                        >
                                          <a href>{rowfield.name}</a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </AccordionDetails>
                              </Accordion>
                            ) : (
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  {row.fieldName}
                                </AccordionSummary>
                                <AccordionDetails>
                                  <input
                                    type="text"
                                    onChange={context.getFltersdataFetch}
                                    data-id={row._id}
                                    data-name={row.slug}
                                    data-type={row.fieldType}
                                    class="form-control-line"
                                    placeholder={row.fieldName}
                                  />
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </React.Fragment>
                        );
                      })}

                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          Ad Type
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul
                            class="toggletab-bx nav nav-tabs toggle-bx"
                            role="tablist"
                          >
                            <li>
                              <a
                                href
                                name="adType"
                                onClick={context.handleCatAdType.bind(
                                  context,
                                  "Featured"
                                )}
                              >
                                Featured
                              </a>
                            </li>
                            {/* <li>
                              <a
                                href
                                name="adType"
                                onClick={context.handleCatAdType.bind(
                                  context,
                                  "Urgent"
                                )}
                              >
                                Urgent
                              </a>
                            </li>
                            <li>
                              <a
                                href
                                name="adType"
                                onClick={context.handleCatAdType.bind(
                                  context,
                                  "Spotlight"
                                )}
                              >
                                Spotlight
                              </a>
                            </li> */}
                          </ul>
                        </AccordionDetails>
                      </Accordion>

                      {context.state.categorySlug == "agriculture" ||
                        context.state.categorySlug == "for-sale" ? (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Seller Type
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul
                              class="toggletab-bx nav nav-tabs toggle-bx"
                              role="tablist"
                            >
                              <li>
                                <a
                                  href
                                  name="sellerType"
                                  onClick={context.handleCatSellerType.bind(
                                    context,
                                    "Company"
                                  )}
                                >
                                  Company
                                </a>
                              </li>
                              <li>
                                <a
                                  href
                                  name="sellerType"
                                  onClick={context.handleCatSellerType.bind(
                                    context,
                                    "Private"
                                  )}
                                >
                                  Private
                                </a>
                              </li>
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <></>
                      )}

                      {context.state.categorySlug == "property" ? (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Seller Type
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul
                              class="toggletab-bx nav nav-tabs toggle-bx"
                              role="tablist"
                            >
                              <li>
                                <a
                                  href
                                  name="sellerType"
                                  onClick={context.handleCatSellerType.bind(
                                    context,
                                    "Company"
                                  )}
                                >
                                  Company
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  name="sellerType"
                                  onClick={context.handleCatSellerType.bind(
                                    context,
                                    "Private"
                                  )}
                                >
                                  Private
                                </a>
                              </li>
                              <li>
                                <a
                                  href="#"
                                  name="sellerType"
                                  onClick={context.handleCatSellerType.bind(
                                    context,
                                    "Agent"
                                  )}
                                >
                                  Agent
                                </a>
                              </li>
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <></>
                      )}

                      {context.state.categorySlug == "jobs" ? (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Seller Type
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul
                              class="toggletab-bx nav nav-tabs toggle-bx"
                              role="tablist"
                            >
                              <li>
                                <a
                                  href
                                  name="recruiterType"
                                  onClick={context.handleCatrecruiterType.bind(
                                    context,
                                    "Company"
                                  )}
                                >
                                  Company
                                </a>
                              </li>
                              <li>
                                <a
                                  href
                                  name="recruiterType"
                                  onClick={context.handleCatrecruiterType.bind(
                                    context,
                                    "Private"
                                  )}
                                >
                                  Private
                                </a>
                              </li>
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <></>
                      )}

                      {context.state.categorySlug == "services" ? (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Seller Type
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul
                              class="toggletab-bx nav nav-tabs toggle-bx"
                              role="tablist"
                            >
                              <li>
                                <a
                                  href
                                  name="providerType"
                                  onClick={context.handleCatproviderType.bind(
                                    context,
                                    "Company"
                                  )}
                                >
                                  Company
                                </a>
                              </li>
                              <li>
                                <a
                                  href
                                  name="providerType"
                                  onClick={context.handleCatproviderType.bind(
                                    context,
                                    "Private"
                                  )}
                                >
                                  Private
                                </a>
                              </li>
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      ) : (
                        <></>
                      )}
                    </div>

                    {context.state.categorySlug == "agriculture" && (
                      <div class="product-listing-sideBar-colum other-option otheropt_newcls">
                        <h2>Other Options</h2>
                        <div class="otheroption_cheperent ">
                          <div class="check-box">
                            <input
                              type="checkbox"
                              value="Yes"
                              name="avaliableNegotiation"
                              id="results-with-pic"
                              onClick={context.handleCatNegotiation}
                            />
                            <label for="results-with-pic">
                              Available for Negotiation
                            </label>
                          </div>
                          <div class="check-box">
                            <input
                              type="checkbox"
                              value="Yes"
                              name="avaliableExchange"
                              id="avaliable-exchange"
                              onClick={context.handleExchange}
                            />
                            <label for="avaliable-exchange">
                              Available for exchange
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                    {context.state.categorySlug == "for-sale" && (
                      <div class="product-listing-sideBar-colum other-option otheropt_newcls">
                        <h2>Other Options</h2>
                        <div class="otheroption_cheperent ">
                          <div class="check-box">
                            <input
                              type="checkbox"
                              value="Yes"
                              name="avaliableNegotiation"
                              id="results-with-pic"
                              onClick={context.handleCatNegotiation}
                            />
                            <label for="results-with-pic">
                              Available for Negotiation
                            </label>
                          </div>
                          <div class="check-box">
                            <input
                              type="checkbox"
                              value="Yes"
                              name="avaliableExchange"
                              id="avaliable-exchange"
                              onClick={context.handleExchange}
                            />
                            <label for="avaliable-exchange">
                              Available for exchange
                            </label>
                          </div>
                          {/* <div class="check-box">
                            <input
                              type="checkbox"
                              value="Yes"
                              name="availableLeasing"
                              id="avaliable-leasing"
                              onClick={context.handleLeasing}
                            />
                            <label for="avaliable-leasing">
                              Available for Leasing
                            </label>
                          </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-lg-9">
                <div
                  id="filter-toggler"
                  className="btnx btn-secondaryx mx-3"
                  onClick={() => this.setState({ openProductFilter: true })}
                >
                  All Filter
                </div>
                <div className="Jcompny_allprod">
                  {showHideListData && (
                    <div className="Jtaballp_content atlistingside_new">
                      {allSubCatfilterProducts.length > 0 ? (
                        <>
                          {allSubCatfilterProducts.map(function (row) {
                            return (
                              <div className="row">
                                <div
                                  className="col-lg-12 listside_newperent"
                                  key={"allSubCatfilterProducts" + row._id}
                                >
                                  <div className="product-listing-product">
                                    <div className="row">
                                      <div className="col-3">
                                        <div className="product-img-bx">
                                          <Link
                                            to={"/product/" + row.productSlug}
                                          >
                                            {row.adType == "Featured" && (
                                              <div className="specialtags">
                                                <span className="black_special">
                                                  {row.adType}
                                                </span>
                                              </div>
                                            )}
                                            {/*row.adType == "Urgent" && (
                                              <div className="specialtags">
                                                <span className="red_special">
                                                  {row.adType}
                                                </span>
                                              </div>
                                            )}
                                            {row.adType == "Spotlight" && (
                                              <div className="specialtags">
                                                <span className="green_special">
                                                  {row.adType}
                                                </span>
                                              </div>
                                            )*/}
                                            {row.productMainImage ? (
                                              <img
                                                src={General.showImage(
                                                  "products/resize-180-180",
                                                  row.productMainImage
                                                )}
                                                alt="uploaded image"
                                              />
                                            ) : (
                                              <img
                                                src="/images/no-image.jpg"
                                                alt="product"
                                              />
                                            )}
                                            <div className="totalimg_sectab">
                                              {/*<Link to="#" className="totlimg_qt">2 <i className="fas fa-camera"></i></Link>*/}
                                            </div>
                                          </Link>
                                        </div>
                                      </div>
                                      <div className="col-9">
                                        <div className="product-outer-bx productBig-outer-bx">
                                          <div className="product-name-bx content_gobottom_new">
                                            <time>{row.time}</time>
                                            <Link
                                              to={"/product/" + row.productSlug}
                                            >
                                              <h4>{row.productName}</h4>
                                            </Link>
                                            <h5>
                                              {/* {row.categoryId.categoryName} -{" "}
                                              {
                                                row.subCategoryId
                                                  .subCategoryName
                                              } */}
                                              {row?.location}
                                            </h5>
                                            {General.showWeekendDeals() &&
                                              row.isWeekEndDeal ? (
                                              <div class="d-flex align-items-center">
                                                <del>
                                                  {General.showPriceDecimal(
                                                    row.productPrice
                                                  )}
                                                </del>
                                                <span className="discountt mr-2">
                                                  -{row?.discountPercent}%
                                                </span>
                                                <h6 className="mt-0">
                                                  <span>
                                                    {General.showPriceDecimal(
                                                      row.weekendPrice
                                                    )}
                                                  </span>
                                                </h6>
                                              </div>
                                            ) : (
                                              <h6>
                                                <span>
                                                  {General.showPriceDecimal(
                                                    row.productPrice
                                                  )}
                                                </span>
                                              </h6>
                                            )}
                                            {row.productDescription && (
                                              <p>{`${row.productDescription.substring(
                                                0,
                                                80
                                              )}...`}</p>
                                            )}
                                            {row.isWishlist === 1 ? (
                                              <button
                                                className="add-to-wishlist"
                                                onClick={() =>
                                                  context.removeWishlistHandler(
                                                    row._id
                                                  )
                                                }
                                              >
                                                <i className="fas fa-heart"></i>
                                              </button>
                                            ) : (
                                              <button
                                                className="add-to-wishlist"
                                                onClick={() =>
                                                  context.addWishlistHandler(
                                                    row._id
                                                  )
                                                }
                                              >
                                                <i className="far fa-heart"></i>
                                              </button>
                                            )}
                                            {row.condition && (
                                              <div className="usednew_button">
                                                <a
                                                  href=""
                                                  className="btnnew_used"
                                                >
                                                  {row.condition}
                                                </a>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {this.state.nothingD ? (
                            <div className="alert alert-danger col-lg-12">
                              <strong>Info!</strong> Nothing here.
                            </div>
                          ) : (
                            <>
                              {this.state.productSkeleton.map(function (
                                rowsskeleton
                              ) {
                                return (
                                  <ContentLoader
                                    viewBox="0 0 1380 300"
                                    height={340}
                                    width={1380}
                                  >
                                    <rect
                                      x="20"
                                      y="{rowsskeleton.id}"
                                      rx="10"
                                      ry="10"
                                      width="300"
                                      height="300"
                                    />
                                    <rect
                                      x="360"
                                      y="{rowsskeleton.id}"
                                      rx="10"
                                      ry="10"
                                      width="1000"
                                      height="300"
                                    />
                                  </ContentLoader>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {showHideGridData && (
                    <div className="Jtaballp_content02 auctionplist_main ">
                      <div className="col-sm-12 actionpage_list">
                        <div
                          className={
                            this.state.nothingD ? "row" : "row low-margin"
                          }
                        >
                          {allSubCatfilterProducts.length > 0 ? (
                            <>
                              {allSubCatfilterProducts.map(function (row) {
                                return (
                                  <div
                                    className="col-sm-3"
                                    key={
                                      "allSubCatfilterProducts" +
                                      row.productSlug
                                    }
                                  >
                                    <div className="product-outer-bx">
                                      <Link to={"/product/" + row.productSlug}>
                                        <div className="product-img-bx">
                                          {row.productMainImage ? (
                                            <img
                                              src={General.showImage(
                                                "products/resize-180-180",
                                                row.productMainImage
                                              )}
                                              alt="uploaded image"
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.jpg"
                                              alt="product"
                                            />
                                          )}
                                          {row.adType == "Featured" && (
                                            <div className="specialtags">
                                              <span className="black_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}
                                          {/*row.adType == "Urgent" && (
                                            <div className="specialtags">
                                              <span className="red_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}
                                          {row.adType == "Spotlight" && (
                                            <div className="specialtags">
                                              <span className="green_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )*/}
                                          <div className="totalimg_sectab">
                                            {/*<Link to={"/product/"+row._id} className="totlimg_qt">2 <i className="fas fa-camera"></i></Link>*/}
                                          </div>
                                        </div>
                                        <div className="product-name-bx">
                                          <h5>{row.time}</h5>
                                          <h4>{row.productName}</h4>
                                          <h3>
                                            {/* {row.categoryId.categoryName} <br />{" "}
                                            {row.subCategoryId.subCategoryName} */}
                                            {row?.location}
                                          </h3>
                                          <h6>
                                            <Link
                                              to={"/product/" + row.productSlug}
                                              className="btnnew_used"
                                            >
                                              View
                                            </Link>{" "}
                                            <span>
                                              {General.showPriceDecimal(
                                                row.productPrice
                                              )}
                                            </span>
                                          </h6>
                                        </div>
                                      </Link>
                                      {row.isWishlist === 1 ? (
                                        <button
                                          className="add-to-wishlist"
                                          onClick={() =>
                                            context.removeWishlistHandler(
                                              row._id
                                            )
                                          }
                                        >
                                          <i className="fas fa-heart"></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="add-to-wishlist"
                                          onClick={() =>
                                            context.addWishlistHandler(row._id)
                                          }
                                        >
                                          <i className="far fa-heart"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {this.state.nothingD ? (
                                <div className="alert alert-danger col-lg-12">
                                  <strong>Info!</strong> Nothing here.
                                </div>
                              ) : (
                                <>
                                  {this.state.productSkeleton.map(function (
                                    rowsskeleton
                                  ) {
                                    return (
                                      <ContentLoader
                                        viewBox="0 0 1380 300"
                                        height={340}
                                        width={1380}
                                      >
                                        <rect
                                          x="20"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="360"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="700"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="1040"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                      </ContentLoader>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="loading-bx">
                  {this.state.totalRecords > 0 && (
                    <>
                      <p>
                        <span>
                          You have viewed {this.state.limit} of{" "}
                          {this.state.totalRecords} Ads
                        </span>
                      </p>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span className="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </>
                  )}

                  {this.state.next_page_number > 0 && (
                    <button
                      onClick={context.getDatabyPageno.bind(
                        context,
                        this.state.next_page_number
                      )}
                    >
                      LOAD MORE
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="side-bar-stoper"></div>
          </div>
        </section>
        <section className="bg-light-dark">
          <div></div>
        </section>
      </React.Fragment>
    );
  }
}

export default MotorSubCategoryOtherList;
