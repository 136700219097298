import React, { Component } from "react";
import User from "Models/User";
import Lodash from "lodash";
import FacebookLogin from "react-facebook-login";
import TwitterLogin from "react-twitter-auth";
import ApiEndPoints from "Helpers/ApiEndPoints";

class SocialForm extends Component {
  constructor(props) {
    super(props);
    this.state = User.dbSchema("SIGNUP");
  }

  componentDidMount = () => {
    // Get Account Setting
    User.getApi(
      "APIS_GET_ACCOUNT_SETTING",
      async (response) => {
        if (response && response[0]["common_setting"]) {
          this.setState({
            showFacebookLogin: response[0]["common_setting"][0]["facebook"],
            showTwitterLogin: response[0]["common_setting"][0]["twitter"],
          });
        }
      },
      false
    );
  };

  responseFacebook = (response) => {
    if (Lodash.has(response, "accessToken")) {
      User.postApi(
        "APIS_FACEBOOK_LOGIN",
        response,
        async (response) => {
          if (Lodash.has(response, "_id")) {
            localStorage.setItem("username", response.email);
            localStorage.setItem("password", response.password);
            User.redirectTo("", true);
          }
        },
        false
      );
    } else {
      User.toastAlert(
        "error",
        "You are failed to login with Facebook, Please try again."
      );
    }
  };

  // facebookTest = () => {
  //   User.postApi(
  //     "APIS_FACEBOOK_LOGIN",
  //     { _id: "60817f51977ebf2153e6be40" },
  //     async (response) => {
  //       console.log(response);
  //       if (Lodash.has(response, "_id")) {
  //         localStorage.setItem("username", response.email);
  //         localStorage.setItem("password", response.password);
  //         localStorage.setItem("UserData", JSON.stringify(response));
  //         User.redirectTo("", true);
  //       }
  //     },
  //     false
  //   );
  // };

  onSuccess = (response) => {
    response.json().then((user) => {
      if (Lodash.has(user, "_id")) {
        User.postApi(
          "APIS_TWITTER_LOGIN",
          user,
          async (response) => {
            if (Lodash.has(response, "_id")) {
              localStorage.setItem("username", response.email);
              localStorage.setItem("password", response.password);
              // if (window.localStorage.length > 0) {
              // }
              setInterval(() => {
                User.redirectTo("", true);
              }, 2000);
            }
          },
          false
        );
      } else {
        User.toastAlert(
          "error",
          "You are failed to login with Twitter, Please try again."
        );
      }
    });
  };

  onFailed = (error) => {
    User.toastAlert(
      "error",
      "You are failed to login with Twitter, Please try again."
    );
  };

  render() {
    return (
      <div className="col-sm-12 show_more">
        {(this.state.showFacebookLogin === true ||
          this.state.showTwitterLogin === true) && (
          <ul>
            {this.state.showFacebookLogin === true && (
              <li className="FacebookLogin">
                <div className="form-group text-center">
                  <FacebookLogin
                    appId="686710349818740"
                    buttonText="Login With Facebook"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={this.responseFacebook}
                  />
                  {/* <button type="button" onClick={this.facebookTest}>
                    Facebook login
                  </button> */}
                </div>
              </li>
            )}
            {this.state.showTwitterLogin === true && (
              <li className="TwitterLogin">
                <div className="form-group text-center">
                  <TwitterLogin
                    loginUrl={ApiEndPoints.getEndPoint("APIS_TWITTER_AUTH")}
                    onFailure={this.onFailed}
                    onSuccess={this.onSuccess}
                    requestTokenUrl={ApiEndPoints.getEndPoint(
                      "APIS_TWITTER_CALLBACK"
                    )}
                  />
                </div>
              </li>
            )}
          </ul>
        )}
      </div>
    );
  }
}

export default SocialForm;
