class ApiEndPoints {
  static apiEndPoints = {
    //User apis
    APIS_LOGIN: "login", //Done
    APIS_SIGNUP: "userSignup", //Done
    APIS_FORGET_PASSWORD: "userForgetPassword", //Done
    APIS_VERIFY_EMAIL: "verify", //Done
    APIS_RESET_PASSWORD: "userResetPassword", //Done
    APIS_NEWS_LETTER: "subscribeToNewsletter", //Done
    APIS_LOGOUT: "logout",
    APIS_FACEBOOK_LOGIN: "facebookLogin",
    APIS_TWITTER_AUTH: "auth/twitter",
    APIS_TWITTER_LOGIN: "twitterLogin",
    APIS_TWITTER_CALLBACK: "auth/twitter/reverse",

    APIS_TWITTER_CONNECT_AUTH: "auth/twitter-connect",
    APIS_TWITTER_CONNECT_LOGIN: "twitterConnectLogin",
    APIS_TWITTER_CONNECT_CALLBACK: "auth/twitter-connect/reverse",

    //APIS_GET_LATEST_PRODUCTS:"getLatestProducts",
    //APIS_GET_BRANDS:"brands",
    APIS_GET_CONFIG_VARIABLES: "config_variables",
    //APIS_GET_LIVE_STREAMINGS:"live_streamings",
    //APIS_GET_UPCOMING_STREAMINGS:"upcoming_streamings",
    APIS_GET_POPULAR_CATEGORIES: "popular_categories",
    APIS_GET_PREMIUM_PRODUCTS: "premium_products",
    //APIS_GET_WEEKLY_DEALS:"weekly_deals",
    APIS_GET_ALL_PRODUCTS_BY_USER: "getAllAdsProductsByUser", //Lokesh
    APIS_GET_LIVE_STREAMING_PRODUCTS_BY_USER: "getLiveStreamingProductsByUser", //Lokesh
    APIS_GET_PRODUCTS_BY_SUB_CATEGORY: "getProductsBySubCategory", //Lokesh
    APIS_GET_ALL_PRODUCTS_BY_LIVE_STREAMING: "getAllProductsByLiveStreaming", //Lokesh
    APIS_GET_UP_COMING_PRODUCTS_BY_LIVE_STREAMING:
      "getUpComingProductsByLiveStreaming", //Lokesh
    APIS_ADD_NEW_ADS: "ads/addNewAds", //Lokesh
    APIS_UPDATE_ADS: "ads/updateAds",
    APIS_UPLOAD_IMAGES: "uploadProductMultiple", //Lokesh
    APIS_GET_HOME_DATA: "search/getHomePageData", //Lokesh
    APIS_GET_LATEST_PRODUCT_BY_CATEGORY: "search/getLatestProductsByCategory",
    APIS_ADD_WISHLIST: "addWishlistProduct",
    API_CURRENT_TRANSACTION_DETAILS: "currentTransactionDetails",

    // APIS_GET_CMS_ABOUT_CONTENT:"cms/getpage/AboutUS", //Lokesh
    // APIS_GET_CMS_MISSION_CONTENT:"cms/getpage/OurMission", //Lokesh
    // APIS_GET_CMS_WHY_CHOOSE_CONTENT:"cms/getpage/WhyChooseUs", //Lokesh
    //APIS_GET_CMS_TERM_CONDITION_CONTENT:"cms/getpage/TermCondition", //Lokesh
    //APIS_GET_CMS_PRIVACY_CONTENT:"cms/getpage/PrivacyPolicy", //Lokesh

    API_GET_USER_BY_EMAIL: "getuser_by_email",
    API_GET_authorizeUrl: "transaction/generateOauthUrl",
    API_GET_LOGIN_USER: "get_login_user",
    API_UPDATE_USER_BY_EMAIL: "updateuser_by_email",
    API_UPDATE_NOTIFICATION: "myprofile/notification",
    API_UPDATE_PASSWORD: "myprofile/change-password",
    API_UPDATE_EMAIL: "myprofile/change-email",
    API_UPDATE_ACCOUNT_DETAIL: "myprofile/updateAccountDetail",
    API_UPDATE_USER_ACCOUNT: "myprofile/updateUserAccount",
    API_GET_USER_PROFILE_DETAILS: "getUserProfileDetails",
    SAVE_STRIPE_ACCOUNT_DETAILS: "user/save-stripe-detail",

    APIS_GET_FIELDS_VALUES: "fields/getFieldsValuesBySubCatId", //Lokesh -- for add new ads
    APIS_GET_FIELDS_VALUES_FILTERS:
      "fields/getFieldsValuesForFiltersBySubCatId", //Lokesh -- for sub category list module
    APIS_GET_CAT_FIELDS_VALUES_FILTERS:
      "fields/getFieldsValuesForFiltersByCatId", //Lokesh -- for sub category list module
    APIS_CONTACT_US: "contact/create",
    APIS_GET_SUB_CATEGORIES_BY_CAT_ID: "subcategories/getSubCategoryByCatId", //Lokesh -- for Category search page
    APIS_GET_SUB_CATEGORIES_BY_PARENT_ID: "getSubCategoryByParentId",
    APIS_ALL_CHILD_SUB_CATEGORIES:
      "subcategories/getChildSubCategoryBySubCatId", //Lokesh -- for Category -- Child Sub Categories search page
    APIS_GET_CHILD_SIBLING_CATEGORIES_BY_SUB_CAT_ID:
      "subcategories/getChildAndSiblingSubCategoryBySubCatId", //Lokesh -- Child Sub Categories search page
    APIS_GET_CATEGORY_DETAIL: "categoryBySlug", //Lokesh
    APIS_GET_PRODUCTS_BY_SEARCH_FILTERS: "search/getProductsBySearchfilters", //Lokesh
    APIS_GET_PRODUCTS_BY_SEARCH_PAGE_FILTERS: "search/getProductsBySearchPagefilters", //Lokesh
    APIS_GET_PRODUCTS_BY_CATEGORY_FILTERS:
      "search/getProductsByCategoryfilters", //Lokesh
    APIS_GET_PRODUCTS_BY_AUCTION_CATEGORY_FILTERS:
      "search/getAuctionProductsByCategoryfilters",
    APIS_GET_MOTOR_CATEGORY_PRODUCTS: "search/getMotorCategoryProducts",
    APIS_GET_LIVESTREAMING_PRODUCTS_BY_CATEGORY_FILTERS:
      "search/getLivestreamingProductsByCategoryfilters",
    APIS_GET_PRODUCTS_BY_SUB_CATEGORY_FILTERS:
      "search/getProductsBySubCategoryfilters", //Lokesh
    APIS_ALL_FAQ_LIST: "faqs/list", //Lokesh
    APIS_ALL_FAQS: "faqs", //Lokesh
    APIS_GET_PLANS_BY_AD: "plan/getPlanByadType", //Lokesh
    APIS_GET_FEATURED_PLAN_BY_AD: "plan/getFeaturePlans",
    APIS_GET_VIDEO_STREAMING_PLAN: "plan/getVideoStreamingPlans",
    APIS_GET_ONE_SLOT_STREAMING_PLAN: "video-stream-charge/getOneSlot",
    APIS_ALL_CATEGORIES_MENU: "categories/menulist", //Lokesh -- done
    APIS_ALL_CHILD_SUB_CATEGORIES_CAT: "categories/menulist", //Lokesh -- done
    APIS_ALL_CATEGORIES: "categories/list", //Lokesh -- done
    APIS_ALL_MOTOR_CATEGORIES: "categories/motorCategories",
    APIS_ALL_CATEGORIES_DATA: "categoriesByType", //Lokesh -- done
    APIS_ALL_CATEGORIES_TYPE_DATA: "categoriesByTypeData/?", //Lokesh -- done
    APIS_GET_SUB_CATEGORY_DETAIL_BY_Id: "subCategoryById", //Lokesh -- done
    APIS_GET_SUB_CATEGORY_DETAIL_BY_SLUG: "subCategoryBySlug", //Lokesh -- done
    APIS_ALL_SUB_CATEGORIES: "subcategories/list/?", //Lokesh -- done
    APIS_ALL_SUB_CATEGORIES_AUTOCOMPLETE: "subcategories/autocompleteData/?",
    APIS_ALL_SEARCH_AUTOCOMPLETE_DATA: "search/autocompleteSearchData",
    //APIS_GET_SETTING_CONTENT_BY_SLUG:"setting/getSingleRecordBySlug", //Lokesh -- done
    APIS_GET_SETTING_DATA: "setting/getAllActiveRecords", //Lokesh -- done
    APIS_GET_CMS_PAGE: "cms/getStaticPage", //Lokesh -- done
    APIS_GET_SETTING_DATA_BY_TITLE: "setting/getSingleRecordByTitle", //Lokesh -- done
    APIS_GET_LISTING_SETTING: "listingSetting",
    APIS_ALL_LEARN_LIST: "learns/list",
    APIS_ALL_LEARNS: "learns",

    APIS_ADD_TO_WISHLIST: "AddProductWishlist",
    APIS_REMOVE_FROM_WISHLIST: "RemoveProductWishlist",
    APIS_GET_PRODUCT_BY_SLUG: "productDetail",
    APIS_GET_PRODUCT_BY_ID: "getProductById",
    APIS_GET_LIVE_PRODUCT_BY_ID: "getLiveProductById",
    APIS_GET_LIVE_PRODUCT_BY_SLUG: "getLiveProductBySlug",
    APIS_ADD_PRODUCT_REVIEW: "ProductsReviews",
    APIS_POST_REPLY_REVIEW: "replyAsSeller",
    APIS_GET_PRODUCT_SAFETY: "productSafetyTips/all",
    API_PRODUCT_MARK_SOLD: "product/productMarkSold",

    APIS_GET_MY_PROFILE_PRODUCT: "myProfileProducts",
    APIS_GET_MY_PROFILE_ACTIVE_PRODUCT: "myProfileActiveProducts",
    APIS_PUBLISH_MY_PRODUCT: "publishProduct",
    APIS_GET_MY_PROFILE_PRODUCT_SEARCH: "myProfileSearchProducts",
    APIS_REMOVE_PRODUCT_FROM_MY_PROFILE: "myProfileRemoveProductById",
    APIS_FACEBOOK_CONNECT_ACCOUNT: "connectFacebookAccount",

    APIS_GET_ACCOUNT_DEACTIVATE_REASON: "deactivateAccountReasons",
    APIS_POST_ACCOUNT_DEACTIVATE: "deactivateAccount",
    APIS_UPLOAD_PROFILE_IMAGE: "uploadProfileImage",
    APIS_GET_ALL_REPORTS: "reports",
    API_USER_ALERTS: "getalerts",
    APIS_POST_REPORT: "submit_report",

    APIS_GET_PACKAGES: "package/get_all_active",
    API_CURRENT_PACKAGE: "Transaction/current",
    API_SELECT_PACKAGES: "package/get_selected_package",
    API_CREATE_TRANSACTION: "Transaction/create/new",
    API_UPDATE_TRANSACTION: "Transaction/update",
    API_UPDATE_TRANSACTION_STATUS: "Transaction/update/status",
    API_CURRENT_PACKAGE_DETAILS: "Transaction/currentDetails",

    API_CHECK_PLAN: "package/check_status",

    APIS_GET_GEO_LOCATION_AUTO_DETECT: "geoFilter/all",
    APIS_GET_COMMENT_SETTING: "comment/all",
    APIS_GET_MAP_MODULE_SETTING: "maps/all",
    APIS_GET_GENERAL_SETTING: "general/all",
    APIS_GET_SECURITY_SETTING: "security/all",
    APIS_GET_LIVESTREAM_SETTING: "livestreamingvideo/all",
    APIS_GET_ACCOUNT_SETTING: "account/all",
    APIS_POST_DATA: "getChecklist",

    APIS_GET_ALL_PRODUCTS_BY_USER_ID: "user/allproducts",

    API_CREATE_LIVE_SCHEDULE: "createLiveStreamingAuction",
    API_UPDATE_LIVE_SCHEDULE: "updateLiveStreamingAuction",
    API_DELETE_LIVE_SCHEDULE: "deleteLiveStreamingAuction",
    API_CHANGE_STATUS_LIVE_SCHEDULE: "changeStatusLiveStreamingAuction",
    API_GET_LIVE_SCHEDULE: "getAllLiveStreamingAuction",
    API_GET_Live_STREAMING_PAGE_DATA: "getLiveStreamingPageData",
    API_GET_LIVEAUCTION_DURATION: "liveAuctionDuration",
    API_LIVE_SCHEDULE_TIME_AVAILABLE_BY_USER: "liveAuctionTimeAvailableByUser",
    API_IS_LIVE_TIME_BY_USER: "checkIsLiveStreamingTime",

    APIS_CREATE_WEEKLY_DEALS: "weeklydeals/create",
    APIS_GET_WEEKLY_DEALS: "weeklydeals/getAll",
    API_GET_WEEKLY_DEALS_PAGE_DATA: "weeklydeals/getWeeklyDealsPageData",
    APIS_GET_ALL_PRODUCTS_FOR_WEEKEND_DEALS: "getAllProductsForWeekendDeals",
    APIS_CREATE_WEEKEND_DEALS: "weekenddeals/create",
    APIS_GET_ALL_WEEKEND_DEALS: "getAllMyWeekendDeals",
    APIS_REMOVE_WEEKEND_DEAL_PRODUCT_FROM_MY_PROFILE:
      "deleteWeekendDealsProduct",

    API_GET_Live_STREAMING_DATA_BY_PRODUCT: "liveStreaming/singleRecord",
    API_GET_SINGLE_LIVE_STREAMING_DATA: "liveStreaming/getSingleRecord",
    APIS_GET_UPCOMINGSTREAMING_ADS_OF_USER:
      "liveStreaming/upcomingUserStreamingAds",
    // APIS_GET_STREAMING_ADS_OF_USER: "liveStreaming/userStreamingAds",
    APIS_GET_COMPLETEDSTREAMING_ADS_OF_USER:
      "liveStreaming/userStreamingCompletedAds",
    APIS_GET_UPDATESTREAMING_AD_OF_USER: "liveStreaming/userStreamingUpdateAd",
    APIS_REGISTER_BID: "liveStreaming/registerbid",
    APIS_UPDATE_REGISTER_BID: "liveStreaming/updateRegisteredbid",
    APIS_GET_REGISTERED_BID_STATUS: "liveStreaming/getRegisteredStatus",
    API_UPDATE_AUCTION_NOBID_STATUS: "liveStreaming/updateNoBidStatus",
    APIS_PREBID_STOP: "liveStreaming/updatePreBidStatus",
    APIS_SUBMIT_EXPECTED_DELIVERY_DATE_LIVE_AUCTION: "liveStreaming/submitExpectedDeliveryDate",
    APIS_SUBMIT_RETURN_SHIPPING_NOTE_LIVE_AUCTION: "liveStreaming/returnShippingNote",
    API_CHANGE_LIVE_AUCTION_STATUS: "liveStreaming/changeLiveAuctionStatus",
    API_CHANGE_LIVE_AUCTION_SELLER_REQUEST_STATUS: "liveStreaming/changeSellerRequestStatus",
    APIS_SUBMIT_LIVE_AUCTION_RETURN_RESON: "liveStreaming/submitReturnReson",
    API_CHANGE_LIVE_AUCTION_RETURN_STATUS: "liveStreaming/submitReturnStatus",
    API_CHANGE_LIVE_AUCTION_RETURN_ADDRESS: "liveStreaming/submitReturnAddress",
    API_LIVE_AUCTIONS_COUNT_BY_ID: "liveStreaming/getLiveAuctionCounters",
    API_LIVE_AUCTIONS_BIDDER_PRODUCT_COUNT: "liveStreaming/getAllLiveStreamingBidderProductCount",

    APIS_FOLLOW_USER: "user/follow",
    APIS_UNFOLLOW_USER: "user/unfollow",
    API_SEARCH_FOLLOWERS: "user/filterfollowers",

    APIS_NOTIFY_UPCOMING_STREAMING: "liveStreaming/notify",
    APIS_REMOVE_NOTIFY_UPCOMING_STREAMING: "liveStreaming/removenotify",
    API_CHECK_CURRENT_USER_SLOTS: "liveStreaming/checkCurrentUserSlot",

    APIS_GET_ALL_CATEGORY_FIELDS: "fields/all",
    API_CREATE_ORDER: "order/create",
    API_STRIPE_INTENT: "transaction/generateStripeIntent",
    API_GET_ORDERED_PRODUCT_STATUS: "order/productStatus",
    API_GET_ORDERED_PRODUCT_BY_BUYERID: "order/all/?",
    API_GET_ORDERED_STATUS_BY_ID: "order/getAllStatus/?",
    API_GET_SELLER_TOTAL_BALANCE: "order/sellerTotalBalance/?",
    API_GET_SELLER_PENDING_TRANSACTION: "order/sellerPendingTransaction/?",
    API_CHANGE_ORDER_STATUS: "order/changeOrderStatus",
    API_CHANGE_SELLER_REQUEST_STATUS: "order/changeSellerRequestStatus",
    APIS_SUBMIT_EXPECTED_DELIVERY_DATE: "order/submitExpectedDeliveryDate",
    APIS_SUBMIT_RETURN_SHIPPING_NOTE: "order/returnShippingNote",
    API_ORDER_REFUND: "order/orderRefund",
    API_ORDER_REFUND_LIVE_AUCTION: "liveStreaming/orderRefund",
    APIS_ORDER_DETAIL: "order/detail",
    API_WITHDRAW_AMOUNT: "order/withdrawAmount",

    APIS_REPORT_REVIEW: "report/review",

    APIS_GET_SOME_RESONS: "master/getAllData",
    APIS_GET_COUNTRIES: "master/getAllCountries",
    APIS_GET_STATE: "master/getAllStates",
    APIS_GET_CITIES: "master/getAllCities",
    APIS_SUBMIT_RETURN_RESON: "order/submitReturnReson",
    API_CHANGE_ORDER_RETURN_STATUS: "order/submitReturnStatus",
    API_CHANGE_ORDER_RETURN_ADDRESS: "order/submitReturnAddress",
    API_GET_ORDERED_BY_STATUS: "order/getDisputeOrder",
    API_GET_ACHIEVEMENT_ORDERED: "order/getAchievementOrders",
    APIS_SUBMIT_AUCTION_PRICES: "product/updateAuctionPrices",
    APIS_GET_START_BID: "liveStreaming/checkLiveStreaming",
    API_GET_USER_BOUGHT_AUCTION_PRODUCTS: "getAllLiveStreamingBoughtAuction",
    API_GET_USER_SOLD_AUCTION_PRODUCTS: "getAllLiveStreamingSoldAuction",
    API_GET_USER_DISPUTE_AUCTION_PRODUCTS: "getAllLiveStreamingDisputeAuction",
    API_GET_USER_ACHIVEMENT_AUCTION_PRODUCTS: "getAllLiveStreamingAchievements",
    APIS_GET_CATEGORY_BY_CAT_ID: "getCategoryByCatId",
    APIS_GET_AUCTION_CATEGORY_BY_CAT_ID: "getCategoryByAuctionCatId",
    APIS_GET_AUCTION_SUB_CATEGORY_BY_CAT_ID:
      "getAuctionSubCategoryByCatId",
    APIS_GET_AUCTION_CHILD_CATEGORY_BY_CAT_ID:
      "getAuctionChildCategoryByCatId",
    API_GET_USER_UPCOMING_AUCTION_PRODUCTS: "getAllLiveStreamingUpcomingProducts",
    API_GET_USER_SCHEDULE_AUCTION_PRODUCTS: "getAllLiveStreamingScheduleProducts",
    API_GET_MAIN_CATEGORIES: "getMainCategories",
    API_SCHEDULE_CALL: "scheduleCall",
    API_EDIT_SCHEDULED_CALL: "editScheduledCall",
    API_DELETE_SCHEDULED_CALL: "deleteScheduledCall",
    API_POST_SELECTED_USER: "getSelectedUser",
    API_GET_SCHEDULED_CALL: "getScheduledCalls",
    API_SET_CALL_STATUS: "setCallStatus",
    API_GET_SCHEDULED_CALL_FOR_SELLER: "getScheduledCallsForSeller",
    API_GET_AD_POSTED_STATUS: "getAdPostedStatus",
    APIS_STRIPE_CHECKOUT: "stripeCheckout",
    APIS_STRIPE_SETUP: "stripeSetup",
    APIS_ORDER_UPDATE: "order/updateOrder",
    APIS_Get_logo: "getLatestLogo",
    API_QUICKBLOX_CREATE_USER: "quickblox/createUser",
    API_QUICKBLOX_GET_USER_LOGIN: "quickblox/getUserByLogin"
  };

  static getBaseUrl() {
    return process.env.REACT_APP_ApiBaseUrl;
  }

  static getEndPoint(key) {
    return ApiEndPoints.getBaseUrl() + ApiEndPoints.apiEndPoints[key];
  }
}

export default ApiEndPoints;
