import React, { Component } from "react";
import SubMenu from "Partials/SubMenu";
import { Link } from "react-router-dom";
import Category from "Models/Category";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      MenuCategories: localStorage.getItem("MenuCategories")
        ? JSON.parse(localStorage.getItem("MenuCategories"))
        : [],
      openSubMenuId: 0,
    };
  }

  componentWillMount = () => {
    var self = this;
    Category.getApi(
      "APIS_ALL_CATEGORIES_MENU",
      async (response) => {
        localStorage.setItem("MenuCategories", JSON.stringify(response));
        self.setState({ MenuCategories: response });
      },
      false
    );
  };

  handleSubMenuToggle = (id) => {
    this.setState((prevState) => ({
      openSubMenuId: prevState.openSubMenuId === id ? null : id,
    }));
  };

  render() {
    var context = this;
    return (
      <nav className="mainh_manudrop" id="mobileNav">
        <div className="navbar-collapse" id="navbar-collapse-1">
          <ul className="nav">
            {this.state.MenuCategories.slice(0, 7).map(function (row, index) {
              return row.subCatData ? (
                <li className="dropdown" key={"TopMenu-" + row._id}>
                  {row.recordType === "Dynamic" ? (
                    <>
                      <div className="">
                        <Link to={"/category/" + row.slug}>
                          {row.categoryName}
                        </Link>
                        <span
                          className="dropdown-toggle downcustom"
                          onClick={() => context.handleSubMenuToggle(row._id)}
                        // className="dropdown-toggle downcustom"
                        // data-toggle="collapse"
                        // data-target={`menu_${row._id}`}
                        // onClick={() => this.toggleSubMenu(row._id)}
                        // onClick={() =>
                        //   document
                        //     .getElementById(`menu_${row._id}`)
                        //     .classList.toggle("show")
                        // }
                        >
                          <i className="fas fa-angle-down"></i>
                        </span>
                      </div>
                      <SubMenu
                        catType="other"
                        SubCategories={row.subCatData}
                        id={row._id}
                        isOpen={context.state.openSubMenuId === row._id}
                      />
                    </>
                  ) : (
                    <>
                      <div className="postiondrop_cstm">
                        <Link to={"/category/" + row.slug}>{row.categoryName}</Link>
                        <span
                          className="dropdown-toggle downcustom"
                          onClick={() => context.handleSubMenuToggle(row._id)}
                        // className="dropdown-toggle downcustom"
                        // data-toggle="collapse"
                        // data-target={`menu_${row._id}`}
                        // // onClick={() => this.toggleSubMenu(row._id)}
                        // onClick={() =>
                        //   document
                        //     .getElementById(`menu_${row._id}`)
                        //     .classList.toggle("show")
                        // }
                        >
                          <i className="fas fa-angle-down"></i>
                        </span>
                      </div>
                      <SubMenu catType="auction" SubCategories={row.subCatData} id={row._id} isOpen={context.state.openSubMenuId === row._id} />
                    </>
                  )}
                </li>
              ) : (
                <li key={"TopMenu-" + row._id}>
                  <div className="postiondrop_cstm">
                    {row.recordType === "Dynamic" ? (
                      <Link to={"/" + row.slug}>
                        {row.categoryName}
                      </Link>
                    ) : (
                      <Link to={"/" + row.slug}>{row.categoryName}</Link>
                    )}
                  </div>
                </li>
              );
            }
            )}
            {(this.state.MenuCategories && this.state.MenuCategories.length > 7) &&
              <li className="dropdown" key="TopMenu-more-cat">
                <div className="postiondrop_cstm">
                  <Link to="#">All Categories</Link>
                </div>
                <SubMenu catType="other" SubCategories={this.state.MenuCategories.slice(7)} id="more-categories" />
              </li>
            }
          </ul>
        </div>
      </nav>
    );
  }
}

export default Menu;
