import React, { Component } from "react";
import { Link } from "react-router-dom";

class SubMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SubCategories: props.SubCategories,
      catType: props.catType,
    };
  }

  render() {
    const { id, isOpen, onToggle } = this.props;
    const { SubCategories, catType } = this.state;
    return (
      <>
        {SubCategories && SubCategories.length > 0 ? (
          <ul className={`dropdown-menu collapse ${isOpen ? "show" : ""}`} id={`menu_${id}`}>
            {SubCategories.map((row) => (
              <li key={"SubCategory-" + row._id}>
                <div className="lisonmegha">
                  {row.categoryName ? (
                    <Link to={(row.recordType === "Dynamic" && catType === "other") ? "/category/" + row.slug : "/live-auction/" + row.slug}>
                      {row.categoryName}
                    </Link>
                  ) : (
                    <Link to={"/sub-category/" + row.slug}>
                      {row.subCategoryName}
                    </Link>
                  )}
                </div>
              </li>
            ))}
          </ul>
        ) : null}
      </>
    );
  }
}

export default SubMenu;
