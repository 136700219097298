import React, { Component } from "react";
import ContactUs from "Models/ContactUs";
import Page from "Models/Page";
import ReCAPTCHA from "react-google-recaptcha";

class ContactUsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = ContactUs.dbSchema();
  }

  handleContact = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (this.state.showCaptcha === true && !this.state.captchaValue) {
      ContactUs.toastAlert("error", "Please Verify Captcha");
      return false;
    }
    var self = this;
    if (
      (await ContactUs.checkValidation(
        await ContactUs.validationRules(),
        this.state
      )) === true
    ) {
      ContactUs.postApi(
        "APIS_CONTACT_US",
        this.state,
        async (response) => {
          self.setState({ email: "", name: "", message: "", subject: "" });
        },
        false
      );
    }
  };

  onChange = (value) => {
    this.setState({ captchaValue: value });
  };

  render() {
    return (
      <React.Fragment>
        <section className="repeat-section abouttop_sectwo">
          <div className="container">
            <div className="addaadd">
              <h2 className="bannerheading">Contact US</h2>
              <form onSubmit={this.handleSubmit} method="post">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.name}
                        onChange={this.handleContact}
                        name="name"
                        className="form-control-line"
                        placeholder="Name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.email}
                        onChange={this.handleContact}
                        name="email"
                        className="form-control-line"
                        placeholder="Email"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.subject}
                        onChange={this.handleContact}
                        name="subject"
                        className="form-control-line"
                        placeholder="Subject"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="text"
                        value={this.state.message}
                        onChange={this.handleContact}
                        name="message"
                        className="form-control-line"
                        placeholder="Message"
                      />
                    </div>
                    {this.state.showCaptcha === true && (
                      <div className="form-group">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_CAPTCHA_CLIENT_KEY}
                          onChange={this.onChange}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-sm-12">
                    <div className="countinu_first text-right">
                      <button type="submit" className="btn-secondaryx">
                        Submit <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default ContactUsComponent;
