import Model from "Models/Model";

class Search extends Model {
  dbSchema() {
    return {
      categoryId: "",
      lat: "",
      lng: "",
      keyword: "",
    };
  }
}

export default new Search();
