  import React, { Component } from "react";
  import SocialForm from 'Forms/SocialForm';
  import User from "Models/User";
  import Lodash from "lodash";

  class ResetPasswordForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        User : User.dbSchema("RESET_PASSWORD"),
        password:true,
        confirmPassword:true,
      }
    }

    componentDidMount = () => {
      let {User} = this.state;
      User["code"] = this.props.code;
      this.setState({User:User});
    }

    submitHandler = async(event) => {
      event.preventDefault();
      var validationRules = await User.validationRules();
      validationRules.confirmPassword.push({lib:"Validator", func:"equals", msg:"Confirm Password must match with password.",option:this.state.User.password});
      
      if(await User.checkValidation(validationRules, this.state.User) === true){
        User.postApi("APIS_RESET_PASSWORD", this.state.User, async(response)=>{
          if(response == true){
            User.redirectTo("login",true);
          }
        }, false);
      }
    };

    onChangeHanlde = event => {
      let {User} = this.state;
      User[event.target.name] = event.target.value;
      this.setState({ User: User});
    };

    passwordToggle = event => {
      event.preventDefault();
      var password = this.state.password ? false : true;
      this.setState({ password:password});
    };

    confirmPasswordToggle = event => {
      event.preventDefault();
      var confirmPassword = this.state.confirmPassword ? false : true;
      this.setState({ confirmPassword:confirmPassword});
    };

    render() {
      return (
          <form onSubmit={this.submitHandler} method="post">
            <div className="formgrop_main_New row">
              <div className="col-sm-12 mt-2">
                <div className="form-group icon_input signpass_New">
                  <input type={this.state.password ? 'password' : 'text'}  onChange={this.onChangeHanlde} value={this.state.User.password} name="password" className="form-control New_control" placeholder="New Password" />
                  <a href="#" onClick={this.passwordToggle}  className="eyeshow_New"><img src="/images/eye.svg" alt="Icon" width="20" /></a>
                </div>
              </div>
              <div className="col-sm-12 mt-2">
                <div className="form-group icon_input signpass_New">
                  <input type={this.state.confirmPassword ? 'password' : 'text'} onChange={this.onChangeHanlde} value={this.state.User.confirmPassword} name="confirmPassword" className="form-control New_control" placeholder="Confirm Password" />
                  <a href="#" onClick={this.confirmPasswordToggle} className="eyeshow_New"><img src="/images/eye.svg" alt="Icon" width="20" /></a>
                </div>
              </div>
              <div className="col-sm-12 marginfour_side">
                <div className="form-group text-center">
                  <button type="submit" className="btnx btn-secondaryx">Submit</button>
                </div>
              </div>
            </div>
            <div className="donthave_New text-center">
              <p>Login to your account <a href="/login">here</a></p>
            </div>
          </form>
      );
    }
  }
  export default ResetPasswordForm;
