import React, { Component } from "react";
import Product from "Models/Product";
import General from "Helpers/General";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Countdown from 'react-countdown';
import Menu from 'Partials/Menu';

class PopularCategoriesSlider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      PopularCategories:[],
    }
  }

  componentDidMount = () => {
    var self = this;

    Product.getApi("APIS_GET_POPULAR_CATEGORIES", async(response)=>{
      if(response.length){
        self.setState({PopularCategories:response});
      }
    }, false);
  }

  render() {
    return (
      <section className="repeat-section popular-categories-section">
        <div className="container">
          <div className="section-heading">
            <h2>Popular Categories</h2>
            <a href="#" className="see-all-btn">See All <i className="fas fa-arrow-right"></i></a>
          </div>
        </div>

        <div className="popular-categories-minH">
          <OwlCarousel className='owl-theme' loop margin={10} nav items={7}>
            {
              this.state.PopularCategories.map(function(row){
                return <div className="item" key={"PopularCategories"+row._id}>
                  <div className="popular-categories-bx">
                    <a href="#">
                      <div className="popular-categories-img">
                        <img src={General.showImageNew('categories',row.categoryImage)} alt={row.categoryName} width="180" height="180" />
                      </div>
                      <h2>{row.categoryName}</h2>
                    </a>
                  </div>
                </div>
              })
            }
          </OwlCarousel>
        </div>
      </section>
    );
  }
}

export default PopularCategoriesSlider;