import React, { Component } from "react";
import MyProfile from "Models/MyProfile";
import MyWishlistComponent from "./MyWishlistComponent";

export class Wishlist extends Component {

  constructor(props) {

    super(props);

    this.state = {
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      wishlists: [],
    }

  }

  componentDidMount = () => {
    this.getAllProducts();
  };

  getAllProducts = () => {
    MyProfile.getApi(
      "APIS_GET_MY_PROFILE_PRODUCT",
      async (response) => {
        this.setState({ wishlists: response.wishlists });
      },
      true
    );
  };

  removeWishlistHandler = (e, id) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    MyProfile.postApi(
      "APIS_REMOVE_FROM_WISHLIST",
      { productId: id },
      async (response) => {
        this.getAllProducts();
        this.setState({ isLoading: false });
      },
      true
    );
  };


  render() {
    const { wishlists } = this.state;
    return (
      <>
        <div
          className="tab-pane this_subwishlist active"
          id="subtab04"
          role="tabpanel"
        >
          <div className="subtab_prodlist_NEW subtab_prodlist_NEW_forpadd">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-3 subtab_firstmain paddingrigt_none_Cfilter"
                  id="subtab_secondfixed"
                >
                  <div className="subcateleft_imgsec">
                    <img
                      src="/images/wishlist-limg.png"
                      alt="Image"
                    />
                  </div>
                </div>
                <div className="col-lg-9 subtab_secondmain">
                  <div className="Jcompny_allprod">
                    <div className="Jtaballp_content atlistingside_new">
                      {wishlists && wishlists.length ? (
                        wishlists.map((wishlist, index) => (
                          <MyWishlistComponent
                            key={index}
                            wishlist={wishlist}
                            removeWishlistHandler={
                              this.removeWishlistHandler
                            }
                          />
                        ))
                      ) : (
                        <div className="alert alert-danger col-lg-12 ">
                          <strong>Info!</strong> Nothing here.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Wishlist;
