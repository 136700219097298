import Model from "Models/Model";

class Order extends Model{

	dbSchema(){
	    return {
	      _id:"", buyer_id:"", seller_id:"", transaction_id:"", reference_id:"", status:""
	    };
	}
}

export default new Order();