import React from "react";
import { Route } from "react-router-dom";
import LoginHeader from "Partials/LoginHeader";
import LoginFooter from "Partials/LoginFooter";
import Toastr from "Partials/Toastr";
const LoginLayout = ({ children }) => (
  <>
    <LoginHeader />
    <Toastr />
    {children}
    <LoginFooter />
  </>
);

const LoginLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <LoginLayout>
          <Component {...matchProps} />
        </LoginLayout>
      )}
    />
  );
};

export default LoginLayoutRoute;
