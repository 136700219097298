import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  CLOSE_MESSAGE_BOX,
  OPEN_MESSAGE_BOX,
  MINIMIZE_MESSAGE_BOX,
  MESSAGE_DATA,
  TOGGLE_SELECTED_CHANNEL,
  SET_SELECTED_CHANNEL,
  SET_OUTGOING_CALL,
  SET_INCOMING_CALL,
  SET_QUICKBLOX_SESSION,
  SET_CONTACT_LIST,
  SET_SELECTED_CHANNEL1,
  SET_SELECTED_USER,
  SET_SELECTED_CHANNEL_DATA,
  SET_QUICKBLOX_CONNECTION,
  SET_QUICKBLOX_UNREADMESSAGE,
  SET_SCHEDULE_MEETING
} from "./Constant";

import User from "Models/User";
import Dictionary from "Models/Dictionary";

const isOpenMessageBoxFromStorage = localStorage.getItem("isOpenMessageBox")
  ? JSON.parse(localStorage.getItem("isOpenMessageBox"))
  : false;

const isClosedMessageBoxFromStorage = localStorage.getItem("isClosedMessageBox")
  ? JSON.parse(localStorage.getItem("isClosedMessageBox"))
  : true;

const ischannelBoxOpenFromStorage = localStorage.getItem("selectedMessageBox")
  ? JSON.parse(localStorage.getItem("selectedMessageBox"))
  : false;
const contactsList = localStorage.getItem("QB_CONTACT_LIST")
  ? JSON.parse(localStorage.getItem("QB_CONTACT_LIST"))
  : [];
const selectedChannel = localStorage.getItem("QB_SELECTED_CHANNEL")
  ? JSON.parse(localStorage.getItem("QB_SELECTED_CHANNEL"))
  : null;
const selectedUser = localStorage.getItem("QB_SELECTED_USER")
  ? JSON.parse(localStorage.getItem("QB_SELECTED_USER"))
  : [];

  let selectedChannelData = [];
  if(localStorage.getItem("QB_SELECTED_CHANNEL_DATA") && localStorage.getItem("QB_SELECTED_CHANNEL_DATA") != "undefined"){
    selectedChannelData = JSON.parse(localStorage.getItem("QB_SELECTED_CHANNEL_DATA"))
  }

  let unreadMessage = 0;

const messageReducer = (
  state = {
    isOpenMessageBox: isOpenMessageBoxFromStorage,
    isClosedMessageBox: isClosedMessageBoxFromStorage,
    msgCheck: ischannelBoxOpenFromStorage,
    contactList: contactsList,
    selectedChannel: selectedChannel,
    selectedUser: selectedUser,
    selectedChannelData: selectedChannelData,
    unreadMessage: unreadMessage,
  },
  action
) => {
//  console.log(action.type);
  switch (action.type) {
    case OPEN_MESSAGE_BOX:
      return {
        ...state,
        isOpenMessageBox: action.payload,
        isClosedMessageBox: false,
      };
    case CLOSE_MESSAGE_BOX:
      return {
        ...state,
        isClosedMessageBox: action.payload,
      };
    case MINIMIZE_MESSAGE_BOX:
      return {
        ...state,
        isOpenMessageBox: action.payload,
        isClosedMessageBox: false,
      };
    case TOGGLE_SELECTED_CHANNEL:
      return {
        ...state,
        msgCheck: action.payload,
      };
    case MESSAGE_DATA:
      return {
        ...state,
        msgData: action.payload,
      };
    case SET_SELECTED_CHANNEL:
      return {
        ...state,
        selectedChannel: action.payload,
      };
    case SET_CONTACT_LIST:
      return {
        ...state,
        contactList: action.payload,
      };
    case SET_SELECTED_CHANNEL1:
      return {
        ...state,
        selectedChannel: action.payload,
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case SET_SELECTED_CHANNEL_DATA:
      return {
        ...state,
        selectedChannelData: action.payload,
      };
    case SET_QUICKBLOX_UNREADMESSAGE:
      return {
        ...state,
        unreadMessage: action.payload,
      };
    default:
      return state;
  }
};

const callReducer = (
  state = {
    outgoingCallState: false,
    incomingCallState: false,
    quickbloxSession: null,
    scheduleMeetingState: {state: false,toQbUserId: null, toUserId: null, username: null, updateInList: false,toUserEmail: null}
  },
  action
) => {
  switch (action.type) {
    case SET_OUTGOING_CALL:
      return {
        ...state,
        outgoingCallState: action.payload,
      };
    case SET_INCOMING_CALL:
      return {
        ...state,
        incomingCallState: action.payload,
      };
    case SET_SCHEDULE_MEETING:
        return {
          ...state,
          scheduleMeetingState: {...state.scheduleMeetingState,...action.payload} ,
        };
    case SET_QUICKBLOX_SESSION:
      return {
        ...state,
        quickbloxSession: action.payload,
      };
    default:
      return state;
  }
};

const qbReducer = (
  state = {
    qbconnection: false
  },
  action
) => {
  switch (action.type) {
    case SET_QUICKBLOX_CONNECTION:
      return {
        qbconnection: action.payload,
      };
    default:
      return state;
  }
};

const AllReducer = combineReducers({
  USER_AUTH: User.setReduxStore,
  MENU_LIST: Dictionary.setReduxStore,
  MESSAGE_BOX: messageReducer,
  VIDEO_CALL: callReducer,
  QB_CONNECT: qbReducer,
});

const InitialState = {
  USER_AUTH: User.dbSchema("Default"),
  MENU_LIST: Dictionary.dbSchema("Default"),
  QB_CONNECT: {qbconnection:false},
  MESSAGE_BOX: {
    isOpenMessageBox: isOpenMessageBoxFromStorage,
    isClosedMessageBox: isClosedMessageBoxFromStorage,
    msgCheck: ischannelBoxOpenFromStorage,
    contactList: contactsList,
    selectedChannel: selectedChannel,
    selectedUser: selectedUser,
    selectedChannelData: selectedChannelData,
    unreadMessage:unreadMessage
  },
  VIDEO_CALL: {
    outgoingCallState: false,
    incomingCallState: false,
    quickbloxSession: null,
    scheduleMeetingState: {}
  },
};

const middleware = [thunk];

const StoreReducer = createStore(
  AllReducer,
  InitialState,
  composeWithDevTools(applyMiddleware(...middleware))
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export { InitialState, AllReducer, StoreReducer };
