import Model from "Models/Model";

class Report extends Model {
  dbSchema() {
    return {
      reportName: "",
      reportEmail: "",
    };
  }

  validationRules() {
    return {
      reportName: [
        { lib: "Custom", func: "NotEmpty", msg: "Name is required" },
      ],
      reportEmail: [
        { lib: "Custom", func: "NotEmpty", msg: "Email is required" },
        {lib:"Validator", func:"isEmail", msg:"This is not a valid email address"},
      ],
    };
  }
}

export default new Report();
