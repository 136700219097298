import General from "Helpers/General";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";

export class MyWishlistComponent extends Component {
  render() {
    const { productId } = this.props.wishlist;
    return (
      <div className="row">
        <div className="col-lg-12 listside_newperent px-3 mb-2">
          <div className="background-color">
            <div className="product-listing-product desktop_view">
              <div className="row">
                <div className="col-3">
                  <div className="product-img-bx">
                    <Link to={`/product/${productId?.productSlug}`}>
                      {productId?.adType && productId.adType === "Featured" && (
                        <div className="specialtags">
                          <span
                            className={
                              (productId.adType === "Featured"
                                ? "black"
                                : "green") + "_special"
                            }
                          >
                            {productId.adType}
                          </span>
                        </div>
                      )}
                      {productId.productMainImage ? (
                        <img
                          src={General.showImage(
                            "products/resize-180-180",
                            productId.productMainImage
                          )}
                          alt={productId.productName}
                        />
                      ) : (
                        <img src="/images/no-image.jpg" alt="product" />
                      )}
                      <div className="totalimg_sectab">
                        <Link
                          to={`/product/${productId.productSlug}`}
                          className="totlimg_qt"
                        >
                          {productId.productImages
                            ? productId.productImages.length
                            : 0}{" "}
                          <i className="fas fa-camera"></i>
                        </Link>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-9">
                  <div className="product-outer-bx productBig-outer-bx">
                    <div className="product-name-bx content_gobottom_new">
                      {/* <Moment fromNow>{productId.time}</Moment> */}
                      <Link to={`/product/${productId.productSlug}`}>
                        <h4>{productId.productName}</h4>
                      </Link>
                      <h5>{productId.location}</h5>
                      <h6>
                        <span>{General.showPriceDecimal(productId.productPrice)}</span>
                      </h6>
                      <p>
                        {productId.productDescription &&
                          productId.productDescription.substring(0, 50)}
                      </p>
                      <button
                        onClick={(e) =>
                          this.props.removeWishlistHandler(e, productId._id)
                        }
                        className="add-to-wishlist"
                      >
                        <i className="fas fa-heart"></i>
                      </button>
                      <div className="usednew_button">
                        <a href="#" className="btnnew_used">
                          New
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-listing-product mobile_view position-relative">
              <div className="product_listing_product_block">
                <div className="product-img-bx">
                  <Link to={`/product/${productId?.productSlug}`}>
                    {productId?.adType && productId.adType === "Featured" && (
                      <div className="specialtags">
                        <span
                          className={
                            (productId.adType === "Featured"
                              ? "black"
                              : "green") + "_special"
                          }
                        >
                          {productId.adType}
                        </span>
                      </div>
                    )}
                    {productId.productMainImage ? (
                      <img
                        src={General.showImage(
                          "products/resize-180-180",
                          productId.productMainImage
                        )}
                        alt={productId.productName}
                      />
                    ) : (
                      <img src="/images/no-image.jpg" alt="product" />
                    )}
                    <div className="totalimg_sectab">
                      <Link
                        to={`/product/${productId.productSlug}`}
                        className="totlimg_qt"
                      >
                        {productId.productImages
                          ? productId.productImages.length
                          : 0}{" "}
                        <i className="fas fa-camera"></i>
                      </Link>
                    </div>
                  </Link>
                </div>
                <div className="product-outer-bx productBig-outer-bx">
                  <div className="product-name-bx content_gobottom_new">
                    {/* <Moment fromNow>{productId.time}</Moment> */}
                    <Link to={`/product/${productId.productSlug}`}>
                      <h4 className="product_mobile_view">{productId.productName}</h4>
                    </Link>
                    <h5>{productId.location}</h5>
                    <h6>
                      <span>{General.showPriceDecimal(productId.productPrice)}</span>
                    </h6>
                    <p>
                      {productId.productDescription &&
                        productId.productDescription.substring(0, 50)}
                    </p>
                    <button
                      onClick={(e) =>
                        this.props.removeWishlistHandler(e, productId._id)
                      }
                      className="add-to-wishlist"
                    >
                      <i className="fas fa-heart"></i>
                    </button>
                    <div className="usednew_button usednew_button_mobile">
                      <a href="#" className="btnnew_used">
                        New
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MyWishlistComponent;
