import React, { Component } from "react";
import Page from "Models/Page";

class CommonPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: props.slug,
      staticContentData: localStorage.getItem("staticContentData")
        ? JSON.parse(localStorage.getItem("staticContentData"))
        : [],
    };
  }

  componentWillMount = () => {
    var self = this;
    Page.getApiByParams(
      "APIS_GET_CMS_PAGE",
      this.state.slug,
      async (response) => {
        localStorage.setItem("staticContentData", JSON.stringify(response));
        self.setState({ staticContentData: response });
      },
      false
    );
  };

  render() {
    const { staticContentData } = this.state;
    return (
      <React.Fragment>
        <section className="abouttop_banner tremsuse_banner">
          <div className="image_abtbanner">
            <img src="/images/terms-use-Bimg.png" alt="Image" />
            <div className="privacy_ply text-center">
              <h2 className="bannerheading">{staticContentData.pageTitle}</h2>
              <a href="#contentsecGo" className="btnx btn-secondaryx">
                View
              </a>
            </div>
          </div>
        </section>
        <section
          id="contentsecGo"
          className="repeat-section abouttop_sectwo padding_bottom150"
        >
          <div className="container">
            <div className="row rowfirst_about">
              <div className="col-sm-12">
                <div
                  dangerouslySetInnerHTML={{
                    __html: staticContentData.pageDescription,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default CommonPageComponent;
