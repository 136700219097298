import React, { Component } from "react";
import Storage from "Helpers/Storage";
import MyRecentViewComponent from "./MyRecentViewComponent";

export class RecentViewed extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount = () => {
    };

    render() {

        return (
            <>
                <div
                    className="tab-pane this_subRecentlyviewed active"
                    id="subtab05"
                    role="tabpanel"
                >
                    <div className="subtab_prodlist_NEW subtab_prodlist_NEW_forpadd">
                        <div className="container">
                            <div className="row">
                                <div
                                    className="col-lg-3 subtab_firstmain paddingrigt_none_Cfilter"
                                    id="subtab_thirdfixed"
                                >
                                    <div className="subcateleft_imgsec">
                                        <img
                                            src="/images/nolongthing01.png"
                                            alt="Image"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-9 subtab_secondmain">
                                    <div className="Jcompny_allprod">
                                        <div className="Jtaballp_content atlistingside_new">
                                            {JSON.parse(
                                                Storage.getLocalData("recentlyViewProducts")
                                            ) !== null ? (
                                                JSON.parse(
                                                    Storage.getLocalData(
                                                        "recentlyViewProducts"
                                                    )
                                                )
                                                    .slice(0, 10)
                                                    .map((product) => (
                                                        <MyRecentViewComponent
                                                            product={product}
                                                        />
                                                    ))
                                            ) : (
                                                <div className="alert alert-danger col-lg-12">
                                                    <strong>Info!</strong> Nothing here.
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default RecentViewed;
