import React from "react";
import Picker from "react-emojipicker";
import { Message } from "./Message";
import { FileIcon, defaultStyles } from "react-file-icon";
import "./PMessagesPanel.css";

// const messageEl = React.createRef(null);
export class MessagesPanel extends React.Component {
  state = {
    input_value: "",
    selectEmo: false,
    selectedFile: "",
    IsSelected: false,
  };

  // componentDidMount = () => {
  //   if (messageEl) {
  //     messageEl.current.addEventListener("DOMNodeInserted", (event) => {
  //       const { currentTarget: target } = event;
  //       target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  //     });
  //   }
  // };

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  send = () => {
    if (
      (this.state.input_value && this.state.input_value != "") ||
      this.state.selectedFile !== ""
    ) {
      this.props.onSendMessage(
        this.props.channel.userId,
        this.state.input_value,
        this.state.selectedFile
      );
      this.setState({ input_value: "" });
      this.setState({ selectedFile: "" });
      this.setState({ IsSelected: false });
    }
  };
  _onhandleChange = () => {
    const image1 = document.getElementById("validationCustom01").files[0];
    let idCardBase64 = "";
    this.getBase64(image1, (result) => {
      idCardBase64 = result;
      this.setState({
        selectedFile: {
          file: idCardBase64,
          name: image1.name,
          type: image1.type,
        },
      });
    });
    this.setState({ IsSelected: true });
  };

  handleInput = (e) => {
    this.setState({ input_value: e.target.value });
  };
  selectEmoji = () => {
    const currentState = this.state.selectEmo;
    this.setState({ selectEmo: !currentState });
  };
  logEmoji = (emoji) => {
    this.setState({ input_value: this.state.input_value + emoji.unicode });
  };

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (
        (this.state.input_value && this.state.input_value != "") ||
        this.state.selectedFile !== ""
      ) {
        this.props.onSendMessage(
          this.props.channel.userId,
          this.state.input_value,
          this.state.selectedFile
        );
        this.setState({ input_value: "" });
        this.setState({ selectedFile: "" });
        this.setState({ IsSelected: false });
      }
    }
  };

  render() {
    let list = "";
    if (this.props.DataValue && this.props.DataValue.length) {
      list = this.props.DataValue.map((m) => (
        <Message
          key={m._id}
          id={m._id}
          fromName={m.fromName}
          fromUserId={m.fromUserId}
          toUserId={m.toUserId}
          toName={m.toName}
          message={m.message}
          selectedchannel={this.props.channel._id}
          createdAt={m.createdAt}
          profilePicture={m.FromprofilePicture ? m.FromprofilePicture : ""}
          ToprofilePicture={m.ToprofilePicture ? m.ToprofilePicture : ""}
          mediaLinks={m.mediaLinks ? m.mediaLinks : ""}
        />
      ));
    } else {
      list = (
        // <div className="no-content-message">There is no messages to show</div>
        <p className="start_conversation">
          Once you start a new conversation, you’ll see it listed here.
        </p>
      );
    }
    return (
      // <div className="">
      // {
      this.props.channel && (
        <>
          <div className="p-2">
            <div className="sent_sidemain-top">{list}</div>
          </div>
          <div className="chatbox_footer .bg-light-dark">
            <div className="writesomt_full">
              <a href="#!" className="writesomt_smiley">
                <i className="far fa-smile" onClick={this.selectEmoji}>
                  {this.state.selectEmo ? (
                    <Picker onEmojiSelected={this.logEmoji} />
                  ) : (
                    ""
                  )}
                </i>
              </a>
              <div className="file-upload-button">
                <input
                  type="file"
                  name="image"
                  onChange={this._onhandleChange}
                  id="validationCustom01"
                />
                <button className="btn">
                  {this.state.IsSelected == true ? (
                    <i class="fa fa-paperclip" style={{ color: "red" }}></i>
                  ) : (
                    <i class="fa fa-paperclip" aria-hidden="true"></i>
                  )}
                </button>
              </div>
              {/* {this.state.IsSelected ? (<div>
					<p>{this.state.selectedFile.name}</p></div>):''} */}
              <input
                type="text"
                onKeyDown={this._handleKeyDown}
                onChange={this.handleInput}
                value={this.state.input_value}
                placeholder="Write something . . ."
              />
              <div className="fulsend_right">
                <button onClick={this.send}>
                  <i className="fas fa-paper-plane"></i>
                </button>
              </div>
            </div>
          </div>
        </>
      )
      // <div className="messages-input">
      //     <input type="text" onChange={this.handleInput} value={this.state.input_value} />
      //     <button onClick={this.send}>Send</button>
      // </div>
      //   }
      // </div>
    );
  }
}
