import Model from "Models/Model";

class AddProduct extends Model {
  dbSchema() {
    return {
      productName: "",
      productDescription: "",
      location: "",
      latitude: "",
      longitude: "",
      place_id: "",
      productMainImage: "",
      //recordType:"",
      productPrice: "",
      //sellerType:"",
      //liveStreamingStartTime:"",
      //liveStreamingEndTime:"",
      isShowMap: false,
      emailCheck: false,
      phoneCheck: false,
      email: "",
      phone:""
    };
  }

  validationRules() {
    return {
      productName: [
        { lib: "Custom", func: "NotEmpty", msg: "Title is required" },
      ],
      productDescription: [
        { lib: "Custom", func: "NotEmpty", msg: "Description is required" },
      ],
      /* location: [
        { lib: "Custom", func: "NotEmpty", msg: "Location is required" },
      ], */
      productPrice: [
        { lib: "Custom", func: "NotEmpty", msg: "Price is required" },
      ],
      delivery: [
        { lib: "Validator", func: "isInt", msg: "Delivery is required", option: { gt: 0 } },
      ],
      // sellerType: [
      //   { lib: "Custom", func: "NotEmpty", msg: "Seller type is required" },
      // ],
      
      // liveStreamingStartTime: [
      //   {
      //     lib: "Custom",
      //     func: "NotEmpty",
      //     msg: "liveStreamingStartTime is required",
      //   },
      // ],
      // liveStreamingEndTime: [
      //   {
      //     lib: "Custom",
      //     func: "NotEmpty",
      //     msg: "liveStreamingEndTime is required",
      //   },
      // ],
    };
  }
}

export default new AddProduct();
