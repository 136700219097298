  import React, { Component } from "react";
  import NewsLetter from "Models/NewsLetter";
  import Lodash from "lodash";

  class NewsLetterForm extends Component {
    constructor(props) {
      super(props);
      // this.state = NewsLetter.dbSchema()
      this.state = {
        headerLogo: localStorage.getItem("HeaderLogo"),
        email: "",
        isLoading: false
      }
    }

    componentDidMount = () => {
    }

    submitHandler = async(event) => {
      event.preventDefault();
      var self = this;
      if(await NewsLetter.checkValidation(await NewsLetter.validationRules(), this.state) === true){
        self.setState({isLoading: true})
        NewsLetter.postApi("APIS_NEWS_LETTER", this.state, async(response)=>{
          self.setState({email:""});
          self.setState({isLoading: true})
        }, false);
      }
    };

    onChangeHanlde = event => {
      this.setState({[event.target.name] : event.target.value});
    };

    render() {
      return (
        <>
        {this.state.isLoading && (
          <div class="loader-wrapper" id="loader_img">
            <div class="loader">
              <img src={this.state.headerLogo} alt="" />
              <div class="material-spinner"></div>
            </div>
          </div>
        )}
        <form onSubmit={this.submitHandler} method="post">
          <div className="newsletter-bx col-md-7">
            <div className="row justify-content-between">
              {/* <div className="col-lg-5">
                <h2>NEWSLETTER</h2>
                <p>Be the first to know about the latest news, sales and more!</p>
              </div> */}
              <div className="col-lg-12">
                <h6 className="font-weight-bold">NEWSLETTER</h6>
                <div className="newsletter-input">
                  <input type="email" value={this.state.email} onChange={this.onChangeHanlde} name="email" placeholder="Email Address . . ." />
                  <button type="submit"><i className="fas fa-paper-plane"></i></button>
                </div>
                  <p><small>Don’t worry we don’t spam</small></p>
              </div>
            </div>
          </div>
        </form>
        </>
      );
    }
  }
  export default NewsLetterForm;
