import Model from "Models/Model";

class Master extends Model{

	dbSchema(){
	    return {
	      _id:"", name:"", type:"", description:""
	    };
	}
}

export default new Master();