import React, { Component } from "react";
import { connect } from "react-redux";
import Moment from "moment";
import QB, { playCallAudio } from "Helpers/Quickblox";
import "emoji-mart/css/emoji-mart.css";

export class GroupMessageComponent extends Component {
  constructor(props) {
    super(props);
    this.msgDivRef = React.createRef();
    this.state = {
      loginUserId: localStorage.getItem("UserData")
      ? JSON.parse(localStorage.getItem("UserData"))["email"]
      : "",
      messages: [],
      textMessage: "",
      image: null,
      selectEmo: false,
    };
  }

  addEmoji = (emoji) => {
    this.setState({
      selectEmo: false,
      textMessage: this.state.textMessage + emoji.native,
    });
  };

  componentDidMount = () => {
    let dialogId = this.props.dialogId;
    this.joinPublicChatRoom(dialogId);
    QB.chat.onMessageListener = this.onMessage;
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.quickblox !== prevProps.quickblox
    ) {
      this.joinPublicChatRoom(this.props.dialogId);
    }
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onMessage = (userId, message) => {
    this.loadMessages(this.props.dialogId);
  };

  _handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.sendMessageHandler(e);
    }
  };

  joinPublicChatRoom = (channelId) => {
    if(this.props.quickblox){
        let self = this;
        try {
            QB.chat.muc.join(channelId, function(error, dialog) {
                if(!error){
                    self.loadMessages(dialog.dialogId);
                   // console.log('Joined the public chat'); 
                }else{
                    console.log('error not Joined the public chat');
                } 
            });
            
        } catch (error) {
            console.log('error not Joined the public chat',error);
        }
    }
  };

  loadMessages = (dialogId) => {
    let params = {
      chat_dialog_id: dialogId,
      sort_asc: "date_sent",
    };
    QB.chat.message.list(params, (err, messages) => {
      if (!err) {
        this.setState({ messages: messages.items });
        this.msgDivRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    });
  };

  sendMessageHandler = (e) => {
    e.preventDefault();
    if(this.state.textMessage.trim() != ""){
      let senderName = JSON.parse(localStorage.getItem("QB_USER")).full_name;
      let dialogId = this.props.dialogId;
        let message = {
          type: "groupchat",
          body: this.state.textMessage,
          extension: {
            save_to_history: 1,
            dialog_id: dialogId,
            senderName : senderName,
          },
          markable: 1
        };
        let dialogJid = QB.chat.helpers.getRoomJidFromDialogId(dialogId);
        try {
          message.id = QB.chat.send(dialogJid, message);
          this.setState({ textMessage: "" });
          this.loadMessages(dialogId);
        } catch (e) {
          console.log(e);
          if (e.name === "ChatNotConnectedError") {
            // not connected to chat
          }
        }
    }
  };

  /* getUserName = (userId) => {
    console.log(JSON.parse(localStorage.getItem("QB_USER")).full_name);
    let userName = QB.users.get(userId, function(error, user) {
      if(!error){
        return user.full_name;
      } 
    });
    return userName;
  } */

  render() {
    return (
        <div className="p-2">  
            <div className="sent_sidemain-top sent_sidemain-live-message-top">
                {this.state.messages.length ? (
                this.state.messages.map((msg,index) => (
                    <div key={index} className="msg-list" ref={this.msgDivRef}>
                    <div className="time_ofchat">
                        {Moment(msg.created_at).format("MMM Do, h:mm:ss a")}
                    </div>
                    {msg.sender_id ===
                    JSON.parse(localStorage.getItem("QB_USER")).id ? (
                        <div className="sent_sidemain_block">
                          <div className="senderBlock">
                          {msg.senderName}
                        </div>
                        <div className="myside_chat">
                            <div className="chat_textdesc">
                            <span className="chat_hello">{msg.message}</span>
                            </div>
                        </div>
                        </div>

                    ) : (
                        <div className="sent_sidemain_block">
                          <div className="receiverBlock">
                          {msg.senderName}
                        </div>
                        <div className="sent_sidemain">
                            <div className="chat_textdesc">
                            <span className="chat_hello">
                                <span>{msg.message}</span>
                            </span>
                            </div>
                        </div>
                        </div>
                    )}
                    </div>
                ))
                ) : (
                <p className="start_conversation">
                    Once you start a new conversation, you’ll see it listed here.
                </p>
                )}
            </div>
            <div className="chatbox_footer .bg-light-dark">
                <div className="writesomt_full">
                    <input
                    type="text"
                    onKeyPress={this._handleKeyPress}
                    onChange={this.handleInput}
                    value={this.state.textMessage}
                    name="textMessage"
                    autoComplete="off"
                    placeholder="Write something . . ."
                    />
                    <div className="fulsend_right">
                    <button onClick={this.sendMessageHandler}>
                        <i className="fas fa-paper-plane"></i>
                    </button>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

/* const mapStateToProps = (state) => {
  return {
    isOpenMessageBox: state.MESSAGE_BOX.isOpenMessageBox,
    msgCheck: state.MESSAGE_BOX.msgCheck,
    contactList: state.MESSAGE_BOX.contactList,
    selectedUser: state.MESSAGE_BOX.selectedUser.length
      ? state.MESSAGE_BOX.selectedUser[0].user
      : [],
    selectedChannel: state.MESSAGE_BOX.selectedChannel,
    messages: state.MESSAGE_BOX.selectedChannelData,
  };
};

export default connect(mapStateToProps, {
  toggleSelectedChannel,
  SetSelectedChannel1,
  SetSelectedUser,
  SetSelectedChannelData,
  SetOutgoingCallState,
  SetQuickbloxSession,
})(GroupMessageComponent); */

const mapStateToProps = (state) => {
   return {
    quickblox: state.QB_CONNECT.qbconnection,
  };
};
export default connect(mapStateToProps, {})(GroupMessageComponent);
