import React from "react";
import ReactDOM from "react-dom";
import App from "Components/App";
import { Provider } from "react-redux";
import { StoreReducer } from "Helpers/StoreReducer";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
  <React.StrictMode>
    {" "}
    <Provider store={StoreReducer}>
      <App />
    </Provider>{" "}
  </React.StrictMode>,
  document.getElementById("root")
);
serviceWorkerRegistration.register();
reportWebVitals();
