import React, { Component } from "react";
import {CardElement, Elements, ElementsConsumer, Billingu} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import Order from "Models/Order";
import Modal from "react-modal";
import { NoEncryption } from "@material-ui/icons";

class CardTokenForm extends Component {

    constructor(props) {
        super(props);
        this.state={
            isLoading:false
        }
    }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {stripe, elements, parentState, onSuccess, onClose, onLoading} = this.props;
    this.setState({isLoading:true});
    onLoading(true);

    if (elements == null) {
      return;
    }

    let buyer_id = Order.getAuthUserId();
    let liveStreamingId = parentState.liveStreamingId;
    let postageDetail = parentState.postageDetail;
    let productId = parentState.products._id;
    let allowedBidUser = parentState.allowedBidUser;
    let notify = parentState.notify;
    let isAllowNewsletter = parentState.isAllowNewsletter;

    const {err, token} = await stripe.createToken(elements.getElement(CardElement));
    if(!err){
        Order.postApi(
            "APIS_REGISTER_BID",
            {
                liveStreamingId: liveStreamingId,
                productId: productId,
                userId: buyer_id,
                deliveryAddress: postageDetail,
                token:token.id,
                allowedBidUser : allowedBidUser,
                notifyMe: notify,
                isAllowNewsletter: isAllowNewsletter,
            },
            async (response) => {
                if (response) {
                    onSuccess();
                }else{
                    onClose();
                    Order.toastAlert("error", "Something went wrong!");
                }
            },
            true
        );
    }else{
        onClose();
        Order.toastAlert("error", "Token failed!"); 
    }

  };

  render() {

    const CardElementOptions = {
      style:{
        base: {
          fontWeight: '600'
        }
      },
      hidePostalCode:true
    };
    return (  
      <div>
      <div className="heading_modalBlock">Authorization </div>
        <form onSubmit={this.handleSubmit}>
          <div className="row px-4 py-2">
            <div className="row">
            <div className="col-12">
                <div className="mb-1">
                <CardElement options={CardElementOptions}/>
                </div>
                <div className="sr_img">
                  <img src="/images/img-1.png" alt="Image"/>
                </div>
            </div>
    
            <div className="col-12 pt-3 border-top">
                <div className="form-group">
                <button type="submit" className="btn themeBtn" disabled={(this.state.isLoading)}>
                    Authorise
                </button>
                </div>
            </div>
            </div>
            </div>
        </form>
      </div>
    );
  }
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
};

const CardTokenPopUp = (props) => (
    <>
    <Modal
        isOpen={props.parentState['checkOutPopup']}
        onRequestClose={() =>
            props.onClose()
        }
        style={customStyles}
        ariaHideApp={false}
        className="shipModal stripePaymentModal"
    >
        <button onClick={() => props.onClose() } className="shipCloseBtn">
            <i className="fa fa-times"></i>
        </button>
        <Elements stripe={stripePromise}>
            <ElementsConsumer>
            {({stripe, elements}) => (
                <CardTokenForm stripe={stripe} elements={elements} parentState={props.parentState} onClose={props.onClose} onSuccess={props.onSuccess} onLoading={props.onLoading} />
            )}
            </ElementsConsumer>
        </Elements>
    </Modal>
    </>
);

export default CardTokenPopUp;