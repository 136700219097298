import React, { Component } from "react";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import Storage from "Helpers/Storage";
import Modal from "react-modal";

export class Security extends Component {

  constructor(props) {

      super(props);

      this.state = {
          userId: localStorage.getItem("UserData")
          ? JSON.parse(localStorage.getItem("UserData"))["_id"]
          : "",
          email: localStorage.getItem("UserData")
            ? JSON.parse(localStorage.getItem("UserData"))["email"]
            : "",
          mobileNumber: localStorage.getItem("UserData")
          ? JSON.parse(localStorage.getItem("UserData"))["mobileNumber"]
          : "",
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
          currentEmail: "",
          newEmail: "",
          confirmEmail: "",
          showChangePasswordModal: false,
          showChangeMobileModal : false,
          showChangeEmailModal: false,
          isLoading : false
      }

  }

  componentDidMount = () => {
    this.getUserByEmail();
  };

  getUserByEmail = () => {
    MyProfile.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        if (response) {
          console.log("resp",response)
          Storage.setLocalData("UserData", JSON.stringify(response));
          this.setState({
            userId: response._id,
            email: response.email,
            mobileNumber: response.mobileNumber,
          });
        }
      },
      true
    );
  };
  hideEmail = (email) => {
    console.log("email",email)
    return email.replace(/(.{2})(.*)(?=@)/, (match, firstPart, middlePart) => {
      let hiddenEmail =  firstPart + middlePart.replace(/./g, '*');
      console.log("hiddenEmail",hiddenEmail);
      return hiddenEmail;
  });
  };


  handleSubmitChangeEmail = async (event) => {
    event.preventDefault();
    const { currentEmail, newEmail, confirmEmail } = this.state;

    if (
      (await MyProfile.checkValidation(await MyProfile.validationRules(), {
        currentEmail,
        newEmail,
        confirmEmail,
      })) === true
    ) {
      this.setState({ isLoading: true });
      MyProfile.postApi(
        "API_UPDATE_EMAIL",
        {
          currentEmail,
          newEmail,
          confirmEmail
        },
        async (response, status) => {
          if (status == "success") {
            this.setState({
              email:confirmEmail,
              currentEmail: "",
              newEmail: "",
              confirmEmail: "",
            });
            this.setState({ isLoading: false, showChangeEmailModal : false });
          }else{
            this.setState({
              currentEmail: "",
              newEmail: "",
              confirmEmail: "",
            });
          }
        },
        true
      );
    }
  };

  handleSubmitChangePassword = async (event) => {
    event.preventDefault();
    const { currentPassword, newPassword, confirmPassword, email } = this.state;

    if (
      (await MyProfile.checkValidation(await MyProfile.validationRules(), {
        currentPassword,
        newPassword,
        confirmPassword,
      })) === true
    ) {
      this.setState({ isLoading: true });
      MyProfile.postApi(
        "API_UPDATE_PASSWORD",
        {
          currentPassword,
          newPassword,
          confirmPassword,
          email,
        },
        async (response, err) => {
          if (response) {
            this.setState({
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            });
            this.setState({ isLoading: false, showChangePasswordModal : false });
          }
        },
        true
      );
    }
  };

  // handleSubmitNewEmail = async (event) => {
  //   event.preventDefault();
  //   const { email } = this.state;

  //   if (
  //     (await MyProfile.checkValidation(await MyProfile.validationRules(), {
  //       email
  //     })) === true
  //   ) {
  //     this.setState({ isLoading: true });
  //     MyProfile.postApi(
  //       "API_UPDATE_ACCOUNT_DETAIL",
  //       {
  //         email,
  //       },
  //       async (response, err) => {
  //         if (response) {
  //           this.setState({ isLoading: false, showChangeEmailModal : false });
  //         }
  //       },
  //       true
  //     );
  //   }
  // };

  handleSubmitMobileNumber = async (event) => {
    event.preventDefault();
    const { mobileNumber } = this.state;

    if (
      (await MyProfile.checkValidation(await MyProfile.validationRules(), {
        mobileNumber
      })) === true
    ) {
      this.setState({ isLoading: true });
      MyProfile.postApi(
        "API_UPDATE_ACCOUNT_DETAIL",
        {
          mobileNumber,
        },
        async (response, err) => {
          if (response) {
            this.setState({ isLoading: false, showChangeMobileModal : false });
          }
        },
        true
      );
    }
  };

  handleMyProfileChangePassword = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleMyProfileChangeEmail = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleMyProfileUpdate = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };


  render() {

    const { isLoading, showChangePasswordModal, showChangeMobileModal, showChangeEmailModal } = this.state;

    return (
      <>
       <div
        className="tab-pane allcom_rodtab active"
        id="subtab023"
        role="tabpanel"
        >
        <div className="subtab_prodlist_NEW_forpadd">
            <div className="container">
            <div className="row">
                <div
                className="col-lg-3 subtab_firstmain noneat_mobile paddingrigt_none_Cfilter d-none d-md-block"
                id="subtab_secondfixed"
                >
                <div className="subcateleft_imgsec">
                    <img
                    src="/images/account-settings-limg.png"
                    alt="Image"
                    />
                </div>
                </div>
                <div className="col-lg-9 subtab_secondmain">
                  <div className="formnotification_inner withbg_white border-radius">
                    <div className="noti_enable repeatSec_innerr border_bott_none padding-bottom">
                      {/* <div className="box_perentrepet">
                        <div className="contdetail_group formcontdetail_inner_mobile">
                            <div className="contact_label">
                            PHONE VERIFICATION{" "}
                            <p>{this.state.mobileNumber ? this.state.mobileNumber : "**********"}</p>
                            </div>
                            <div className="cont_fuild paddingleft_mobilonly">
                            Your phone is not verified with
                            dBoroll. Click verify now to complete
                            phone verification.
                            </div>
                        </div>
                        <div className="contdetail_group">
                            <div className="bottonsend text-right">
                            <a
                                href="#"
                                className="btnx btn-secondaryx"
                            >
                                Verify Now
                            </a>
                            </div>
                            <div className="bottonsend text-right">
                              <button 
                                className="btnx btn-secondaryx"
                                onClick={() =>
                                  this.setState({
                                    showChangeMobileModal: true,
                                  })
                                }
                              >
                                Change
                              </button>
                            </div>
                        </div>
                      </div> */}
                    </div>

                    <div className="noti_enable repeatSec_innerr border_bott_none padding-top">
                      <div className="box_perentrepet">
                        <div className="contdetail_groups">
                            <div className="d-flex flex-wrap justify-content-between">
                              <p className="contact_label mailLabel ">{this.hideEmail(this.state.email)}</p>
                              <button 
                                className="btnx btn-secondaryx font-size-14"
                                onClick={() =>
                                  this.setState({
                                    showChangeEmailModal: true,
                                  })
                                }
                              >
                              Change
                              </button>
                            </div>
                        </div>
                      </div>
                    </div>
                    <div className="noti_enable repeatSec_innerr border_bott_none">
                      <div className="box_perentrepet">
                        <div className="contdetail_groups">
                            <div className="d-flex justify-content-between">
                              <p className="contact_label">Change Password</p>
                              <button 
                                className="btnx btn-secondaryx font-size-14" 
                                onClick={() =>
                                  this.setState({
                                    showChangePasswordModal: true,
                                  })
                                }
                              >
                                Change
                              </button>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        <Modal
          isOpen={showChangePasswordModal}
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ showChangePasswordModal: false })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
          className="shipModal"
        >
          <button onClick={() =>this.setState({ showChangePasswordModal: false }) } className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
              <div
                className="col-lg-12 listside_newperent"
              >
               <form
                    onSubmit={this.handleSubmitChangePassword}
                >
                    <div className="formnotification_inner withbg_white border-radius">
                    <div className="noti_enable">
                        <h3 className="titleof_tbasec">
                        CHANGE PASSWORD
                        </h3>
                        <div className="box_perentrepet">
                        <div className="contdetail_groups">
                            <div className="contact_labels">
                            Current Password
                            </div>
                            <div className="cont_fuilds">
                            <input
                                type="password"
                                onChange={
                                this.handleMyProfileChangePassword
                                }
                                name="currentPassword"
                                value={this.state.currentPassword}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        <div className="contdetail_groups">
                            <div className="contact_labels">
                            New Password
                            </div>
                            <div className="cont_fuilds">
                            <input
                                type="password"
                                onChange={
                                this.handleMyProfileChangePassword
                                }
                                name="newPassword"
                                value={this.state.newPassword}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        <div className="contdetail_groups">
                            <div className="contact_labels">
                            Confirm Password
                            </div>
                            <div className="cont_fuilds">
                            <input
                                type="password"
                                onChange={
                                this.handleMyProfileChangePassword
                                }
                                name="confirmPassword"
                                value={this.state.confirmPassword}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        <div className="contdetail_group">
                            <div className="cont_fuilds">
                            8 characters or longer. Combine
                            upper and lowercase letters and
                            numbers.
                            </div>
                        </div>
                        <div className="contdetail_group">
                            <div className="bottonsend text-center">
                            <button
                                type="submit"
                                className="btnx btn-secondaryx"
                            >
                                Save Changes
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </form>
              </div>
        </Modal>
        <Modal
          isOpen={showChangeEmailModal}
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ showChangeEmailModal: false })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
          className="shipModal"
        >
          <button onClick={() =>this.setState({ showChangeEmailModal: false }) } className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
              <div
                className="col-lg-12 listside_newperent"
              >
               <form
                    onSubmit={this.handleSubmitChangeEmail}
                >
                    <div className="formnotification_inner withbg_white border-radius">
                    <div className="noti_enable">
                        <h3 className="titleof_tbasec">
                        CHANGE Email
                        </h3>
                        <div className="box_perentrepet">
                        <div className="contdetail_groups">
                            <div className="contact_labels">
                            Current Email
                            </div>
                            <div className="cont_fuilds">
                            <input
                                type="email"
                                onChange={
                                this.handleMyProfileChangeEmail
                                }
                                name="currentEmail"
                                value={this.state.currentEmail}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        <div className="contdetail_groups">
                            <div className="contact_labels">
                            New Email
                            </div>
                            <div className="cont_fuilds">
                            <input
                                type="email"
                                onChange={
                                this.handleMyProfileChangeEmail
                                }
                                name="newEmail"
                                value={this.state.newEmail}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        <div className="contdetail_groups">
                            <div className="contact_labels">
                            Confirm Email
                            </div>
                            <div className="cont_fuilds">
                            <input
                                type="email"
                                onChange={
                                this.handleMyProfileChangeEmail
                                }
                                name="confirmEmail"
                                value={this.state.confirmEmail}
                                className="social_red_fuild"
                            />
                            </div>
                        </div>
                        {/* <div className="contdetail_group">
                            <div className="cont_fuilds">
                            8 characters or longer. Combine
                            upper and lowercase letters and
                            numbers.
                            </div>
                        </div> */}
                        <div className="contdetail_group">
                            <div className="bottonsend text-center">
                            <button
                                type="submit"
                                className="btnx btn-secondaryx"
                            >
                                Save Changes
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </form>
              </div>
        </Modal>
        <Modal
          isOpen={showChangeMobileModal}
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ showChangeMobileModal: false })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
          className="shipModal"
        >
          <button onClick={() =>this.setState({ showChangeMobileModal: false }) } className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
              <div
                className="col-lg-12 listside_newperent"
              >
               <form
                    onSubmit={this.handleSubmitMobileNumber}
                >
                    <div className="formnotification_inner withbg_white border-radius">
                    <div className="noti_enable">
                        <h3 className="titleof_tbasec">
                        CHANGE MOBILE NUMBER
                        </h3>
                        <div className="box_perentrepet">
                          <div className="contdetail_group">
                              <div className="contact_label">
                                  Phone No<sup>*</sup>
                              </div>
                              <div className="cont_fuild">
                                  <input
                                  onChange={
                                      this.handleMyProfileUpdate
                                  }
                                  name="mobileNumber"
                                  type="number"
                                  value={this.state.mobileNumber}
                                  className="social_red_fuild"
                                  />
                              </div>
                          </div>
                        <div className="contdetail_group">
                            <div className="bottonsend text-center">
                            <button
                                type="submit"
                                className="btnx btn-secondaryx"
                            >
                                Save Changes
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </form>
              </div>
        </Modal>
        {/* <Modal
          isOpen={showChangeEmailModal}
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ showChangeEmailModal: false })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
          className="shipModal"
        >
          <button onClick={() =>this.setState({ showChangeEmailModal: false }) } className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
              <div
                className="col-lg-12 listside_newperent"
              >
               <form
                    onSubmit={this.handleSubmitNewEmail}
                >
                    <div className="formnotification_inner withbg_white border-radius">
                    <div className="noti_enable">
                        <h3 className="titleof_tbasec">
                        CHANGE EMAIL
                        </h3>
                        <div className="box_perentrepet">
                        <div className="contdetail_groups">
                            <div className="contact_labels">
                                Email<sup>*</sup>
                            </div>
                            <div className="cont_fuilds">
                                <input
                                onChange={
                                    this.handleMyProfileUpdate
                                }
                                name="email"
                                type="text"
                                value={this.state.email}
                                className="social_red_fuild"
                                />
                            </div>
                        </div>
                        <div className="contdetail_group">
                            <div className="bottonsend text-center">
                            <button
                                type="submit"
                                className="btnx btn-secondaryx"
                            >
                                Save Changes
                            </button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </form>
              </div>
        </Modal> */}
      </>
    );
  }
}

export default Security;
