const QB = require("quickblox/quickblox.min");

// var CREDENTIALS = {
//   appId: process.env.REACT_APP_QUICKBLOX_ID,
//   authKey: process.env.REACT_APP_QUICKBLOX_AUTH_KEY,
//   authSecret: process.env.REACT_APP_QUICKBLOX_SECRET,
//   accountKey: process.env.REACT_APP_QUICKBLOX_ACCOUNT_KEY,
// };

/* var CREDENTIALS = {
  appId: 97949,
  authKey: "9ASf8TU-vgG2Tnn",
  authSecret: "sm3hcXgsOr7pqqG",
  accountKey: "nSWrBRJ_KYDd7JZckNB4",
}; */

/* var CREDENTIALS = {
  appId: 98223,
  authKey: "cR3VxT4M6jrr4Ch",
  authSecret: "SK3pbAk8VX8cvwp",
  accountKey: "gxTKiZMUW5eKCg1UeJMK",
}; */



// Provided by Mahi.........
// var CREDENTIALS = {
//   appId: 99804,
//   authKey: "PkUukN5wY-SgDaW",
//   authSecret: "vpn-gNt59wUOp9S",
//   accountKey: "naZtDywzqm2Ciizo3DHX",
// };

var CREDENTIALS = {
  appId: 103112,
  authKey: "ak_Lm6jHNBFPAmA3F2",
  authSecret: "as_SXr6jP3CJ6WEjKA",
  accountKey: "ack_xdf4KBsNq-yXZ_f14Mbp",
};



var CONFIG = {
  debug: { mode: 0 },
  webrtc: {
    answerTimeInterval: 60,
    autoReject: false,
    incomingLimit: 5,
    dialingTimeInterval: 5,
    disconnectTimeInterval: 30,
    statsReportTimeInterval: true,
    }
};

QB.init(
  CREDENTIALS.appId,
  CREDENTIALS.authKey,
  CREDENTIALS.authSecret,
  CREDENTIALS.accountKey,
  CONFIG
);

var alertSound = new Audio(
  `${process.env.REACT_APP_FRONTEND_URL}/samples_webrtc_audio_calling.mp3`
);
alertSound.loop = true;

var incomingCallTone = new Audio(
  `${process.env.REACT_APP_FRONTEND_URL}/samples_webrtc_audio_ringtone.mp3`
);
incomingCallTone.loop = true;

export function playCallAudio() {
  alertSound.play();
}

export function stopCallAudio() {
  alertSound.pause();
}

export function incomingCallRingtone(mode) {
  if (mode) {
    incomingCallTone.play();
  } else {
    incomingCallTone.pause();
  }
}

export function playEndCallAudio() {
  new Audio(
    `${process.env.REACT_APP_FRONTEND_URL}/samples_webrtc_audio_end_of_call.mp3`
  ).play();
}

export default QB;
