import React, { Component } from "react";
import MyTotalBalance from "./MyTotalBalance";
import MyPendingTransaction from "./MyPendingTransaction";
import ActivateComponent from "./ActivateComponent";

export class Balance extends Component {

    constructor(props) {

        super(props);

        this.state = {
            userId: localStorage.getItem("UserData")
            ? JSON.parse(localStorage.getItem("UserData"))["_id"]
            : "",
            tabView : 'total_balance'
        }

    }

    handleTabes = (tab) => {
        this.setState({
            tabView : tab
        });
    }

    render() {

        const {tabView} = this.state;
        const { updateValue } = this.props;

        return (
            <React.Fragment>
                <div
                    id="myownlevel"
                    role="tabpanel"
                >
                    <div className="product-all-detail-tab balance_tab">
                        <ul
                        className="nav nav-tabs my-custom-tab cuttan_newimg specialshap_likedetailP"
                        role="tablist"
                        >
                            <li className="nav-item specialshap_item">
                                <a
                                    className="nav-link active in"
                                    data-toggle="tab"
                                    herf={void(0)}
                                    role="tab"
                                    onClick={() => this.handleTabes('total_balance')}
                                >
                                    <span> Total Balance </span>
                                </a>
                            </li>
                            <li className="nav-item specialshap_item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    herf={void(0)}
                                    role="tab"
                                    onClick={() => this.handleTabes('pending_transactions')}
                                >
                                    <span> Pending Transaction</span>
                                </a>
                            </li>
                            {/* <li className="nav-item specialshap_item">
                                <a
                                    className="nav-link"
                                    data-toggle="tab"
                                    herf={void(0)}
                                    role="tab"
                                    onClick={() => this.handleTabes('activate')}
                                >
                                    <span> Activate</span>
                                </a>
                            </li> */}
                        </ul>
                    </div>
         
                    <div className="tab-content subtab_tabcont">
                        { (tabView === 'total_balance') ? 
                            <MyTotalBalance handleTabes={this.handleTabes}/>
                            : <MyPendingTransaction/> 
                        }
                    </div>

                </div>

            </React.Fragment>
        );
    }
}

export default Balance;
