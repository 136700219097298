import Model from "Models/Model";

class LiveStreamingBid extends Model {
  dbSchema() {
    return {
      amount: "",
    };
  }

  validationRules() {
    return {
      amount: [
        { lib: "Custom", func: "NotEmpty", msg: "Amount is required" },
        { lib: "Validator", func: "isNumeric", msg: "Amount must be numeric" },
      ],
    };
  }
}

export default new LiveStreamingBid();
