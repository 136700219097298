import React, { Component } from "react";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import User from "Models/User";
import Storage from "Helpers/Storage";
import Lodash from "lodash";
import Moment from "react-moment";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import axios from "axios";
import formData from "form-data";
import ApiEndPoints from "Helpers/ApiEndPoints";
import $ from "jquery";
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import TwitterLogin from "react-twitter-auth";

export class ContactDetail extends Component {

  constructor(props) {

    super(props);

    this.state = {
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      firstName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["firstName"]
        : "",
      lastName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["lastName"]
        : "",
      companyName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["companyName"]
        : "",
      email: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["email"]
        : "",
      username: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["username"]
        : "",
      mobileNumber: "",
      city: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["city"]
        : "",
      state: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["state"]
        : "",
      address: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["address"]
        : "",
      gender: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["gender"]
        : 0,
      sellerType: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["sellerType"]
        : "",
      businessType: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["businessType"]
        : "",
      deliveryAddress: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["deliveryAddress"]
        : "",
      registrationNumber: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["registrationNumber"]
        : "",
      profilePicture: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["registrationNumber"]
        : "",
      about: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["about"]
        : "",
      latitude: "",
      longitude: "",
      location: "",
      place_id: "",
      remainingAboutChars: JSON.parse(localStorage.getItem("UserData"))["about"] ? 200 - JSON.parse(localStorage.getItem("UserData"))["about"].length : 200,
      facebookProvider: "",
      twitterProvider: "",
      isLoading: false
    }

  }

  componentDidMount = () => {
    this.getUserByEmail();
  };


  getUserByEmail = () => {
    MyProfile.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        if (response) {
          console.log("--", response);
          Storage.setLocalData("UserData", JSON.stringify(response));
          this.setState({
            userId: response._id,
            firstName: response.firstName,
            lastName: response.lastName,
            companyName: response.companyName,
            email: response.email,
            username: response.username,
            mobileNumber: response.mobileNumber,
            city: response.city,
            state: response.state,
            address: response.address,
            gender: response.gender,
            about: response.about,
            sellerType: response.sellerType,
            profilePicture: response.profilePicture
              ? response.profilePicture
              : "",
            deliveryAddress: response.deliveryAddress,
            registrationNumber: (response?.registrationNumber) ? response.registrationNumber : "",
            latitude: response.latitude,
            longitude: response.longitude,
            location: response.location,
            place_id: response.place_id,
            facebookProvider: response.facebookProvider
              ? response.facebookProvider
              : "",
            twitterProvider: response.twitterProvider
              ? response.twitterProvider
              : "",
          });
          console.log("sellerType", this.state.sellerType);
        }
      },
      true
    );
  };

  handleProfileImage = async (e) => {
    e.preventDefault();
    var apiEndPoint = ApiEndPoints.getEndPoint("APIS_UPLOAD_PROFILE_IMAGE");
    var fmData = new formData();
    fmData.append("profileImage", e.target.files[0]);
    var config = {
      method: "post",
      url: apiEndPoint,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-access-token": MyProfile.getToken(),
      },
      data: fmData,
    };

    await axios(config).then((response) => {
      if (response.data.success) {
        MyProfile.toastAlert("success", "Profile Successfully uploaded");
        this.setState({ profilePicture: response.data.filename });
      } else {
        MyProfile.toastAlert("error", "Something went wrong");
      }
    });
  };

  handleSubmitMyDetails = async (event) => {
    event.preventDefault();
    const { firstName, lastName, companyName, email, username, mobileNumber, deliveryAddress, sellerType } = this.state;
    if (this.state.profilePicture.trim() == "") {
      document.getElementById("profileImg").style.display = "block";
      return;
    } else {
      document.getElementById("profileImg").style.display = "none";
    }
    if (
      (await MyProfile.checkValidation(await MyProfile.validationRules(), {
        firstName,
        lastName,
        companyName,
        email,
        sellerType,
        username
      })) === true
    ) {
      this.setState({ isLoading: true });
      MyProfile.postApi(
        "API_UPDATE_USER_BY_EMAIL",
        {
          id: this.state.userId,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          companyName: this.state.companyName,
          email: this.state.email,
          username: this.state.username,
          mobileNumber: this.state.mobileNumber,
          state: this.state.state,
          city: this.state.city,
          deliveryAddress: this.state.deliveryAddress,
          gender: this.state.gender,
          about: this.state.about,
          sellerType: this.state.sellerType,
          latitude: this.state.latitude,
          longitude: this.state.longitude,
          location: this.state.location,
          place_id: this.state.place_id,
        },
        async (response) => {
          this.getUserByEmail();
          this.setState({ isLoading: false });
        },
        true
      );
    }
  };

  handleMyProfileContactDetails = (event) => {
    if (event.target.name == "about") {
      if (event.target.value.length <= 200) {
        this.setState({
          remainingAboutChars: 200 - event.target.value.length
        });
      }
    }
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChange = (address) => {
    this.setState({ location: address });
  };

  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      const place_id = results[0].place_id;
      const address = results[0].formatted_address;
      getLatLng(results[0])
        .then((latLng) => {
          this.setState({
            latitude: latLng.lat,
            longitude: latLng.lng,
            location: address,
            place_id: place_id
          });
        })
        .catch((error) => console.error("Error", error));
    });
  };

  responseFacebook = (response) => {
    if (Lodash.has(response, "accessToken")) {
      MyProfile.postApi(
        "APIS_FACEBOOK_CONNECT_ACCOUNT",
        response,
        async (response) => {
          if (response) {
            this.setState({ facebookProvider: response });
          } else {
            MyProfile.toastAlert(
              "error",
              "You are failed to connect with Facebook, Please try again."
            );
          }
        },
        true
      );
    } else {
      MyProfile.toastAlert(
        "error",
        "You are failed to login with Facebook, Please try again."
      );
    }
  };

  onSuccessTwitter = (response) => {

    response.json().then((user) => {

      if (Lodash.has(user, "_id")) {
        User.toastAlert("success", "Twitter Account Connected!");
        this.setState({ twitterProvider: user });
      } else {
        if (Lodash.has(user, "error")) {
          User.toastAlert("error", user.message);
        } else {
          User.toastAlert(
            "error",
            "You are failed to login with Twitter, Please try again."
          );
        }
      }
    });
  };

  onFailedTwitter = (error) => {
    User.toastAlert(
      "error",
      "You are failed to login with Twitter, Please try again."
    );
  };
  // Social Connect (Twitter) ::End


  render() {
    var context = this;
    const { facebookProvider, twitterProvider, remainingAboutChars, sellerType } = this.state;
    console.log("---sellerType", this.state.sellerType, sellerType);
    return (
      <>
        <div
          className="tab-pane allcom_rodtab active"
          id="subtab011"
          role="tabpanel"
        >
          <div className="subtab_prodlist_NEW_forpadd">
            <div className="formof_contdetails">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-3 subtab_firstmain noneat_mobile paddingrigt_none_Cfilter "
                    id="subtab_secondfixed"
                  >
                    <div className="img_profileSec text-right img_profileSec_mobile">
                      <div className="img_block_container">
                        <div className="img_block">
                          {this.state.profilePicture ? (
                            <img
                              src={General.showImageNew(
                                "profile",
                                this.state.profilePicture
                              )}
                              onError={
                                (e) => e.target.src = '/images/no-image.jpg'
                              }
                              alt={this.state.profilePicture}
                            />
                          ) : (
                            <img
                              src="/images/no-image.jpg"
                              alt="image"
                            />
                          )}
                        </div>
                        <p
                          id={"profileImg"}
                          className="text-danger text-center discount-option-required"
                          style={{
                            display: "none",
                          }}
                        >
                          Profile Image is required.
                        </p>
                      </div>
                      <div className="file_upload_btn">
                        <input
                          id="fileupload"
                          onChange={this.handleProfileImage}
                          type="file"
                          className="fuilddesh"
                          name="img"
                          placeholder="Product Images"
                        />
                        <label
                          htmlFor="fileupload"
                          className="number-reveal-btn right_img mb-0"
                        >
                          <span>
                            Upload File
                            <img
                              src="/images/edit-icon.png"
                              alt="images"
                            />
                          </span>
                        </label>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="col-lg-9 subtab_secondmain">
                    <form onSubmit={this.handleSubmitMyDetails}>
                      <div className="formcontdetail_inner withbg_white p-3 formcontdetail_inner_mobile">
                        <div className="contdetail_group">
                          <div className="contact_label">
                            First Name<sup>*</sup>
                          </div>
                          <div className="cont_fuild">
                            <input
                              onChange={
                                this.handleMyProfileContactDetails
                              }
                              name="firstName"
                              type="text"
                              value={this.state.firstName}
                              className="social_red_fuild"
                            />
                          </div>
                        </div>
                        <div className="contdetail_group">
                          <div className="contact_label">
                            Last Name<sup>*</sup>
                          </div>
                          <div className="cont_fuild">
                            <input
                              onChange={
                                this.handleMyProfileContactDetails
                              }
                              name="lastName"
                              type="text"
                              value={this.state.lastName}
                              className="social_red_fuild"
                            />
                          </div>
                        </div>
                        <div className="contdetail_group">
                          <div className="contact_label">
                            Company Name<sup>*</sup>
                          </div>
                          <div className="cont_fuild">
                            <input
                              onChange={
                                this.handleMyProfileContactDetails
                              }
                              name="companyName"
                              type="text"
                              value={this.state.companyName}
                              className="social_red_fuild"
                            />
                          </div>
                        </div>
                        <div className="contdetail_group">
                          <div className="contact_label">
                            Username<sup>*</sup>
                          </div>
                          <div className="cont_fuild">
                            <input
                              onChange={
                                this.handleMyProfileContactDetails
                              }
                              name="username"
                              type="text"
                              value={this.state.username}
                              className="social_red_fuild"
                            />
                          </div>
                        </div>
                        <div className="contdetail_group">
                          <div className="contact_label">
                            About
                          </div>
                          <div className="cont_fuild">
                            <textarea
                              name="about"
                              onChange={
                                this.handleMyProfileContactDetails
                              }
                              value={this.state.about}
                              rows="2"
                              placeholder="About.."
                              className="form-control"
                              maxLength="200"
                            ></textarea>
                            <p className="font-size-14">{remainingAboutChars} characters remaining</p>
                          </div>
                        </div>
                        <div className="contdetail_group">
                          <div className="contact_label">
                            Location
                          </div>
                          <div className="cont_fuild select_location">
                            <PlacesAutocomplete
                              searchOptions={{
                                componentRestrictions: {
                                  country: "UK",
                                },
                              }}
                              value={this.state.location}
                              onChange={this.handleChange}
                              onSelect={this.handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <div className=" location-box">
                                    <input
                                      {...getInputProps({
                                        placeholder: "Location",
                                        className: "location-search-input social_red_fuild",
                                        name: "location"
                                      })}
                                    />
                                    <span className="location-icon"> <i class="fas fa-map-marker-alt"></i></span>
                                  </div>

                                  <div className="autocomplete-dropdown-container">
                                    <div>
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                          ? "suggestion-item--active"
                                          : "suggestion-item";
                                        const style = suggestion.active
                                          ? {
                                            backgroundColor: "#fafafa",
                                            cursor: "pointer",
                                          }
                                          : {
                                            backgroundColor: "#ffffff",
                                            cursor: "pointer",
                                          };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                            key={suggestion.index}
                                          >
                                            <span>{suggestion.description}</span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </div>
                        <div className="contdetail_group">
                          <div className="contact_label">
                            Gender
                          </div>
                          <div className="cont_fuild">
                            <div className="custom_cheak mt-3 onlyformob_seller">
                              <ul>
                                <li>
                                  <div className="check-box big">
                                    <input
                                      onChange={this.handleMyProfileContactDetails}
                                      className="form-control-line New_control"
                                      type="radio"
                                      value={1}
                                      defaultChecked={this.state.gender === 1}
                                      name="gender"
                                      id="male"
                                    />
                                    <label htmlFor="male">Male</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box big">
                                    <input
                                      onChange={this.handleMyProfileContactDetails}
                                      className="form-control-line New_control"
                                      type="radio"
                                      value={2}
                                      name="gender"
                                      defaultChecked={this.state.gender === 2}
                                      id="female"
                                    />
                                    <label htmlFor="female">Female</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="contdetail_group">
                          <div className="contact_label">
                            Seller type
                          </div>
                          <div className="cont_fuild">
                            <div className="custom_cheak mt-3 onlyformob_seller">
                              <ul>
                                <li>
                                  <div className="check-box big">
                                    <input
                                      onChange={context.handleMyProfileContactDetails}
                                      className="form-control-line New_control"
                                      type="radio"
                                      value="1"
                                      defaultChecked={sellerType === 1}
                                      name="sellerType"
                                      id="Company"
                                    />
                                    <label htmlFor="Company">Company</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box big">
                                    <input
                                      onChange={context.handleMyProfileContactDetails}
                                      className="form-control-line New_control"
                                      type="radio"
                                      value="2"
                                      defaultChecked={sellerType === 2}
                                      name="sellerType"
                                      id="Soletrader"
                                    />
                                    <label htmlFor="Soletrader">Soletrader</label>
                                  </div>
                                </li>
                                <li>
                                  <div className="check-box big">
                                    <input
                                      onChange={context.handleMyProfileContactDetails}
                                      className="form-control-line New_control"
                                      type="radio"
                                      value="3"
                                      name="sellerType"
                                      defaultChecked={sellerType === 3}
                                      id="Private"
                                    />
                                    <label htmlFor="Private">Private</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <p
                                id={"sellerType"}
                                className="text-danger discount-option-required"
                                style={{
                                  display: "none",
                                }}
                              >
                                Please fill all company detail on company information page.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="contdetail_group">
                          <div className="bottonsend text-center">
                            <button
                              type="submit"
                              className="btnx btn-secondaryx font-size-16"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="socialnetywiork_inner withbg_white border-radius mt-3">
                      <div className="socialnetw_bx">
                        <div className="leftsocial">
                          <i className="fab fa-facebook-f"></i>
                          <span>Facebook</span>
                        </div>
                        <div className="righttsocial">
                          <div className="bottonsend">
                            {!facebookProvider && (
                              <FacebookLogin
                                appId="1608150026042237"
                                autoLoad={false}
                                fields="name,email,picture"
                                callback={this.responseFacebook}
                                render={(renderProps) => (
                                  <button
                                    onClick={renderProps.onClick}
                                    className="btnx btn-secondaryx font-size-16"
                                  >
                                    Connect
                                  </button>
                                )}
                              />
                            )}
                            {facebookProvider && (
                              <Link className="btnx btn-secondaryx font-size-16">
                                Connected
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="socialnetw_bx">
                        <div className="leftsocial">
                          <i className="fab fa-twitter"></i>
                          <span>Twitter</span>
                        </div>
                        <div className="righttsocial">
                          <div className="bottonsend">
                            {!twitterProvider ? (
                              <TwitterLogin
                                loginUrl={ApiEndPoints.getEndPoint(
                                  "APIS_TWITTER_CONNECT_AUTH"
                                )}
                                onFailure={this.onFailedTwitter}
                                onSuccess={this.onSuccessTwitter}
                                requestTokenUrl={ApiEndPoints.getEndPoint(
                                  "APIS_TWITTER_CONNECT_CALLBACK"
                                )}
                                text="connect"
                                showIcon={false}
                                customHeaders={{
                                  userId:
                                    MyProfile.getAuthUserId(),
                                }}
                                className="btnx btn-secondaryx font-size-16"
                              />
                            ) : (
                              <Link className="btnx btn-secondaryx font-size-16">
                                Connected
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ContactDetail;
