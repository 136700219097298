import React, { Component } from "react";
import {CardElement, Elements, ElementsConsumer, Billingu} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { toBePartiallyChecked } from "@testing-library/jest-dom/dist/matchers";
import Order from "Models/Order";

class StripePayment extends Component {

  constructor(props) {
      super(props);
      this.state={
          isLoading:false,
          shippingAddress:this.props.orderDetail?.shippingAddress,
      }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {stripe, elements, orderDetail, paymentHandler, onLoading} = this.props;

    if (elements == null) {
      return;
    }

    if(e.target.shippingAddress.value.trim() === ""){
      document.getElementById("shipping-address").style.display = "block";
      return;
    }

    this.setState({isLoading:true});
    onLoading(true);

    let shippingAddress =e.target.shippingAddress.value;
    let buyer_id = orderDetail.id;
    let total_amount = orderDetail.paymentAmount;

    let intentId;
    await Order.postApi(
        "API_STRIPE_INTENT",
        {
          user_id: buyer_id,
          transaction_type:"product",
          total_amount:total_amount
        },
        async (response,status) => {
          if (status == "success") {
            intentId = response; 
          }
        },
        true
      );

      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
      });

      if(error){
          this.setState({isLoading:false});
          onLoading(false);
          return;
      }
  
      const confirmCardPayment = await stripe.confirmCardPayment(intentId,{
        payment_method: paymentMethod.id
      });

      if(confirmCardPayment.paymentIntent.status === "succeeded"){
        let paymentIntent = window.btoa(JSON.stringify(confirmCardPayment.paymentIntent));
        Order.postApi(
          "API_CREATE_ORDER",
          {
            buyer_id: buyer_id,
            seller_id:  orderDetail.productUserId,
            intent_id: confirmCardPayment.paymentIntent.id,
            payment_intent: paymentIntent,
            status: confirmCardPayment.paymentIntent.status,
            product_id: orderDetail.buy_productId,
            amount: orderDetail.productPriceCost,
            buyer_protuction_cost: orderDetail.buyerProtuctionCost,
            delivery_charge:orderDetail.deliveryCharge,
            total_amount:total_amount,
            shipping_address: shippingAddress
          },
          async (response, status) => {
            paymentHandler(status,response);
          },
          true
        );
      }else{
        paymentHandler("transaction_error",null);
      }
  };

  render() {
    const {stripe, orderDetail} = this.props;
    const CardElementOptions = {
      style:{
        base:{
          fontWeight: '600'
        }
      },
      hidePostalCode:true
    };
 
    return (
      <div>
      <div className="heading_modalBlock">Payment   </div>
      <form onSubmit={this.handleSubmit}>
        <div className="row px-4 py-2">
          <div className="col-12">
            <div className="form-group">
              <textarea
                name="shippingAddress"
                className="form-control-line "
                rows="3"
                onChange={(e) =>
                this.setState({
                  shippingAddress: e.target.value,
                })}
                value={this.state.shippingAddress}
                placeholder="Shipping address.."
              ></textarea>
              <p id="shipping-address" className="text-danger" style={{display:"none"}}>Shipping Address is required*</p>
            </div>
          </div>
          <div className="col-12">
            <div className="mb-1">
              <CardElement options={CardElementOptions}/>
            </div>
            <div className="sr_img">

            <img src="/images/img-1.png" alt="Image"/>
            </div>
          </div>
          <div className="col-12 pt-3 border-top">
            <div className="form-group">
              <button type="submit" className="btn themeBtn" disabled={(this.state.isLoading)}>
                Pay £{orderDetail.paymentAmount}
              </button>
            </div>
          </div>
        </div>
      </form>
   </div>
      
    );
  }
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const CheckoutForm = (props) => (
  <Elements stripe={stripePromise}>
    <ElementsConsumer>
      {({stripe, elements}) => (
        <StripePayment stripe={stripe} elements={elements} orderDetail={props.orderDetail} paymentHandler={props.paymentHandler} onLoading={props.onLoading}/>
      )}
    </ElementsConsumer>
  </Elements>
);

export default CheckoutForm;