import React, { Component } from "react";
import Faqs from "Models/Faqs";

class LearnToLivestreamComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      LearnsData: localStorage.getItem("LearnsData")
        ? JSON.parse(localStorage.getItem("LearnsData"))
        : [],
      allLearns: localStorage.getItem("allLearns")
        ? JSON.parse(localStorage.getItem("allLearns"))
        : [],
      selectedCategoryIndex: 2,
      singleSubCatData: null,
    };
  }

  componentDidMount = () => {
    var self = this;
    Faqs.getApi(
      "APIS_ALL_LEARN_LIST",
      async (response) => {
        localStorage.setItem("LearnsData", JSON.stringify(response));
        self.setState({ LearnsData: response });

        if (
          response &&
          response.length > 0 &&
          response[this.state.selectedCategoryIndex].subCatData.length > 0
        ) {
          this.setSingleSubCatData(response);
        }
      },
      false
    );

    Faqs.getApi(
      "APIS_ALL_LEARNS",
      async (response) => {
        localStorage.setItem("allLearns", JSON.stringify(response));
        self.setState({ allLearns: response });
      },
      false
    );
  };

  setSingleSubCatData = (response) => {
    this.setState({
      singleSubCatData:
        response[this.state.selectedCategoryIndex].subCatData[0],
    });
  };

  render() {
    const { LearnsData, singleSubCatData, selectedCategoryIndex } = this.state;
    return (
      <React.Fragment>
        <section className="learntop_sectwo bg-light-dark onlyforlearn_sectionpa">
          <div className="learnsel_faqtabperent">
            <div className="container">
              <ul className="nav nav-tabs learnsel_faqtab" role="tablist">
                {LearnsData &&
                  LearnsData.map((learnCat, index) => (
                    <li
                      className="nav-item"
                      key={`learnCatData_${learnCat._id}`}
                    >
                      <a
                        className={`nav-link ${
                          index === selectedCategoryIndex ? "active" : ""
                        }`}
                        data-toggle="tab"
                        role="tab"
                        // href="#basic_sec"
                        href={`#learnCat_${learnCat._id}`}
                        onClick={() => {
                          this.setState(
                            { selectedCategoryIndex: index },
                            () => {
                              if (
                                LearnsData &&
                                LearnsData.length > 0 &&
                                LearnsData[selectedCategoryIndex].subCatData
                                  .length > 0
                              ) {
                                this.setSingleSubCatData(LearnsData);
                              }
                            }
                          );
                        }}
                      >
                        <div className="learnlistbox">
                          <div className="learn_imgbx">
                            {/* <img src="/images/learnsell05.png" alt="Image" /> */}
                            <img
                              src={
                                `${process.env.REACT_APP_ApiBaseUrl}image/categories/resize/` +
                                learnCat.image_name
                              }
                              alt="Image"
                            />
                          </div>
                          <div className="learnbx_content">
                            <h2>{learnCat.category_name}</h2>
                            <div className="button_learn">
                              <span className="btnx btn-secondaryx">View</span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="tab-content mainperent_tabcontent">
              {LearnsData &&
                LearnsData.map((learnCat, index) => (
                  <div
                    className={`tab-pane ${
                      index === selectedCategoryIndex ? "active" : ""
                    }`}
                    id={`learnCat_${learnCat._id}`}
                    role="tabpanel"
                  >
                    <section className="learnmultipal_secns bg-light-dark">
                      <h2 className="sectionmainhead text-center">
                        <span>{learnCat.category_name}</span>
                      </h2>
                      <div className="container">
                        <div id="filter-toggler01" className="mobilebtn_togg">
                          All Menu of Basic
                        </div>
                        <div className="row specalfor_multirow">
                          <div className="col-sm-3">
                            <div className="product-sideBar-parent PsidebarP_01">
                              <div
                                className="product-listing-sideBar"
                                id="side-bar-fixed"
                              >
                                <div id="filter-closer">
                                  <img src="/images/cancel.svg" alt="icon" />
                                </div>
                                {learnCat.subCatData &&
                                  learnCat.subCatData.map((learnSubCat) => (
                                    <div className="product-listing-sideBar-colum sideBar-category">
                                      <h2 className="toggle-category">
                                        {learnSubCat.sub_category_name}
                                        <i className="fas fa-chevron-down"></i>
                                      </h2>

                                      <ul
                                        className="toggletab-bx  nav nav-tabs"
                                        role="tablist"
                                      >
                                        {learnSubCat.learnData &&
                                          learnSubCat.learnData.map(
                                            (learn, index) => (
                                              <li className="nav-item">
                                                <a
                                                  // className={`nav-link ${
                                                  //   index === 0 ? "active" : ""
                                                  // }`}
                                                  className="nav-link"
                                                  data-toggle="tab"
                                                  href={`#learnData_${learn._id}`}
                                                  onClick={() =>
                                                    this.setState({
                                                      singleSubCatData:
                                                        learnSubCat,
                                                    })
                                                  }
                                                >
                                                  {learn.title}
                                                </a>
                                              </li>
                                            )
                                          )}
                                      </ul>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-9">
                            <div className="tab-content">
                              {singleSubCatData &&
                                singleSubCatData.learnData &&
                                singleSubCatData.learnData.map(
                                  (learnData, index) => (
                                    <div
                                      id={`learnData_${learnData._id}`}
                                      className={`tab-pane ${
                                        index === 0 ? "active" : ""
                                      }`}
                                    >
                                      <div className="content_tabmulti_First">
                                        <div className="bxinner_multi">
                                          <div className="heading_multi">
                                            <h2 className="multilable">
                                              {learnData.title}{" "}
                                            </h2>
                                          </div>
                                          <div className="multi_regularcont">
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: learnData.content,
                                              }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                ))}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default LearnToLivestreamComponent;
