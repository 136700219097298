import React, { Component } from "react";
import ForgetPasswordForm from 'Forms/ForgetPasswordForm';

class ForgetPassword extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
        <section className="signin_Newpage contentBodyHeight bg-light-dark repeat-section-login-New">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="loginbx_main_New">
                    <div className="log_sign_inner_New">
                      <div className="headingsign_New">
                          <p>Forget Password</p>
                        </div>
                        <ForgetPasswordForm/>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

export default ForgetPassword;
