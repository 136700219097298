import Model from "Models/Model";

class User extends Model {
  dbSchema(type = "") {
    switch (type) {
      case "SIGNUP":
        return {
          email: "",
          username:"",
          password: "",
          firstName: "",
          lastName: "",
          location:"",
          place_id:"",
          latitude:"",
          longitude:"",
          showFacebookLogin: true,
          showTwitterLogin: true,
        };
        break;
      case "LOGIN":
        return {
          email: "",
          password: "",
        };
        break;
      case "FORGET_PASSWORD":
        return {
          email: "",
        };
        break;
      case "RESET_PASSWORD":
        return {
          code: "",
          password: "",
          confirmPassword: "",
        };
        break;
      case "QB_CONNECT":
        return {
          connection: false
        };
        break;
      default:
        return {
          _id: "",
          firstName: "",
          lastName: "",
          username:"",
          email: "",
          location:"",
          place_id:"",
          latitude:"",
          longitude:"",
          mobileNumber: "",
          password: "",
        };
    }
  }

  validationRules() {
    return {
      firstName: [
        { lib: "Custom", func: "NotEmpty", msg: "First Name is required" },
        /* { lib: "Validator", func: "isAlpha", msg: "Special correctors are not allowed." } */,
      ],
      lastName: [
        { lib: "Custom", func: "NotEmpty", msg: "Last Name is required" },
        /* { lib: "Validator", func: "isAlpha", msg: "Special correctors are not allowed." } */,
      ],
      email: [
        { lib: "Custom", func: "NotEmpty", msg: "Email is required" },
        {lib:"Validator", func:"isEmail", msg:"This is not a valid email address"},
      ],
      username: [
        { lib: "Custom", func: "NotEmpty", msg: "Username is required" }
      ],
      location: [
        { lib: "Custom", func: "NotEmpty", msg: "Location is required" }
      ],
      mobileNumber: [
        { lib: "Custom", func: "NotEmpty", msg: "Mobile Number is required" },
        {
          lib: "Validator",
          func: "isNumeric",
          msg: "Please enter valid phone number",
        },
        {
          lib: "Validator",
          func: "isLength",
          msg: "Mobile number must be 10 digit long",
          option: { min: 10, max: 10 },
        },
      ],
      password: [
        { lib: "Custom", func: "NotEmpty", msg: "Password is required" },
        {
          lib: "Validator",
          func: "isStrongPassword",
          msg: "Your password must be at least 8 characters, and contain at least one upper and lower case letter, one number and one special character.",
          option: {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          },
        },
      ],
      confirmPassword: [
        { lib: "Custom", func: "NotEmpty", msg: "Password is required" },
      ],
    };
  }

  setReduxStore(state = {}, action) {
    switch (action.type) {
      case "USER_AUTH":
        return action.text;
      default:
        return state;
    }
    console.log(state);
  }
}

export default new User();
