import Model from "Models/Model";

class CheckoutModel extends Model {
 
  validationRules() {
    return {
      firstName: [
        { lib: "Custom", func: "NotEmpty", msg: "First Name is required" },
      ],
      lastName: [
        { lib: "Custom", func: "NotEmpty", msg: "Last Name is required" },
      ],
      mobileNumber: [
        { lib: "Custom", func: "NotEmpty", msg: "Mobile Number is required" },
        {
          lib: "Validator",
          func: "isNumeric",
          msg: "Please enter valid phone number",
        },
        {
          lib: "Validator",
          func: "isLength",
          msg: "Mobile number must be 10 digit long",
          option: { min: 10, max: 10 },
        },
      ],
      streetAddress: [
        { lib: "Custom", func: "NotEmpty", msg: "Street address name is required" }
      ],
      country_id: [
        { lib: "Custom", func: "NotEmpty", msg: "Country is required" }
      ],
      region_id: [
        { lib: "Custom", func: "NotEmpty", msg: "Region is required" }
      ],
      city_id: [
        { lib: "Custom", func: "NotEmpty", msg: "City is required" }
      ],
      postcode: [
        { lib: "Custom", func: "NotEmpty", msg: "Postcode is required" },
        {
          lib: "Validator",
          func: "matches",
          msg: "Please enter valid uk postcode",
          option: { matches : "/^[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}$/i" },
        }
      ],
      /* streetAddressSecond: [
        { lib: "Custom", func: "NotEmpty", msg: "Street address2 is required" },
      ], */
    };
  }
}

export default new CheckoutModel();
