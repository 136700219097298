import React, { Component } from "react";
import Product from "Models/Product";
import General from "Helpers/General";
import Countdown from "react-countdown";
import SubCategory from "Models/SubCategory";
import SubCategoryOtherList from "Components/Products/SubCategoryOtherList";
import AuctionSubCategoryOtherList from "Components/Products/AuctionSubCategoryOtherList";
import MotorSubCategoryOtherList from "./MotorSubCategoryOtherList";
var qs = require("qs");

class ProductsBySubCatListComponent extends Component {
  constructor(props) {
    super(props);
    const path = this.props.location.pathname;
    const mainCategorySlug = path.split("/")[1];
    const subCategoryId = this.props.match.params.subCategoryId;
    const subCat = localStorage.getItem("PostsubCategories")
      ? JSON.parse(localStorage.getItem("PostsubCategories")).find(
        (cat) => cat.slug === subCategoryId
      )
      : "";
    const lat = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).lat;
    const lng = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).lng;
    const keyword = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).keyword;

    const pagefrom = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).page;

    const sctid = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).sctid;

    const ctid = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).ctid;

    const keytype = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).keytype;

    this.state = {
      mainCategorySlug: mainCategorySlug,
      subCategoryId: subCat ? subCat._id : "",
      categorySlug: subCat ? subCat.categoryId.slug : "",
      subCategorySlug: subCategoryId,
      singleSubCategoryData: subCat ? subCat : "",
      categoryId: subCat ? subCat.categoryId._id : "",
      lat: lat ? lat : "",
      lng: lng ? lng : "",
      keyword: keyword ? keyword : "",
      pagefrom: pagefrom ? pagefrom : "",
      sctid: sctid ? sctid : "",
      ctid: ctid ? ctid : "",
      keytype: keytype ? keytype : "",
    };

  }

  componentDidMount = () => {
    var self = this;
    // let subCategoryId = this.state.subCategoryId;
    let subCategorySlug = this.props.match.params.subCategoryId;
    SubCategory.postApi(
      "APIS_GET_SUB_CATEGORY_DETAIL_BY_SLUG",
      { params: { subCategorySlug } },
      async (response) => {
        // console.log(response);
        self.setState({ categorySlug: response.categoryId.slug });
        self.setState({ subCategoryId: response._id });
        self.setState({ singleSubCategoryData: response });
      },
      false
    );
  };

  render() {

    const { mainCategorySlug, subCategoryId, categorySlug, categoryId, lat, lng, keyword, pagefrom, sctid, ctid, keytype, subCategorySlug } =
      this.state;
    return (
      <React.Fragment>
        {subCategoryId && mainCategorySlug === "live-auctions" &&
          <AuctionSubCategoryOtherList
            subCategoryId={subCategoryId}
            categoryId={categoryId}
            categorySlug={categorySlug}
            lat={lat}
            lng={lng}
            keyword={keyword}
            pagefrom={pagefrom}
            sctid={sctid}
            ctid={ctid}
            keytype={keytype}
            subCategorySlug={subCategorySlug}
          />
        }
        {subCategoryId && mainCategorySlug != "live-auctions" &&
          <SubCategoryOtherList
            subCategoryId={subCategoryId}
            categoryId={categoryId}
            categorySlug={categorySlug}
            lat={lat}
            lng={lng}
            keyword={keyword}
            pagefrom={pagefrom}
            sctid={sctid}
            ctid={ctid}
            keytype={keytype}
            subCategorySlug={subCategorySlug}
          />
        }
      </React.Fragment>
    );
  }
}

export default ProductsBySubCatListComponent;
