import Model from "Models/Model";

class LiveStreamingProductItem extends Model{

	dbSchema(){
	    return {
	      _id:"", buyer_id:"", userId:"", status:""
	    };
	}
}

export default new LiveStreamingProductItem();