import Model from "Models/Model";

class Settings extends Model{

	dbSchema(){
	    return {
	      _id:""
	    };
	}
}

export default new Settings();
