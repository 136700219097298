import { StoreReducer } from "Helpers/StoreReducer";
import Lodash from "lodash";
import Countdown from "react-countdown";
import numeral from "numeral";
import moment, { updateLocale } from "moment";

class General {
  dispatchReduxStore(type, data) {
    StoreReducer.dispatch({
      type: type,
      text: data,
    });
  }

  getReduxStore(action) {
    return StoreReducer.getState()[action];
  }

  showImage(folderName, fileName) {
    return "https://dlevelcdn.b-cdn.net/image/" + folderName + "/" + fileName;
  }

  showImageNew(folderName, fileName) {
    return (
      "https://dlevelcdn.b-cdn.net/upload/" + folderName + "/resize/" + fileName
      //  "http://localhost:6010/upload/" + folderName + "/resize/" + fileName
    );
  }

  getCurrency() {
    return "£";
  }

  showPrice(price) {
    return this.getCurrency() + " " + numeral(price).format("0,0");
  }

  showPriceDecimal(price) {
    return this.getCurrency() + " " + price;
    // return this.getCurrency() + " " + numeral(price).format("0,0.00");
  }
  showPriceOneDecimal(price) {
    return this.getCurrency() + " " + price;
  }

  showPercentDecimal(price) {
    return numeral(price).format("0,0.00") + " %";
  }

  showTimeToGo(time) {
    return <Countdown date={Date.now() + 60 * 60 * 24 * 3200} />;
  }

  showRatingCount(reviews, number) {
    if (reviews) {
      const allReviews = reviews.filter((review) => review.rating === number);
      return allReviews.length;
    } else {
      return 0;
    }
  }

  showRatingPercentage(reviews, number) {
    if (reviews && reviews.length > 0) {
      return Math.round(
        (this.showRatingCount(reviews, number) * 100) / reviews.length
      );
    } else {
      return 0;
    }
  }

  debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }


  showWeekendDeals() {
    const sunday = moment().weekday(0);
    const friday = moment().weekday(5);
    const saturday = moment().weekday(6);
    if (
      moment().isSame(sunday) ||
      moment().isSame(friday) ||
      moment().isSame(saturday)
    ) {
      return true;
    } else {
      return false;
    }
  }
}

export default new General();
