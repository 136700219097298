import React, { Component } from "react";
import OtherList from "Components/Products/OtherList";
import AuctionList from "Components/Products/AuctionList";
var qs = require("qs");

class ProductsByCatListComponent extends Component {
  constructor(props) {
    super(props);
    const categoryslug = this.props.match.params.categoryslug;

    const lat = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).lat;
    const lng = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).lng;
    const keyword = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).keyword;

    this.state = {
      categoryslug: categoryslug,
      lat: lat ? lat : "",
      lng: lng ? lng : "",
      keyword: keyword ? keyword : "",
    };
  }

  render() {
    const { categoryslug, lat, lng, keyword } = this.state;
  
    return (
      <React.Fragment>
        {(categoryslug === "live-auction") ?
        <AuctionList
          categoryslug={categoryslug}
          lat={lat}
          lng={lng}
          keyword={keyword}
        />
        :
        <OtherList
          categoryslug={categoryslug}
          lat={lat}
          lng={lng}
          keyword={keyword}
        />
        }
      </React.Fragment>
    );
  }
}

export default ProductsByCatListComponent;
