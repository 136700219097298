import React, { Component } from "react";
import {CardElement, Elements, ElementsConsumer, Billingu} from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";
import { toBePartiallyChecked } from "@testing-library/jest-dom/dist/matchers";
import Order from "Models/Order";
import Modal from "react-modal";

class OrderCheckout extends Component {

  constructor(props) {
      super(props);
      this.state={
          isLoading:false
      }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const {stripe, elements, parentState, paymentHandler, onLoading} = this.props;

    if (elements == null) {
      return;
    }

    this.setState({isLoading:true});
    onLoading(true);

    let shippingAddress = parentState.postageDetail;
    let buyer_id = Order.getAuthUserId();
    let transaction_type = parentState.transaction_type;
    let total_amount = parentState.paymentAmount;

    let intentId;
    await Order.postApiLoader(
        "API_STRIPE_INTENT",
        {
          user_id: buyer_id,
          transaction_type:transaction_type,
          total_amount:total_amount
        },
        async (response,status) => {
          if (status == "success") {
            intentId = response; 
          }
        },
        true
      );

      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
      });

      if(error){
          this.setState({isLoading:false});
          onLoading(false);
          return;
      }
  
      const confirmCardPayment = await stripe.confirmCardPayment(intentId,{
        payment_method: paymentMethod.id
      });

      if(confirmCardPayment.paymentIntent.status === "succeeded"){
        let paymentIntent = window.btoa(JSON.stringify(confirmCardPayment.paymentIntent));
        Order.postApiLoader(
          "API_CREATE_ORDER",
          {
            buyer_id: buyer_id,
            seller_id:  parentState.productUserId,
            intent_id: confirmCardPayment.paymentIntent.id,
            payment_intent: paymentIntent,
            status: confirmCardPayment.paymentIntent.status,
            product_id: parentState.buy_productId,
            amount: parentState.productPriceCost,
            buyer_protuction_cost: parentState.buyerProtuctionCost,
            delivery_charge:parentState.deliveryCharge,
            admin_cost : parentState.adminCost,
            total_amount:total_amount,
            shipping_address: shippingAddress,
            delivery:parentState.delivery,
          },
          async (response, status) => {
            paymentHandler(status,response);
          },
          true
        );
      }else{
        paymentHandler("transaction_error",null);
      }
  };

  render() {
    const {stripe, parentState} = this.props;
    const CardElementOptions = {
      style:{
        base:{
          fontWeight: '600'
        }
      },
      hidePostalCode:true
    };
 
    return (
      <div>
        <div className="heading_modalBlock">Payment   </div>
        <form onSubmit={this.handleSubmit}>
            <div className="row px-4 py-2">
            <div className="col-12">
                <div className="mb-1">
                <CardElement options={CardElementOptions}/>
                </div>
                <div className="sr_img">

                <img src="/images/img-1.png" alt="Image"/>
                </div>
            </div>
            <div className="col-12 pt-3 border-top">
                <div className="form-group">
                <button type="submit" className="btn themeBtn" disabled={(this.state.isLoading)}>
                    Pay £{parentState.paymentAmount}
                </button>
                </div>
            </div>
            </div>
        </form>
    </div>
    );
  }
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
};

const CheckoutOrderForm = (props) => (
    <Modal
        isOpen={props.parentState['orderPaymentModel']}
        onRequestClose={() =>
            props.onClose()
        }
        style={customStyles}
        ariaHideApp={false}
        className="shipModal stripePaymentModal"
    >
        <button onClick={() => props.onClose() } className="shipCloseBtn">
            <i className="fa fa-times"></i>
        </button>
        <Elements stripe={stripePromise}>
            <ElementsConsumer>
            {({stripe, elements}) => (
                <OrderCheckout stripe={stripe} elements={elements} parentState={props.parentState} paymentHandler={props.paymentHandler} onLoading={props.onLoading}/>
            )}
            </ElementsConsumer>
        </Elements>
    </Modal>
);

export default CheckoutOrderForm;