import React, { Component } from "react";
import Faqs from "Models/Faqs";
import SlideToggle from "react-slide-toggle";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

class FaqsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      FaqsData: localStorage.getItem('FaqsData')?JSON.parse(localStorage.getItem('FaqsData')):[],
      allFaqs: localStorage.getItem('allFaqs')?JSON.parse(localStorage.getItem('allFaqs')):[],
    }
  }

  componentWillMount = () => {
      var self = this;
      Faqs.getApi("APIS_ALL_FAQ_LIST", async(response)=>{
        localStorage.setItem('FaqsData', JSON.stringify(response));
        self.setState({FaqsData:response});
      }, false);

      Faqs.getApi("APIS_ALL_FAQS", async(response)=>{
        localStorage.setItem('allFaqs', JSON.stringify(response));
        self.setState({allFaqs:response});
      }, false);
  }


  render() {
    const { allFaqs } = this.state;
    return (
      <React.Fragment>        
        <section className="learntop_sectwo bg-light-dark">
          <div className="learnsel_faqtabperent">
            <div className="container"> 
              <ul className="nav nav-tabs learnsel_faqtab" role="tablist">
                  {
                    this.state.FaqsData.map(function(row, index){
                    return <li className="nav-item" key={"FaqsData"+row._id}>
                      <a className={index == 0 ? 'nav-link active' : 'nav-link'} data-toggle="tab" role="tab" href={"#faqCat-"+index}>
                        <div className="learnlistbox">
                          <div className="learn_imgbx">
                            {/* <img src="/images/learnsell01.png" alt="Image"/> */}
                            <img src={`${process.env.REACT_APP_ApiBaseUrl}image/categories/resize/` + row.image_name} alt="Image"/>
                          </div>
                          <div className="learnbx_content"> 
                            <h2>{row.category_name}</h2>
                            <div className="button_learn">
                              <span className="btnx btn-secondaryx">View</span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                    })
                  }
              </ul>
            </div>
              <div className="tab-content mainperent_tabcontent">
                {
                  this.state.FaqsData.map(function(row, index){
                    return <div className={index == 0 ? 'tab-pane active' : 'tab-pane'} id={"faqCat-"+index} role="tabpanel">
                        <section class="learnmultipal_secns bg-light-dark">
                            <h2 class="sectionmainhead text-center"><span>{row.category_name}</span></h2>
                            <div class="container"> 
                            <div id="filter-toggler01" class="mobilebtn_togg">All Menu of {row.category_name}</div>
                              <div class="row specalfor_multirow">
                                <div class="col-sm-3">
                                  <div class="product-sideBar-parent PsidebarP_01">
                                    <div class="transparent-layer"></div>
                                    <div class="product-listing-sideBar" id="side-bar-fixed">
                                      <div id="filter-closer"><img src="/images/cancel.svg" alt="icon" /></div>
                                      {
                                        row.subCatData.map(function(rowsub, indexsub){
                                          return <div class="product-listing-sideBar-colum sideBar-category" key={"row.subCatData"+rowsub._id}>
                                              <Accordion>
                                                <AccordionSummary
                                                  expandIcon={<ExpandMoreIcon />}
                                                  aria-controls="panel1a-content"
                                                  id="panel1a-header"
                                                >{rowsub.sub_category_name}
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                  <ul class="toggletab-bx nav nav-tabs" role="tablist">
                                                    {
                                                      rowsub.faqData.map(function(rowfaq, indexfaq){
                                                      return <li class="nav-item">
                                                          <a className={indexfaq == 0 ? 'nav-link active' : 'nav-link'} data-toggle="tab" href={"#premium01-"+rowfaq._id}>
                                                            <strong>{rowfaq.title}</strong> 
                                                          </a>
                                                        </li>
                                                      })
                                                    }                                                    
                                                  </ul>
                                                </AccordionDetails>
                                              </Accordion>
                                            </div>
                                          })
                                      }
                                    </div>
                                  </div>
                                </div>
                                <div class="col-sm-9">
                                  <div class="tab-content">
                                  {
                                        row.subCatData.map(function(rowsub, indexsub){
                                          return <div class="product-listing-sideBar-colum sideBar-category" key={"row.subCatData"+rowsub._id}>
                                            <div class="content_tabmulti_First">
                                              <div class="bxinner_multi">
                                                <div class="heading_multi">
                                                {/* {console.log("rowfaqd.title", rowfaqd.title)} */}
                                                {rowsub.faqData.map(item=>{
                                                  {console.log("item",item)}
                                                  return <h2 class="multilable">{item.title}</h2>;
                                                })}
                                                 
                                                  {console.log("allfaqs", allFaqs)}
                                                </div>
                                                <div class="multi_regularcont">                                                  
                                                  {/* <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: rowfaqd.content
                                                    }}></div> */}
                                                </div>
                                              </div>
                                            </div>
                                            
                                            </div>
                                          })
                                      }
                                    {/* {
                                      allFaqs.length > 0 && <>
                                      {
                                        allFaqs.map(function(rowfaqd, indexfaqd){
                                        return <div id={"premium01-"+rowfaqd._id} className={indexfaqd == 0 ? 'tab-pane active' : 'tab-pane'}>
                                            <div class="content_tabmulti_First">
                                              <div class="bxinner_multi">
                                                <div class="heading_multi">
                                                  <h2 class="multilable">{rowfaqd.title}</h2>
                                                </div>
                                                <div class="multi_regularcont">                                                  
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: rowfaqd.content
                                                    }}></div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        })
                                      }
                                      </>
                                    }    
                                                                           */}
                                  </div>
                                </div>
                              </div>
                            </div>
                        </section>
                    </div>
                    })
                  }
              </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default FaqsComponent;