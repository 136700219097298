import { ThreeSixty } from "@material-ui/icons";
import React from "react";
import Moment from 'moment';
import General from "Helpers/General";
import Zoom from "react-medium-image-zoom";
export class Message extends React.Component {
  state = {
    imageExtension: "",
    fileExtension: "",
  };

componentDidMount (){
    if( this.props.mediaLinks){
      var fileName =  this.props.mediaLinks;
      var fileExtension = fileName.split('.').pop(); //"pdf"
      if(fileExtension === "pdf" || fileExtension === "txt" || fileExtension === "doc" || fileExtension === "csv" ){
        this.setState({ fileExtension:fileName});
      }else{
        this.setState({ imageExtension:fileName});
      }
    }

  }

  render() {
    const senderNamew = localStorage.getItem("AuthIdentity");
    const base64Url = senderNamew.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    const UserId =JSON.parse(window.atob(base64));
  
    return (
      <div className="msg-list">
        <div class="time_ofchat">
        {this.props.createdAt?Moment(this.props.createdAt).format("MMM Do, h:mm:ss a"):""}
		 								</div>
        {
          (this.props.fromUserId===UserId.response._id  || this.props.fromUserId===this.props.selectedchannel ) ?
        <div className="sent_sidemain_block">
         
        <div className="myside_chat">
              {/* <div className="chatboc_imgg">
          <img
                src={
                  this.props.profilePicture
                    ? General.showImageNew("profile", this.props.profilePicture)
                    : "/images/muser.jpg"
                }
                alt="Image"
              />   </div>
             */}
         
            {/* <div className="chat_name">
                <b>{this.props.fromName}nilesh</b>
            </div> */}
              <div className="chat_textdesc">
                <span className="chat_hello">
                
                  <span>{this.props.message}</span>
                  {this.state.imageExtension?<span className="attech-image" style={{"display":"block","width":"80px","height":"80px"}}>
                  <Zoom><img src={process.env.REACT_APP_SERVER +`/getImage/${this.state.imageExtension}`}/></Zoom>
                  </span>:""}
                  {this.state.fileExtension? <span><a href={process.env.REACT_APP_SERVER +`/getImage/${this.state.fileExtension}`} download>Click to download <span>{this.state.fileExtension}</span></a></span>:''}
                </span>
             </div>
             </div>
      
          {/* <div className="chatu_time">
            <p>{this.props.createdAt?Moment(this.props.createdAt).format("HH:mm"):""}</p>
          </div> */}
        </div>
          :
            (this.props.toUserId===this.props.selectedchannel || this.props.toUserId===UserId.response._id ) ? 
            <div className="sent_sidemain_block">
         <div className="sent_sidemain" >
          <div className="chatboc_imgg">
          <img
                src={
                  this.props.ToprofilePicture
                    ? General.showImageNew("profile", this.props.ToprofilePicture)
                    : "/images/muser.jpg"
                }
                alt="Image"
              />   
              </div>

              {/* <div className="chat_name">
                <b>{this.props.toName}shilpa</b>
              </div> */}
          <div className="chat_textdesc" id={this.props.receiverId}>
            <span className="chat_hello">
       
              <span>{this.props.message}</span>
            </span>
          </div>     
             
                 </div>
          {/* <div className="chatu_time">
            <p>{this.props.createdAt?Moment(this.props.createdAt).format("HH:mm"):""}</p>
          </div> */}
        </div>
            :
            <div className="no-content-message">There is no messages to show</div>

        }
      </div>
      
    );
  }
}
