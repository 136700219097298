import Model from "Models/Model";

class Product extends Model{

	dbSchema(){
	    return {
	      _id:"", firstName:"", lastName:"", email:"", mobileNumber:"", password:""
	    };
	}
}

export default new Product();
